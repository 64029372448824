export function takeOnly<T = Record<string, unknown>>(
  allFilters: T,
  ...keys: (keyof T)[]
): Partial<T> {
  const obj: Partial<T> = {}
  for (const key of keys) {
    obj[key] = allFilters[key]
  }
  return obj
}

export function keys<T extends Record<string, unknown>>(
  obj: T
): Extract<keyof T, string>[] {
  return Object.keys(obj) as any
}
