import { FC } from 'react'

import { FilterTag } from '@tk/frontend/primitives'

type BasicFilterTagProps = {
  fieldLabel: string
  valueLabel: string
  onDelete?: () => void
}

export const BasicFilterTag: FC<BasicFilterTagProps> = ({
  fieldLabel,
  valueLabel,
  onDelete,
}) => {
  return (
    <FilterTag label={fieldLabel} onDelete={onDelete}>
      {valueLabel}
    </FilterTag>
  )
}
