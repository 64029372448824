import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { UpsertForm } from './UpsertForm'

type Model =
  | {
      type: 'create'
      categoryId?: number
    }
  | { type: 'update'; updateId: number; categoryId?: number }

export const UpsertModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide} maxWidth={false}>
      <DialogTitle>
        {model.type === 'create' ? 'New Package' : 'Update Package'}
      </DialogTitle>

      <DialogContent>
        <UpsertForm
          groupId={'updateId' in model ? model.updateId : undefined}
          categoryId={model.categoryId}
          onFinished={modal.hide}
          showFilterSections={model.type === 'update'}
        />
      </DialogContent>
    </Dialog>
  )
})
