import { GridFilterInputValueProps } from '@mui/x-data-grid-premium'

import { trpc } from '@tk/frontend/api'
import {
  ServersideGridFilter,
  ServersideGridFilterOption,
} from '@tk/frontend/primitives/datagrid/columns'

export function GridFilterRecordChain(props: GridFilterInputValueProps) {
  return (
    <ServersideGridFilter
      {...props}
      useFilteredList={useFilteredList}
      useSelectedOption={useSelectedOption}
    />
  )
}

function useFilteredList(filter: string | undefined) {
  return trpc.records.list.useQuery(
    {
      type: ['CHAIN'],
      filter: '%' + filter + '%',
      size: 30,
    },
    {
      select(data): ServersideGridFilterOption[] {
        return data.content?.map((option) => {
          return {
            label: option.name,
            value: option.id,
          }
        })
      },
    }
  )
}

function useSelectedOption(selectedIds: number[] | undefined) {
  const result = trpc.records.getRecords.useQuery(
    {
      ids: selectedIds ?? [],
    },
    {
      enabled: !!selectedIds,
      select(data): ServersideGridFilterOption[] {
        return data
      },
    }
  )

  return result.data ?? []
}
