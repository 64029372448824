import React, { Children, ReactNode } from 'react'

import { Box, Stack, Typography } from '@mui/material'

import { InfoPopover } from '@tk/frontend/primitives'
import { PaperCell } from '@tk/frontend/primitives/layout'

export type SectionTitleRowProps = {
  title: string
  titleInfo?: ReactNode

  children?: ReactNode
}

export function SectionTitleRow({
  title,
  titleInfo,
  children,
}: SectionTitleRowProps) {
  return (
    <Stack paddingLeft="1rem" direction="row" alignItems="center" height="3rem">
      <InfoPopover content={titleInfo}>
        <Typography variant="h3" margin="0" color="whitesmoke">
          {title}
        </Typography>
      </InfoPopover>

      <Box flex="1 1 auto" />

      {Children.map(children, (child, i) => {
        if (!child) {
          return null
        }

        return <PaperCell key={i}>{child}</PaperCell>
      })}
    </Stack>
  )
}
