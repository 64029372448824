import { GridRenderEditCellParams } from '@mui/x-data-grid-premium'

import { Outputs, trpc } from '@tk/frontend/api'
import { SelectorCell } from '@tk/frontend/primitives/datagrid/edit-cells'

import { transformUiListToSelectorCellOptions } from './types'

type Ruleset = Outputs['bloomberg']['ruleset']['get']

export type RulesetEditCellProps = {
  params: GridRenderEditCellParams<Ruleset>
}

export function RulesetEditCell(props: RulesetEditCellProps) {
  const rulesetsQuery = trpc.bloomberg.ruleset.list.useQuery(undefined, {
    select: (data) => transformUiListToSelectorCellOptions(data),
  })

  return (
    <SelectorCell<Ruleset>
      params={props.params}
      value={props.params.value}
      loading={rulesetsQuery.isPending}
      options={rulesetsQuery.data ?? []}
    />
  )
}
