import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'

import { Colours } from '@lib/theme'

export const theme = createTheme({
  spacing: 4,
  typography: {
    fontSize: 12,
    h1: {
      fontSize: '1.6rem',
    },
    h2: {
      fontSize: '1.4rem',
    },
    h3: {
      fontSize: '1.2rem',
    },
    h4: {
      fontSize: '1.1rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.8rem',
    },
  },
  palette: {
    primary: {
      dark: Colours.Brand.BlueDark2,
      main: Colours.Brand.BlueMid2,
      light: Colours.Brand.BlueLight2,
      contrastText: Colours.BgShade.Light,
    },
    secondary: {
      dark: Colours.FgShade.Dark,
      main: Colours.FgShade.Darkish,
      light: Colours.FgShade.Light,
    },
    warning: {
      dark: Colours.Brand.RedDark,
      main: Colours.Brand.RedMid,
      light: Colours.Brand.RedLight,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.25rem 0.5rem 0.1rem 0.5rem',
        },
        iconSizeSmall: {
          marginTop: '-0.1rem',
        },
        iconSizeMedium: {
          marginTop: '-0.15rem',
        },
        iconSizeLarge: {
          marginTop: '-0.2rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: '0.5rem 0.5rem',
          backgroundColor: Colours.BgShade.Mid,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: Colours.BgShade.Light,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: '1rem',
      },
    },
    MuiTableCell: {
      defaultProps: {
        sx: {
          padding: '0.5rem 0.25rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
    },
  },
})

export function Theme({ children }: any) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {children}
    </ThemeProvider>
  )
}
