import React from 'react'

import * as Icons from '@mui/icons-material'

import { generateRecordEditUri } from '@tk/frontend/app/Records/RecordsRouter.routes'
import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'

export type EditRecordButtonProps = {
  selectedId: null | number | string
}

export function EditRecordButton({ selectedId }: EditRecordButtonProps) {
  const isEditMode = useEntitlement('records.manage')

  return (
    <ButtonLink
      to={{
        pathname: selectedId
          ? generateRecordEditUri(String(selectedId))
          : undefined,
      }}
      variant="contained"
      disabled={!selectedId}
      startIcon={isEditMode ? <Icons.Edit /> : <Icons.Preview />}
      color="primary"
    >
      {isEditMode ? 'Edit' : 'View'}
    </ButtonLink>
  )
}
