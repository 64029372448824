export function labelify(
  name: string | undefined,
  description: string | undefined
) {
  const _name = (name ?? description ?? '').trim()
  const _description = (description ?? '').trim()

  if (_name === _description) {
    return _name
  } else {
    return `${name} ${description ? `(${description})` : ''}`.trim()
  }
}
