import type { Roles } from '@tk/api'

import { trpc } from '@tk/frontend/api'

export const useEntitlement = (
  entitlement: Roles.Entitlement | Roles.Entitlement[] | undefined
): boolean => {
  const [roleQuery] = trpc.admin.userRoles.me.useSuspenseQuery(undefined, {
    gcTime: Infinity,
    staleTime: Infinity,
  })

  if (entitlement === undefined) {
    // No entitlement needed
    return true
  }

  const requiredEntitlement =
    typeof entitlement === 'string' ? [entitlement] : entitlement

  return requiredEntitlement.some((item) =>
    roleQuery.entitlements.includes(item)
  )
}
