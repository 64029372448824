import { useMemo } from 'react'
import { To } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'

import { LinkedRecordsDto } from '@lib/pgs'
import { RecordAssetTypeSelect } from '@tk/frontend/app/Records/common/crud/RecordAssetTypeSelect'
import {
  generateRecordEditUri,
  generateRecordListUri,
} from '@tk/frontend/app/Records/RecordsRouter.routes'
import { ButtonLink, getFormComponents } from '@tk/frontend/primitives'

export type LinkedDataSectionProps = {
  records: LinkedRecordsDto[]
}

const Form = getFormComponents<never>()

export function LinkedDataSection({ records }: LinkedDataSectionProps) {
  const editUris = useMemo(
    () => records.map((r) => generateRecordEditUri(String(r.id))),
    [records]
  )
  const listUris = useMemo(
    () =>
      records.map<To>((record) =>
        generateRecordListUri({
          filter: record.name,
        })
      ),
    [records]
  )

  return (
    <Form.Section title="Linked Data">
      <RecordAssetTypeSelect />

      <Box display="flex" flexDirection="column" alignItems="center">
        {records.length === 0 && <strong>No linked records!</strong>}

        {records.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableBody>
                {records.map((r, i) => (
                  <TableRow key={r.id}>
                    <TableCell>
                      <strong>{r.name}</strong>

                      <div>{r.description}</div>
                    </TableCell>

                    <TableCell>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                      >
                        <ButtonLink
                          to={{
                            pathname: editUris[i],
                          }}
                          target="_blank"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          endIcon={<Icons.OpenInNew />}
                        >
                          Edit
                        </ButtonLink>

                        <ButtonLink
                          to={listUris[i]}
                          target="_blank"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          endIcon={<Icons.OpenInNew />}
                        >
                          List
                        </ButtonLink>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Form.Section>
  )
}
