import { useMemo } from 'react'

import {
  DelimitedArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

const Param = withDefault(DelimitedArrayParam, [] as string[])

export function useRowSelectionModel(queryParamName = 'selected') {
  const [_model, setModel] = useQueryParam(queryParamName, Param, {
    updateType: 'replaceIn',
  })

  const model = useMemo(() => {
    return _model.map((textOrNull) => {
      const number = parseInt(textOrNull!)

      if (isNaN(number)) {
        return textOrNull
      } else {
        return number
      }
    })
  }, [_model])

  return {
    selectedId: !!model && model.length > 0 ? model[0] : null,
    model: model as (string | number)[],
    setModel: setModel as (val: (number | string)[]) => void,
  }
}
