import { useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import { useSimpleImportContext } from './SimpleImportContext'

export function usePreviewUrl(taskId: number): string {
  const { previewUriTemplate } = useSimpleImportContext()

  return useMemo(() => {
    return generatePath(previewUriTemplate, {
      taskId: String(taskId),
    })
  }, [previewUriTemplate, taskId])
}
