import { RecordPreview, RecordRelinkPreviewDto } from '@lib/pgs'
import * as gz from '@lib/pgs-types'

export { RecordRelinkPreviewDto }
export type PreviewDto = gz.ImportPreview<RecordRelinkPreviewDto>

export type ActionType = RecordPreview['action']
export type Color = 'red' | 'grey' | 'green'

export const actionColors: Record<ActionType, Color> = {
  UNLINKED: 'red',
  ERROR: 'red',
  UNMOVED: 'grey',
  LINKED: 'green',
}
