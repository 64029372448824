import { Backdrop, Paper, Stack, Typography } from '@mui/material'

import { Colours } from '@lib/theme'
import { ButtonLink } from '@tk/frontend/primitives'

interface PreviewNotFoundProps {
  label: string
  path: string
}

export function PreviewNotFound({ label, path }: PreviewNotFoundProps) {
  return (
    <Backdrop
      open
      sx={{ backgroundColor: Colours.BgShade.Blue, paddingLeft: '12rem' }}
    >
      <Paper>
        <Stack alignItems="center" margin="10rem">
          <Typography variant="h1">Import preview has expired</Typography>

          <Typography variant="body1">Please import again</Typography>

          <ButtonLink to={path}>Go to {label}</ButtonLink>
        </Stack>
      </Paper>
    </Backdrop>
  )
}
