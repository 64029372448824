import { useLayoutEffect, useRef } from 'react'
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import {
  LocalizationProvider,
  TimePicker as MuiTimePicker,
} from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { DateTime } from 'luxon'

interface DateRangeProps<FormValues extends FieldValues = FieldValues> {
  name?: Path<FormValues>
  label?: string
  rules?: UseControllerProps<FormValues>['rules']
  autoFocus?: boolean
  disabled?: boolean
  minTime?: DateTime | null
  maxTime?: DateTime | null
}

export function TimePicker<FormValues extends FieldValues = FieldValues>({
  name,
  autoFocus,
  label,
  rules,
  disabled = false,
  minTime,
  maxTime,
}: Readonly<DateRangeProps>) {
  const { field, fieldState } = useController<FormValues>({
    name: name ?? ('' as any),
    rules,
    shouldUnregister: true,
  })

  const errorMessage = fieldState.error?.message

  const ref = useRef<any>()
  const focus = useRef(autoFocus)

  useLayoutEffect(() => {
    if (focus.current) {
      ref.current?.focus?.()
    }
  }, [])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <MuiTimePicker
          {...field}
          disabled={disabled}
          label={label}
          maxTime={maxTime || undefined}
          minTime={minTime || undefined}
          timezone="utc"
          ampm={false}
          slotProps={{
            actionBar: {
              actions: ['clear', 'accept'],
            },
            textField: {
              size: 'small',
              error: !!errorMessage,
              helperText: errorMessage,
            },
          }}
        />
      </LocalizationProvider>

      {!!errorMessage}
    </>
  )
}
