import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'

import { trpc } from '@tk/frontend/api'
import {
  RecordEditDto,
  RecordEditFieldsCore,
  RecordEditFieldsLinked,
} from '@tk/frontend/app/Records/common/crud'
import { RecordAssetTypeSelect } from '@tk/frontend/app/Records/common/crud/RecordAssetTypeSelect'
import { useRecordEditTelemetry } from '@tk/frontend/app/Records/Edit/useRecordEditTelemetry'
import { generateRecordEditUri } from '@tk/frontend/app/Records/RecordsRouter.routes'
import {
  getFormComponents,
  usePromiseNotification,
  useTitle,
} from '@tk/frontend/primitives'
import { CustomSnackbar } from '@tk/frontend/primitives/CustomSnackbar'

const Form = getFormComponents<RecordEditDto>()

export function RecordCreatePage() {
  useTitle('New Record')
  const snackbar = useSnackbar()
  const promiseNotification = usePromiseNotification()
  const navigate = useNavigate()

  const defaultValues: Partial<RecordEditDto> = {
    status: 'DO_NOT_COLLECT',
    type: 'ITEM',
  }

  const form = Form.useForm({
    defaultValues: defaultValues,
  })
  const { formState } = form

  const { trackSaved, trackValidationFailed } = useRecordEditTelemetry(
    'create',
    defaultValues
  )
  const recordMutation = trpc.records.create.useMutation({
    onSuccess(record) {
      navigate({
        pathname: generateRecordEditUri(record.id),
      })
    },
  })

  const handleSubmit = useMemo(
    () =>
      form.handleSubmit(
        async (values) => {
          const record = await promiseNotification(
            recordMutation.mutateAsync(values),
            {
              successMessage: 'Created',
              progressMessage: 'Creating...',
              failureMessage: 'Creation failed',
            }
          )

          trackSaved(record, formState.dirtyFields)
        },
        (err) => {
          console.error(err)

          trackValidationFailed(formState.dirtyFields, err)

          snackbar.enqueueSnackbar(
            <Stack>
              <div>Validation failed. Please see your form for errors</div>
            </Stack>,
            {
              content: (key, message) => (
                <CustomSnackbar key={key} message={message} variant="error" />
              ),
            }
          )
        }
      ),
    [
      form,
      formState.dirtyFields,
      promiseNotification,
      recordMutation,
      snackbar,
      trackSaved,
      trackValidationFailed,
    ]
  )

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={handleSubmit}>
        <Form.SectionTitleRow title="Record Data">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={recordMutation.isPending || recordMutation.isSuccess}
          >
            Create
          </Button>
        </Form.SectionTitleRow>

        <Form.SectionBodyGrid>
          <input {...form.register('id')} hidden />

          <RecordEditFieldsCore mode="CREATE" />

          <Form.Section title="Linked Data">
            <RecordAssetTypeSelect />
          </Form.Section>
        </Form.SectionBodyGrid>

        <Form.SectionBodyGrid>
          <RecordEditFieldsLinked />
        </Form.SectionBodyGrid>
      </Form.FormStack>
    </Form.Provider>
  )
}
