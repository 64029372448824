import { trpc } from '@tk/frontend/api'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import { ExportButton } from '@tk/frontend/primitives/reference-export'

import { CurrencyTable } from './CurrencyTable'

export function CurrencyCrud() {
  const exportMutation = trpc.reference.currency.export.useMutation()

  return (
    <PageContentLayout
      title="Currencies"
      controls={
        <>
          <ExportButton handleExport={exportMutation.mutateAsync} />
        </>
      }
    >
      <CurrencyCrudTable />
    </PageContentLayout>
  )
}

function CurrencyCrudTable() {
  const [list] = trpc.reference.currency.list.useSuspenseQuery()

  return <CurrencyTable list={list} />
}
