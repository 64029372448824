import { useEffect } from 'react'

import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium'
import { NumberParam, useQueryParam } from 'use-query-params'

import { useFilterModel } from '@tk/frontend/primitives/datagrid'
import { MultiSelectFilterId } from '@tk/frontend/primitives/datagrid/columns'

import { FilterKey } from './filters-config'

export function useNavigationParamsRedirect() {
  const { setModel } = useFilterModel()

  const [initialPricingSourceIds, setInitialPricingSourceIds] = useQueryParam(
    'initial-pricing-source-ids',
    NumberParam
  )

  const [initialTickerIds, setInitialTickerIds] = useQueryParam(
    'initial-ticker-ids',
    NumberParam
  )

  const [initialEntitlementIds, setInitialEntitlementIds] = useQueryParam(
    'initial-entitlement-ids',
    NumberParam
  )

  const [initialProcessIds, setInitialProcessIds] = useQueryParam(
    'initial-process-ids',
    NumberParam
  )

  const [initialRulesetIds, setInitialRulesetIds] = useQueryParam(
    'initial-ruleset-ids',
    NumberParam
  )

  useEffect(() => {
    if (initialPricingSourceIds && initialPricingSourceIds > 0) {
      setInitialPricingSourceIds(undefined, 'replaceIn')

      const defaultPricingSourceFilter: GridFilterItem = {
        id: -1,
        field: 'pricingSource' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialPricingSourceIds],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultPricingSourceFilter.field
          ),
          defaultPricingSourceFilter,
        ]

        return model
      })
    }

    if (initialTickerIds && initialTickerIds > 0) {
      setInitialTickerIds(undefined, 'replaceIn')

      const defaultTickerFilter: GridFilterItem = {
        id: -1,
        field: 'ticker' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialTickerIds],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter((it) => it.field !== defaultTickerFilter.field),
          defaultTickerFilter,
        ]

        return model
      })
    }

    if (initialEntitlementIds && initialEntitlementIds > 0) {
      setInitialEntitlementIds(undefined, 'replaceIn')

      const defaultEntitlementFilter: GridFilterItem = {
        id: -1,
        field: 'entitlement' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialEntitlementIds],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultEntitlementFilter.field
          ),
          defaultEntitlementFilter,
        ]

        return model
      })
    }

    if (initialProcessIds && initialProcessIds > 0) {
      setInitialProcessIds(undefined, 'replaceIn')

      const defaultProcessFilter: GridFilterItem = {
        id: -1,
        field: 'process' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialProcessIds],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultProcessFilter.field
          ),
          defaultProcessFilter,
        ]

        return model
      })
    }

    if (initialRulesetIds && initialRulesetIds > 0) {
      setInitialRulesetIds(undefined, 'replaceIn')

      const defaultRulesetFilter: GridFilterItem = {
        id: -1,
        field: 'ruleset' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialRulesetIds],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultRulesetFilter.field
          ),
          defaultRulesetFilter,
        ]

        return model
      })
    }
  }, [
    initialEntitlementIds,
    initialPricingSourceIds,
    initialProcessIds,
    initialRulesetIds,
    initialTickerIds,
    setInitialEntitlementIds,
    setInitialPricingSourceIds,
    setInitialProcessIds,
    setInitialRulesetIds,
    setInitialTickerIds,
    setModel,
  ])
}
