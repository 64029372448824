import { ExportStateDto } from '@lib/pgs'
import { Outputs } from '@tk/frontend/api'
import { GridEnrichedColDef } from '@tk/frontend/primitives/datagrid'
import { theme } from '@tk/frontend/theme'

export type PreviewType = Extract<
  Outputs['records']['import']['preview'],
  { status: 'success' }
>
export type PreviewDto = PreviewType['response']['content'][0]
export type RecordDto = PreviewDto['currentVersion']

export type { GridEnrichedColDef }

//
// Export Maps

export type ExportState = ExportStateDto['state']
export const ExportStateLabel: Record<ExportState, string> = {
  NEW: 'Processing',
  GENERATING_EXPORT: 'Processing',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Complete',
  FAILED: 'Failed',
}

export const ExportProcessingStates: ExportState[] = [
  'NEW',
  'GENERATING_EXPORT',
]

export const ExportStateColour: Record<ExportState, string> = {
  NEW: theme.palette.secondary.main,
  GENERATING_EXPORT: theme.palette.secondary.main,
  CANCELLED: theme.palette.secondary.main,
  COMPLETED: theme.palette.secondary.main,
  FAILED: theme.palette.error.main,
}
