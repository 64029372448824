import { BloombergMappingEntity, ReferenceDataEntity } from './events'
import { capture, init, signout } from './posthog'
import { useIdentity } from './useIdentity'

export * as forms from './forms'
export type { ReferenceDataEntity, BloombergMappingEntity }

export const telemetry = {
  useIdentity,
  capture,
  init,
  signout,
}
