import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { ActorForm } from './ActorForm'

type Model = {}

export const CreateModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>New Actor</DialogTitle>
      <DialogContent>
        <ActorForm onFinished={modal.hide} />
      </DialogContent>
    </Dialog>
  )
})
