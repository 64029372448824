import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'

import { MutationModal } from './MutationModal'
import { Region } from './types'

export type Props = {
  data: Region
}

export function RegionActions(props: Props) {
  const modal = useModal(MutationModal)

  return (
    <ActionButtonGroup>
      <Button
        color="primary"
        startIcon={<Icons.Add />}
        onClick={() => {
          modal.show({
            type: 'location',
            data: {
              region: props.data,
            },
          })
        }}
      >
        Add
      </Button>

      <Button
        startIcon={<Icons.Edit />}
        onClick={() => {
          modal.show({
            type: 'region',
            data: props.data,
          })
        }}
      >
        Edit
      </Button>
    </ActionButtonGroup>
  )
}
