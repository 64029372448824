import { forwardRef } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack'

export const CustomSnackbar = forwardRef<
  HTMLDivElement,
  {
    key: SnackbarKey
    message: SnackbarMessage
    variant: 'error' | 'info' | 'success'
  }
>(({ key, message, variant }, ref) => {
  const snackbar = useSnackbar()

  return (
    <Box
      ref={ref}
      sx={{
        py: (t) => t.spacing(4),
        px: (t) => t.spacing(6),
        borderRadius: (t) => t.shape.borderRadius / 2,
        backgroundColor: (t) => t.palette[variant].main,
        boxShadow: (t) => t.shadows[3],
        maxWidth: '70ch',
        color: '#fff',
      }}
    >
      <Box sx={{ display: 'flex', gap: 3 }}>
        {variant === 'error' && <Icons.Error />}
        {variant === 'info' && <Icons.Info />}
        {variant === 'success' && <Icons.Check />}
        {message}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          variant="text"
          color="inherit"
          onClick={() => snackbar.closeSnackbar(key)}
        >
          Dismiss
        </Button>
      </Box>
    </Box>
  )
})
