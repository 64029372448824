import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import { FidGroupRequestDto } from '@tk/frontend/app/Fids/types'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { FidGroupForm } from './FidGroupForm'
import { generateFidGroupsEditUri } from './FidGroupRouter.routes'

const Form = getFormComponents<FidGroupRequestDto>()

export function FidGroupCreatePage() {
  const form = Form.useForm({
    defaultValues: {
      fidMaps: [],
    },
  })

  const navigate = useNavigate()
  const notify = usePromiseNotification()
  const createMutation = trpc.fids.groups.create.useMutation({
    onSuccess(data) {
      navigate({
        pathname: generateFidGroupsEditUri(data.id),
      })
    },
  })
  const utils = useUtils()

  const handleSubmit = form.handleSubmit(async (values) => {
    await notify(createMutation.mutateAsync(values), {
      progressMessage: `Creating Field Group`,
      successMessage: `Created Field Group`,
      failureMessage: `Failed to create Field Group`,
    })

    utils.fids.invalidate()
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Create Field Group">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={createMutation.isPending || createMutation.isSuccess}
          >
            Create
          </Button>
        </Form.SectionTitleRow>

        <FidGroupForm />
      </Form.FormStack>
    </Form.Provider>
  )
}
