import { useModal } from '@parameta/nice-modal-react'

import { usePromiseNotification } from '@tk/frontend/primitives/progress'
import { useFileUploader } from '@tk/frontend/primitives/useFileUploader'

import { ModalRenderer } from './ModalRenderer'
import { Entities, useSimpleImportTelemetry } from './simple-import-telemetry'
import { RouteDef } from './types'

export type SimpleImportConfig = {
  entity: Entities
  route: RouteDef
}

export function useSimpleImport({ entity, route }: SimpleImportConfig) {
  const { trackStart, trackStartFailed } = useSimpleImportTelemetry(entity)

  const notify = usePromiseNotification()

  const prepareImportMutation = route.prepare.useMutation()

  const startImportMutation = route.start.useMutation({
    retry: false,
  })

  const modal = useModal(ModalRenderer)

  const fileUploader = useFileUploader({
    types: 'csv',
    onFile: async (file, uploadFile) => {
      const trackError = (name: string) => (err: any) => {
        trackStartFailed(file, name)
        throw err
      }

      async function process() {
        const storageInfo = await prepareImportMutation
          .mutateAsync()
          .catch(trackError('prepare-import'))

        await uploadFile(file, storageInfo.uploadUri).catch(
          trackError('upload-file')
        )

        const importInfo = await startImportMutation
          .mutateAsync({
            objectKey: storageInfo.objectKey,
            name: file.name,
          })
          .catch(trackError('start-import'))

        trackStart(file, importInfo.taskId)

        modal.show({
          phase: 'processing',
          taskId: importInfo.taskId,
        })
      }

      await notify(process(), {
        progressMessage: 'Starting import...',
        failureMessage: 'Import failed',
        successMessage: 'Import started',
      })
    },
  })

  return {
    startImport: fileUploader.start,
    startImportWorking: fileUploader.working,
  }
}
