import React, { ReactNode } from 'react'

import { Stack } from '@mui/material'

export type FormStackProps = {
  children: ReactNode
  onSubmit: React.FormEventHandler<HTMLFormElement>
  submitOnCtrlEnter?: boolean
  autoComplete?: 'off' | (string & {})
}

export function FormStack({
  children,
  onSubmit,
  submitOnCtrlEnter,
  autoComplete = 'off',
}: FormStackProps) {
  return (
    <Stack
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        alignItems: 'center',
      }}
      flex="1 0 auto"
      padding="1rem"
      onKeyDownCapture={(e) => {
        if (submitOnCtrlEnter && e.key === 'Enter' && e.ctrlKey) {
          e.preventDefault()
          e.stopPropagation()
          onSubmit(e)
        }
      }}
      autoComplete={autoComplete}
    >
      <Stack width="100%" maxWidth="80rem">
        {children}
      </Stack>
    </Stack>
  )
}
