import { useCallback, useMemo } from 'react'

import { Box } from '@mui/material'
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium'

import {
  Option,
  Selector,
  SelectorProps,
} from '@tk/frontend/primitives/forms/Selector'
import { labelify } from '@tk/frontend/primitives/labelify'

import { useAutoFocus } from './useAutoFocus'

export type CellBaseEntity = {
  id: number
  name: string
  description?: string | undefined
}

export type CellOption<TEntity extends CellBaseEntity> = Option<number> & {
  entity: TEntity
}

export type SelectorCellProps<
  TEntity extends CellBaseEntity,
  TOption extends CellOption<TEntity> = CellOption<TEntity>
> = {
  params: GridRenderEditCellParams
} & {
  value: TEntity | undefined
  options: SelectorProps<TOption>['options']
  loading?: boolean
  noEmptyOption?: boolean
  onSearch?: (ev: any, search: string) => void
}

export function SelectorCell<
  TEntity extends CellBaseEntity,
  TOption extends CellOption<TEntity> = CellOption<TEntity>
>({
  params,
  value,
  options,
  onSearch,
  loading,
  noEmptyOption,
}: SelectorCellProps<TEntity, TOption>) {
  const api = useGridApiContext()

  const option = useMemo<TOption | undefined>(() => {
    return value?.id
      ? ({
          label: labelify(value.name, value.description),
          value: value.id,
          entity: value,
        } as TOption)
      : undefined
  }, [value])

  const handleSelect = useCallback(
    (value: TOption | undefined) => {
      api.current.setEditCellValue({
        id: params.id,
        field: params.field,
        debounceMs: 300,
        value: value?.entity,
      })
    },
    [api, params.field, params.id]
  )

  const inputRef = useAutoFocus(params)

  return (
    <Box
      height="100%"
      width="100%"
      padding="0.1rem"
      onKeyDown={(e) => {
        // Prevent enter to select option from propagating to edit row and saving the edits
        if (e.key === 'Enter' && !e.ctrlKey && !e.altKey && !e.shiftKey) {
          e.stopPropagation()
        }
      }}
    >
      <Selector<TOption>
        fullWidth
        fullHeight
        variant="outlined"
        noEmptyOption={noEmptyOption}
        options={options}
        value={option}
        loading={loading}
        onSelect={handleSelect}
        onSearch={onSearch}
        inputProps={{
          inputRef: inputRef,
        }}
      />
    </Box>
  )
}
