import { Inputs, Outputs } from '@tk/frontend/api'

export type Editable = {
  [key in keyof NamedBusinessObject]?: boolean
}
export const EditableDefaults: Editable = {
  name: true,
  description: true,
}

export type NamedBusinessObject = Outputs['reference']['frequency']['get']

export type NamedBusinessObjectUpdate =
  Inputs['reference']['frequency']['update']

export type AttributeObject = Outputs['multibrand']['attribute']['get']

export type AttributeObjectUpdate = Inputs['multibrand']['attribute']['update']
