import { Typography, TypographyProps } from '@mui/material'

export interface PairedBusinessObjectsCellProps extends TypographyProps {
  dto1?: { name?: string; description?: string } | null
  dto2?: { name?: string; description?: string } | null
}

export function PairedBusinessObjectsCell({
  dto1,
  dto2,
  ...props
}: PairedBusinessObjectsCellProps) {
  return (
    <div>
      <Line dto={dto1} {...props} />

      <Line dto={dto2} {...props} />
    </div>
  )
}

interface LineProps extends TypographyProps {
  dto: { name?: string; description?: string } | null | undefined
}

function Line({ dto, ...props }: LineProps) {
  if (!dto?.name) {
    return null
  }

  return (
    <div>
      <Typography sx={{ display: 'inline' }} variant="body2" {...props}>
        {dto.name}{' '}
      </Typography>

      {dto.description && (
        <Typography
          sx={{ display: 'inline' }}
          variant="body2"
          color="GrayText"
          {...props}
        >
          ({dto.description})
        </Typography>
      )}
    </div>
  )
}
