import { cloneElement, ReactNode, Suspense } from 'react'

import { AppBar, Box, Container, Paper, Stack, Typography } from '@mui/material'

import { PaperSectionSpinner, useTitle } from '@tk/frontend/primitives'

export type PageContentLayoutProps = {
  title: string
  children: ReactNode
  floatingControls?: ReactNode
  controls?: ReactNode
  childrenContainer?: JSX.Element
  maxContentWidth?: number | string | false
}

export function PageContentLayout({
  title,
  children,
  floatingControls,
  controls = <></>,
  childrenContainer = (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    ></Paper>
  ),
  maxContentWidth = '80rem',
}: PageContentLayoutProps) {
  useTitle(title)

  return (
    <Stack flex="1 1 auto">
      <AppBar position="static">
        <Stack direction="row" position="relative">
          <Typography
            color="GrayText"
            variant="h4"
            margin={0}
            lineHeight="inherit"
          >
            {title}
          </Typography>

          <Box flex="1 1 auto" />

          {floatingControls && (
            <Box
              position="absolute"
              top="-0.5rem"
              bottom="-0.5rem"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ marginLeft: '0', overflow: 'hidden' }}
            >
              {floatingControls}
            </Box>
          )}

          {controls}
        </Stack>
      </AppBar>

      <Container
        maxWidth={false}
        sx={{
          maxWidth: maxContentWidth || '100%',
          alignSelf: 'center',
          flex: '1 1 auto',
          paddingBottom: '1rem',
        }}
      >
        <Stack height="100%" width="100%">
          {cloneElement(childrenContainer, {
            children: (
              <Suspense fallback={<PaperSectionSpinner />}>{children}</Suspense>
            ),
          })}
        </Stack>
      </Container>
    </Stack>
  )
}
