import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { trpc, useUtils } from '@tk/frontend/api'
import { NamedBusinessObjectForm } from '@tk/frontend/primitives/datagrid/tree'
import {
  NamedBusinessObjectCore,
  useReferenceDataCrudForm,
} from '@tk/frontend/telemetry/reference-data-crud'

import { LocationForm } from './LocationForm'
import { Location, Region } from './types'

type RegionModel = {
  type: 'region'
  data: Partial<Region>
}

type LocationModel = {
  type: 'location'
  data: Partial<Location>
}

export const MutationModal = create<RegionModel | LocationModel>((model) => {
  const modal = useModal()

  let title = ''
  if (model.type === 'location') {
    title = !model.data.id ? 'New Location' : 'Editing Location'
  } else {
    title = !model.data.id ? 'New Region' : 'Editing Region'
  }

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {model.type === 'region' ? (
          <NamedBusinessObjectForm
            initialValues={model.data}
            useMutation={useRegionMutation}
            onFinished={modal.hide}
            useTelemetry={useTelemetry}
          />
        ) : (
          <LocationForm initialValues={model.data} onFinished={modal.hide} />
        )}
      </DialogContent>
    </Dialog>
  )
})

function useRegionMutation(isExisting: boolean) {
  const utils = useUtils()

  const { useMutation } = isExisting
    ? trpc.reference.region.update
    : trpc.reference.region.create

  return useMutation({
    onSuccess() {
      utils.reference.region.invalidate()
      utils.reference.location.invalidate()
    },
  })
}

function useTelemetry(values: Partial<NamedBusinessObjectCore>) {
  return useReferenceDataCrudForm('region', values)
}
