export const getPgsRoleValue = (pgsRole: string): string => {
  if (pgsRole === 'PGS_MANAGE') {
    return 'MANAGE'
  }
  if (pgsRole === 'PGS_READ') {
    return 'READ'
  }
  return ''
}

export const getPicardRoleValue = (picardRole: string): string => {
  if (picardRole === 'PICARD_MANAGE') {
    return 'MANAGE'
  }
  if (picardRole === 'PICARD_READ') {
    return 'READ'
  }
  return ''
}

export const getExtractaRoleValue = (extractaRole: string): string => {
  if (extractaRole === 'EXTRACTA_MANAGE') {
    return 'MANAGE'
  }
  if (extractaRole === 'EXTRACTA_READ') {
    return 'READ'
  }
  return ''
}

export const getEntitlementsRoleValue = (entitlementsRole: string): string => {
  if (entitlementsRole === 'ENTITLEMENTS_MANAGE') {
    return 'MANAGE'
  }
  if (entitlementsRole === 'ENTITLEMENTS_READ') {
    return 'READ'
  }
  return ''
}
