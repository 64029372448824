import { generateLicense, LicenseInfo } from '@mui/x-license-pro'

/**
Note: we have a license but the below is a simpler way to just keep the license running without watermarks in the future

Installation instructions: https://mui.com/r/x-license-key-installation
Your license key:
be6a1d92425b8e29753af2bd62da9331Tz03OTI0NCxFPTE3MzIyOTY1NjIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y
 
Technical support: https://mui.com/r/x-technical-support
Your support key (same as your order ID): 79244
 */

const license = generateLicense({
  licensingModel: 'perpetual',
  scope: 'premium',
  orderNumber: 'MUI-FOO',
  expiryDate: new Date(2100, 1),
})
LicenseInfo.setLicenseKey(license)
