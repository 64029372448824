import { useMemo } from 'react'

import { Typography } from '@mui/material'
import { DateTime } from 'luxon'

export type NameDateCellProps = {
  name: string | null | undefined
  date: string | Date | null | undefined
}

export function NameDateCell({ name, date }: NameDateCellProps) {
  const fmtDate = useMemo(() => {
    if (!date) {
      return ''
    }

    const luxDate =
      typeof date === 'string'
        ? DateTime.fromISO(date)
        : DateTime.fromJSDate(date)

    return luxDate.toFormat('HH:mm dd LLL yyyy')
  }, [date])

  return (
    <div>
      <Typography variant="body2">{name}</Typography>

      <Typography variant="body2" color="GrayText">
        {fmtDate}
      </Typography>
    </div>
  )
}
