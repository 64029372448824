import { Suspense } from 'react'
import ReactDiffViewer from 'react-diff-viewer-continued'

import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { trpc } from '@tk/frontend/api'

type RecordMarketDataStateComparisonModalProps = {
  recordName: string
  source: string
  backbone: string
  accountName: string
}

export const RecordMarketDataStateComparisonModal =
  create<RecordMarketDataStateComparisonModalProps>((props) => {
    const modal = useModal()

    return (
      <Dialog open={modal.visible} onClose={modal.hide} maxWidth="lg" fullWidth>
        <DialogTitle>
          Compare Last collected tick and Current RTDS image
        </DialogTitle>
        <DialogContent>
          <Suspense
            fallback={
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={50} thickness={4} />
              </Stack>
            }
          >
            <RecordStatus {...props} />
          </Suspense>
        </DialogContent>
      </Dialog>
    )
  })

const RecordStatus = ({
  recordName,
  source,
  backbone,
  accountName,
}: RecordMarketDataStateComparisonModalProps) => {
  const [result] = trpc.records.getRecordMarketData.useSuspenseQuery({
    name: recordName,
    source: source,
    backbone: backbone,
  })

  const fieldCount = result.activityStatus.lastMessage
    ? result.activityStatus.lastMessage['fieldCount']
    : 'No Data'
  const type = result.activityStatus.lastMessage
    ? result.activityStatus.lastMessage['type']
    : 'No Data'

  const lastMessage = { ...result.activityStatus.lastMessage }

  const transformedFields = result.marketDataState.fields.reduce(
    (aggr, { name, value }) => {
      aggr[name] = value ?? 'null'
      return aggr
    },
    {} as Record<string, string>
  )

  const sortedFields = Object.keys(transformedFields)
    .sort()
    .reduce((acc, key) => {
      acc[key] = transformedFields[key]
      return acc
    }, {} as Record<string, string>)

  const snowflakeMetadata = result.activityStatus.lastMessage
    ? {
        'Name': result.activityStatus.recordName,
        'Last Seen': result.activityStatus.lastSeen.toISOString() ?? '',
        'Source': result.activityStatus.source,
        'Status': result.activityStatus.status ?? 'No Data',
        'Field Count': fieldCount ?? 'No Data',
        'Type': type ?? 'No Data',
      }
    : {}

  const rtdsMetadata = {
    Name: result.marketDataState.recordMarketDataStateRequest.record,
    Source: result.marketDataState.recordMarketDataStateRequest.source.name,
    Backbone:
      result.marketDataState.recordMarketDataStateRequest.source.backbone,
    Status: result.marketDataState.rtdsState,
  }

  const removeFields = ['name', 'type', 'recordName', 'fieldCount', 'time']

  const newLastMessage = () => {
    removeFields.forEach((item) => {
      if (lastMessage[item]) {
        delete lastMessage[item]
      }
    })
    return lastMessage
  }

  const prettyPrintJson = (json: any) => JSON.stringify(json, null, 2)

  return (
    <>
      <Typography>
        {`Subscription account name: ${accountName ?? 'unknown'}`}
      </Typography>
      <Stack direction="row" marginTop="1rem">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Snowflake Metadata</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.activityStatus.lastMessage ? (
                Object.entries(snowflakeMetadata).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell>No data</TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>RTDS Metadata</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(rtdsMetadata).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {result.activityStatus.lastMessage && (
        <Box display="flex" flexDirection="row" marginTop="1rem">
          <ReactDiffViewer
            leftTitle="Last tick collected from Snowflake"
            rightTitle="Current RTDS Image"
            oldValue={prettyPrintJson(newLastMessage())}
            newValue={prettyPrintJson(sortedFields)}
            splitView={true}
          />
        </Box>
      )}
    </>
  )
}
