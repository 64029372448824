import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Paper, Stack, Typography } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import _ from 'lodash'

import { trpc } from '@tk/frontend/api'
import * as routes from '@tk/frontend/app/routes'
import {
  defaultProps,
  GridEnrichedColDef,
  usePaginationQueryParams,
} from '@tk/frontend/primitives/datagrid'
import {
  getDiffCell as getCoreDiffCell,
  getRowId,
} from '@tk/frontend/primitives/datagrid/diff'
import { PageContentLayout, PaperCell } from '@tk/frontend/primitives/layout'
import {
  ImportPreviewRowFilter,
  ImportStateColour,
  PreviewNotFound,
  usePreviewRowFilterParam,
} from '@tk/frontend/primitives/simple-import'

import { ApplyImportButton } from './ApplyImportButton'
import { ImportTaskSummary } from './ImportTaskSummary'
import { PreviewDto, RecordDto } from './types'
import { useImportHasAnyError } from './useImportHasAnyError'

const getDiffCell = getCoreDiffCell<RecordDto>

export function ImportProgressPreview() {
  const { id: idString } = useParams<{ id: string }>()
  const id = parseInt(idString!)

  const [rowFilter] = usePreviewRowFilterParam()
  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [rowFilter],
    initialPageSize: 100,
  })

  const [preview, previewQuery] = trpc.records.import.preview.useSuspenseQuery({
    taskId: id,
    page: pagination.page,
    size: pagination.pageSize,
    invalidOnly: rowFilter === 'errors',
  })

  const numErrors = useImportHasAnyError(id)

  const [status] = trpc.records.import.status.useSuspenseQuery({
    taskId: id,
  })

  const columns = useMemo<GridEnrichedColDef<PreviewDto>[]>(() => {
    return [
      {
        type: 'string',
        field: 'rowNumber',
        headerName: 'Row Number',
        filterable: false,
        sortable: false,
        aggregable: false,
      },
      {
        type: 'string',
        field: 'errorMessage',
        headerName: 'Summary',
        width: 200,
        filterable: false,
        sortable: false,
        aggregable: false,
        renderCell(params) {
          if (params.row.errorMessage || !params.row.isValid) {
            return (
              <Typography fontWeight="bold" color={ImportStateColour['FAILED']}>
                {params.row.errorMessage ?? 'ERROR'}
              </Typography>
            )
          }

          if (params.row.currentVersion) {
            const identical = _.isEqual(
              params.row.currentVersion,
              params.row.newVersion
            )

            return (
              <Typography
                fontWeight="bold"
                color={(theme) =>
                  identical ? 'GrayText' : theme.palette.primary.main
                }
              >
                {identical ? 'UNCHANGED' : 'UPDATE'}
              </Typography>
            )
          } else {
            return (
              <Typography
                fontWeight="bold"
                color={(theme) => theme.palette.success.main}
              >
                CREATE
              </Typography>
            )
          }
        },
      },
      getDiffCell('name', 'Name', { width: 300 }),
      getDiffCell('description', 'Description', { width: 400 }),
      getDiffCell('type', 'Type', { width: 100 }),
      getDiffCell('status', 'Status', { width: 100 }),
      getDiffCell('scope', 'Scope', { width: 100 }),
      getDiffCell('fidGroup.name', 'Field Group', { width: 100 }),
      getDiffCell('source.name', 'Source'),
      getDiffCell('backbone.name', 'Backbone'),
      getDiffCell('location.name', 'Location'),
      getDiffCell('region.name', 'Region'),
      getDiffCell('assetClass.name', 'Asset Class'),
      getDiffCell('assetType.name', 'Asset Type'),
      getDiffCell('clearingType.name', 'Clearing Type'),
      getDiffCell('priceType.name', 'Price Type'),
      getDiffCell('currency1.name', 'Currency 1'),
      getDiffCell('currency2.name', 'Currency 2'),
      getDiffCell('company.name', 'Company'),
      getDiffCell('pricingFrequency.name', 'Pricing Frequency'),
      getDiffCell('period1.name', 'Period 1'),
      getDiffCell('period2.name', 'Period 2'),
      getDiffCell('period3.name', 'Period 3'),
      getDiffCell('period4.name', 'Period 4'),
      getDiffCell('dayCount1.name', 'Day Count 1'),
      getDiffCell('dayCount2.name', 'Day Count 2'),
      getDiffCell('settlementIndex1.name', 'Settlement Index 1'),
      getDiffCell('settlementIndex2.name', 'Settlement Index 2'),
      getDiffCell('standardDiscounting.name', 'Standard Discounting'),
      getDiffCell('strikeUnit', 'Strike Unit'),
      getDiffCell('strikeValue', 'Strike'),
      getDiffCell('settlementMethod.name', 'Settlement Method'),
      getDiffCell('resetFrequency1.name', 'Reset Frequency 1'),
      getDiffCell('resetFrequency2.name', 'Reset Frequency 2'),
      getDiffCell('paymentFrequency1.name', 'Payment Frequency 1'),
      getDiffCell('paymentFrequency2.name', 'Payment Frequency 2'),
      getDiffCell('validFromDate', 'Valid From Date'),
      getDiffCell('validToDate', 'Valid To Date'),
    ]
  }, [])

  return preview.status === 'success' ? (
    <PageContentLayout
      title="Records Import"
      controls={
        <>
          <ApplyImportButton
            taskId={id}
            disabled={numErrors > 0 || status.state === 'FAILED'}
          />
        </>
      }
      childrenContainer={<></>}
      maxContentWidth={false}
    >
      <Stack display="flex" height="100%">
        <Stack direction="row">
          <ImportTaskSummary taskId={id} />

          <PaperCell>
            <ImportPreviewRowFilter />
          </PaperCell>
        </Stack>

        <Paper
          sx={{ display: 'flex', flex: '1 1 0', minHeight: 0, minWidth: 0 }}
        >
          <DataGridPremium
            {...defaultProps}
            pagination
            paginationMode="server"
            paginationModel={pagination}
            onPaginationModelChange={onPaginationChange}
            pageSizeOptions={[100]}
            rows={preview.response.content ?? []}
            rowCount={preview.response.totalElements ?? 0}
            columns={columns}
            getRowId={getRowId}
            loading={previewQuery.isRefetching}
          />
        </Paper>
      </Stack>
    </PageContentLayout>
  ) : (
    <PreviewNotFound
      label="Records"
      path={routes.app.recordManagement.records}
    />
  )
}
