import { keys } from '@lib/utils'
import { Outputs, trpc } from '@tk/frontend/api'
import { labelify } from '@tk/frontend/primitives'

type Lists = Outputs['reference']['ui']['recordLists']
type ListItem = { id: number; name: string; description?: string }
type SourceItem = Lists['source'][0]
type AssetTypeItem = Lists['assetType'][0]
type Option = { label: string; value: any; group?: string }
type OptionLists = {
  [key in keyof Lists]: Option[]
}

function mapNamedBusinessObjectsToOptions<Item extends ListItem = ListItem>(
  items: Item[],
  getGroup?: (item: Item) => string
): Option[] {
  return items.map((item) => {
    const label = labelify(item.name, item.description)
    const group = getGroup?.(item) ?? undefined

    return {
      label: label,
      value: item.id,
      group,
    }
  })
}

export function useRecordReferenceLists() {
  return trpc.reference.ui.recordLists.useSuspenseQuery(undefined, {
    select(data: Lists): OptionLists {
      const result: Partial<OptionLists> = {}

      const listsKeys = keys(data)
      for (const key of listsKeys) {
        switch (key) {
          case 'source':
            result[key] = mapNamedBusinessObjectsToOptions(
              data[key] as SourceItem[],
              (item) => {
                const name = item.backbone?.name ?? 'Unknown'
                const description = item.backbone?.description

                return labelify(name, description)
              }
            )
            break
          case 'assetType':
            result[key] = mapNamedBusinessObjectsToOptions(
              data[key] as AssetTypeItem[],
              (item) => {
                const name = item.assetClass?.name
                const description = item.assetClass?.description

                return labelify(name, description)
              }
            )
            break
          default:
            result[key] = mapNamedBusinessObjectsToOptions(data[key])
            break
        }
      }
      return result as OptionLists
    },
  })
}
