import { MutableRefObject, useCallback, useEffect, useRef } from 'react'

import { DataGridPremiumProps, GridApi } from '@mui/x-data-grid-premium'

export type RowExpansionProps = Required<
  Pick<DataGridPremiumProps, 'isGroupExpandedByDefault'>
>

/**
 * when "rows" changes this state resets internally, but we update
 * rows reactively when editing rows so need to cache the state at all times
 *
 * https://github.com/mui/mui-x/issues/10085
 */
export function useRowExpansionProps(
  api: MutableRefObject<GridApi>
): RowExpansionProps {
  const expansionState = useRef<Record<string, boolean>>({})
  useEffect(() => {
    if (!api.current?.subscribeEvent) {
      return
    }

    return api.current.subscribeEvent('rowExpansionChange', (node) => {
      expansionState.current[node.id] = node.childrenExpanded ?? false
    })
  }, [api])

  const isGroupExpandedByDefault = useCallback<
    RowExpansionProps['isGroupExpandedByDefault']
  >(function (node) {
    return expansionState.current[node.id] ?? false
  }, [])

  return {
    isGroupExpandedByDefault,
  }
}
