import { ReactNode } from 'react'

import styled from '@mui/styled-engine'

type OptionLike = {
  label: string
  value: string
  definition: ReactNode
}

export interface FieldInfoProps {
  title?: string
  details?: ReactNode
  options?: OptionLike[]
}

const Container = styled('div')`
  max-width: 30rem;

  ul {
    margin-block-start: 0.5rem;
    margin-block-end: 0;
    padding-inline-start: 1.5rem;
  }

  li {
    margin-top: 0.5rem;
  }
`

export function FieldInfo({ title, details, options }: FieldInfoProps) {
  return (
    <Container>
      {title && <span>{title}</span>}

      {details && <span>{details}</span>}

      {!!options && (
        <ul>
          {options.map((opt) => {
            if (!opt.definition) {
              return null
            }

            return <li key={opt.value}>{opt.definition}</li>
          })}
        </ul>
      )}
    </Container>
  )
}
