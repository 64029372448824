import { ReactNode } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import type { Roles } from '@tk/api'

import { useEntitlement } from './roles'
import { KnownFeature, useFeature } from './useFeature'

export type WhenProps = {
  can?: Roles.Entitlement | Roles.Entitlement[]
  flag?: KnownFeature
  children: ReactNode
  whenNot?: ReactNode
}

export function When({ can, flag, whenNot = null, children }: WhenProps) {
  const userCan = When.useEntitlement(can)
  const featureEnabled = useFeature(flag)

  if (userCan && featureEnabled) {
    return <>{children}</>
  } else if (whenNot) {
    return <>{whenNot}</>
  } else {
    return null
  }
}

When.useEntitlement = useEntitlement

export function RouterWhen(props: Omit<WhenProps, 'whenNot' | 'children'>) {
  return (
    <When {...props} whenNot={<Navigate to="/404" replace />}>
      <Outlet />
    </When>
  )
}
