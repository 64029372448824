import { useMemo } from 'react'

import { TextField } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { StringParam, useQueryParam } from 'use-query-params'

import { emulateDbFilter } from '@lib/utils'
import { Outputs, trpc } from '@tk/frontend/api'
import { usePromiseNotification } from '@tk/frontend/primitives'
import {
  defaultProps,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import {
  BooleanCell,
  TwoLineTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { EntitlementsCell } from './EntitlementsCell'

type UserRole = Outputs['admin']['userRoles']['list'][number]

export function UserRolesPage() {
  const notify = usePromiseNotification()
  const [filterValue, setFilterValue] = useQueryParam('filter', StringParam)

  const [list, listQuery] = trpc.admin.userRoles.list.useSuspenseQuery()
  const roleUpdater = trpc.admin.userRoles.update.useMutation({
    onSettled() {
      listQuery.refetch()
    },
  })

  const columns = useColumns()

  const filteredList = useMemo(() => {
    return list.filter((row) => emulateDbFilter(filterValue, row.email))
  }, [filterValue, list])

  return (
    <PageContentLayout title="Admin: User Roles" maxContentWidth={false}>
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial email. ie. "%bob%"'
        fullWidth
      />

      <DataGridPremium<UserRole>
        {...defaultProps}
        rows={filteredList ?? []}
        columns={columns}
        getRowId={(row) => row.email}
        //
        initialState={{
          sorting: {
            sortModel: [
              { field: 'type', sort: 'desc' },
              { field: 'role', sort: 'asc' },
              { field: 'email', sort: 'asc' },
            ],
          },
        }}
        //
        editMode="row"
        isCellEditable={(params) =>
          !!params.colDef.editable && params.row.type === 'db'
        }
        processRowUpdate={async (newRole, oldRole) => {
          if (newRole.role !== oldRole.role) {
            await notify(
              roleUpdater.mutateAsync({
                email: oldRole.email,
                role: newRole.role,
              }),
              {
                progressMessage: 'Update role for ' + oldRole.email,
                successMessage: 'Updated role for ' + oldRole.email,
                failureMessage: 'Failed to update role for ' + oldRole.email,
              }
            )
          }

          return newRole
        }}
      />
    </PageContentLayout>
  )
}

function useColumns() {
  const [roleOptions] = trpc.admin.roles.list.useSuspenseQuery()

  return useMemo<GridEnrichedColDef<UserRole>[]>(() => {
    return [
      {
        type: 'string',
        field: 'email',
        headerName: 'User',
        editable: false,
        width: 300,
      },
      {
        type: 'boolean',
        field: 'type',
        headerName: 'Is Hardcoded',
        editable: false,
        width: 100,
        valueGetter(params) {
          return params.row.type === 'hardcoded'
        },
        renderCell(params) {
          return <BooleanCell value={params.value} />
        },
      },
      {
        type: 'singleSelect',
        field: 'role',
        headerName: 'Role',
        editable: true,
        width: 200,
        valueOptions(params) {
          return roleOptions
        },
        renderCell(params) {
          return (
            <TwoLineTextCell
              line1={
                roleOptions.find((r) => r.value === params.value)?.label ??
                'Unknown'
              }
              line2={params.value}
            />
          )
        },
      },
      {
        type: 'string',
        field: 'entitlements',
        headerName: 'Entitlements',
        editable: false,
        flex: 1,
        renderCell(params) {
          return <EntitlementsCell entitlements={params.row.entitlements} />
        },
      },
    ]
  }, [roleOptions])
}
