import { MutableRefObject } from 'react'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { GridApi } from '@mui/x-data-grid-premium'

export type FilterPanelButtonProps = {
  gridApiRef: MutableRefObject<GridApi>
}

export function FilterPanelButton({ gridApiRef }: FilterPanelButtonProps) {
  return (
    <Button
      title="Open Filters"
      onClick={() => {
        gridApiRef.current.showFilterPanel()
      }}
      variant="contained"
      startIcon={<Icons.FilterList fontSize="inherit" />}
      size="large"
    >
      Filters
    </Button>
  )
}
