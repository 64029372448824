import { useMemo } from 'react'

import { StringParam, useQueryParam } from 'use-query-params'

import { emulateDbFilter } from '@lib/utils'

type ClientFilterType = {
  name: string
  description?: string
}

export function useClientFilter<T extends ClientFilterType>(initialList: T[]) {
  const [filterValue, setFilterValue] = useQueryParam('filter', StringParam)

  const filteredList = useMemo(() => {
    return initialList.filter(
      (row) =>
        emulateDbFilter(filterValue, row.name) ||
        emulateDbFilter(filterValue, row.description)
    )
  }, [initialList, filterValue])

  return { filteredList, filterValue, setFilterValue }
}
