import React from 'react'
import { useController } from 'react-hook-form'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'

type DayPickerProps = {
  name: string
}

const daysOfWeek = [
  { label: 'Mon', value: 1 },
  { label: 'Tue', value: 2 },
  { label: 'Wed', value: 3 },
  { label: 'Thu', value: 4 },
  { label: 'Fri', value: 5 },
  { label: 'Sat', value: 6 },
  { label: 'Sun', value: 0 },
]

export function DayPicker({ name }: Readonly<DayPickerProps>) {
  const { field } = useController({ name })

  const value = field.value ?? []

  const handleCheckboxChange = (dayValue: number, isChecked: boolean) => {
    const updatedDays = isChecked
      ? [...value, dayValue]
      : value.filter((v: number) => v !== dayValue)
    field.onChange(updatedDays)
  }

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      {daysOfWeek.map((day) => (
        <FormControlLabel
          key={day.value}
          control={
            <Checkbox
              checked={value.includes(day.value)}
              onChange={(e) =>
                handleCheckboxChange(day.value, e.target.checked)
              }
              color="primary"
              sx={{ margin: 0, padding: '0px' }}
            />
          }
          label={day.label}
          labelPlacement="top"
        />
      ))}
    </Stack>
  )
}
