import * as Icons from '@mui/icons-material'

import { MenuItemLink } from '@tk/frontend/primitives/MenuItemLink'
import { telemetry } from '@tk/frontend/telemetry'

import { generateExportListUri } from './Exports.routes'

export function ExportListButton() {
  return (
    <MenuItemLink
      to={generateExportListUri()}
      startIcon={<Icons.List />}
      onClick={() => {
        telemetry.capture('gtk/record-export/list')
      }}
    >
      Exports List
    </MenuItemLink>
  )
}
