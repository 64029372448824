import React, { ReactNode } from 'react'

import { styled } from '@mui/material'

export const RowStack = styled('div')`
  display: flex;
  flex-direction: row;

  > * {
    flex: 1 1 auto;
    margin-right: 0.5rem;
  }

  > :last-child {
    margin-right: 0;
  }
`

export const Cell = styled('div')`
  display: flex;
  flex: 0 0 auto;
  min-height: 100%;
  max-height: 100%;
  align-items: center;
  justify-content: center;
`

export type RowProps = {
  children: ReactNode
}

export function Row({ children }: RowProps) {
  return (
    <div>
      <RowStack>{children}</RowStack>
    </div>
  )
}
