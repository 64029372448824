import { useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { Button, Paper, Stack } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import { ROUTES } from '@tk/frontend/app/routes'
import { getFormComponents, useEntitlement } from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

const Form = getFormComponents<{ name: string }>()

export function CreateProjectPage() {
  const navigate = useNavigate()

  const utils = useUtils()
  const createProject = trpc.recordProjects.create.useMutation()

  const [projects] = trpc.recordProjects.list.useSuspenseQuery()
  const options = useMemo(() => {
    return projects.map<Option>(({ name, complete }) => {
      return {
        label: `${name} (${complete ? 'Completed' : 'In Progress'})`,
        value: name.toLowerCase(),
      }
    })
  }, [projects])

  const form = Form.useForm({
    defaultValues: {
      name: '',
    },
  })

  const name = form.watch('name')
  const matchingOption = options?.find(
    (o) => o.value === name?.toLowerCase().trim()
  )

  const canCreate = useEntitlement('record-projects.manage')

  return (
    <PageContentLayout
      title="Record Designer - Start"
      childrenContainer={<Stack alignItems="center"></Stack>}
    >
      <Paper sx={{ width: '40rem' }}>
        <Form.Provider {...form}>
          <Form.FormStack
            onSubmit={form.handleSubmit(async (values) => {
              let projectName = ''
              if (matchingOption) {
                projectName = values.name
              } else {
                const newProject = await createProject.mutateAsync({
                  name: values.name,
                })
                projectName = newProject.name
              }

              await utils.recordProjects.invalidate()

              navigate(
                generatePath(ROUTES.app.recordDesigner.project, {
                  projectName,
                })
              )
            })}
          >
            <Form.SelectField
              name="name"
              label="Enter IDC Number or Project Name"
              fullWidth
              variant="filled"
              options={options}
              freeSolo
              rules={Form.rules.required}
              disabled={createProject.isPending}
            />

            <Button
              variant="contained"
              type="submit"
              disabled={
                !name ||
                createProject.isPending ||
                (!matchingOption && !canCreate)
              }
            >
              {matchingOption ? 'Load Project' : 'Create Project'}
            </Button>
          </Form.FormStack>
        </Form.Provider>
      </Paper>
    </PageContentLayout>
  )
}
