import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { When } from '@tk/frontend/primitives'

import { UpsertModal } from './CreateModal'

export function RulesetCreateButton() {
  const modal = useModal(UpsertModal)

  return (
    <When can="entitlements.manage">
      <Button
        startIcon={<Icons.Create />}
        variant="contained"
        onClick={() =>
          modal.show({
            data: {},
          })
        }
      >
        Create
      </Button>
    </When>
  )
}
