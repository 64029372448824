import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { EntitlementRulesetDto } from '@lib/pgs'
import { SuspenseBoundaryLocal } from '@tk/frontend/primitives'

import { RulesetForm } from './RulesetForm'

type Model = {
  data: Partial<EntitlementRulesetDto>
}

export const UpsertModal = create<Model>((model) => {
  const modal = useModal()

  const title = !model.data.id ? 'New Ruleset' : 'Edit Ruleset'

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <SuspenseBoundaryLocal>
          <RulesetForm initialValues={model.data} onFinished={modal.hide} />
        </SuspenseBoundaryLocal>
      </DialogContent>
    </Dialog>
  )
})
