import { ApiUserDto, ApiUserUpdateDto } from '@lib/pgs'

export const ApiRole: Record<ApiRole, ApiRole> = {
  PGS_READ: 'PGS_READ',
  PGS_MANAGE: 'PGS_MANAGE',
  EXTRACTA_READ: 'EXTRACTA_READ',
  EXTRACTA_MANAGE: 'EXTRACTA_MANAGE',
  PICARD_READ: 'PICARD_READ',
  PICARD_MANAGE: 'PICARD_MANAGE',
  ENTITLEMENTS_READ: 'ENTITLEMENTS_READ',
  ENTITLEMENTS_MANAGE: 'ENTITLEMENTS_MANAGE',
  ADMIN: 'ADMIN',
}

export type ApiRole = Exclude<ApiUserDto['apiRoles'], undefined>[number]

type ApiRoleFields = {
  admin: boolean
  pgsRole: '' | 'READ' | 'MANAGE'
  extractaRole: '' | 'READ' | 'MANAGE'
  picardRole: '' | 'READ' | 'MANAGE'
  entitlementsRole: '' | 'READ' | 'MANAGE'
}

export type TransformedApiUserDto = Omit<ApiUserDto, 'username'> & {
  name: string
} & ApiRoleFields

export type ApiUserUpdateDtoWithId = {
  id: number
} & ApiUserUpdateDto
