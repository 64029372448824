import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { Box, Drawer } from '@mui/material'

import { Colours } from '@lib/theme'
import { SuspenseBoundaryWithBlockingSpinner } from '@tk/frontend/primitives/suspense'

const drawerWidth = '12rem'

export type LayoutProps = {
  sidebar?: ReactNode
  decoration?: ReactNode
}

export function Layout({ sidebar, decoration }: LayoutProps) {
  return (
    <Box sx={{ display: 'flex' }}>
      {sidebar && (
        <Drawer
          id="sidebar"
          variant="permanent"
          sx={{
            width: drawerWidth,
          }}
          PaperProps={{
            elevation: 5,
            sx: {
              width: drawerWidth,
              paddingBottom: '2.5rem',
            },
          }}
        >
          {sidebar}
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          backgroundColor: Colours.BgShade.Blue,
        }}
      >
        <SuspenseBoundaryWithBlockingSpinner>
          <Outlet />
        </SuspenseBoundaryWithBlockingSpinner>
      </Box>

      {decoration}
    </Box>
  )
}
