import { OktaAuthOptions } from '@okta/okta-auth-js'
import { PostHogConfig } from 'posthog-js'
import { z } from 'zod'

export interface Environment {
  production: boolean
  api: {
    url: string
  }
  auth: OktaAuthOptions
  posthog: Partial<PostHogConfig> & { forceDisable?: boolean }
  rum:
    | undefined
    | {
        endpoint: string | undefined
        appId: string | undefined
        identityPoolId: string | undefined
        appVersion: string | undefined
        appRegion: string | undefined
      }

  featureFlags: {
    unimplemented: boolean
    bloombergMappings: boolean
    commercialPackages: boolean
    fids: boolean
    recordDesigner: boolean
    dataextract: boolean
    entitlements: boolean
  }
}

const booleanFromString = z
  .string()
  .optional()
  .transform((str) => {
    if (!str) {
      return false
    }
    const normalized = str.toLowerCase()
    if (normalized === 'true' || str === '1') {
      return true
    }
    if (normalized === 'false' || str === '0') {
      return false
    }
    throw new Error('Boolean string must be "true", "false", "1", or "0"')
  })

const envSchema = z.object({
  VITE_E2E: z.string().optional(),
  VITE_OKTA_ISSUER: z.string(),
  VITE_OKTA_CLIENT_ID: z.string(),
  VITE_RUM_ENDPOINT: z.string().optional(),
  VITE_RUM_APP_ID: z.string().optional(),
  VITE_RUM_IDENTITY_POOL_ID: z.string().optional(),
  VITE_RUM_APP_VERSION: z.string().optional(),
  VITE_RUM_APP_REGION: z.string().optional(),
  VITE_FT_UNIMPLEMENTED: booleanFromString,
  VITE_FT_BLOOMBERG_MAPPINGS: booleanFromString,
  VITE_FT_COMMERCIAL_PACKAGES: booleanFromString,
  VITE_FT_FIDS: booleanFromString,
  VITE_FT_RECORD_DESIGNER: booleanFromString,
  VITE_FT_DATAEXTRACT: booleanFromString,
  VITE_FT_ENTITLEMENTS: booleanFromString,
})

const env = envSchema.parse(import.meta.env)

export const isE2E = env.VITE_E2E && env.VITE_E2E.length > 0
const FT_FLAGS = import.meta.env.MODE === 'test' || false

export const environment: Environment = {
  production: import.meta.env.PROD,
  api: {
    url: document.location.host.includes('localhost')
      ? 'http://localhost:3334'
      : 'https://api.' + document.location.host,
  },
  auth: {
    issuer: env.VITE_OKTA_ISSUER,
    clientId: env.VITE_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
  },
  rum: env.VITE_RUM_ENDPOINT
    ? {
        endpoint: env.VITE_RUM_ENDPOINT,
        appId: env.VITE_RUM_APP_ID,
        identityPoolId: env.VITE_RUM_IDENTITY_POOL_ID,
        appVersion: env.VITE_RUM_APP_VERSION,
        appRegion: env.VITE_RUM_APP_REGION,
      }
    : undefined,
  posthog: {
    // FIXME: Right now we have only one instance and project, this may change in the future
    forceDisable: !!import.meta.env.DEV,
    token: 'phc_MR4PXZETluBvRS1Vt4F0redDAse3ZZcWbk6cdwfBfxh',
    api_host: 'https://posthog.dhb-npd.tpicapcloud.com',
  },
  featureFlags: {
    unimplemented: env.VITE_FT_UNIMPLEMENTED ?? FT_FLAGS,
    bloombergMappings: env.VITE_FT_BLOOMBERG_MAPPINGS,
    commercialPackages: env.VITE_FT_COMMERCIAL_PACKAGES,
    fids: env.VITE_FT_FIDS,
    recordDesigner: env.VITE_FT_RECORD_DESIGNER,
    dataextract: env.VITE_FT_DATAEXTRACT,
    entitlements: env.VITE_FT_ENTITLEMENTS,
  },
}
