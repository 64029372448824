import { useCallback } from 'react'

export function useFileDownloader(name?: string) {
  return useCallback(
    (url: string) => {
      if (!name) {
        return
      }

      const link = document.createElement('a')
      link.download = name
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    [name]
  )
}
