import { useEffect, useMemo } from 'react'

import _ from 'lodash'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import {
  SortDirection,
  sortDirKeys,
  useFilterModel,
  usePaginationQueryParams,
  useSortQueryParams,
} from '@tk/frontend/primitives/datagrid'

import { FilterKey, FILTERS_CONFIG } from './filters-config'
import { useRetiredFilter } from './RecordMappingFilter'
import { RecordMappingListQuery } from './types'

const _sortByKeys = ['id', 'name'] as const
export type SortBy = (typeof _sortByKeys)[number]
export const sortByKeys: SortBy[] = ['id', 'name']

export type { SortDirection }
export { sortDirKeys }

export function useRecordMappingQueryParams() {
  const [filter, setFilter] = useQueryParam(
    'filter',
    withDefault(StringParam, '')
  )
  const [retired] = useRetiredFilter()

  const { sort, setSort, direction, setDirection } =
    useSortQueryParams<SortBy>('name')

  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [filter, sort, direction],
    initialPageSize: 100,
  })
  const { page, pageSize } = pagination

  const filterObject = useFilterObject()

  useEffect(() => {
    // Force page reset when anything which makes the current page irrelevant happens
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _forceEffect = filter || pageSize || sort || direction

    onPaginationChange({ page: 0, pageSize })
  }, [direction, filter, onPaginationChange, pageSize, sort])

  return useMemo(() => {
    const query: RecordMappingListQuery = {
      filter,
      direction: direction,
      sort: sort,
      page,
      size: pageSize,
      retired: retired === 'ACTIVE' ? 'NOT_RETIRED' : retired,
      ...filterObject,
    }

    return {
      filter,
      setFilter,
      sort: sort!,
      setSort,
      direction: direction!,
      setDirection,
      pagination,
      onPaginationChange,
      sizeOptions: _([pagination.pageSize, 100, 500, 1000])
        .uniq()
        .sortBy()
        .value(),
      query: query,
    } as const
  }, [
    direction,
    filter,
    filterObject,
    onPaginationChange,
    page,
    pageSize,
    pagination,
    retired,
    setDirection,
    setFilter,
    setSort,
    sort,
  ])
}

function useFilterObject() {
  const filterModel = useFilterModel().model

  return useMemo(() => {
    return filterModel?.items.reduce((params, item) => {
      const key = item.field as FilterKey
      const filterConfig = FILTERS_CONFIG[key]
      if (!filterConfig) {
        console.error(
          'Cannot set query for key',
          key,
          'because there is no config for it. Which is weird'
        )
        return params
      }

      filterConfig.setApiQueryProperty(params, item.value)

      return params
    }, {} as RecordMappingListQuery)
  }, [filterModel?.items])
}
