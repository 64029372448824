import { useCallback } from 'react'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { StringParam, useQueryParam } from 'use-query-params'

export type RetiredType = 'ALL' | 'ACTIVE' | 'RETIRED'

export function useRetiredFilter() {
  const [value, setValue] = useQueryParam('retired', StringParam)

  const retiredFilter = (value as RetiredType) ?? 'ACTIVE'

  const setRetiredFilter = useCallback(
    (value: RetiredType) => setValue(value, 'replaceIn'),
    [setValue]
  )

  return [retiredFilter, setRetiredFilter] as const
}

export function RecordMappingRetiredFilter() {
  const [value, onChange] = useRetiredFilter()

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value: RetiredType) => onChange(value)}
    >
      <ToggleButton value="ACTIVE" color="success">
        Active
      </ToggleButton>
      <ToggleButton value="RETIRED" color="warning">
        Retired
      </ToggleButton>
      <ToggleButton value="ALL" color="info">
        All
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
