// Borrowed from: https://usehooks-ts.com/react-hook/use-debounce
// At time of writing required Node 16 but we are on 14. Can move to the official version once we're updated
import { startTransition, useEffect, useState } from 'react'

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      // startTransition means that suspense hooks like useSuspenseQuery won't trigger a fallback
      //    when their inputs change. Otherwise typing on the keyboard might cause this
      startTransition(() => {
        setDebouncedValue(value)
      })
    }, delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}
