import { ApiUserDto } from '@lib/pgs'

import { ApiRole, ApiUserUpdateDtoWithId, TransformedApiUserDto } from './types'

export const transformToTransformedApiUserDto = (
  user: ApiUserDto
): TransformedApiUserDto => {
  const roles = user.apiRoles ?? []

  const getRoleValue = (
    manageRole: ApiRole,
    readRole: ApiRole
  ): '' | 'READ' | 'MANAGE' => {
    if (roles.includes(manageRole)) {
      return 'MANAGE'
    }
    if (roles.includes(readRole)) {
      return 'READ'
    }
    return ''
  }

  return {
    ...user,
    name: user.username,
    admin: roles.includes(ApiRole.ADMIN),
    pgsRole: getRoleValue(ApiRole.PGS_MANAGE, ApiRole.PGS_READ),
    extractaRole: getRoleValue(ApiRole.EXTRACTA_MANAGE, ApiRole.EXTRACTA_READ),
    picardRole: getRoleValue(ApiRole.PICARD_MANAGE, ApiRole.PICARD_READ),
    entitlementsRole: getRoleValue(
      ApiRole.ENTITLEMENTS_MANAGE,
      ApiRole.ENTITLEMENTS_READ
    ),
  }
}

export const transformToApiUserUpdateDto = (
  user: TransformedApiUserDto
): ApiUserUpdateDtoWithId => {
  const apiRoles: ApiRole[] = [
    ...(user.admin ? [ApiRole.ADMIN] : []),
    ...(user.pgsRole
      ? [ApiRole[`PGS_${user.pgsRole}` as keyof typeof ApiRole]]
      : []),
    ...(user.extractaRole
      ? [ApiRole[`EXTRACTA_${user.extractaRole}` as keyof typeof ApiRole]]
      : []),
    ...(user.picardRole
      ? [ApiRole[`PICARD_${user.picardRole}` as keyof typeof ApiRole]]
      : []),
    ...(user.entitlementsRole
      ? [
          ApiRole[
            `ENTITLEMENTS_${user.entitlementsRole}` as keyof typeof ApiRole
          ],
        ]
      : []),
  ]

  return {
    id: user.id,
    username: user.name,
    description: user.description,
    enabled: user.enabled,
    apiRoles,
  }
}
