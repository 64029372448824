import { Typography } from '@mui/material'

import { WrappedTextCellContainer } from '@tk/frontend/primitives/datagrid/cells'

export type EntitlementsCellProps = {
  entitlements: string[] | undefined
}

export function EntitlementsCell({ entitlements = [] }: EntitlementsCellProps) {
  return (
    <WrappedTextCellContainer>
      {entitlements.sort().map((entitlement, index) => (
        <Typography
          key={index}
          variant="body2"
          display="inline"
          color={index % 2 === 0 ? 'InfoText' : 'GrayText'}
        >
          {entitlement}
          {index < entitlements.length - 1 ? ', ' : ''}
        </Typography>
      ))}
    </WrappedTextCellContainer>
  )
}
