import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { trpc } from '@tk/frontend/api'
import {
  useFileDownloader,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { telemetry } from '@tk/frontend/telemetry'

type ExportDownloadButtonProps = {
  taskId: string
}

export function ExportDownloadButton({ taskId }: ExportDownloadButtonProps) {
  const notify = usePromiseNotification()

  const fileDownloader = useFileDownloader('export-download')

  const download = trpc.records.export.download.useMutation({
    onSuccess(data) {
      fileDownloader(data.downloadUri)
    },
  })

  return (
    <Button
      startIcon={<Icons.Download />}
      disabled={download.isPending}
      onClick={async () => {
        await notify(
          download.mutateAsync({
            taskId,
          }),
          {
            progressMessage: 'Starting download...',
            failureMessage: 'Download failed',
            successMessage: 'Download started',
          }
        )

        telemetry.capture('gtk/record-export/download', { exportId: taskId })
      }}
    >
      Download
    </Button>
  )
}
