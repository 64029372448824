import * as Icons from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { trpc, useUtils } from '@tk/frontend/api'
import { useProjectCrud } from '@tk/frontend/app/RecordDesigner/useProjectCrud'
import { useWarningTabs } from '@tk/frontend/app/RecordDesigner/useWarningTabs'
import {
  ConfirmPopover,
  useEntitlement,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { StatusModalRenderer } from './StatusModal'

export function ApproveAndCreateButton() {
  const { project } = useProjectCrud()

  const approveAndCreateRecordsQuery =
    trpc.recordProjects.approveAndCreateRecords.useMutation()

  const promiseNotification = usePromiseNotification()

  const utils = useUtils()

  const warningMessages = useWarningTabs()

  const canApprove = useEntitlement('record-projects.approve')

  const statusModal = useModal(StatusModalRenderer)

  return (
    <ConfirmPopover
      prompt={
        <>
          <Typography variant="h5" align="center" marginTop="0.5rem">
            Are you sure you want to create the records?
          </Typography>

          {warningMessages.length > 0 && (
            <Typography color={(t) => t.palette.warning.main}>
              <ul style={{ marginBottom: 0 }}>
                {warningMessages.map((tab) => (
                  <li key={tab}>{`There are problems with the ${tab} tab`}</li>
                ))}
              </ul>
            </Typography>
          )}
        </>
      }
      onOk={async () => {
        const promise = approveAndCreateRecordsQuery.mutateAsync({
          name: project.name,
        })
        const result = await promiseNotification(promise, {
          progressMessage: 'Started Creating...',
          failureMessage: 'Creation failed',
          successMessage: 'Record creation in progress',
        })

        statusModal.show({
          type: 'status',
          uuid: result.uuid,
        })

        utils.recordProjects.invalidate()
      }}
    >
      <Button
        variant="contained"
        startIcon={<Icons.StartRounded />}
        disabled={!canApprove}
      >
        {canApprove
          ? 'Approve & Create Records'
          : 'You do not have permission to Approve'}
      </Button>
    </ConfirmPopover>
  )
}
