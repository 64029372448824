import {
  FieldNamesMarkedBoolean,
  FieldValues,
  Path,
  FieldErrors as RhfFieldErrors,
} from 'react-hook-form'

import { keys } from '@lib/utils'

import { flattenUntil, isFieldError, isTrueBoolean } from './flattenUntil'

export type RhfDirtyFields<TDto extends FieldValues> = Partial<
  FieldNamesMarkedBoolean<TDto>
>

export type RhfErrorFields<TDto extends FieldValues> = Partial<
  RhfFieldErrors<TDto>
>

export type DirtyFields = 'not-tracked' | string[]

export function getDirtyFields<TDto extends FieldValues>(
  _dirtyFields: RhfDirtyFields<TDto> | 'not-tracked'
): DirtyFields {
  if (_dirtyFields === 'not-tracked') {
    return _dirtyFields
  }

  const flattenedDirtyFields = flattenUntil(_dirtyFields, isTrueBoolean)
  const dirtyKeys = keys(flattenedDirtyFields)

  return dirtyKeys
}

export type FieldErrors = {
  errorFields: 'not-tracked' | string[]
  errorMessages: 'not-tracked' | string[]
}

export function getFieldErrors<TDto extends FieldValues>(
  _errors: RhfErrorFields<TDto> | 'not-tracked'
): FieldErrors {
  if (_errors === 'not-tracked') {
    return {
      errorFields: 'not-tracked',
      errorMessages: 'not-tracked',
    } as const
  }

  const fieldErrors = flattenUntil(_errors, isFieldError)

  const errorFields = Object.keys(fieldErrors as object) as Path<TDto>[]

  const errorMessages = errorFields.map((key) =>
    String(
      fieldErrors[key]?.message ?? fieldErrors[key]?.type ?? 'Unknown Error'
    )
  )

  return {
    errorFields,
    errorMessages,
  }
}
