import { useCallback } from 'react'

import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { difference } from 'lodash'
import { useSnackbar } from 'notistack'

import { CustomSnackbar } from '@tk/frontend/primitives/CustomSnackbar'

import { Fids, FieldSelectionModelType } from './types'

interface UseFieldRowSelectionModelProps {
  fids: Fids
  fieldSelectionModel: FieldSelectionModelType
}

const useFieldRowSelectionModel = ({
  fids,
  fieldSelectionModel,
}: UseFieldRowSelectionModelProps) => {
  const snackbar = useSnackbar()

  const onRowSelectionModelChange = useCallback(
    (newModel: GridRowSelectionModel) => {
      const addedIds = difference(newModel, fieldSelectionModel.model)
      const removedIds = difference(fieldSelectionModel.model, newModel)

      const newSelections = fids.filter((fid) => addedIds.includes(fid.rowId))

      const alreadySelectedFidIds = fieldSelectionModel.model
        .map((id) => fids.find((f) => f.rowId === id)?.fidId)
        .filter(Boolean)

      const availableSelections = newSelections.filter(
        (fid) => !alreadySelectedFidIds.includes(fid.fidId)
      )

      const unavailableSelections = newSelections.filter((fid) =>
        alreadySelectedFidIds.includes(fid.fidId)
      )

      const toggledGroupName = fids.find(
        (fid) => fid.rowId === (addedIds[0] || removedIds[0])
      )?.fidGroupName

      if (toggledGroupName && newSelections.length > 0) {
        const groupRows = fids.filter(
          (fid) => fid.fidGroupName === toggledGroupName
        )
        const groupRowIds = groupRows.map((fid) => fid.rowId)

        const selectedGroupIds = groupRowIds.filter(
          (id) =>
            fieldSelectionModel.model.includes(id) && !addedIds.includes(id)
        )

        const isPartiallyOrFullySelected = selectedGroupIds.length > 0

        const isIndividualSelect =
          newSelections.length === 1 && groupRowIds.length > 1

        if (isPartiallyOrFullySelected && !isIndividualSelect) {
          fieldSelectionModel.setModel(
            fieldSelectionModel.model.filter(
              (id) => !groupRowIds.includes(Number(id))
            )
          )
        } else {
          if (unavailableSelections.length > 0) {
            snackbar.enqueueSnackbar(
              `${unavailableSelections.length} field(s) were not selected because they are already selected in another group.`,
              {
                content: (key, message) => (
                  <CustomSnackbar key={key} message={message} variant="info" />
                ),
              }
            )
          }

          fieldSelectionModel.setModel([
            ...fieldSelectionModel.model,
            ...availableSelections.map((fid) => fid.rowId),
          ])
        }
      } else {
        fieldSelectionModel.setModel(newModel)
      }
    },
    [fids, fieldSelectionModel, snackbar]
  )

  return { onRowSelectionModelChange }
}

export default useFieldRowSelectionModel
