import { useCallback, useMemo } from 'react'

import { createEnumParam, useQueryParam, withDefault } from 'use-query-params'

import { useProjectTabValidity } from './useProjectTabValidity'

const tabs = ['editor', 'test'] as const

const TabParam = withDefault(createEnumParam([...tabs]), 'editor')

export type TabValue = (typeof tabs)[number]

export function useProjectTab() {
  const [_tab, setTab] = useQueryParam('tab', TabParam)
  const tab = _tab ?? 'editor'
  const tabIndex = useMemo(() => {
    return tabs.indexOf(tab)
  }, [tab])

  const isNextTabValid = useProjectTabValidity(tabs[tabIndex + 1])
  const isPreviousTabValid = useProjectTabValidity(tabs[tabIndex - 1])

  const goToNextTab = useCallback(() => {
    setTab((currentTab) => {
      if (!currentTab) {
        return 'editor'
      }

      const currentIndex = tabs.indexOf(currentTab)

      return tabs[currentIndex + 1] ?? currentTab
    }, 'replaceIn')
  }, [setTab])

  const goToPreviousTab = useCallback(() => {
    setTab((currentTab) => {
      if (!currentTab) {
        return 'editor'
      }

      const currentIndex = tabs.indexOf(currentTab)

      return tabs[currentIndex - 1] ?? currentTab
    }, 'replaceIn')
  }, [setTab])

  return {
    tab,
    setTab,
    tabIndex,
    goToNextTab,
    goToPreviousTab,
    isNextTabValid,
    isPreviousTabValid,
  }
}
