import { ReactNode } from 'react'

import { Grid, GridProps, Paper, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Container = styled(Paper)`
  padding: 1rem;

  min-height: 100%;
`

const TitleBox = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};

  text-transform: uppercase;
`

export type SectionTitleProps = {
  children: ReactNode
}

export function SectionTitle({ children }: SectionTitleProps) {
  return (
    <TitleBox>
      <Typography margin={0} fontSize="0.8rem">
        {children}
      </Typography>
    </TitleBox>
  )
}

export type SectionProps = {
  title?: string
  children: ReactNode
  gridColumns?: number
} & GridProps

export function Section({
  children,
  title,
  gridColumns = 6,
  width,
  ...props
}: SectionProps) {
  return (
    <Grid item width={width} xs={gridColumns} {...props}>
      <Container>
        <Stack>
          {title && <SectionTitle>{title}</SectionTitle>}

          {children}
        </Stack>
      </Container>
    </Grid>
  )
}
