import { ReactNode } from 'react'

import { Cancel } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'

export type FilterTagProps = {
  label: ReactNode
  children: ReactNode
  onDelete?: () => void
}

export function FilterTag({ label, children, onDelete }: FilterTagProps) {
  return (
    <Stack
      data-test-id="filter-tag"
      direction="row"
      alignItems="stretch"
      overflow="hidden"
      maxWidth="70rem"
      borderRadius="5px"
      spacing={3}
      bgcolor={(theme) => theme.palette.grey[100]}
      boxShadow={3}
      height="1.5rem"
    >
      <Stack
        direction="row"
        borderRadius="4px"
        alignItems="center"
        px={3}
        bgcolor={(theme) => theme.palette.secondary.main}
        color="white"
      >
        <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
          {label}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={0} alignItems="center" overflow="hidden">
        <Typography
          fontSize={12}
          sx={{
            maxWidth: '40rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: onDelete ? '0px' : '15px',
          }}
        >
          {children}
        </Typography>

        {onDelete && (
          <IconButton
            sx={{
              'borderRadius': 1,
              'color': (theme) => theme.palette.grey[400],
              'py': 0,
              'transition': '0.15s ease-in-out',
              '&:hover': { color: (theme) => theme.palette.grey[500] },
              'height': '100%',
            }}
            onClick={onDelete}
            title="Delete"
          >
            <Cancel fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}
