import { useCallback } from 'react'

import { trpc } from '@tk/frontend/api'
import { useFileDownloader } from '@tk/frontend/primitives'

export function useSheetTemplateDownload() {
  const downloadMutation =
    trpc.recordProjects.prepareDownloadTemplate.useMutation({
      retry: false,
    })

  const fileDownloader = useFileDownloader('record-design-template.xlsx')

  const startDownload = useCallback(async () => {
    const data = await downloadMutation.mutateAsync()

    await fileDownloader(data.downloadUri)
  }, [fileDownloader, downloadMutation])

  return { startDownload }
}
