import * as Icons from '@mui/icons-material'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { trpc } from '@tk/frontend/api'
import { usePromiseNotification } from '@tk/frontend/primitives'
import { TwoLineTextCell } from '@tk/frontend/primitives/datagrid/cells'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { EntitlementsCell } from './EntitlementsCell'

export function RolesPage() {
  const notify = usePromiseNotification()

  const [list] = trpc.admin.roles.list.useSuspenseQuery()
  const roleAssumer = trpc.admin.userRoles.assume.useMutation({
    onSuccess() {
      window.location.pathname = '/'
    },
  })

  return (
    <PageContentLayout title="Admin: Roles" maxContentWidth={false}>
      <TableContainer sx={{ padding: '0.5rem', paddingTop: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>Entitlements</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list.map((role) => {
              return (
                <TableRow key={role.value}>
                  <TableCell>
                    <TwoLineTextCell line1={role.label} line2={role.value} />
                  </TableCell>

                  <TableCell>
                    <EntitlementsCell entitlements={role.entitlements} />
                  </TableCell>

                  <TableCell>
                    <Button
                      startIcon={<Icons.Policy />}
                      color="warning"
                      onClick={async () => {
                        await notify(
                          roleAssumer.mutateAsync({
                            type: 'assume',
                            role: role.value,
                          }),
                          {
                            progressMessage: 'Assuming role: ' + role.label,
                            successMessage: 'Assumed role: ' + role.label,
                            failureMessage:
                              'Failed assuming role: ' + role.label,
                          }
                        )
                      }}
                    >
                      Assume
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContentLayout>
  )
}
