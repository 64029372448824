import { useNavigate } from 'react-router-dom'

import { Button } from '@mui/material'

import { trpc } from '@tk/frontend/api'
import { ConfirmPopover, usePromiseNotification } from '@tk/frontend/primitives'
import { telemetry } from '@tk/frontend/telemetry'

import { generateRelinkListUri } from './Relink.routes'

export type ApplyRelinkButtonProps = {
  taskId: number
  disabled?: boolean
}

export function ApplyRelinkButton({
  taskId,
  disabled,
}: ApplyRelinkButtonProps) {
  const notify = usePromiseNotification()
  const persist = trpc.records.relink.commit.useMutation()
  const navigate = useNavigate()

  return (
    <ConfirmPopover
      prompt="This will apply all changes to PGS and may take some time, do you want to continue?"
      onOk={async () => {
        await notify(persist.mutateAsync({ taskId }), {
          progressMessage: 'Applying Record linking to PGS',
          failureMessage: 'Record linking failed',
          successMessage: 'Record linking now in progress',
        })

        telemetry.capture('gtk/relink/apply', { importId: taskId })
        navigate(generateRelinkListUri(taskId))
      }}
    >
      <Button variant="contained" disabled={persist.isPending || disabled}>
        Apply All Changes
      </Button>
    </ConfirmPopover>
  )
}
