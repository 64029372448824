import { getFormComponents } from '@tk/frontend/primitives'

import { RecordEditDto } from './types'
import { useRecordReferenceLists } from './useRecordReferenceLists'

const Form = getFormComponents<RecordEditDto>()

export function RecordAssetTypeSelect() {
  const [lists] = RecordAssetTypeSelect.useRecordReferenceLists()

  return (
    <Form.Field
      label="Asset Type"
      name="assetType.id"
      input={<Form.SelectField group options={lists.assetType} />}
    />
  )
}

RecordAssetTypeSelect.useRecordReferenceLists = useRecordReferenceLists
