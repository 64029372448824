import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { ROUTES } from '@tk/frontend/app/routes'

import { ApproveAndCreateButton } from './ApproveAndCreate'
import { useProjectCrud } from './useProjectCrud'

export function ActionButton() {
  const { project } = useProjectCrud()

  const navigate = useNavigate()
  return (
    <>
      {!project.complete && <ApproveAndCreateButton />}

      {project.complete && (
        <Button
          startIcon={<Icons.ListRounded />}
          onClick={() =>
            navigate(
              ROUTES.app.recordManagement.defaultRecords({
                touchedByNames: project.name,
              })
            )
          }
        >
          View Records
        </Button>
      )}
    </>
  )
}
