import { Route, Routes } from 'react-router-dom'

import * as routes from './Relink.routes'
import { RelinkProgressList } from './RelinkProgressList'
import { RelinkProgressPreview } from './RelinkProgressPreview'

export function RelinkRouter() {
  return (
    <Routes>
      <Route path={routes.relative.list} element={<RelinkProgressList />} />
      <Route
        path={routes.relative.preview}
        element={<RelinkProgressPreview />}
      />
    </Routes>
  )
}
