import React from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useGridApiRef } from '@mui/x-data-grid-premium'

import { ExtractMenu } from '@tk/frontend/app/DataExtract'
import { RecordDesignerMenu } from '@tk/frontend/app/RecordDesigner/RecordDesignerMenu'
import { When } from '@tk/frontend/primitives'
import { useRowSelectionModel } from '@tk/frontend/primitives/datagrid'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { CommercialPackagesMenu } from './CommercialPackagesMenu'
import { CreateRecordButton } from './Create'
import { EditRecordButton } from './Edit'
import { ImportExportMenu } from './ImportExportMenu'
import { RecordsFilter } from './RecordsFilter'
import { RecordsTable } from './RecordsTable'
import { RecordsTableFilterRow } from './RecordsTableFilterRow'
import { RelinkRecordsMenu } from './RelinkRecordsMenu'
import { useNavigationParamsRedirect } from './useNavigationParamsRedirect'
import { useRecordsQueryParams } from './useRecordsQueryParams'
import { useRecordsTableTelemetry } from './useRecordsTableTelemetry'

export function RecordsTablePage() {
  const gridApiRef = useGridApiRef()
  const selection = useRowSelectionModel()

  useNavigationParamsRedirect()

  const params = useRecordsQueryParams()

  useRecordsTableTelemetry({
    filter: params.filter,
    page: params.pagination.page,
  })

  return (
    <PageContentLayout
      title="Records"
      childrenContainer={<React.Fragment />}
      controls={
        <>
          <When
            flag="dataextract"
            can={['data-extract.read', 'data-extract-sales-snap.manage']}
          >
            <ExtractMenu />
          </When>

          <RelinkRecordsMenu />

          <CommercialPackagesMenu />

          <RecordDesignerMenu />

          <ImportExportMenu />
        </>
      }
      maxContentWidth="100%"
    >
      <Paper sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center">
          <Box display="flex" flex="1 1 auto" flexDirection="row">
            <RecordsFilter />
          </Box>

          <Box flex="0 0 auto" paddingRight="1rem">
            <Stack direction="row" spacing={1}>
              <When can="records.manage">
                <CreateRecordButton />
              </When>

              <EditRecordButton selectedId={selection.selectedId} />
            </Stack>
          </Box>
        </Stack>
      </Paper>

      <RecordsTableFilterRow gridApiRef={gridApiRef} />

      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flex: '1 1 0',
          overflow: 'hidden',
        }}
      >
        <RecordsTable gridApiRef={gridApiRef} />
      </Paper>
    </PageContentLayout>
  )
}
