import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { trpc, useUtils } from '@tk/frontend/api'
import { NamedBusinessObjectForm } from '@tk/frontend/primitives/datagrid/tree'
import {
  NamedBusinessObjectCore,
  useReferenceDataCrudForm,
} from '@tk/frontend/telemetry/reference-data-crud'

import { SourceForm } from './SourceForm'
import { Backbone, Source } from './types'

type BackboneModel = {
  type: 'backbone'
  data: Partial<Backbone>
}

type SourceModel = {
  type: 'source'
  data: Partial<Source>
}

export const MutationModal = create<BackboneModel | SourceModel>((model) => {
  const modal = useModal()

  let title = ''
  if (model.type === 'source') {
    title = !model.data.id ? 'New Source' : 'Editing Source'
  } else {
    title = !model.data.id ? 'New Backbone' : 'Editing Backbone'
  }

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {model.type === 'backbone' ? (
          <NamedBusinessObjectForm
            initialValues={model.data}
            useMutation={useBackboneMutation}
            onFinished={modal.hide}
            useTelemetry={useTelemetry}
          />
        ) : (
          <SourceForm initialValues={model.data} onFinished={modal.hide} />
        )}
      </DialogContent>
    </Dialog>
  )
})

function useBackboneMutation(isExisting: boolean) {
  const utils = useUtils()

  const { useMutation } = isExisting
    ? trpc.reference.backbone.update
    : trpc.reference.backbone.create

  return useMutation({
    onSuccess() {
      utils.reference.backbone.invalidate()
      utils.reference.source.invalidate()
    },
  })
}

function useTelemetry(values: Partial<NamedBusinessObjectCore>) {
  return useReferenceDataCrudForm('region', values)
}
