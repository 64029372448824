import { Box, Divider, Stack, Typography } from '@mui/material'
import {
  DataGridPremium,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridRowClassNameParams,
} from '@mui/x-data-grid-premium'

import { useFilterModel } from '@tk/frontend/primitives/datagrid'
import {
  getGroupingColDef,
  TREE_CHILD_CLASS,
  TREE_PARENT_CLASS,
  TreeDataGridStyles,
} from '@tk/frontend/primitives/datagrid/tree'

import { AffectedRecordsSummary } from './AffectedRecordsSummary'
import { DocumentLoadButtons } from './DocumentLoadButtons'
import { useProjectColumns } from './useProjectColumns'
import { ProjectDocument, useProjectCrud } from './useProjectCrud'
import { useProjectTreeRows } from './useProjectTreeRows'

export type MultibrandLine = Exclude<
  ProjectDocument['lines'],
  undefined
>['result'][number]

export function ProjectEditorTab() {
  const treeRows = useProjectTreeRows()

  const filterModel = useFilterModel('editor-filters')
  const columns = useProjectColumns()

  return (
    <Stack flex={1} spacing={0}>
      <Stack
        direction="row"
        alignItems="center"
        paddingTop="1rem"
        paddingX="1rem"
        marginBottom="1rem"
      >
        <Box flex={1} />

        <DocumentLoadButtons />

        <Divider orientation="vertical" flexItem variant="middle" />

        <AffectedRecordsSummary />
      </Stack>

      <Divider orientation="horizontal" />

      <Stack flex={1}>
        <TreeDataGridStyles>
          <DataGridPremium
            rows={treeRows}
            density="compact"
            disableAggregation
            disableRowGrouping
            initialState={{
              pinnedColumns: {
                left: [
                  GRID_TREE_DATA_GROUPING_FIELD,
                  'id',
                  'row.inputSource',
                  'row.inputName',
                  '_inputNameRecord',
                ],
              },
            }}
            groupingColDef={getGroupingColDef(
              {
                width: 125,
              },
              {
                getLabel(count) {
                  return `${count} Inputs`
                },
              }
            )}
            treeData
            getTreeDataPath={(row) => row.groupKey}
            columns={columns}
            getRowId={(row) => row.id}
            filterModel={filterModel.model}
            onFilterModelChange={filterModel.setModel}
            experimentalFeatures={{
              columnGrouping: true,
              lazyLoading: true,
            }}
            defaultGroupingExpansionDepth={-1}
            columnGroupingModel={[
              {
                groupId: 'original_record',
                headerName: 'Input Record',
                renderHeaderGroup(params) {
                  return (
                    <Typography variant="subtitle2">
                      {params.headerName}
                    </Typography>
                  )
                },
                children: [
                  { field: 'row.inputSource' },
                  { field: 'row.inputName' },
                  { field: '_inputNameRecord' },
                ],
              },
              {
                groupId: 'sourceFid',
                headerName: 'Output Record',
                renderHeaderGroup(params) {
                  return (
                    <Typography variant="subtitle2">
                      {params.headerName}
                    </Typography>
                  )
                },
                children: [{ field: 'row.outputSource' }],
              },
              {
                groupId: 'rrn',
                headerName: 'Root Record Name',
                renderHeaderGroup(params) {
                  return (
                    <Typography variant="subtitle2">
                      {params.headerName}
                    </Typography>
                  )
                },
                children: [{ field: 'row.stub' }, { field: 'row.rrn' }],
              },
              {
                groupId: 'attributes',
                headerName: 'Attributes',
                renderHeaderGroup(params) {
                  return (
                    <Typography variant="subtitle2">
                      {params.headerName}
                    </Typography>
                  )
                },
                children: [
                  { field: 'row.location' },
                  { field: 'row.clearing' },
                  { field: 'row.priceType' },
                  { field: 'row.frequency' },
                ],
              },
              {
                groupId: 'brand',
                headerName: 'Brand',
                renderHeaderGroup(params) {
                  return (
                    <Typography variant="subtitle2">
                      {params.headerName}
                    </Typography>
                  )
                },
                children: [{ field: 'row.brandId' }],
              },
            ]}
            slots={{
              noRowsOverlay: NoRowsOverlay,
            }}
            sx={{ border: 'none' }}
            getRowClassName={getRowClassName}
          />
        </TreeDataGridStyles>
      </Stack>
    </Stack>
  )
}

const getRowClassName = (params: GridRowClassNameParams<MultibrandLine>) =>
  params.row.id > 1_000_000 ? TREE_PARENT_CLASS : TREE_CHILD_CLASS

function NoRowsOverlay() {
  const { project } = useProjectCrud()

  let isWarning = false
  let text = 'No rows yet, to start please fill in the template and upload it'

  if (!project.document.isValid && project.document.lines?.status === 'error') {
    isWarning = true
    text =
      'There is a problem with the file you uploaded, please ensure it matches the template format'
  }

  return (
    <Box
      width="100%"
      height="100%"
      maxHeight="10rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        padding="0.5rem 1rem"
        borderRadius="0.5rem"
        bgcolor={(t) => (isWarning ? t.palette.warning.light : undefined)}
      >
        <Typography variant="h4" fontWeight="700">
          {text}
        </Typography>
      </Box>
    </Box>
  )
}
