import { useCallback, useDeferredValue } from 'react'

import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { useDebounce } from '@lib/web'

type Options = {
  name?: string
  defaultValue?: string
}

export function useFilterQueryParams(opts?: Options) {
  const [value, setValue] = useQueryParam(
    opts?.name ?? 'filter',
    withDefault(StringParam, opts?.defaultValue ?? '')
  )
  const safeValue = value ?? opts?.defaultValue ?? ''

  const deferredValue = useDeferredValue(safeValue)
  const debouncedValue = useDebounce(safeValue, 400)

  return {
    value: safeValue,
    deferredValue: deferredValue,
    debouncedValue: debouncedValue,
    setValue: useCallback(
      (value: string | undefined) => {
        setValue(value, 'replaceIn')
      },
      [setValue]
    ),
  }
}
