import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  labelify,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'
import { useReferenceDataCrudForm } from '@tk/frontend/telemetry/reference-data-crud'

import { Location } from './types'

type LocationValues = Partial<Omit<Location, 'region'>> & {
  region?: Partial<Location['region']> | undefined
}

const Form = getFormComponents<LocationValues>()

export type Props = {
  initialValues: LocationValues
  onFinished?: () => void
}

export function LocationForm({ initialValues = {}, onFinished }: Props) {
  const id = initialValues.id
  const isExisting = typeof id === 'number' && id > 0
  const progress = usePromiseNotification()

  const form = Form.useForm({
    defaultValues: initialValues,
  })
  const { handleSubmit: _handleSubmit, formState, getValues } = form

  const { trackEditSave, trackEditValidationError } = useReferenceDataCrudForm(
    'location',
    initialValues
  )

  const mutation = LocationForm.useLocationMutation(isExisting)
  const [region] = LocationForm.useRegionQuery()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync(values as any)
        const result = await progress(
          promise,
          isExisting
            ? {
                progressMessage: 'Updating ' + values.name,
                successMessage: 'Updated ' + values.name,
                failureMessage: 'Error updating ' + values.name,
              }
            : {
                progressMessage: 'Creating',
                successMessage: 'Created',
                failureMessage: 'Error creating',
              }
        )

        trackEditSave(formState.dirtyFields, result)

        onFinished?.()
      },
      (err) => {
        trackEditValidationError(formState.dirtyFields, err, getValues())
      }
    )
  }, [
    _handleSubmit,
    formState.dirtyFields,
    getValues,
    isExisting,
    mutation,
    onFinished,
    progress,
    trackEditSave,
    trackEditValidationError,
  ])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        {isExisting && <input hidden type="number" {...form.register('id')} />}

        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />
        <Form.Field
          label="Region (Required)"
          name="region.id"
          input={
            <Form.SelectField options={region} rules={Form.rules.required} />
          }
        />

        <Form.Row>
          <Button type="submit">{isExisting ? 'Update' : 'Create'}</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useLocationMutation(isExisting: boolean) {
  const utils = useUtils()

  const { useMutation } = isExisting
    ? trpc.reference.location.update
    : trpc.reference.location.create

  return useMutation({
    onSuccess() {
      utils.reference.region.invalidate()
      utils.reference.location.invalidate()
    },
  })
}
LocationForm.useLocationMutation = useLocationMutation

function useRegionQuery() {
  return trpc.reference.region.list.useSuspenseQuery(undefined, {
    select: (items) => {
      return items.map<Option>((item) => {
        return {
          label: labelify(item.name, item.description),
          value: item.id,
        }
      })
    },
  })
}
LocationForm.useRegionQuery = useRegionQuery
