import { useMemo } from 'react'
import { get } from 'react-hook-form'

import * as Icons from '@mui/icons-material'
import { Typography } from '@mui/material'
import {
  GRID_ROOT_GROUP_ID,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { uniqBy } from 'lodash'

import { trpc } from '@tk/frontend/api'
import { ROUTES } from '@tk/frontend/app/routes'
import { AppLink } from '@tk/frontend/primitives'
import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'
import { createMultibrandRecordName } from '@tk/lib/projects'

import { MultibrandLine } from './ProjectPage'
import { useProjectCrud } from './useProjectCrud'

type ExtraCols = {
  _stub?: undefined
  _inputNameRecord?: undefined
  _multibrandRecord?: undefined
  groupKey: string[]
}

export type Columns = MultibrandLine & ExtraCols

export function useProjectColumns() {
  const { projectName } = useProjectCrud()

  const [summary] = trpc.recordProjects.getSummary.useSuspenseQuery({
    name: projectName,
  })

  return useMemo(() => {
    return createColumns<Columns>([
      {
        type: 'string',
        field: 'id',
        headerName: 'Row Number',
        valueGetter(params: GridValueGetterParams<MultibrandLine>) {
          // Parents have IDs over 1m and don't have valid row numbers
          if (params.row.id < 1_000_000) {
            // spreadsheet is 1 indexed AND has a header, so +2
            return get(params.row, params.field) + 2
          }

          return ''
        },
        renderCell(params) {
          return (
            <Typography
              fontFamily="monospace"
              sx={{ opacity: 0.5 }}
              fontWeight={900}
            >
              {params.value}
            </Typography>
          )
        },
        align: 'right',
        headerAlign: 'right',
        sortable: true,
        width: 100,
      },
      {
        type: 'string',
        field: 'row.inputSource',
        headerName: 'Source',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 100,
      },
      {
        type: 'string',
        field: 'row.inputName',
        headerName: 'Record',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 150,
      },
      {
        type: 'boolean',
        field: '_inputNameRecord',
        headerName: 'Exists?',
        filterable: true,
        valueGetter(params) {
          const inputSourceName = params.row.row?.inputSource
          const inputName = params.row.row?.inputName
          if (!inputName || !inputSourceName || !summary) {
            return
          }

          const key = inputSourceName + ':' + inputName
          const existingId = summary.recordIdsByKey[key]

          return existingId
        },
        renderCell(params) {
          const existingId = params.value
          if (!existingId) {
            return ''
          }

          const { isChild, getGroupRows } = getTreeGroupRows(params)
          if (!isChild && getGroupRows().length > 1) {
            // When grouping multiple inputs we need this link in the children
            // But when no grouping we want it in the parent
            return null
          }

          return existingId ? (
            <AppLink
              to={ROUTES.app.recordManagement.records + '/edit/' + existingId}
              target="_blank"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icons.OpenInNew fontSize="small" />
            </AppLink>
          ) : (
            ''
          )
        },
        width: 75,
      },
      {
        type: 'string',
        field: 'row.outputSource',
        headerName: 'Source',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 100,
      },
      {
        type: 'string',
        field: 'row.stub',
        headerName: 'Stub',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 75,
      },
      {
        type: 'string',
        field: 'row.rrn',
        headerName: 'Root Record Name',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 150,
      },
      getDividerTokenColDef(':'),
      {
        type: 'string',
        field: 'row.location',
        headerName: 'Location',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 75,
      },
      {
        type: 'string',
        field: 'row.clearing',
        headerName: 'Clearing',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 75,
      },
      {
        type: 'string',
        field: 'row.priceType',
        headerName: 'Price Type',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 75,
      },
      {
        type: 'string',
        field: 'row.frequency',
        headerName: 'Frequency',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 75,
      },
      getDividerTokenColDef('!'),
      {
        type: 'string',
        field: 'row.brand',
        headerName: 'Brand',
        valueGetter,
        renderCell,
        filterable: true,
        sortable: true,
        width: 50,
      },
      {
        type: 'boolean',
        field: '_multibrandRecord',
        headerName: 'Exists?',
        filterable: true,
        valueGetter(params) {
          if (!params.row.row) {
            return
          }

          const outputSourceName = params.row.row.outputSource
          const outputName = createMultibrandRecordName(params.row.row)
          if (!outputName || !outputSourceName || !summary) {
            return
          }

          const key = outputSourceName + ':' + outputName
          const existingId = summary.recordIdsByKey[key]

          return existingId
        },
        renderCell(params) {
          const existingId = params.value
          if (!existingId) {
            return ''
          }

          const { isChild } = getTreeGroupRows(params)
          if (isChild) {
            // We don't display multibrand data in the child rows since it's always identical so we also hide the link here
            return null
          }

          return existingId ? (
            <AppLink
              to={ROUTES.app.recordManagement.records + '/edit/' + existingId}
              target="_blank"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Icons.OpenInNew fontSize="small" />
            </AppLink>
          ) : (
            ''
          )
        },
        width: 75,
      },
    ]).map(setColumnDefaults)
  }, [summary])
}

// Not sure why we need this, the datagrid should 'just work' but it's not
function valueGetter(params: GridValueGetterParams<MultibrandLine>) {
  return get(params.row, params.field)
}

function renderCell<T extends GridRenderCellParams<MultibrandLine>>(params: T) {
  const errorField = params.field.replace('row.', 'error.')
  const error = get(params.row, errorField)

  //
  // Change the parent row text to include either a summary or the sole unique value as the label

  const { isChild, getGroupRows } = getTreeGroupRows(params)

  const uniqueValues = uniqBy(getGroupRows(), (it) =>
    get(it, params.field)
  ).filter(Boolean)

  let isRealValue = true
  let label = params.value
  if (isChild) {
    if (uniqueValues.length === 1) {
      label = ``
    }
  } else {
    if (uniqueValues.length > 1) {
      label = `(${uniqueValues.length} Unique)`
      isRealValue = false
    }
  }

  return (
    <Typography
      color={error ? 'red' : undefined}
      fontFamily="monospace"
      fontWeight={isRealValue ? 500 : 900}
      sx={{
        opacity: isRealValue ? 1 : 0.5,
      }}
    >
      {error ?? label}
    </Typography>
  )
}

export function getDividerTokenColDef(token: string): GridColDef {
  return {
    type: 'string',
    field: token as any,
    headerName: '',
    hideable: false,
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    renderHeader(params) {
      return null
    },
    renderCell(params) {
      const { isChild } = getTreeGroupRows(params)
      if (isChild) {
        // We don't display multibrand data in the child rows since it's always identical so we also hide the syntax here
        return null
      }

      return (
        <Typography variant="h2" fontWeight={500} fontFamily="monospace">
          {token}
        </Typography>
      )
    },
    width: 5,
  }
}

function getTreeGroupRows<T extends GridRenderCellParams<MultibrandLine>>(
  params: T
) {
  const api = params.api as GridApiPremium
  const isChild =
    !!params.rowNode.parent && params.rowNode.parent !== GRID_ROOT_GROUP_ID

  function getGroupRows() {
    return api
      .getRowGroupChildren({
        groupId: isChild ? params.rowNode.parent! : params.rowNode.id,
      })
      .map((rowId) => api.getRow(rowId) as MultibrandLine)
  }

  return { isChild, getGroupRows }
}
