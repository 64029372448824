import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { StringParam, useQueryParam } from 'use-query-params'

export type ImportListFilterState = 'all' | 'completed' | 'ready' | 'failed'

export function useImportListParam() {
  const [_value, setValue] = useQueryParam('row-filter', StringParam)

  const value = _value as ImportListFilterState | undefined | null

  return [
    value ?? 'all',
    (value: ImportListFilterState) => setValue(value, 'replaceIn'),
  ] as const
}

export function ImportListToggleFilter() {
  const [value, onChange] = useImportListParam()

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value: ImportListFilterState) => onChange(value)}
    >
      <ToggleButton value={'all' as ImportListFilterState} color="info">
        All
      </ToggleButton>

      <ToggleButton value={'ready' as ImportListFilterState} color="info">
        Active
      </ToggleButton>

      <ToggleButton value={'completed' as ImportListFilterState} color="info">
        Completed
      </ToggleButton>

      <ToggleButton value={'failed' as ImportListFilterState} color="warning">
        Failed
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
