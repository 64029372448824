import { Path } from 'react-hook-form'

import { Filter, RecordDto, RecordRequest } from '@lib/pgs'

type FilterNames = Path<RecordDto & { filter: 'filter' }>

type FilterFieldKeys = keyof RecordRequest

const filterFieldMappings = {
  filter: 'filter',
  assetTypeId: 'assetType.name',
  marketGroupId: 'marketGroups',
  chainIds: 'parentChains',
  fidGroupIds: 'fidGroup.name',
  sourceId: 'source.name',
  currencyId: 'currency1.name',
  touchedByNames: 'touchedByNames',
  subscriptionId: 'subscription',
  status: 'status',
  type: 'type',
  scope: 'scope',
} as Record<FilterFieldKeys, FilterNames>

export const transformFiltersToTags = (
  filtersType: 'SUBTRACTIVE' | 'ADDITIVE',
  filters?: Filter[]
) =>
  (filters || [])
    .filter((filter) => filter.type === filtersType)
    .map((filter) =>
      Object.entries(filter.recordRequest)
        .filter(([key]) => key in filterFieldMappings)
        .flatMap(([key, filterValue]) => {
          const value = Array.isArray(filterValue) ? filterValue : [filterValue]
          const filterName = filterFieldMappings[key as FilterFieldKeys]

          return value
            .filter((v) => v !== null)
            .map((itemValue, i) => ({
              key: `${filterName}-${itemValue}-${i}`,
              field: filterName,
              value: itemValue,
            }))
        })
    )
