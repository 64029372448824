import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { EntitlementRulesetDto } from '@lib/pgs'
import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { useEntitlementLists } from './useEntitlementList'

export type RulesetFormValues = Inputs['entitlements']['ruleset']['create'] & {
  id: number
}

const Form = getFormComponents<RulesetFormValues>()

export type UpsertFormProps = {
  initialValues: Partial<EntitlementRulesetDto>
  onFinished?: () => void
}

export function RulesetForm({ initialValues, onFinished }: UpsertFormProps) {
  const form = Form.useForm({
    defaultValues: { ...initialValues },
  })

  const { distributionList, actorList, marketGroupList } =
    RulesetForm.useEntitlementList()
  const { handleSubmit: _handleSubmit } = form

  const progress = usePromiseNotification()
  const id = initialValues.id
  const isExisting = typeof id === 'number' && id > 0
  const mutation = RulesetForm.useRulesetMutation(isExisting)

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync({
          ...values,
          metadata:
            values.metadata?.trim() === '' ? undefined : values.metadata,
        })
        await progress(
          promise,
          isExisting
            ? {
                progressMessage: 'Updating ' + values.name,
                successMessage: 'Updated ' + values.name,
                failureMessage: 'Error updating ' + values.name,
              }
            : {
                progressMessage: 'Creating',
                successMessage: 'Created',
                failureMessage: 'Error creating',
              }
        )

        onFinished?.()
      },
      (errs) => {
        console.warn('Validation error', errs)
      }
    )
  }, [_handleSubmit, isExisting, mutation, onFinished, progress])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        {isExisting && <input hidden type="number" {...form.register('id')} />}

        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />

        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Field
          name="distribution.id"
          label="Distribution (Required)"
          input={
            <Form.SelectField
              options={distributionList}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Field
          name="actor.id"
          label="Actor (Required)"
          input={
            <Form.SelectField options={actorList} rules={Form.rules.required} />
          }
        />

        <Form.Field
          name="marketGroup.id"
          label="Market Group (Required)"
          input={
            <Form.SelectField
              options={marketGroupList}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Field
          name="metadata"
          label="Metadata"
          input={
            <Form.TextField
              multiline={{ minRows: 3 }}
              rules={{
                validate: (value) => {
                  if (typeof value === 'string' && value.trim() !== '') {
                    try {
                      JSON.parse(value)
                      return true
                    } catch (e) {
                      return 'Invalid JSON format. Please provide a valid JSON string.'
                    }
                  }
                  return true
                },
              }}
            />
          }
        />

        <Form.Row>
          <Button type="submit">{isExisting ? 'Update' : 'Save'}</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useRulesetMutation(isExisting: boolean) {
  const utils = useUtils()

  const { useMutation } = isExisting
    ? trpc.entitlements.ruleset.update
    : trpc.entitlements.ruleset.create

  return useMutation({
    onSuccess() {
      utils.entitlements.ruleset.invalidate()
    },
  })
}

RulesetForm.useRulesetMutation = useRulesetMutation
RulesetForm.useEntitlementList = useEntitlementLists
