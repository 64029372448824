import { Path } from 'react-hook-form'

import * as gz from '@lib/pgs-types'
import { Defined } from '@lib/utils'
import { Inputs, Outputs } from '@tk/frontend/api'
import { labelify } from '@tk/frontend/primitives'
import { GridEnrichedColDef } from '@tk/frontend/primitives/datagrid'
import {
  CellBaseEntity,
  CellOption,
} from '@tk/frontend/primitives/datagrid/edit-cells'

export type RecordMappingDto = Outputs['bloomberg']['mapping']['get']

export type RecordMappingListQuery = Inputs['bloomberg']['mapping']['list']

export type ImportPreviewOutput = Extract<
  Outputs['bloomberg']['mapping']['import']['preview'],
  { status: 'success' }
>

export type MarketEntityDto = Exclude<
  ImportPreviewOutput['response']['content'][number]['currentVersion'],
  undefined
>
export type ImportPreviewDto = gz.ImportPreviewManyToOne<MarketEntityDto>

export type PreviewWithDiffPage = Extract<
  Outputs['bloomberg']['mapping']['import']['previewWithDiff'],
  { status: 'success' }
>

export type PreviewWithDiffItem = PreviewWithDiffPage['content'][number]

export type PreviewWithDiffItemChange = PreviewWithDiffItem['changes'][number]

export type PreviewWithDiffItemChangeMapping = Exclude<
  Extract<PreviewWithDiffItemChange, { type: 'update' }>['current'],
  undefined
>

export type TreeItemRecord = {
  _type: 'record'
  recordId: number
  recordMapping: RecordMappingDto
} & RecordMappingDto

export type TreeItemMapping = {
  _type: 'mapping'
  recordId: number
  recordMapping: RecordMappingDto
} & Defined<RecordMappingDto['bloombergMappings']>[number]

export type TreeItem = TreeItemRecord | TreeItemMapping

export type RecordMappingColName = Path<Required<TreeItemMapping>>

export type RecordMappingColDef = GridEnrichedColDef<Required<TreeItemMapping>>

export function flattenRecordMappings(recordMapping: RecordMappingDto[]) {
  return recordMapping.flatMap<TreeItem>((recordMapping) => {
    const treeItemRecord: TreeItemRecord = {
      ...recordMapping,
      _type: 'record',
      recordId: recordMapping.id,
      recordMapping: recordMapping,
    }

    const treeItemMappings: TreeItemMapping[] =
      recordMapping.bloombergMappings?.map<TreeItemMapping>(
        (bloombergMapping) => {
          return {
            ...bloombergMapping,
            _type: 'mapping',
            recordId: recordMapping.id,
            recordMapping: recordMapping,
          }
        }
      ) ?? []

    const treeItems: TreeItem[] = []

    return treeItems.concat([treeItemRecord], treeItemMappings)
  })
}

export function transformUiListToSelectorCellOptions<
  TEntity extends CellBaseEntity
>(data: TEntity[]): CellOption<TEntity>[] {
  return (data ?? []).map((item) => {
    return {
      label: labelify(item.name, item.description),
      value: item.id,
      entity: item,
    }
  })
}
