import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'

import { TypedBackbone, TypedRowUnion, TypedSource } from './types'

export function useRows() {
  const [backbones] = trpc.reference.backbone.list.useSuspenseQuery(undefined, {
    select(data): TypedBackbone[] {
      return data.map<TypedBackbone>((item) => {
        return {
          ...item,
          _type: 'backbone',
        }
      })
    },
  })

  const [sources] = trpc.reference.source.list.useSuspenseQuery(undefined, {
    select(data): TypedSource[] {
      return data.map<TypedSource>((item) => {
        return {
          ...item,
          _type: 'source',
        }
      })
    },
  })

  const rows = useMemo<TypedRowUnion[]>(() => {
    const parent = backbones ?? []
    const child = sources ?? []

    return [...parent, ...child]
  }, [sources, backbones])

  return rows
}
