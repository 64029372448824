import * as gz from '@lib/pgs-types'
import { Outputs } from '@tk/frontend/api'
import { GridEnrichedColDef } from '@tk/frontend/primitives/datagrid'

export type Source = Outputs['reference']['source']['list'][0]
export type Backbone = Exclude<Source['backbone'], undefined>

export type SourceStatus = (typeof gz.SourceStatus)['_output']
export const SourceStatusOptions = gz.SourceStatus.options.map(
  (opt) => opt.value
)

//
// DataGrid Structures

export type AllKeys = Source & Backbone

export type { GridEnrichedColDef }

//
// Tree View Data

export type TypedBackbone = Backbone & {
  _type: 'backbone'
}
export type TypedSource = Source & {
  _type: 'source'
}
export type TypedRowUnion = TypedBackbone | TypedSource
