import React from 'react'

import { Box } from '@mui/material'

import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { ApiUserCreateButton } from './ApiUserCreateButton'
import ApiUsersCrudTable from './ApiUsersCrudTable'

export function AdminPage() {
  return (
    <PageContentLayout
      title="Api Users - Admin"
      controls={<ApiUserCreateButton />}
      maxContentWidth={false}
    >
      <Box flexGrow={1} height="100%" width="100%">
        <ApiUsersCrudTable />
      </Box>
    </PageContentLayout>
  )
}

export default AdminPage
