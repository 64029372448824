import { useMemo } from 'react'

import { GridSortDirection } from '@mui/x-data-grid-premium'
import { createEnumParam, StringParam, useQueryParam } from 'use-query-params'

export type SortDirection = Extract<GridSortDirection, string>
export const sortDirKeys = ['asc', 'desc'] as SortDirection[]

const SortDirParam = createEnumParam(sortDirKeys)

export function useSortQueryParams<TSortField extends string>(
  defaultField: TSortField,
  defaultDirection: SortDirection = 'asc'
) {
  const [sort, setSort] = useQueryParam('sort', StringParam)
  const [direction, setDirection] = useQueryParam('direction', SortDirParam)

  return useMemo(() => {
    return {
      sort: (sort ?? defaultField) as TSortField,
      setSort,
      direction: (direction ?? defaultDirection) as SortDirection,
      setDirection,
    } as const
  }, [defaultDirection, defaultField, direction, setDirection, setSort, sort])
}
