import { ReactNode, useMemo } from 'react'

import { Tooltip, Typography, TypographyProps } from '@mui/material'
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium'

import { Outputs } from '@tk/frontend/api'
import { PairedBusinessObjectsCell } from '@tk/frontend/primitives/datagrid/cells'
import { createColumns } from '@tk/frontend/primitives/datagrid/columns'

export type ValidationRow = Outputs['recordProjects']['testProject'][number]

export function useTestingColumns() {
  return useMemo(() => {
    return createColumns<ValidationRow>([
      {
        type: 'singleSelect',
        field: 'type',
        headerName: 'Record Column',
        valueOptions: [
          { value: 'input', label: 'Input' },
          { value: 'output', label: 'Output' },
        ],
        renderHeader: getHoverInfoHeader(
          'When creating records, we will add both the Input and Output side to PGS, and link them together'
        ),
      },
      {
        type: 'string',
        field: 'record',
        headerName: 'Record',
        width: 250,
        renderCell(params) {
          return (
            <PairedBusinessObjectsCell
              dto1={{ name: params.row.record }}
              dto2={{
                name: params.row.source.backbone + ':' + params.row.source.name,
              }}
              fontFamily="monospace"
            />
          )
        },
      },
      {
        type: 'boolean',
        field: 'isValid',
        headerName: 'Is Valid',
        renderHeader: getHoverInfoHeader(
          'Indicates whether all conditions have passed successfully'
        ),
      },
      {
        type: 'string',
        field: 'marketDataState.rtdsState',
        headerName: 'RTDS Status',
        renderHeader: getHoverInfoHeader(
          <>
            <p>the current RTDS State for the Record</p>
            <ul>
              <li>OK = Live & Ticking</li>
              <li>
                CLOSED = Indicates the Record is offline either temporarily or
                permanently, or it never existed
              </li>
              <li>
                CLOSED_RECOVER = Indicates the Record is offline temporarily
              </li>
              <li>
                SUSPECT = Indicates some or all of the item's data is stale or
                unknown
              </li>
            </ul>
          </>
        ),
        valueGetter(params) {
          return params.row.marketDataState.rtdsState
        },
      },
      {
        type: 'boolean',
        field: 'validations.hasEdfMarker',
        headerName: 'EDF Marker',
        renderHeader: getHoverInfoHeader(
          'FIDs which are published on RTDS for this record, but are not in the selected Field Group'
        ),
        width: 100,
        valueGetter(params) {
          return params.row.validations.hasEdfMarker
        },
        renderCell(params) {
          return (
            <Typography
              color={
                params.value
                  ? (t) => t.palette.success.main
                  : (t) => t.palette.error.main
              }
              fontFamily="monospace"
            >
              {params.value ? 'OK' : 'Missing'}
            </Typography>
          )
        },
      },
      {
        type: 'string',
        field: 'validations.onBothFidGroupAndInFeedAndWithValue',
        headerName: 'Healthy Fields',
        renderHeader: getHoverInfoHeader(
          'FIDs which which are in both the selected Field Group and published on RTDS'
        ),
        width: 100,
        valueGetter(params) {
          if (params.row.type === 'input') {
            return 'N/A'
          }

          return (
            params.row.validations.onBothFidGroupAndInFeedAndWithValue.length +
            params.row.validations.onBothFidGroupAndInFeedButNullValue.length
          )
        },
        renderCell: getColoredFieldValidationCell(
          (val) => (t) =>
            val === 0 ? t.palette.error.main : t.palette.primary.main
        ),
      },
      {
        type: 'string',
        field: 'validations.onBothFidGroupAndInFeedButNullValue',
        headerName: 'Fields with NULL value',
        renderHeader: getHoverInfoHeader(
          'FIDs which which are in both the selected Field Group and published on RTDS, but which are publishing NULL values'
        ),
        width: 150,
        valueGetter(params) {
          if (params.row.type === 'input') {
            return 'N/A'
          }

          return (
            params.row.validations.onBothFidGroupAndInFeedButNullValue.length ||
            ''
          )
        },
        renderCell: getColoredFieldValidationCell(
          () => (t) => t.palette.error.main
        ),
      },
      {
        type: 'string',
        field: 'validations.inFidGroupButNotOnFeed',
        headerName: 'Fields not on Feed',
        renderHeader: getHoverInfoHeader(
          'FIDs which are defined in the selected Field Group, but were not published on RTDS for this record'
        ),
        width: 150,
        valueGetter(params) {
          if (params.row.type === 'input') {
            return 'N/A'
          }

          return params.row.validations.inFidGroupButNotOnFeed.length || ''
        },
        renderCell: getColoredFieldValidationCell(
          () => (t) => t.palette.error.main
        ),
      },
      {
        type: 'string',
        field: 'validations.onFeedButNotInFidGroup',
        headerName: 'Fields only on Feed',
        renderHeader: getHoverInfoHeader(
          'FIDs which are published on RTDS for this record, but are not in the selected Field Group'
        ),
        width: 150,
        valueGetter(params) {
          if (params.row.type === 'input') {
            return 'N/A'
          }

          return params.row.validations.onFeedButNotInFidGroup.length || ''
        },
        renderCell: getColoredFieldValidationCell(
          () => (t) => t.palette.error.main
        ),
      },
    ])
  }, [])
}

function getColoredFieldValidationCell(
  color: (value: any) => TypographyProps['color']
) {
  return function renderCell<T extends GridRenderCellParams<ValidationRow>>(
    params: T
  ) {
    if (params.row.type === 'input') {
      return (
        <Typography color="gray" fontFamily="monospace">
          N/A
        </Typography>
      )
    }

    return (
      <Typography color={color(params.value)} fontFamily="monospace">
        {params.value}
      </Typography>
    )
  }
}

function getHoverInfoHeader(text: ReactNode) {
  return function renderCell<
    T extends GridColumnHeaderParams<ValidationRow, any, any>
  >(params: T) {
    return (
      <Tooltip title={text}>
        <span>{params.colDef.headerName ?? ''}</span>
      </Tooltip>
    )
  }
}
