import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { FidGroupRequestDto } from '@lib/pgs'

import { FidGroupCloneForm } from './FidGroupCloneForm'

type Model = {
  data: Partial<FidGroupRequestDto>
}

export const FidGroupCloneModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>{`Clone Field Group for ${model.data.name}`}</DialogTitle>
      <DialogContent>
        <FidGroupCloneForm data={model.data} onFinished={modal.hide} />
      </DialogContent>
    </Dialog>
  )
})
