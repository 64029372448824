import { trpc } from '@tk/frontend/api'

type Option = { label: string; value: any }

export function useEntitlementLists() {
  const { data: distributionList } =
    trpc.entitlements.distribution.list.useQuery(undefined, {
      select(data) {
        return data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          } as Option
        })
      },
    })

  const { data: actorList } = trpc.entitlements.actor.list.useQuery(undefined, {
    select(data) {
      return data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        } as Option
      })
    },
  })

  const { data: marketGroupList } =
    trpc.commercialPackages.ui.listPackages.useQuery()

  return { distributionList, actorList, marketGroupList }
}
