import { Link, To, useNavigate } from 'react-router-dom'

import { Button, ButtonProps } from '@mui/material'

export type ButtonLinkProps = ButtonProps & {
  to?: To | undefined
  target?: string
}

// TODO: this isn't exactly spec compliant but does work.
// A more spec compliant link will just take some effort and this is a cheap solution for now
export function ButtonLink({
  to,
  target,
  children,
  disabled,
  ...props
}: ButtonLinkProps) {
  const navigate = useNavigate()
  const isDisabled = disabled || !to

  const buttonInner = (
    <Button
      variant="contained"
      disabled={isDisabled}
      color="primary"
      {...props}
    >
      {children}
    </Button>
  )

  if (isDisabled) {
    return buttonInner
  }

  return (
    <Link
      to={to ?? {}}
      target={target}
      onClick={(e) => {
        // This is necessary because when used inside a table cell, the table cell hijacks the click handler
        e.stopPropagation()
        e.preventDefault()
        navigate(to ?? {})
      }}
      style={{
        textDecoration: 'none',
        cursor: isDisabled ? 'default' : undefined,
      }}
    >
      {buttonInner}
    </Link>
  )
}
