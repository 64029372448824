import { useCallback } from 'react'

import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'

import { trpc } from '@tk/frontend/api'
import { ButtonLink } from '@tk/frontend/primitives'
import { NameDateCell } from '@tk/frontend/primitives/datagrid/cells'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import {
  ImportProcessingStates,
  ImportState,
  ImportStateColour,
  ImportStateLabel,
} from '@tk/frontend/primitives/simple-import'
import { theme } from '@tk/frontend/theme'

import {
  ImportListFilterState,
  ImportListToggleFilter,
  useImportListParam,
} from './ImportListToggleFilter'
import { generateImportPreviewUri } from './Imports.routes'

type SortLabel = 'id' | 'name'

type Direction = 'asc' | 'desc'

const getStatusFromFilterState = (
  status?: ImportListFilterState
): ImportState[] | undefined => {
  switch (status) {
    case 'all':
      return undefined
    case 'ready':
      return ['READY_TO_PERSIST', 'GENERATING_PREVIEW', 'NEW', 'PERSISTING']
    case 'completed':
      return ['COMPLETED']
    case 'failed':
      return ['CANCELLED', 'FAILED']
    default:
      return undefined
  }
}

const PREVIEWABLE_STATES: ImportState[] = ['READY_TO_PERSIST', 'FAILED']

export function ImportProgressList() {
  const [focusedTaskId] = useQueryParam('focus', NumberParam)
  const [filterValue, setFilterValue] = useQueryParam('filter', StringParam)
  const [sort = 'id', setSort] = useQueryParam('sort', StringParam)
  const [direction = 'desc', setDirection] = useQueryParam(
    'direction',
    StringParam
  )
  const [status] = useImportListParam()

  const handleSortChange = useCallback(
    (columnName: SortLabel) => {
      if (sort === columnName) {
        setDirection(direction === 'asc' ? 'desc' : 'asc')
      } else {
        setSort(columnName)
        setDirection('desc')
      }
    },
    [direction, setDirection, setSort, sort]
  )

  const importTaskList = trpc.records.import.taskList.useQuery(
    {
      filter: filterValue ?? '',
      sort: sort as SortLabel,
      direction: direction as Direction,
      states: getStatusFromFilterState(status),
    },
    {
      refetchInterval: 2500,
      refetchIntervalInBackground: true,
    }
  )

  return (
    <PageContentLayout
      title="Record Imports"
      controls={
        <>
          <CircularProgress
            sx={{ opacity: importTaskList.isRefetching ? 1 : 0 }}
            size="1.5rem"
            thickness={10}
            variant="indeterminate"
          />
        </>
      }
    >
      <Stack display="flex" height="100%">
        <Stack direction="row">
          <TextField
            value={filterValue ?? ''}
            onChange={(e) => setFilterValue(e.target.value)}
            label="Filter"
            variant="filled"
            inputProps={{
              role: 'search',
            }}
            placeholder='You may use wildcards (%) to search for partial Name. ie. "%{File name}%"'
            fullWidth
          />

          <ImportListToggleFilter />
        </Stack>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={150}>
                  <TableSortLabel
                    active={sort === 'id'}
                    direction={sort === 'id' ? (direction as Direction) : 'asc'}
                    onClick={() => handleSortChange('id')}
                  >
                    Task Id
                  </TableSortLabel>
                </TableCell>

                <TableCell width={300}>
                  <TableSortLabel
                    active={sort === 'name'}
                    direction={
                      sort === 'name' ? (direction as Direction) : 'asc'
                    }
                    onClick={() => handleSortChange('name')}
                  >
                    Task Name
                  </TableSortLabel>
                </TableCell>

                <TableCell width={300}>Submitted</TableCell>
                <TableCell width={150}>Processed</TableCell>
                <TableCell width={150}>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {importTaskList.isInitialLoading && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                    <CircularProgress
                      sx={{ opacity: importTaskList.isInitialLoading ? 1 : 0 }}
                      size="2rem"
                      thickness={5}
                      variant="indeterminate"
                    />
                  </TableCell>
                </TableRow>
              )}

              {importTaskList.data?.content?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                    No Data Found!
                  </TableCell>
                </TableRow>
              )}

              {importTaskList.data?.content?.map((item) => (
                <TableRow
                  key={item.taskId}
                  style={{
                    borderLeft:
                      focusedTaskId === item.taskId
                        ? `5px solid ${theme.palette.primary.main}`
                        : undefined,
                    backgroundColor:
                      focusedTaskId === item.taskId
                        ? theme.palette.grey[300]
                        : undefined,
                  }}
                >
                  <TableCell>{item.taskId}</TableCell>

                  <TableCell>{item.name}</TableCell>

                  <TableCell>
                    <NameDateCell
                      name={item.submittedBy}
                      date={item.submittedAt}
                    />
                  </TableCell>

                  <TableCell>
                    {item.processedCount === 0 && item.totalCount === 0
                      ? ''
                      : `${item.processedCount} / ${item.totalCount}`}
                  </TableCell>

                  <TableCell>
                    <Stack
                      direction="row"
                      color={ImportStateColour[item.state]}
                    >
                      {ImportProcessingStates.includes(item.state) && (
                        <CircularProgress size="1rem" thickness={10} />
                      )}

                      <strong>
                        {ImportStateLabel[item.state] ??
                          'Unknown: ' + item.state}
                      </strong>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    {PREVIEWABLE_STATES.includes(item.state) && (
                      <ButtonLink to={generateImportPreviewUri(item.taskId)}>
                        Preview Changes
                      </ButtonLink>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </PageContentLayout>
  )
}
