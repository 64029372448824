import { useCallback } from 'react'

import { trpc } from '@tk/frontend/api'
import { useFileDownloader } from '@tk/frontend/primitives'

export function useSheetDownload(current?: {
  objectKey: string
  fileName: string
}) {
  const downloadMutation =
    trpc.recordProjects.prepareDownloadSpreadsheet.useMutation({
      retry: false,
    })

  const fileDownloader = useFileDownloader(current?.fileName)

  const startDownload = useCallback(async () => {
    if (!current) {
      return
    }

    const data = await downloadMutation.mutateAsync({
      objectKey: current.objectKey,
      fileName: current.fileName,
    })

    await fileDownloader(data.downloadUri)
  }, [current, fileDownloader, downloadMutation])

  return { startDownload }
}
