import { trpc } from '@tk/frontend/api'
import { usePromiseNotification } from '@tk/frontend/primitives'
import { useFileUploader } from '@tk/frontend/primitives/useFileUploader'

import { useProjectCrud } from './useProjectCrud'

export function useSheetUpload() {
  const { projectName, invalidateProject, optimisticUpdate } = useProjectCrud()

  const prepareUpload =
    trpc.recordProjects.prepareUploadSpreadsheet.useMutation({
      retry: false,
    })

  const uploadSpreadsheet = trpc.recordProjects.uploadSpreadsheet.useMutation({
    retry: false,
    onSuccess(data, variables, context) {
      invalidateProject()
    },
  })

  const notify = usePromiseNotification()

  const fileUpload = useFileUploader({
    types: 'xlsx',
    async onFile(file, uploadFile) {
      async function go() {
        const { objectKey, uploadUri } = await prepareUpload.mutateAsync()

        await uploadFile(file, uploadUri)

        const result = await uploadSpreadsheet.mutateAsync({
          file: {
            objectKey,
            name: file.name,
          },
          project: {
            name: projectName,
          },
        })

        optimisticUpdate(result)
      }

      await notify(go(), {
        progressMessage: 'Importing Spreadsheet',
        failureMessage: 'Error Importing Spreadsheet',
        successMessage: 'Imported Spreadsheet',
      })
    },
  })

  return fileUpload
}
