import { MutableRefObject } from 'react'

import { Alert, Stack } from '@mui/material'
import { DataGridPremium, GridApi } from '@mui/x-data-grid-premium'

import {
  defaultProps,
  useColumnVisibilityModel,
  useFilterModel,
  useRowSelectionModel,
  useSortModel,
} from '@tk/frontend/primitives/datagrid'

import { RecordDto } from './types'
import { useRecordColumns } from './useRecordColumns'
import { useRecordsList } from './useRecordsList'
import { SortBy, useRecordsQueryParams } from './useRecordsQueryParams'

export type RecordsTableProps = {
  gridApiRef?: MutableRefObject<GridApi>
}
const ARRAY: any[] = []
export function RecordsTable({ gridApiRef }: RecordsTableProps) {
  const params = useRecordsQueryParams()
  const sort = useSortModel<SortBy>('name', 'asc')
  const selection = useRowSelectionModel()

  const [list, listQuery] = useRecordsList()
  const rows = list.content ?? ARRAY

  const filterModel = useFilterModel()

  const cols = useRecordColumns()
  const columnVisibility = useColumnVisibilityModel<RecordDto>({
    id: false,
    parentChains: false,
    touchedByNames: false,
  })

  return (
    <Stack
      flex="1 1 0"
      minHeight={0}
      minWidth={0}
      style={{ backgroundColor: 'white' }}
    >
      <DataGridPremium
        {...defaultProps}
        columns={cols}
        density="compact"
        //
        loading={
          listQuery.isFetching ||
          (!listQuery.error && listQuery.data?.page !== params.pagination.page)
        }
        rows={rows}
        rowCount={list.totalElements ?? 0}
        //
        columnVisibilityModel={columnVisibility.model}
        onColumnVisibilityModelChange={columnVisibility.setModel}
        //
        pagination
        paginationMode="server"
        paginationModel={params.pagination}
        onPaginationModelChange={params.onPaginationChange}
        pageSizeOptions={params.sizeOptions}
        //
        sortingMode="server"
        sortModel={sort.model}
        onSortModelChange={sort.setModel}
        rowSelectionModel={selection.model}
        onRowSelectionModelChange={selection.setModel}
        keepNonExistentRowsSelected
        //
        disableColumnPinning={false}
        pinnedColumns={{ left: ['name'], right: ['actions'] }}
        //
        filterMode="server"
        filterModel={filterModel.model}
        onFilterModelChange={filterModel.setModel}
        slotProps={filterModel.slotProps}
        //
        apiRef={gridApiRef}
        //
        slots={{
          noRowsOverlay: () => {
            if (
              listQuery.error &&
              listQuery.error.shape &&
              'pgs' in listQuery.error.shape &&
              listQuery.error.shape?.pgs.message ===
                'Invalid regular expression'
            ) {
              return (
                <Alert severity="error">
                  There is a problem with your filters, please refine your
                  search futher and try again.
                </Alert>
              )
            }

            return <Alert severity="info">No Records Found</Alert>
          },
        }}
      />
    </Stack>
  )
}
