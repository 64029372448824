import { useCallback } from 'react'

import {
  getDefaultGridFilterModel,
  GridFilterModel,
  GridLogicOperator,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-premium'
import { JsonParam, QueryParamConfig, useQueryParam } from 'use-query-params'

// FIXME: this produces a tidier URI than JsonParam, BUT it's also not good.
// We should have a dedicated QueryParams setup which does a fundamental
//  transformation of GridFilterModel to a nice-ish URL.
const Base64Param: QueryParamConfig<GridFilterModel | undefined> = {
  ...JsonParam,
  decode(value) {
    if (typeof value === 'string') {
      return JsonParam.decode(window.atob(value))
    }
  },
  encode(value) {
    const json = JsonParam.encode(value)

    if (typeof json === 'string') {
      return window.btoa(json)
    }
    return undefined
  },
}

type SetFilterModelParam =
  | GridFilterModel
  | ((oldModel: GridFilterModel | undefined) => GridFilterModel)

const DefaultGridFilterModel = getDefaultGridFilterModel()

export function useFilterModel(name = 'grid-filters'): {
  model: GridFilterModel | undefined
  setModel: (model: SetFilterModelParam) => void
  slotProps: GridSlotsComponentsProps
} {
  const [model, setModel] = useQueryParam<GridFilterModel | undefined>(
    name,
    Base64Param
  )

  return {
    model: model ?? DefaultGridFilterModel,
    setModel: useCallback(
      (model: SetFilterModelParam) => setModel(model, 'replaceIn'),
      [setModel]
    ),
    slotProps,
  }
}

const slotProps: GridSlotsComponentsProps = {
  filterPanel: {
    columnsSort: 'asc',
    logicOperators: [GridLogicOperator.And],
  },
}
