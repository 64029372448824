import { GridFilterInputValueProps } from '@mui/x-data-grid-premium'

import { trpc } from '@tk/frontend/api'
import {
  ServersideGridFilter,
  ServersideGridFilterOption,
} from '@tk/frontend/primitives/datagrid/columns'

export function GridFilterSubscription(props: GridFilterInputValueProps) {
  return (
    <ServersideGridFilter
      {...props}
      group
      useFilteredList={useFilteredList}
      useSelectedOption={useSelectedOption}
    />
  )
}

function useFilteredList(filter: string | undefined) {
  return trpc.reference.subscription.list.useQuery(
    {
      filter: '%' + filter + '%',
    },
    {
      select(data): ServersideGridFilterOption[] {
        return (
          data.map((option) => {
            return {
              label: option.accountName,
              value: option.id,
            }
          }) ?? []
        )
      },
    }
  )
}

function useSelectedOption(selectedIds: number[] | undefined) {
  const all = trpc.useQueries((t) => {
    return (selectedIds ?? []).map((id) =>
      t.reference.subscription.get(
        {
          id: id,
        },
        {
          select(option): ServersideGridFilterOption {
            return {
              label: option.accountName,
              value: option.id,
            }
          },
        }
      )
    )
  })

  return all.map((v) => v.data!).filter(Boolean)
}
