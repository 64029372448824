import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { When } from '@tk/frontend/primitives'

import { MutationModal } from './MutationModal'

export function BackboneCreateButton() {
  const modal = useModal(MutationModal)

  return (
    <When can="reference-data.manage">
      <Button
        startIcon={<Icons.Add />}
        variant="contained"
        onClick={() =>
          modal.show({
            type: 'backbone',
            data: {},
          })
        }
      >
        Create
      </Button>
    </When>
  )
}
