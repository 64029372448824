import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Paper, Stack } from '@mui/material'
import {
  DataGridPremium,
  GridRowClassNameParams,
} from '@mui/x-data-grid-premium'

import { RecordPreview } from '@lib/pgs'
import { trpc } from '@tk/frontend/api'
import * as routes from '@tk/frontend/app/routes'
import {
  defaultProps,
  usePaginationQueryParams,
} from '@tk/frontend/primitives/datagrid'
import { PageContentLayout, PaperCell } from '@tk/frontend/primitives/layout'
import {
  ImportPreviewRowFilter,
  PreviewNotFound,
  usePreviewRowFilterParam,
} from '@tk/frontend/primitives/simple-import'

import { ApplyRelinkButton } from './ApplyRelinkButton'
import { groupingColDef, TREE_CLASSES } from './constants'
import { RelinkTaskSummary } from './RelinkTaskSummary'
import { useGenerateColumns } from './useGenerateColumns'
import { useGenerateRows } from './useGenerateRows'

export function RelinkProgressPreview() {
  const { id: idString } = useParams<{ id: string }>()
  const id = parseInt(idString!, 10)

  const [rowFilter] = usePreviewRowFilterParam()
  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [rowFilter],
    initialPageSize: 100,
  })

  const [preview, previewQuery] = trpc.records.relink.preview.useSuspenseQuery({
    taskId: id,
    page: pagination.page,
    size: pagination.pageSize,
    invalidOnly: rowFilter === 'errors',
  })

  const [status] = trpc.records.relink.status.useSuspenseQuery({ taskId: id })

  const numErrors = useMemo(() => {
    if (preview.status !== 'success') {
      return 0
    }

    return preview.response.content.reduce((errorCount: number, item: any) => {
      const newVersionRecords = item.newVersion?.records || []
      const errorRecords = newVersionRecords.filter(
        (record: RecordPreview) => record.action === 'ERROR'
      )
      return errorCount + errorRecords.length
    }, 0)
  }, [preview])

  const rows = useGenerateRows(preview)

  const columns = useGenerateColumns()

  return preview.status === 'success' ? (
    <PageContentLayout
      title="Record Linking Preview"
      controls={
        <ApplyRelinkButton
          taskId={id}
          disabled={numErrors > 0 || status.state === 'FAILED'}
        />
      }
      childrenContainer={<></>}
      maxContentWidth={false}
    >
      <Stack display="flex" height="100%">
        <Stack direction="row">
          {/* Pass the number of errors to RelinkTaskSummary */}
          <RelinkTaskSummary taskId={id} numErrors={numErrors} />
          <PaperCell>
            <ImportPreviewRowFilter />
          </PaperCell>
        </Stack>

        <Paper
          sx={{ display: 'flex', flex: '1 1 0', minHeight: 0, minWidth: 0 }}
        >
          <DataGridPremium
            {...defaultProps}
            treeData
            getTreeDataPath={getTreeDataPath}
            pagination
            paginationMode="server"
            paginationModel={pagination}
            onPaginationModelChange={onPaginationChange}
            pageSizeOptions={[100]}
            defaultGroupingExpansionDepth={-1}
            rows={rows}
            rowCount={preview.response.totalElements ?? 0}
            columns={columns}
            getRowId={getRowId}
            getRowClassName={getRowClassName}
            loading={previewQuery.isRefetching}
            groupingColDef={groupingColDef}
            rowBuffer={5}
          />
        </Paper>
      </Stack>
    </PageContentLayout>
  ) : (
    <PreviewNotFound
      label="Records"
      path={routes.app.recordManagement.records}
    />
  )
}

const getRowId = (row: { id: string; parentId?: string; _type: string }) => {
  return `${row.parentId ?? ''}-${row._type}-${row.id}`
}

const getTreeDataPath = (row: {
  _type: string
  id: string
  parentId?: string
}) => {
  switch (row._type) {
    case 'marketEntity':
      return [`marketEntity-${row.id}`]
    case 'record':
      return [`marketEntity-${row.parentId}`, `record-${row.id}`]
    default:
      return []
  }
}

const getRowClassName = (
  params: GridRowClassNameParams<{ _type: string; action?: string }>
) => {
  const { _type, action } = params.row

  switch (_type) {
    case 'marketEntity':
      return TREE_CLASSES.PARENT
    case 'record':
      switch (action) {
        case 'UNLINKED':
          return 'row-removed'
        case 'UNMOVED':
          return 'row-unchanged'
        case 'LINKED':
          return 'row-added'
        default:
          return ''
      }
    default:
      return TREE_CLASSES.CHILD
  }
}
