import { useCallback } from 'react'

import { useModal } from '@parameta/nice-modal-react'

import { usePromiseNotification } from '@tk/frontend/primitives'

import { ModalRenderer } from './ModalRenderer'
import { SimpleExportEntity, useTelemetry } from './telemetry'
import { RouteDef } from './types'

export type UseSimpleExportOpts = {
  entity: SimpleExportEntity
  filter?: string | undefined
  simpleExportRoute: RouteDef
}

export function useSimpleExport({
  entity,
  filter,
  simpleExportRoute,
}: UseSimpleExportOpts) {
  const { trackStart } = useTelemetry(entity)
  const createExport = simpleExportRoute.start.useMutation()
  const modal = useModal(ModalRenderer)

  const notify = usePromiseNotification()

  const startExport = useCallback(async () => {
    const { taskId } = await notify(
      createExport.mutateAsync({
        filter: filter,
      }),
      {
        progressMessage: 'Starting export...',
        successMessage: 'Export started',
        failureMessage: 'Export failed',
      }
    )

    trackStart(taskId)

    modal.show({
      type: 'export',
      taskId,
      simpleExportRoute,
      entity,
    })
  }, [
    createExport,
    entity,
    filter,
    modal,
    notify,
    simpleExportRoute,
    trackStart,
  ])

  return {
    startExport,
  }
}
