import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { DistributionForm } from './DistributionForm'

type Model = {}

export const CreateModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>New Distribution</DialogTitle>
      <DialogContent>
        <DistributionForm onFinished={modal.hide} />
      </DialogContent>
    </Dialog>
  )
})
