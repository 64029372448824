import { useCallback } from 'react'

import { Button } from '@mui/material'

import { trpc } from '@tk/frontend/api'
import { useFileDownloader } from '@tk/frontend/primitives'

export const DownloadButton = ({ id }: { id: number }) => {
  const downloadMutation = trpc.extracta.getDownloadURL.useMutation({
    retry: false,
  })

  const fileDownloader = useFileDownloader('data-extract')

  const startDownload = useCallback(async () => {
    const data = await downloadMutation.mutateAsync(id)

    await fileDownloader(data.downloadUrl)
  }, [fileDownloader, downloadMutation, id])

  return <Button onClick={startDownload}>Download</Button>
}
