import { useMemo } from 'react'

import produce from 'immer'
import { groupBy } from 'lodash'

import { createMultibrandRecordName, MultibrandLine } from '@tk/lib/projects'

import { useProjectCrud } from './useProjectCrud'

export function useProjectTreeRows() {
  const { project } = useProjectCrud()

  return useMemo(() => {
    const rows = project.document.lines?.result ?? []

    const rowsByOutputRecord = groupBy(rows, (it) =>
      it.row ? createMultibrandRecordName(it.row) : 'No Output'
    )
    const keys = Object.keys(rowsByOutputRecord)

    return keys.flatMap((key) => {
      const rowsForOutputRecord = rowsByOutputRecord[key]

      const seenCounts = new Map<string, number>()
      const children = rowsForOutputRecord.map((line) => {
        const inputKey = getInputKey(line.row)

        const groupKey =
          rowsForOutputRecord.length === 1
            ? [key]
            : [key, `${line.row?.inputSource}:${line.row?.inputName}`]

        // If the row is a duplicate then the key will collide and crash the datagrid, we need to suffix the duplicate number to it
        if (inputKey) {
          const seenCount = seenCounts.get(inputKey)
          seenCounts.set(inputKey, seenCount ? seenCount + 1 : 1)

          if (seenCount) {
            groupKey[groupKey.length - 1] += seenCount
          }
        }

        return {
          ...line,
          groupKey: groupKey,
        }
      })

      if (children.length > 1) {
        const parent = produce(
          rowsForOutputRecord[0] as (typeof children)[number],
          (draft) => {
            draft.id = draft.id + 1000000
            draft.groupKey = [key]
          }
        )

        return [parent, ...children]
      } else {
        return [children[0]]
      }
    })
  }, [project.document.lines?.result])
}

const getInputKey = (row?: MultibrandLine | null) =>
  row ? row.inputSource + ':' + row.inputName : null
