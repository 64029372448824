import { useEffect } from 'react'

import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Stack,
  Step,
  stepConnectorClasses,
  Stepper,
} from '@mui/material'

import { ProjectStepButton } from './ProjectStepButton'
import { useProjectCrud } from './useProjectCrud'
import { useProjectTab } from './useProjectTab'

export function ProjectStepper() {
  const tabParams = useProjectTab()

  const { project } = useProjectCrud()

  useEffect(() => {
    if (tabParams.tab === 'test' && !project.document.isValid) {
      tabParams.setTab('editor', 'replaceIn')
    }
  }, [project.document.isValid, tabParams])

  return (
    <Stack
      direction="row"
      sx={{
        height: '4rem',
        padding: '1rem',
      }}
    >
      <Stepper
        nonLinear
        activeStep={tabParams.tabIndex}
        sx={{
          width: '100%',
          [`.${stepConnectorClasses.root}`]: {
            maxWidth: '5rem',
          },
        }}
      >
        <Step>
          <ProjectStepButton tabId="editor" label="Records" />
        </Step>
        <Step>
          <ProjectStepButton
            tabId="test"
            label="Test"
            disabled={project.document.isValid}
          />
        </Step>
      </Stepper>
      <ButtonGroup variant="outlined">
        <Button
          startIcon={<ChevronLeft />}
          disabled={!tabParams.isPreviousTabValid}
          onClick={tabParams.goToPreviousTab}
        >
          Previous
        </Button>
        <Button
          endIcon={<ChevronRight />}
          disabled={!tabParams.isNextTabValid}
          onClick={tabParams.goToNextTab}
        >
          Next
        </Button>
      </ButtonGroup>
    </Stack>
  )
}
