import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'

// This component is a temporary patch while Okta gets themselves upgraded to support the react 18 and react-router v6 combination
// TODO: https://github.com/okta/okta-react/issues/178

export const SecurePath = () => {
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      )
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect()
    }
  }, [oktaAuth, authState?.isAuthenticated, authState])

  if (!authState || !authState?.isAuthenticated) {
    // TODO: display a global spinner during this phase.
    return null
  }

  return <Outlet />
}
