import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import {
  Autocomplete,
  CircularProgress,
  FilterOptionsState,
  Popper,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { QueryObserverBaseResult } from '@tanstack/react-query'
import { sortBy } from 'lodash'

export type Option<T = any> = {
  group?: string
  label: string
  value: T
}

export type InputValue = FilterOptionsState<Option<FieldValues>>

export type UseFilteredList = (
  filter: string | undefined
) => QueryObserverBaseResult<Option[] | undefined, any>

export type UseSelectedOption = (
  selectedValues: number[] | undefined
) => QueryObserverBaseResult<Option[] | undefined, any>

export interface ServersideSelectFieldProps<
  FormValues extends FieldValues = FieldValues
> {
  name?: Path<FormValues>
  label?: string
  placeholder?: string
  options?: Option[]
  width?: string
  fullWidth?: boolean
  variant?: TextFieldProps['variant']
  rules?: UseControllerProps<FormValues>['rules']
  group?: boolean
  autoFocus?: boolean
  disabled?: boolean
  useFilteredList: UseFilteredList
  useSelectedOption: UseSelectedOption
}

const EMPTY_OPTION: Option = {
  label: '[None]',
  value: '',
  group: '',
}
const LOADING_OPTION: Option = {
  label: 'Loading...',
  value: '',
  group: '',
}

const isOptionEqual = (option: Option, value: Option) => {
  return option.value === value.value
}

function coerce(value: string | Option | undefined): Option | undefined {
  return typeof value === 'string' ? { label: value, value: value } : value
}

export function ServersideSelectField<
  FormValues extends FieldValues = FieldValues
>(props: ServersideSelectFieldProps<FormValues>) {
  const { field, fieldState } = useController<FormValues>({
    name: props.name ?? ('' as any),
    rules: props.rules,
  })

  const [inputValue, setInputValue] = useState('')

  const filteredOptionsQuery = props.useFilteredList(inputValue)

  const selectedOptionsData = props.useSelectedOption([field.value])
  const options = useMemo(() => {
    let result = filteredOptionsQuery.data ?? []

    const knownIds = new Set(result.map((option) => option.value))
    for (const selectedOption of selectedOptionsData.data ?? []) {
      if (!knownIds.has(selectedOption.value)) {
        result.push(selectedOption)
      }
    }

    if (props.group) {
      result = sortBy(result, (option) => [option.group ?? '', option.label])
    }

    return result
  }, [filteredOptionsQuery.data, props.group, selectedOptionsData.data])

  const errorMessage = fieldState.error?.message

  const inputRef = useRef<any>()
  const focus = useRef(props.autoFocus)
  useLayoutEffect(() => {
    if (focus.current) {
      inputRef.current?.focus?.()
    }
  }, [])

  const isInitialLoading =
    (field.value && selectedOptionsData.isLoading) ||
    filteredOptionsQuery.isLoading

  return (
    <Autocomplete
      size="small"
      sx={{
        width: props.width ?? (props.fullWidth ? 'auto' : '7rem'),
      }}
      disableClearable
      loading={isInitialLoading}
      options={isInitialLoading ? [] : [EMPTY_OPTION].concat(options)}
      groupBy={
        props.group ? (option) => option.group ?? 'Ungrouped' : undefined
      }
      getOptionLabel={(option) => coerce(option)?.label ?? ''}
      PopperComponent={SelectPopper}
      isOptionEqualToValue={isOptionEqual}
      {...field}
      selectOnFocus
      fullWidth={props.fullWidth}
      value={
        isInitialLoading
          ? LOADING_OPTION
          : selectedOptionsData.data?.[0] ?? EMPTY_OPTION
      }
      onChange={(e, item, reason) => {
        field.onChange(coerce(item)?.value)
      }}
      autoComplete
      onInputChange={(ev, val, reason) => {
        if (reason === 'reset') {
          setInputValue('')
        } else {
          setInputValue(val)
        }
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label}
            variant={props.variant ?? 'standard'}
            size="small"
            placeholder={props.placeholder}
            error={!!errorMessage}
            autoFocus={props.autoFocus}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {isInitialLoading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      thickness={10}
                    />
                  ) : null}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )
      }}
      disabled={props.disabled || isInitialLoading}
    />
  )
}

const SelectPopper = function (props: any) {
  return (
    <Popper
      {...props}
      style={{ width: 'auto', maxWidth: '30rem' }}
      placement="bottom-start"
    />
  )
}
