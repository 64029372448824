import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  labelify,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'
import { useReferenceDataCrudForm } from '@tk/frontend/telemetry/reference-data-crud'

import { Source, SourceStatusOptions } from './types'

type SourceValues = Partial<Omit<Source, 'backbone'>> & {
  backbone?: Partial<Source['backbone']> | undefined
}

const Form = getFormComponents<SourceValues>()

export type Props = {
  initialValues: SourceValues
  onFinished?: () => void
}

const STATUS_OPTIONS = SourceStatusOptions.map<Option>((value) => ({
  value: value,
  label: value,
}))

export function SourceForm({ initialValues = {}, onFinished }: Props) {
  const id = initialValues.id
  const isExisting = typeof id === 'number' && id > 0
  const progress = usePromiseNotification()

  const form = Form.useForm({
    defaultValues: initialValues,
  })
  const { handleSubmit: _handleSubmit, formState, getValues } = form

  const { trackEditSave, trackEditValidationError } = useReferenceDataCrudForm(
    'source',
    initialValues
  )

  const mutation = SourceForm.useSourceMutation(isExisting)
  const [backbone] = SourceForm.useBackboneQuery()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync(values as any)
        const result = await progress(
          promise,
          isExisting
            ? {
                progressMessage: 'Updating ' + values.name,
                successMessage: 'Updated ' + values.name,
                failureMessage: 'Error updating ' + values.name,
              }
            : {
                progressMessage: 'Creating',
                successMessage: 'Created',
                failureMessage: 'Error creating',
              }
        )

        trackEditSave(formState.dirtyFields, result)

        onFinished?.()
      },
      (err) => {
        trackEditValidationError(formState.dirtyFields, err, getValues())
      }
    )
  }, [
    _handleSubmit,
    formState.dirtyFields,
    getValues,
    isExisting,
    mutation,
    onFinished,
    progress,
    trackEditSave,
    trackEditValidationError,
  ])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        {isExisting && <input hidden type="number" {...form.register('id')} />}

        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Field
          label="Status (Required)"
          name="status"
          input={
            <Form.SelectField
              options={STATUS_OPTIONS}
              rules={Form.rules.required}
            />
          }
        />
        <Form.Field
          label="Backbone (Required)"
          name="backbone.id"
          input={
            <Form.SelectField options={backbone} rules={Form.rules.required} />
          }
        />

        <Form.Row>
          <Button type="submit">{isExisting ? 'Update' : 'Create'}</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useSourceMutation(isExisting: boolean) {
  const utils = useUtils()

  const { useMutation } = isExisting
    ? trpc.reference.source.update
    : trpc.reference.source.create

  return useMutation({
    onSuccess() {
      utils.reference.source.invalidate()
      utils.reference.backbone.invalidate()
    },
  })
}
SourceForm.useSourceMutation = useSourceMutation

function useBackboneQuery() {
  return trpc.reference.backbone.list.useSuspenseQuery(undefined, {
    select: (items) => {
      return items.map<Option>((item) => {
        return {
          label: labelify(item.name, item.description),
          value: item.id,
        }
      })
    },
  })
}
SourceForm.useBackboneQuery = useBackboneQuery
