import { useEffect } from 'react'

import { AuthState, CustomUserClaims, UserClaims } from '@okta/okta-auth-js'
import posthog from 'posthog-js'

import { useOktaAuth } from '@lib/okta-web'

import { capture } from './posthog'
import { debug } from './telemetry-logging'

export function useIdentity() {
  const okta = useOktaAuth()

  useEffect(() => {
    debug('[posthog:useIdentity] reinit')

    const handler = async (a: AuthState) => {
      let user: UserClaims<CustomUserClaims>
      try {
        user = await okta.oktaAuth.getUser()
        debug('[posthog:useIdentity] got user')
      } catch (e) {
        debug('[posthog:useIdentity] not yet authenticated')
        return
      }

      debug('[posthog:useIdentity] user session started')
      posthog.identify(
        user.email,
        {
          email: user.email?.toLowerCase(),
          lastLogin: new Date().toISOString(),
        },
        { firstLogin: new Date().toISOString() }
      )

      capture('gtk/session-start')
    }

    okta.oktaAuth.authStateManager.subscribe(handler)

    return () => {
      okta.oktaAuth.authStateManager.unsubscribe(handler)
    }
  }, [okta.oktaAuth])
}
