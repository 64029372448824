import { ReactNode } from 'react'
import { Path, useFieldArray, useFormContext } from 'react-hook-form'

import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { keepPreviousData } from '@tanstack/react-query'

import { trpc } from '@tk/frontend/api'
import { FidGroupRequestDto, scopeValues } from '@tk/frontend/app/Fids/types'
import { getFormComponents, InfoPopover } from '@tk/frontend/primitives'
import {
  Option,
  UseFilteredList,
  UseSelectedOption,
} from '@tk/frontend/primitives/forms/ServersideSelectField'

const Form = getFormComponents<FidGroupRequestDto>()

export function FidGroupForm() {
  const form = useFormContext()
  const fidMapField = useFieldArray({
    control: form.control,
    name: 'fidMaps',
    keyName: 'id',
  })

  const [fidOptions] = trpc.fids.list.listAsOptions.useSuspenseQuery({})

  const SCOPE_OPTIONS: Option[] = scopeValues.map((value) => ({
    value,
    label: value,
  }))

  return (
    <Form.SectionBodyGrid>
      <Form.Section title="Details">
        <Form.Field
          name="name"
          label="Name (Required)"
          info={
            <Form.FieldInfo details="This can be whatever you like, for instance short name, a stub, or a list of stubs" />
          }
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />

        <Form.Field
          name="description"
          label="Description (Required)"
          info={
            <Form.FieldInfo details="A detailed description of the Field Group" />
          }
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Field
          name="notes"
          label="Notes"
          input={<Form.TextField multiline={{ minRows: 2 }} />}
        />
      </Form.Section>

      <Form.Section title="FID Maps" gridColumns={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <FidMapEditHeader
                  width="20%"
                  info="Optional: The FID which is mapped from on a source, for instance a FIX FID"
                >
                  Source FID
                </FidMapEditHeader>

                <FidMapEditHeader
                  width="20%"
                  info="The FID we are publishing via this Field Group. ie. the RTDS Multibrand FID"
                >
                  Published FID (Required)
                </FidMapEditHeader>

                <FidMapEditHeader
                  width="20%"
                  info="A Field Group specific name for this FID. Overrides the FID acronym"
                >
                  Name / Alias (Required)
                </FidMapEditHeader>

                <FidMapEditHeader
                  width="20%"
                  info="Determines whether the Fid should be distributed to customers or is for internal-only use"
                >
                  Scope (Required)
                </FidMapEditHeader>

                <FidMapEditHeader
                  width="20%"
                  info="A more detailed description of this FID"
                >
                  Description
                </FidMapEditHeader>

                <FidMapEditHeader></FidMapEditHeader>
              </TableRow>
            </TableHead>

            <TableBody>
              {fidMapField.fields.map((field, i) => {
                const getName = (
                  field: Path<FidGroupRequestDto['fidMaps'][number]>
                ) => `fidMaps.${i}.${field}` as any

                return (
                  <TableRow key={field.id}>
                    <TableCell>
                      <Form.Field
                        name={getName('sourceFid.id')}
                        input={
                          <Form.ServersideSelectField
                            autoFocus
                            options={fidOptions}
                            useSelectedOption={useSelectedFid}
                            useFilteredList={useFilteredFids}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Form.Field
                        name={getName('fid.id')}
                        input={
                          <Form.ServersideSelectField
                            options={fidOptions}
                            rules={Form.rules.required}
                            useSelectedOption={useSelectedFid}
                            useFilteredList={useFilteredFids}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Form.Field
                        name={getName('name')}
                        input={<Form.TextField rules={Form.rules.required} />}
                      />
                    </TableCell>

                    <TableCell>
                      <Form.Field
                        name={getName('scope')}
                        input={
                          <Form.SelectField
                            rules={Form.rules.required}
                            options={SCOPE_OPTIONS}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Form.Field name={getName('description')} />
                    </TableCell>

                    <TableCell>
                      <ButtonGroup size="small" variant="text">
                        <Button
                          color="secondary"
                          onClick={() =>
                            fidMapField.insert(i + 1, {
                              ...form.getValues(`fidMaps.${i}`),
                            })
                          }
                        >
                          Clone
                        </Button>

                        <Button
                          color="warning"
                          onClick={() => fidMapField.remove(i)}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          onClick={() =>
            fidMapField.append({
              sourceFid: { id: null },
              fid: { id: null },
              name: '',
              scope: '',
              description: '',
            })
          }
        >
          Add
        </Button>
      </Form.Section>
    </Form.SectionBodyGrid>
  )
}

type FidMapEditHeaderProps = {
  children?: ReactNode
  info?: ReactNode
  width?: string | number
}
function FidMapEditHeader({ children, info, width }: FidMapEditHeaderProps) {
  return (
    <TableCell width={width}>
      <InfoPopover
        iconPosition="prefix"
        colour="dark"
        content={info ? <Form.FieldInfo details={info} /> : undefined}
      >
        <div>{children}</div>
      </InfoPopover>
    </TableCell>
  )
}

const useSelectedFid: UseSelectedOption = (ids) => {
  if (ids && ids.length > 1) {
    console.warn('useSelectedFid does not support multiple-select right now')
  }

  return trpc.fids.list.get.useQuery(
    {
      id: ids?.[0] ?? -1,
    },
    {
      enabled: !!ids?.[0],
      select(data) {
        if (!data) {
          return
        }

        return [
          {
            label: `${data.externalId} (${data.name})`,
            value: data.id,
          } as Option,
        ]
      },
      placeholderData: keepPreviousData,
    }
  )
}

const useFilteredFids: UseFilteredList = (filter) => {
  return trpc.fids.list.listAsOptions.useQuery(
    {
      filter,
    },
    {
      placeholderData: keepPreviousData,
    }
  )
}
