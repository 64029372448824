import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'

import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'

import { Outputs } from '@tk/frontend/api'
import { defaultProps } from '@tk/frontend/primitives/datagrid'
import { TwoLineTextCell } from '@tk/frontend/primitives/datagrid/cells'
import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'

type Props = {
  loading: boolean
  records: Outputs['extracta']['getExtractableRecordsAndFids']['records']
  selectedRecords: number[]
  setSelectedRecords: Dispatch<SetStateAction<number[]>>
}

export function RecordsDataGrid({
  records,
  loading,
  selectedRecords,
  setSelectedRecords,
}: Props) {
  useEffect(() => {
    if (selectedRecords.length === 0) {
      setSelectedRecords(
        records.map((record) => record['id'] as unknown as number) as number[]
      )
    }
  }, [selectedRecords, records, setSelectedRecords])

  const columns: GridColDef[] = useMemo(
    () =>
      createColumns<{
        'source.name': string
        'source.backbone.name': string
        'name': string
        'description': string
      }>([
        {
          field: 'source.name',
          headerName: 'Source',
          width: 150,
          renderCell(params) {
            return (
              <TwoLineTextCell
                line1={params.row['source.backbone.name']}
                line2={params.row['source.name']}
              />
            )
          },
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 300,
          type: 'string',
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 1,
          type: 'string',
        },
      ]).map(setColumnDefaults),
    []
  )

  return (
    <DataGridPremium
      {...defaultProps}
      density="compact"
      rows={records ?? []}
      columns={columns}
      experimentalFeatures={{ lazyLoading: true }}
      checkboxSelection
      loading={loading}
      rowSelectionModel={selectedRecords}
      onRowSelectionModelChange={(model) => {
        setSelectedRecords(model as number[])
      }}
      sx={{
        '& .MuiDataGrid-columnHeader[aria-colindex="1"] .MuiCheckbox-root:has(input[type="checkbox"])':
          {
            display: 'none',
          },
      }}
    />
  )
}
