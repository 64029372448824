import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium'
import produce from 'immer'

export const deleteFilterTagFromModel = ({
  oldModel,
  item,
  value,
}: {
  oldModel?: GridFilterModel
  item: GridFilterItem
  value: string
}) => {
  if (!oldModel) {
    return {
      items: [],
    }
  }

  return produce(oldModel, (draftModel) => {
    const index = draftModel.items.findIndex((i) => i.id === item.id)

    if (index !== -1) {
      if (typeof draftModel.items[index].value === 'string') {
        delete draftModel.items[index].value
      } else {
        const valueIndex = (
          draftModel.items[index].value as string[]
        ).findIndex((v) => v === value)

        if (valueIndex !== -1) {
          draftModel.items[index].value.splice(valueIndex, 1)
        }
      }
    }
  })
}
