import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Paper, Stack, Typography } from '@mui/material'
import { DataGridPremium, GridRowIdGetter } from '@mui/x-data-grid-premium'

import { trpc, useUtils } from '@tk/frontend/api'
import * as routes from '@tk/frontend/app/routes'
import {
  defaultProps,
  GridEnrichedColDef,
  usePaginationQueryParams,
} from '@tk/frontend/primitives/datagrid'
import { getListCell } from '@tk/frontend/primitives/datagrid/cells'
import { PageContentLayout, PaperCell } from '@tk/frontend/primitives/layout'
import {
  ApplyImportButton,
  ImportPreviewRowFilter,
  ImportPreviewSummary,
  ImportStateColour,
  PreviewNotFound,
  usePreviewRowFilterParam,
  usePreviewTelemetry,
  useSimpleImportProvider,
} from '@tk/frontend/primitives/simple-import'

import { Container, DiffLine, getChangesDiffCell } from './ChangesDiffCell'
import { keyWiseDiff } from './keyWiseDiff'
import { PreviewWithDiffItem } from './types'

export function RecordMappingsImportPreview() {
  const utils = useUtils()

  const simpleImportProvider = useSimpleImportProvider({
    route: trpc.bloomberg.mapping.import,
    utils: utils.bloomberg.mapping.import,
    previewUriTemplate: routes.app.bloomberg.recordMappingsImportPreview,
  })

  return (
    <simpleImportProvider.Provider {...simpleImportProvider.providerProps}>
      <RecordMappingsImportPreviewContent />
    </simpleImportProvider.Provider>
  )
}

function RecordMappingsImportPreviewContent() {
  const { taskId: taskIdString } = useParams<{ taskId: string }>()
  const taskId = parseInt(taskIdString!)

  const [rowFilter] = usePreviewRowFilterParam()
  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [rowFilter],
    initialPageSize: 100,
  })

  const [preview, previewQuery] =
    trpc.bloomberg.mapping.import.previewWithDiff.useSuspenseQuery({
      taskId: taskId!,
      page: pagination.page,
      size: pagination.pageSize,
      invalidOnly: rowFilter === 'errors',
    })

  usePreviewTelemetry('bloomberg/record-mappings', taskId)

  const columns = useMemo<GridEnrichedColDef<PreviewWithDiffItem>[]>(() => {
    return [
      {
        type: 'string',
        field: 'errorMessage',
        headerName: 'Summary',
        width: 200,
        filterable: false,
        sortable: false,
        aggregable: false,
        renderCell(params) {
          if (params.row.errorMessage || !params.row.isValid) {
            return (
              <Typography fontWeight="bold" color={ImportStateColour['FAILED']}>
                {params.row.errorMessage ?? 'Error'}
                <br />
                {params.row.line}
              </Typography>
            )
          }
          if (params.row.type === 'update') {
            return (
              <Typography
                fontWeight="bold"
                color={(theme) => theme.palette.primary.main}
              >
                UPDATE
              </Typography>
            )
          } else {
            return (
              <Typography
                fontWeight="bold"
                color={(theme) => theme.palette.success.main}
              >
                CREATE
              </Typography>
            )
          }
        },
      },

      getListCell('recordSource', 'Record Sources', {
        width: 150,
      }),
      getListCell('recordName', 'Record Names', {
        width: 400,
      }),

      {
        type: 'string',
        field: 'type',
        headerName: 'Status',
        width: 150,
        filterable: false,
        sortable: false,
        renderCell(params) {
          return (
            <Container>
              {params.row.changes.map((change, i) => {
                if (change.type === 'update') {
                  const [, distance] = keyWiseDiff(change.current, change.next)

                  if (distance === 0) {
                    return (
                      <DiffLine key={i}>
                        <Typography
                          fontWeight="bold"
                          fontSize="inherit"
                          color={(theme) => theme.palette.secondary.main}
                        >
                          NO CHANGE
                        </Typography>
                      </DiffLine>
                    )
                  } else {
                    return (
                      <DiffLine key={i}>
                        <Typography
                          fontWeight="bold"
                          fontSize="inherit"
                          color={(theme) => theme.palette.success.main}
                        >
                          UPDATED
                        </Typography>
                      </DiffLine>
                    )
                  }
                } else if (change.type === 'create') {
                  return (
                    <DiffLine key={i}>
                      <Typography
                        fontWeight="bold"
                        fontSize="inherit"
                        color={(theme) => theme.palette.success.main}
                      >
                        CREATE
                      </Typography>
                    </DiffLine>
                  )
                } else {
                  return (
                    <DiffLine key={i}>
                      <Typography
                        fontWeight="bold"
                        fontSize="inherit"
                        color={(theme) => theme.palette.warning.main}
                      >
                        RETIRE
                      </Typography>
                    </DiffLine>
                  )
                }
              })}
            </Container>
          )
        },
      },

      getChangesDiffCell('ticker.name', 'Tickers'),
      getChangesDiffCell('entitlement.name', 'Entitlements'),
      getChangesDiffCell('pricingSource.name', 'Pricing Sources'),
      getChangesDiffCell('process.name', 'Process'),
      getChangesDiffCell('ruleset.name', 'Ruleset'),
    ]
  }, [])
  console.log('PREVIEW', preview)
  return preview.status === 'success' ? (
    <PageContentLayout
      title="Bloomberg Mappings Import"
      controls={
        <ApplyImportButton
          entity="bloomberg/record-mappings"
          taskId={taskId}
          disabled={preview.anyInvalid}
        />
      }
      childrenContainer={<></>}
      maxContentWidth={false}
    >
      <Stack display="flex" height="100%">
        <Stack direction="row">
          <ImportPreviewSummary taskId={taskId} />

          <PaperCell>
            <ImportPreviewRowFilter />
          </PaperCell>
        </Stack>

        <Paper
          sx={{ display: 'flex', flex: '1 1 0', minHeight: 0, minWidth: 0 }}
        >
          <DataGridPremium<PreviewWithDiffItem>
            {...defaultProps}
            pagination
            paginationMode="server"
            paginationModel={pagination}
            onPaginationModelChange={onPaginationChange}
            rows={preview.content ?? []}
            rowCount={preview.totalElements ?? 0}
            columns={columns}
            getRowId={getRowId}
            loading={previewQuery.isRefetching}
            getRowHeight={() => 'auto'}
          />
        </Paper>
      </Stack>
    </PageContentLayout>
  ) : (
    <PreviewNotFound
      label="Records Mappings"
      path={routes.app.bloomberg.recordMappings}
    />
  )
}

const getRowId: GridRowIdGetter<PreviewWithDiffItem> = (row) => row.rowId
