import { EntitlementDto } from '@lib/pgs'
import { NamedBusinessObjectTableProps } from '@tk/frontend/primitives/datagrid/table'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

export const ALL_TO_SEE_OPTIONS: Option[] = [
  { label: 'All To See', value: 'ALL_TO_SEE' },
  { label: 'None To See', value: 'NONE_TO_SEE' },
]

type EntitlementType = EntitlementDto['type']
export const TYPE_OPTIONS: Option<EntitlementType>[] = [
  {
    value: 'TERMINAL',
    label: 'Terminal',
  },
  {
    value: 'B_PIPE',
    label: 'B-Pipe',
  },
  {
    value: 'TERMINAL_AND_B_PIPE',
    label: 'Terminal & B-Pipe',
  },
]

export type PackageActions = Exclude<
  NamedBusinessObjectTableProps['packageActions'],
  undefined
>
