import { useMemo } from 'react'

import { Error } from '@mui/icons-material'
import { StepButton, stepLabelClasses } from '@mui/material'

import { TabValue, useProjectTab } from './useProjectTab'
import { useProjectTabValidity } from './useProjectTabValidity'
import { useWarningTabs } from './useWarningTabs'

interface Props {
  tabId: TabValue
  label: string
  disabled?: boolean
}

export function ProjectStepButton({ tabId, label }: Readonly<Props>) {
  const { tab, setTab } = useProjectTab()
  const warningTabs = useWarningTabs()
  const isTabValid = useProjectTabValidity(tabId)

  const isCurrentTab = useMemo(() => {
    return tab === tabId
  }, [tab, tabId])

  const isTabErrored = useMemo(() => {
    return warningTabs.includes(tabId)
  }, [tabId, warningTabs])

  return (
    <StepButton
      disabled={!isTabValid}
      onClick={() => setTab(tabId)}
      icon={isTabErrored ? <Error color="warning" /> : undefined}
      sx={{
        [`.${stepLabelClasses.label}`]: {
          color:
            isTabErrored && isCurrentTab
              ? (t) => t.palette.warning.main
              : undefined,
        },
      }}
    >
      {label}
    </StepButton>
  )
}
