import {
  SimpleImportContextType,
  SimpleImportProvider,
} from './SimpleImportContext'

export function useSimpleImportProvider({
  route,
  utils,
  previewUriTemplate,
}: SimpleImportContextType) {
  return {
    providerProps: {
      route,
      utils,
      previewUriTemplate,
    } as SimpleImportContextType,

    /**
     * Provider must rendered with `{...providerProps}` set.
     *
     * We used to return a newly configured Provider, but React struggles to understand the component between re-renders and remounts all children on every state change.
     *
     * This caused bugs like paging now working because the page state keeps resetting.
     */
    Provider: SimpleImportProvider,
  }
}
