import * as Icons from '@mui/icons-material'
import { Box, Stack, TextField } from '@mui/material'
import { DateTime } from 'luxon'
import {
  createEnumParam,
  DateTimeParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import { Inputs } from '@tk/frontend/api'
import { useFilterQueryParams } from '@tk/frontend/primitives/datagrid'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import { MenuItem } from '@tk/frontend/primitives/menu'

import { DateRangeFilter } from './DateRangeFilter'
import { DropdownMenuFilter } from './DropdownMenuFilter'
import { ExtractaReportsTable } from './ExtractaReportsTable'

export type StrictReportType = NonNullable<
  Inputs['extracta']['list']['types']
>[number]

export type ReportType = StrictReportType | 'ALL_REPORTS'

const ReportTypeParam = withDefault(
  createEnumParam<ReportType>([
    'ALL_REPORTS',
    'HOURLY_SNAPS',
    'DAILY_SNAP',
    'ALL_TICKS',
  ]),
  'ALL_REPORTS'
)

const defaultStart = DateTime.now().minus({ days: 7 }).startOf('day').toJSDate()
const defaultEnd = DateTime.now().endOf('day').toJSDate()

export const ExtractaReportsPage = () => {
  const filter = useFilterQueryParams()
  const owner = useFilterQueryParams({ name: 'owner', defaultValue: 'user' })

  const [_reportType, setReportType] = useQueryParam(
    'reportType',
    ReportTypeParam
  )
  const reportType = _reportType ?? undefined

  const [dateFrom, setDateFrom] = useQueryParam(
    'date-from',
    withDefault(DateTimeParam, defaultStart)
  )
  const [dateTo, setDateTo] = useQueryParam(
    'date-to',
    withDefault(DateTimeParam, defaultEnd)
  )

  return (
    <PageContentLayout title="Data Extraction" maxContentWidth={false}>
      <Stack display="flex" flexDirection="column" height="100%">
        <Box
          sx={{
            display: 'flex',
            padding: '16px',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" alignItems="stretch">
            <DropdownMenuFilter
              label="Report Type"
              value={reportType}
              onChange={(e) => setReportType(e.target.value as ReportType)}
            >
              <MenuItem
                startIcon={<Icons.LanguageRounded />}
                value="ALL_REPORTS"
              >
                All Reports
              </MenuItem>
              <MenuItem
                startIcon={<Icons.CandlestickChartRounded />}
                value="ALL_TICKS"
              >
                All Ticks
              </MenuItem>
              <MenuItem
                startIcon={<Icons.HourglassBottomRounded />}
                value="HOURLY_SNAPS"
              >
                Hourly Snaps
              </MenuItem>
              <MenuItem
                startIcon={<Icons.HourglassFullRounded />}
                value="DAILY_SNAP"
              >
                Daily Snap
              </MenuItem>
            </DropdownMenuFilter>

            <DropdownMenuFilter
              label="Creator"
              value={owner.value}
              onChange={(e) => owner.setValue(e.target.value)}
            >
              <MenuItem startIcon={<Icons.LanguageRounded />} value="all">
                All
              </MenuItem>
              <MenuItem startIcon={<Icons.PersonOutlineRounded />} value="user">
                My Reports
              </MenuItem>
            </DropdownMenuFilter>

            <DateRangeFilter
              localeText={{ start: 'Date From', end: 'Date To' }}
              value={[
                DateTime.fromJSDate(dateFrom),
                DateTime.fromJSDate(dateTo),
              ]}
              onChange={(newValue) => {
                if (newValue[0]) {
                  setDateFrom(
                    newValue[0].startOf('day').toJSDate(),
                    'replaceIn'
                  )
                }

                if (newValue[1]) {
                  setDateTo(newValue[1].endOf('day').toJSDate(), 'replaceIn')
                }
              }}
              disableFuture
            />
          </Stack>

          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextField
              label="Search"
              variant="outlined"
              placeholder="You may use wildcards (%) to search for partial report names"
              size="small"
              value={filter.value}
              onChange={(e) => {
                filter.setValue(e.target.value)
              }}
              InputProps={{
                style: {
                  width: '380px',
                },
                startAdornment: (
                  <Icons.Search
                    fontSize="small"
                    sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                  />
                ),
              }}
            />
          </Box>
        </Box>
        <Stack
          flex="1 1 0"
          overflow="hidden"
          paddingBottom="1rem"
          paddingX="1rem"
        >
          <ExtractaReportsTable
            name={filter.debouncedValue}
            types={
              reportType && reportType !== 'ALL_REPORTS'
                ? [reportType]
                : undefined
            }
            createdBy={owner.value === 'all' ? undefined : owner.value}
            startDate={dateFrom}
            endDate={dateTo}
          />
        </Stack>
      </Stack>
    </PageContentLayout>
  )
}
