import * as Icons from '@mui/icons-material'

import { generateRecordCreateUri } from '@tk/frontend/app/Records/RecordsRouter.routes'
import { ButtonLink } from '@tk/frontend/primitives'

export function CreateRecordButton() {
  return (
    <ButtonLink
      to={{
        pathname: generateRecordCreateUri(),
      }}
      variant="contained"
      startIcon={<Icons.Add />}
      color="primary"
    >
      Create
    </ButtonLink>
  )
}
