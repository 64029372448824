import { get, Path } from 'react-hook-form'

import { Divider, Stack, StackProps, Typography } from '@mui/material'

import { GridEnrichedColDef } from '@tk/frontend/primitives/datagrid'
import { ApiFieldLineError } from '@tk/frontend/primitives/simple-import'

import { PreviewWithDiffItem, PreviewWithDiffItemChangeMapping } from './types'

type BaseKeys = Path<PreviewWithDiffItemChangeMapping>

export function getChangesDiffCell<BaseKey extends BaseKeys>(
  key: BaseKey,
  name: string,
  overrides: Partial<GridEnrichedColDef<PreviewWithDiffItem>> | undefined = {}
): GridEnrichedColDef<PreviewWithDiffItem> {
  return {
    type: 'string',
    field: key as any,
    headerName: name,
    width: 200,
    ...overrides,
    filterable: false,
    sortable: false,
    aggregable: false,
    renderCell(params) {
      return (
        <Container>
          {params.row.changes.map((change, i) => {
            const current = get(
              'current' in change ? change.current : undefined,
              key,
              undefined
            )
            const next = get(
              'next' in change ? change.next : undefined,
              key,
              undefined
            )
            const fieldError = get(
              change.errors,
              key.split('.')[0],
              undefined
            ) as undefined | ApiFieldLineError

            if (fieldError) {
              return (
                <DiffLine key={i}>
                  <Typography
                    fontFamily={'"Roboto Mono", monospace'}
                    fontSize="inherit"
                    color={(theme) => theme.palette.error.main}
                  >
                    <strong>Error Row {fieldError.rowNumber}:</strong>{' '}
                    {fieldError.message ?? `Invalid value: ${fieldError.value}`}
                  </Typography>
                </DiffLine>
              )
            }

            if (change.type === 'retire') {
              return (
                <DiffLine key={i}>
                  <Typography
                    fontFamily={'"Roboto Mono", monospace'}
                    fontSize="inherit"
                    color={(theme) => theme.palette.warning.main}
                  >
                    - {current}
                  </Typography>
                </DiffLine>
              )
            }

            if (change.type === 'create') {
              return (
                <DiffLine key={i}>
                  <Typography
                    fontFamily={'"Roboto Mono", monospace'}
                    fontSize="inherit"
                    color={(theme) => theme.palette.success.main}
                  >
                    + {next}
                  </Typography>
                </DiffLine>
              )
            }

            if (change.type === 'update' && current !== next) {
              return (
                <DiffLine key={i}>
                  <Typography
                    fontFamily={'"Roboto Mono", monospace'}
                    fontSize="inherit"
                    color={(theme) => theme.palette.warning.main}
                  >
                    - {current}
                  </Typography>

                  <Typography
                    fontFamily={'"Roboto Mono", monospace'}
                    fontSize="inherit"
                    color={(theme) => theme.palette.success.main}
                  >
                    + {next}
                  </Typography>
                </DiffLine>
              )
            }

            return (
              <DiffLine key={i}>
                <Typography
                  fontFamily={'"Roboto Mono", monospace'}
                  fontSize="inherit"
                  color={(theme) => theme.palette.secondary.main}
                >
                  {current}
                </Typography>
              </DiffLine>
            )
          })}
        </Container>
      )
    },
  }
}

export const DiffLine = (props: StackProps) => (
  <Stack height="2.5rem" justifyContent="space-evenly" spacing={0} {...props} />
)

export const Container = (props: StackProps) => (
  <Stack
    divider={<Divider />}
    marginTop="0.5rem"
    marginBottom="0.5rem"
    spacing="0.5rem"
    width="100%"
    {...props}
  />
)
