import { ReactNode, useCallback } from 'react'

import { Stack } from '@mui/system'
import { TRPCClientError } from '@trpc/client'
import { useSnackbar } from 'notistack'

import { RouterError } from '@tk/frontend/api'

import { CustomSnackbar } from './CustomSnackbar'

export type PromiseNotificationOpts<T> = {
  progressMessage: string
  successMessage: string | ((result: T) => string)
  failureMessage: string
  handleError?: (error: RouterError) => ReactNode | undefined
}

export const usePromiseNotification = () => {
  const snackbar = useSnackbar()

  return useCallback(
    async <T,>(
      promise: Promise<T>,
      {
        progressMessage,
        successMessage,
        failureMessage,
        handleError = () => undefined,
      }: PromiseNotificationOpts<T>
    ): Promise<T> => {
      const workingSnack = snackbar.enqueueSnackbar(progressMessage, {
        autoHideDuration: null,
        content: (key, message) => (
          <CustomSnackbar key={key} message={message} variant="info" />
        ),
      })

      try {
        const result = await promise

        const message =
          typeof successMessage === 'string'
            ? successMessage
            : successMessage(result)
        snackbar.enqueueSnackbar(message, {
          autoHideDuration: 3000,
          content: (key, message) => (
            <CustomSnackbar key={key} message={message} variant="success" />
          ),
        })

        return result
      } catch (err: any) {
        let errorInfo: ReactNode = String(err)
        if (err instanceof TRPCClientError) {
          console.warn(err)
          const routerError = err.shape as any as RouterError

          const presentedError = selectErrorHandler(
            routerError,
            handleError,
            getPgsError,
            getMessageFallback
          )
          if (presentedError) {
            errorInfo = presentedError
          }
        }

        snackbar.enqueueSnackbar(
          <Stack>
            <div>{failureMessage}</div>
            <div>{errorInfo}</div>
          </Stack>,
          {
            title: failureMessage,
            content: (key, message) => (
              <CustomSnackbar key={key} message={message} variant="error" />
            ),
          }
        )

        throw err
      } finally {
        snackbar.closeSnackbar(workingSnack)
      }
    },
    [snackbar]
  )
}

function getPgsError(routerError: RouterError | undefined) {
  if (!routerError) {
    return <div>Unknown Error</div>
  }

  if ('pgs' in routerError) {
    const { message, fieldErrors = [] } = routerError.pgs
    if (fieldErrors?.length > 0) {
      return (
        <>
          <p>{message}</p>

          {fieldErrors.map((fieldError, i) => {
            return (
              <p key={i}>
                <strong>{fieldError.field}: </strong>
                {fieldError.message ?? String(fieldError.value)}
              </p>
            )
          })}
        </>
      )
    }

    if (typeof routerError.pgs === 'string') {
      return <p>{routerError.pgs}</p>
    } else {
      return (
        <div>
          {routerError.pgs?.debugMessage ??
            routerError.pgs?.message ??
            'Unknown error'}
        </div>
      )
    }
  }

  return undefined
}

function getMessageFallback(routerError: RouterError) {
  if (routerError.message) {
    return String(routerError.message)
  }

  return undefined
}

function selectErrorHandler(
  error: RouterError,
  ...handlers: ((error: RouterError) => ReactNode)[]
) {
  for (const handler of handlers) {
    const presentation = handler(error)
    if (presentation) {
      return presentation
    }
  }

  return undefined
}
