import { DynamoDBClient } from '@aws-sdk/client-dynamodb'
import {
  GetObjectCommand,
  GetObjectCommandOutput,
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3'
import { DynamoDBDocument } from '@aws-sdk/lib-dynamodb'
import { z } from 'zod'

export const MultibrandLine = z.object({
  inputSource: z.string(),
  inputName: z.string(),
  outputSource: z.string(),
  stub: z.string(),
  rrn: z.string(),
  location: z.string(),
  clearing: z.string(),
  priceType: z.string(),
  frequency: z.string(),
  brand: z.string(),
})
export type MultibrandLine = z.infer<typeof MultibrandLine>

export const MultibrandLineStrict = z.object({
  inputSource: z.string().min(1, 'Required'),
  inputName: z.string().min(1, 'Required'),
  outputSource: z.string().min(1, 'Required'),
  stub: z.string().min(1, 'Required'),
  rrn: z.string().min(1, 'Required'),
  location: z.string().min(1, 'Required'),
  clearing: z.string().min(1, 'Required'),
  priceType: z.string().min(1, 'Required'),
  frequency: z.string().min(1, 'Required'),
  brand: z.string().min(1, 'Required'),
})

// See transformExcelWorkbookToObjects.ts
export const RecordSheetParseResult = z.object({
  status: z.enum(['ok', 'error']),
  result: z
    .array(
      z.object({
        id: z.number(),
        row: MultibrandLine.optional().nullable(),
        error: z
          .record(MultibrandLine.keyof(), z.string().optional())
          .optional()
          .nullable(),
      })
    )
    .catch([]),
})

export const ProjectDocument = z.object({
  isValid: z.boolean().optional(),
  version: z.literal(1),
  inputSourceId: z.number().optional(),
  outputSourceId: z.number().optional(),
  stubId: z.number().optional(),
  fidGroupId: z.number().optional(),
  linesSheet: z
    .object({
      objectKey: z.string(),
      fileName: z.string(),
    })
    .optional(),
  lines: RecordSheetParseResult.optional(),
  uuid: z.string().optional(),
})
export type ProjectDocument = z.infer<typeof ProjectDocument>

const SerialBool = z.preprocess(
  (val) => (typeof val === 'boolean' ? val : val === 'true'),
  z.boolean()
)

export const ProjectRow = z.object({
  name: z.string(),
  date: z.coerce.date(),
  updatedAt: z.coerce.date(),
  updatedBy: z.string(),
  complete: SerialBool,
  document: z.preprocess(
    (val) => (typeof val === 'string' ? JSON.parse(val) : val),
    ProjectDocument
  ),
  completedAt: z.coerce.date().nullable().optional(),
  completedBy: z.string().nullable().optional(),
})
export const MinimalProjectRow = z.object({
  name: z.string(),
  date: z.coerce.date(),
  updatedAt: z.coerce.date(),
  updatedBy: z.string(),
  complete: SerialBool,
  completedAt: z.coerce.date().nullable().optional(),
  completedBy: z.string().nullable().optional(),
})
export type ProjectRow = z.infer<typeof ProjectRow>
export type ProjectRowUpdate = Omit<ProjectRow, 'updatedBy' | 'updatedAt'>

type ProjectAttribute = keyof (typeof MinimalProjectRow)['_output']
type ProjectTableData = Record<ProjectAttribute, unknown>

function sanitiseName(name: string) {
  return name.toLowerCase().trim()
}

interface DynamoOpts {
  tableName: string
  client: DynamoDBClient
}
interface S3Opts {
  bucketName: string
  client: S3Client
}

export class RecordProjectRepository {
  readonly _table: DynamoDBDocument

  constructor(readonly dynamoOpts: DynamoOpts, readonly s3Opts: S3Opts) {
    this._table = DynamoDBDocument.from(dynamoOpts.client, {
      marshallOptions: { removeUndefinedValues: true },
    })
  }

  private createS3Name = (projectName: string) => {
    return `${projectName}.json`
  }

  private putS3Object = async (name: string, data: ProjectDocument) => {
    await this.s3Opts.client.send(
      new PutObjectCommand({
        Bucket: this.s3Opts.bucketName,
        Key: this.createS3Name(name),
        Body: JSON.stringify(data, null, 2),
      })
    )
  }

  fetch = async (name: string): Promise<ProjectRow | null> => {
    console.log('[RecordProjectRepository] fetching', name)
    const projectName = sanitiseName(name)

    const result = await this._table.get({
      TableName: this.dynamoOpts.tableName,
      ConsistentRead: true,
      Key: {
        name: projectName,
      },
    })

    let s3objectBody: GetObjectCommandOutput['Body']
    try {
      const s3Object = await this.s3Opts.client.send(
        new GetObjectCommand({
          Bucket: this.s3Opts.bucketName,
          Key: this.createS3Name(projectName),
        })
      )
      s3objectBody = s3Object.Body
    } catch (e) {
      if (e instanceof Error && e.name === 'NoSuchKey') {
        // undefined ok
      } else {
        throw e
      }
    }

    if (result.Item && s3objectBody) {
      const dynamoRow = MinimalProjectRow.parse(result.Item)

      const s3ObjectText = await s3objectBody.transformToString()
      const document = ProjectDocument.parse(JSON.parse(s3ObjectText))

      return {
        ...dynamoRow,
        document,
      }
    }

    return null
  }

  create = async (row: ProjectRow) => {
    console.log('[RecordProjectRepository] creating', row.name)

    const { name, date, updatedAt, updatedBy, document } = ProjectRow.parse(row)
    const projectName = sanitiseName(name)

    const existingProject = await this.fetch(row.name)
    if (existingProject) {
      throw new Error(`Project "${projectName}" already exists`)
    }

    await this._table.put({
      TableName: this.dynamoOpts.tableName,
      Item: {
        name: projectName,
        date: date.toISOString(),
        updatedAt: updatedAt.toISOString(),
        updatedBy,
        complete: false,
        completedAt: null,
        completedBy: null,
      } as ProjectTableData,
    })

    await this.putS3Object(projectName, document)

    return (await this.fetch(row.name))!
  }

  update = async (row: ProjectRow) => {
    console.log('[RecordProjectRepository] updating', row.name)

    const parsed = ProjectRow.parse(row)
    const {
      name,
      updatedAt,
      updatedBy,
      complete,
      document,
      completedAt,
      completedBy,
    } = parsed
    const projectName = sanitiseName(name)

    const existingProject = await this.fetch(name)
    if (!existingProject) {
      throw new Error(`Project "${projectName}" does not exist`)
    }

    await this._table.update({
      TableName: this.dynamoOpts.tableName,
      Key: {
        name: projectName,
      },
      UpdateExpression:
        'set complete = :complete, updatedAt = :updatedAt, updatedBy = :updatedBy, completedAt= :completedAt, completedBy = :completedBy',
      ExpressionAttributeValues: {
        ':complete': String(complete),
        ':updatedAt': updatedAt.toISOString(),
        ':updatedBy': updatedBy,
        ':completedAt': completedAt?.toISOString() ?? null,
        ':completedBy': completedBy ?? null,
      },
      ReturnValues: 'ALL_NEW',
    })

    await this.putS3Object(projectName, document)

    return (await this.fetch(row.name))!
  }

  list = async () => {
    console.log('[RecordProjectRepository] listing')

    const result = await this._table.scan({
      TableName: this.dynamoOpts.tableName,
    })

    if (result.LastEvaluatedKey) {
      console.error(
        '[RecordProjectRepository:list]',
        'DynamoDB can only return up to 1MB of items in a scan, and this has been exceeded. Implement Paging!'
      )
    }

    return z.array(MinimalProjectRow).parse(result.Items ?? [])
  }
}
