import { useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { trpc } from '@tk/frontend/api'
import { ROUTES } from '@tk/frontend/app/routes'
import { FullscreenLoader } from '@tk/frontend/primitives'

export function CreateProjectViaFlowPage() {
  const { slug } = useParams<{ slug: string }>()

  const navigate = useNavigate()
  const [project] = trpc.recordProjects.findOrCreate.useSuspenseQuery({
    name: slug ?? '',
  })

  useEffect(() => {
    if (project) {
      setTimeout(() => {
        const path = generatePath(ROUTES.app.recordDesigner.project, {
          projectName: project.name,
        })

        navigate(path, { replace: true })
      }, 1500)
    }
  }, [navigate, project])

  return <FullscreenLoader message="Starting Workflow..." />
}
