import { useParams } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { useRouteEffect } from '@lib/web'
import { trpc, useUtils } from '@tk/frontend/api'
import { FidGroupDto, FidGroupEditDto } from '@tk/frontend/app/Fids/types'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { FidGroupForm } from './FidGroupForm'

const Form = getFormComponents<FidGroupEditDto>()

export function FidGroupEditPage() {
  const { id } = useParams<{ id: string }>()
  const { initialData, fidGroup } = useFormInitialData(id!)

  const form = Form.useForm({
    defaultValues: initialData,
  })

  useRouteEffect(() => {
    form.reset(initialData)
  })

  const notify = usePromiseNotification()
  const updateMutation = trpc.fids.groups.update.useMutation({
    onSuccess(data) {
      utils.fids.invalidate()
      form.reset(transformToFormData(data))
    },
  })
  const utils = useUtils()

  const handleSubmit = form.handleSubmit(async (values) => {
    await notify(updateMutation.mutateAsync(values), {
      progressMessage: `Updating Field Group ${fidGroup.name}`,
      successMessage: `Updating Field Group`,
      failureMessage: `Failed to update Field Group`,
    })
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={handleSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Update Field Group">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={updateMutation.isPending || !form.formState.isDirty}
          >
            Save
          </Button>
        </Form.SectionTitleRow>

        <input {...form.register('id')} hidden />
        <FidGroupForm />
      </Form.FormStack>
    </Form.Provider>
  )
}

function transformToFormData(dto: FidGroupDto) {
  return dto as Partial<FidGroupDto>
}

function useFormInitialData(id: string) {
  const [fidGroup] = trpc.fids.groups.get.useSuspenseQuery(parseInt(id))

  return {
    initialData: transformToFormData(fidGroup),
    fidGroup: fidGroup,
  }
}
