import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'

import {
  TypedAssetClass,
  TypedAssetTag,
  TypedAssetType,
  TypedRowUnion,
} from './types'

export function useAssetRows() {
  const [[assetClasses, assetTypes, assetTags]] = trpc.useSuspenseQueries(
    (t) => {
      return [
        t.reference.assetClass.list(undefined, {
          select(data): TypedAssetClass[] {
            return data.map((assetClass) => {
              return {
                ...assetClass,
                _type: 'assetClass',
              }
            })
          },
        }),

        t.reference.assetType.list(undefined, {
          select(data): TypedAssetType[] {
            return data.map((assetType) => {
              return {
                ...assetType,
                _type: 'assetType',
              }
            })
          },
        }),

        t.reference.assetType.list(undefined, {
          select(data): TypedAssetTag[] {
            const result: TypedAssetTag[] = []
            data.forEach((assetType) => {
              assetType.assetTags.forEach((assetTag) => {
                result.push({
                  ...assetTag,
                  _type: 'assetTag',
                  parent: assetType,
                })
              })
            })
            return result
          },
        }),
      ]
    }
  )

  const rows = useMemo<TypedRowUnion[]>(() => {
    const classes = assetClasses ?? []
    const types = assetTypes ?? []
    const tags = assetTags ?? []

    return [...classes, ...types, ...tags]
  }, [assetClasses, assetTags, assetTypes])

  return rows
}
