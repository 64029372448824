import { GridRenderEditCellParams } from '@mui/x-data-grid-premium'

import { Outputs, trpc } from '@tk/frontend/api'
import { SelectorCell } from '@tk/frontend/primitives/datagrid/edit-cells'

import { transformUiListToSelectorCellOptions } from './types'

type ProcessEditcell = Outputs['bloomberg']['ruleset']['get']

export type ProcessEditCellProps = {
  params: GridRenderEditCellParams<ProcessEditcell>
}

export function ProcessEditCell(props: ProcessEditCellProps) {
  const processesQuery = trpc.bloomberg.process.list.useQuery(undefined, {
    select: (data) => transformUiListToSelectorCellOptions(data),
  })

  return (
    <SelectorCell<ProcessEditcell>
      params={props.params}
      value={props.params.value}
      loading={processesQuery.isPending}
      options={processesQuery.data ?? []}
    />
  )
}
