import { Typography } from '@mui/material'

export type SecondaryHeaderProps = {
  text: string
}

export function SecondaryHeader({ text }: SecondaryHeaderProps) {
  return (
    <Typography variant="inherit" color="GrayText">
      {text}
    </Typography>
  )
}
