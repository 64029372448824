import { ReactNode, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { trpc, useUtils } from '@tk/frontend/api'
import { useRecordsList } from '@tk/frontend/app/Records/useRecordsList'
import { useRecordsQueryParams } from '@tk/frontend/app/Records/useRecordsQueryParams'
import { app, ROUTES } from '@tk/frontend/app/routes'
import { usePromiseNotification } from '@tk/frontend/primitives'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { CreateFormFields } from './CreateFormFields'
import { FidGroupSelectionDataGrid } from './FidGroupSelectionDataGrid'
import { FidOrderingDataGrid } from './FidOrderingDataGrid'
import { RecordsDataGrid } from './RecordsDataGrid'
import { Form } from './types'
import { useFieldSelectionModel } from './useFieldSelectionModel'

const dnaSources = [
  'ICLDNSOURCE',
  'ICNYKSOURCE',
  'ICSNGSOURCE',
  'LDNSOURCE',
  'NYKSOURCE',
  'SNGSOURCE',
]

type TabKey = 'details' | 'records' | 'fields'
const TabParam = withDefault(StringParam, 'records' as TabKey)

export const CreateReportPage = () => {
  const [selectedRecords, setSelectedRecords] = useState<number[]>([])
  const [tab, setTab] = useQueryParam('tab', TabParam)

  const form = Form.useForm({
    defaultValues: {
      dateRange: [null, null],
    },
  })

  const { handleSubmit: _handleSubmit } = form

  const progress = usePromiseNotification()
  const createReportMutation = useCreateMutation()

  const fieldSelectionModel = useFieldSelectionModel()

  const recordParams = useRecordsQueryParams()

  const extractableData = trpc.extracta.getExtractableRecordsAndFids.useQuery({
    recordParams: recordParams.query,
  })

  const [nameOverrides, setNameOverrides] = useState<{ [key: string]: string }>(
    {}
  )

  const [records] = useRecordsList()

  const areSourcesDna = useMemo(() => {
    return (
      records.content
        .map((c) => c.source.name)
        .filter((name) => dnaSources.includes(name)).length > 0
    )
  }, [records.content])

  const handleSubmit = useMemo(() => {
    return _handleSubmit(async (values) => {
      const fids = fieldSelectionModel.model.map((selectedFid) => {
        const fid = extractableData.data?.fids.find(
          (fid) => fid.rowId === selectedFid
        )

        if (!fid) {
          throw new Error('could not find fid')
        }

        const override: string | undefined = nameOverrides[fid.rowId]

        return {
          id: fid?.fidId,
          header: override ?? fid?.fidName,
        }
      })

      const promise = createReportMutation.mutateAsync({
        ...values,
        ticksType: areSourcesDna ? values.ticksType : 'CORRECT',
        recordIds: selectedRecords,
        startDate:
          values.dateRange && values.dateRange[0]
            ? values.dateRange[0]?.toJSDate()
            : new Date(),
        endDate:
          values.dateRange && values.dateRange[1]
            ? values.dateRange[1]?.toJSDate()
            : new Date(),
        fids,
        snapTime: values.snapTime?.toLocaleString({
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
        }),
        startTime: values.startTime?.toLocaleString({
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
        }),
        endTime: values.endTime?.toLocaleString({
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
        }),
      })

      await progress(promise, {
        progressMessage: 'Creating extract',
        successMessage: 'Created extract',
        failureMessage: 'Error creating extract',
      })
    })
  }, [
    areSourcesDna,
    _handleSubmit,
    fieldSelectionModel.model,
    createReportMutation,
    selectedRecords,
    extractableData.data,
    progress,
    nameOverrides,
  ])

  return (
    <Form.Provider {...form}>
      <PageContentLayout maxContentWidth="100%" title="Create Data Extract">
        <Stack
          flex={1}
          padding="1rem"
          paddingTop="0"
          width="100%"
          component="form"
          onSubmit={handleSubmit}
          spacing={8}
          overflow="auto"
        >
          <Stack>
            <Box display="flex" flexDirection="row">
              <Box display="flex" flex={1}></Box>
              <Box display="flex" flex={1} justifyContent="center">
                <Tabs
                  value={tab}
                  onChange={(_, value) => setTab(value, 'pushIn')}
                >
                  <Tab
                    value={'records' as TabKey}
                    label={`Records (${
                      extractableData.isPending
                        ? 'Loading...'
                        : extractableData.data?.records.length
                    })`}
                  />
                  <Tab
                    sx={
                      Object.keys(form.formState.errors).length > 0
                        ? { color: 'red !important' }
                        : {}
                    }
                    value={'details' as TabKey}
                    label="Details"
                  />
                  <Tab
                    value={'fields' as TabKey}
                    label={`Fields (${
                      extractableData.isPending
                        ? 'Loading...'
                        : extractableData.data?.fids.length
                    })`}
                  />
                </Tabs>
              </Box>

              <Box
                display="flex"
                flex={1}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  startIcon={<Icons.DirectionsRunRounded />}
                >
                  Start Extract
                </Button>
              </Box>
            </Box>

            <TabContent tab="details">
              <CreateFormFields
                enableRightAndWrongTicksControl={areSourcesDna}
              />
            </TabContent>
          </Stack>

          <TabContent tab="records">
            <Typography variant="h3">Records</Typography>
            <Typography variant="subtitle1">
              This is a summary of the records selection, to change it,{' '}
              <Link to={ROUTES.app.recordManagement.defaultRecords({})}>
                use the Records page
              </Link>
            </Typography>

            <Stack
              display="flex"
              flexDirection="column"
              flex="1 1 0"
              minHeight={0}
            >
              <RecordsDataGrid
                loading={extractableData.isPending}
                records={extractableData.data?.records ?? []}
                selectedRecords={selectedRecords}
                setSelectedRecords={setSelectedRecords}
              />
            </Stack>
          </TabContent>

          <TabContent tab="fields">
            <Stack
              display="flex"
              flexDirection="column"
              flex="1 1"
              gap={6}
              minHeight={0}
            >
              <Stack display="flex" flex="1" flexDirection="column" gap={3}>
                <Typography variant="h3">Field Groups Selection</Typography>
                <Stack
                  display="flex"
                  flexDirection="column"
                  flex="1 1 0"
                  minHeight={0}
                >
                  <FidGroupSelectionDataGrid
                    loading={extractableData.isPending}
                    fids={extractableData.data?.fids ?? []}
                  />
                </Stack>
              </Stack>
              <Stack
                display="flex"
                flexDirection="column"
                flex="1"
                gap={3}
                marginTop="0px !important"
              >
                <Typography variant="h3">Field Ordering</Typography>
                <Stack
                  display="flex"
                  flexDirection="column"
                  flex="1 1 0"
                  minHeight={0}
                >
                  <FidOrderingDataGrid
                    loading={extractableData.isPending}
                    nameOverrides={nameOverrides}
                    setNameOverride={(key, value) => {
                      setNameOverrides((prev) => ({ ...prev, [key]: value }))
                    }}
                    fids={extractableData.data?.fids ?? []}
                  />
                </Stack>
              </Stack>
            </Stack>
          </TabContent>
        </Stack>
      </PageContentLayout>
    </Form.Provider>
  )
}

const useCreateMutation = () => {
  const navigate = useNavigate()
  const utils = useUtils()

  const { useMutation } = trpc.extracta.create

  return useMutation({
    retry: false,
    onSuccess() {
      utils.extracta.list.invalidate()
      navigate(app.extracta.reports)
    },
  })
}

function TabContent({ tab, children }: { tab: TabKey; children: ReactNode }) {
  const [current] = useQueryParam('tab', TabParam)
  const hideStyle = { display: 'none' }

  return (
    <Stack
      flex={1}
      direction="column"
      style={tab === current ? undefined : hideStyle}
    >
      {children}
    </Stack>
  )
}
