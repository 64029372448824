import { useRef, useState } from 'react'

import * as Icons from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'
import { keepPreviousData } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { useFileDownloader } from '@tk/frontend/primitives'
import { CustomSnackbar } from '@tk/frontend/primitives/CustomSnackbar'

import { SimpleExportEntity, useTelemetry } from './telemetry'
import { RouteDef } from './types'

export type ExportModel = {
  type: 'export'
  taskId: string
  simpleExportRoute: RouteDef
  entity: SimpleExportEntity
}

function ExportView({ taskId, simpleExportRoute, entity }: ExportModel) {
  const snackbar = useSnackbar()
  const modal = useModal()
  const { trackDownload } = useTelemetry(entity)

  const { exportState, totalCount } = useExportState(simpleExportRoute, taskId)

  const downloadFile = useFileDownloader('tickers-export')

  const [downloadStarted, setDownloadStarted] = useState(false)

  const handleDownload = () => {
    if (exportState.type === 'ready') {
      setDownloadStarted(true)

      downloadFile(exportState.downloadUri)

      snackbar.enqueueSnackbar('Download started...', {
        content: (key, message) => (
          <CustomSnackbar key={key} message={message} variant="success" />
        ),
      })

      trackDownload(taskId)

      setTimeout(modal.hide, 500)
    }
  }

  return (
    <Dialog open={!downloadStarted && modal.visible} fullWidth maxWidth="xs">
      <DialogTitle>
        Export
        <IconButton
          sx={{ position: 'absolute', right: 8, top: 8 }}
          onClick={modal.hide}
        >
          <Icons.Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {exportState.type === 'ready' && (
          <Stack direction="row" alignItems="center">
            <Icons.Check color="success" />
            <span>Ready to download</span>
          </Stack>
        )}

        {exportState.type === 'status' && (
          <Stack direction="row" alignItems="center">
            <CircularProgress size={16} thickness={10} />
            <span>
              Working... {exportState.processedCount}/{exportState.totalCount}
            </span>
          </Stack>
        )}

        {exportState.type === 'error' && (
          <>
            <Stack direction="row" alignItems="center">
              <Icons.Error color="error" />
              <span>Export Failed</span>
            </Stack>
            <p>"{exportState.errorMessage ?? exportState.state}"</p>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={exportState.type !== 'ready' || downloadStarted}
          onClick={handleDownload}
          data-row-count={totalCount}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const ModalRenderer = create<ExportModel>((model) => {
  if (model.type === 'export') {
    return <ExportView {...model} />
  }
  return <></>
})

function useExportState(simpleExportRoute: RouteDef, taskId: string) {
  const enabled = useRef(true)

  const [exportState] = simpleExportRoute.poll.useSuspenseQuery(
    {
      taskId: taskId,
    },
    {
      refetchInterval: enabled.current ? 500 : false,
      placeholderData: keepPreviousData,
    }
  )

  if (exportState.type !== 'status') {
    enabled.current = false
  }

  // Only status has this number but we want it for validation in e2e tests too, so we need to cache it
  const totalCountRef = useRef(0)
  if (exportState.type === 'status' && totalCountRef.current === 0) {
    totalCountRef.current = exportState.totalCount
  }

  return { exportState, totalCount: totalCountRef.current }
}
