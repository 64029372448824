import { forwardRef } from 'react'
import {
  createPath,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom'

import MuiLink from '@mui/material/Link'

/**
 * Custom Link component that combines Mui Link and react-router-dom Link.
 * It accepts the same props as react-router-dom's Link component.
 *
 * @link https://reactrouter.com/en/main/components/link
 */
export const AppLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => {
    const navigate = useNavigate()
    const path = useResolvedPath(props.to, { relative: props.relative })

    return (
      <MuiLink
        component={RouterLink}
        onClick={(e) => {
          // This is necessary because when used inside a table cell, the table cell hijacks the click handler
          e.preventDefault()
          e.stopPropagation()

          if (props.target) {
            window.open(createPath(path), props.target)
          } else {
            navigate(props.to)
          }
        }}
        ref={ref}
        {...props}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
    )
  }
)
