import { get, Path } from 'react-hook-form'

import { Typography } from '@mui/material'

import * as gz from '@lib/pgs-types'
import { GridEnrichedColDef } from '@tk/frontend/primitives/datagrid'

type DiffValueError = {
  type: 'error'
  error: string
}
type DiffValueValues = {
  type: 'values'
  values: [any, any]
}
type DiffValue = DiffValueError | DiffValueValues

type BaseDto = Record<string, unknown>

export function getDiffCell<
  Dto extends BaseDto,
  PreviewDto extends gz.ImportPreview<Dto> = gz.ImportPreview<Dto>
>(
  key: Path<Dto>,
  name: string,
  overrides: Partial<GridEnrichedColDef<PreviewDto>> | undefined = {}
): GridEnrichedColDef<PreviewDto> {
  return {
    type: 'string',
    field: key as any,
    headerName: name,
    width: 200,
    ...overrides,
    filterable: false,
    sortable: false,
    aggregable: false,
    valueGetter(params): DiffValue {
      const error = params.row.fieldErrors?.find((field) => field.field === key)
      if (error) {
        return {
          type: 'error',
          error: error.message ?? `Error, invalid data: ${String(error.value)}`,
        }
      }

      let current = get(params.row.currentVersion, key) ?? ''
      let next = get(params.row.newVersion, key) ?? ''

      if (current instanceof Date) {
        current = current.toISOString()
      }
      if (next instanceof Date) {
        next = next.toISOString()
      }

      if (Array.isArray(current)) {
        current = current.map((item) => item.name).join(', ')
      }
      if (Array.isArray(next)) {
        next = next.map((item) => item.name).join(', ')
      }

      if (typeof current === 'object' || typeof next === 'object') {
        console.error(
          '[getDiffCell] selected value must be a primitive type but was an object! Got',
          current,
          next
        )
      }

      return {
        type: 'values',
        values: [current, next],
      }
    },
    renderCell(params) {
      const value = params.value as DiffValue
      if (value.type === 'error') {
        return (
          <Typography
            fontFamily={'"Roboto Mono", monospace'}
            fontSize="inherit"
            color={(theme) => theme.palette.warning.main}
            fontWeight="bold"
          >
            {value.error}
          </Typography>
        )
      }

      const [current, next] = value.values
      if (current === next) {
        return (
          <Typography
            fontFamily={'"Roboto Mono", monospace'}
            fontSize="inherit"
            color={(theme) => theme.palette.secondary.main}
          >
            {current}
          </Typography>
        )
      }

      return (
        <div>
          <Typography
            fontFamily={'"Roboto Mono", monospace'}
            fontSize="inherit"
            color={(theme) => theme.palette.warning.main}
          >
            - {current}
          </Typography>

          <Typography
            fontFamily={'"Roboto Mono", monospace'}
            fontSize="inherit"
            color={(theme) => theme.palette.success.main}
          >
            + {next}
          </Typography>
        </div>
      )
    },
  }
}
