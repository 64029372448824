import { useMemo } from 'react'

import {
  DataGridPremium,
  GridColDef,
  GridRowParams,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium'

import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'
import { useRowExpansionProps } from '@tk/frontend/primitives/datagrid/tree'

import { checkboxColumn } from './CheckBoxColumn'
import { Fids } from './types'
import useFieldRowSelectionModel from './useFieldRowSelectionModel'
import { useFieldSelectionModel } from './useFieldSelectionModel'

type FidGroupDataGridProps = {
  fids: Fids
  loading: boolean
}

export const FidGroupSelectionDataGrid = ({
  fids,
  loading,
}: FidGroupDataGridProps) => {
  const apiRef = useGridApiRef()

  const fieldSelectionModel = useFieldSelectionModel()

  const columns: GridColDef[] = useMemo(
    () =>
      createColumns([
        checkboxColumn(apiRef),
        {
          field: 'fidGroupName',
          headerName: 'Field Group',
          width: 300,
          type: 'string',
          groupable: true,
        },
        {
          field: 'fidName',
          headerName: 'Name/Alias',
          width: 150,
          type: 'string',
        },
        {
          field: 'acronym',
          headerName: 'Acronym',
          width: 150,
          type: 'string',
        },
        {
          field: 'externalId',
          headerName: 'Fid',
          width: 150,
          type: 'string',
        },
        {
          field: 'scope',
          headerName: 'Scope',
          width: 150,
          type: 'string',
        },
      ]).map(setColumnDefaults),
    [apiRef]
  )

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['fidGroupName'],
      },
    },
  })

  const rowExpansion = useRowExpansionProps(apiRef)

  const { onRowSelectionModelChange } = useFieldRowSelectionModel({
    fids,
    fieldSelectionModel,
  })

  return (
    <DataGridPremium
      apiRef={apiRef}
      density="compact"
      initialState={initialState}
      disableRowSelectionOnClick
      isRowSelectable={(params: GridRowParams) =>
        (params.row as { available: boolean }).available === true
      }
      keepNonExistentRowsSelected
      isGroupExpandedByDefault={rowExpansion.isGroupExpandedByDefault}
      checkboxSelection
      getRowId={(row: Fids[0]) => row.rowId}
      columns={columns}
      loading={loading}
      rows={fids ?? []}
      rowSelectionModel={fieldSelectionModel.model}
      onRowSelectionModelChange={onRowSelectionModelChange}
      experimentalFeatures={{ lazyLoading: true }}
      sx={{
        'marginTop': '0px !important',
        '& .MuiDataGrid-columnHeader[aria-colindex="1"] .MuiCheckbox-root:has(input[type="checkbox"])':
          {
            display: 'none',
          },
      }}
    />
  )
}
