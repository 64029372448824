import { Outputs } from '@tk/frontend/api'

import { Option } from './forms/SelectField'

type StrikeUnit = Exclude<Outputs['records']['get']['strikeUnit'], undefined>

export const STRIKE_OPTIONS: Option<StrikeUnit>[] = [
  {
    label: 'ATM',
    value: 'ATM',
  },
  {
    label: 'Basis Points',
    value: 'BASIS_POINTS',
  },
  {
    label: 'Percent',
    value: 'PERCENT',
  },
]

export function formatStrike(
  unit: StrikeUnit | undefined,
  value: number | undefined
) {
  if (!unit || !value) {
    return ''
  }

  switch (unit) {
    case 'PERCENT':
      return `${value}%`
    case 'BASIS_POINTS':
      return `${value} bps`
    case 'ATM':
      return 'ATM'
  }

  console.error('Strike with unexpected unit', unit, 'could not be formatted')

  return ''
}
