import { useCallback } from 'react'

import {
  BloombergMappingEntity,
  ReferenceDataEntity,
  telemetry,
} from '@tk/frontend/telemetry'

export type SimpleExportEntity = BloombergMappingEntity | ReferenceDataEntity

export function useTelemetry(entity: SimpleExportEntity) {
  return {
    trackStart: useCallback(
      (exportId: number | string) => {
        telemetry.capture('gtk/simple-export/start', {
          entity: entity,
          exportId: exportId,
        })
      },
      [entity]
    ),
    trackDownload: useCallback(
      (exportId: number | string) => {
        telemetry.capture('gtk/simple-export/download', {
          entity: entity,
          exportId: exportId,
        })
      },
      [entity]
    ),
  }
}
