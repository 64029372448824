import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { useReferenceDataCrudForm } from '@tk/frontend/telemetry/reference-data-crud'

export type NamedBusinessObjectFormValues = {
  id?: number
  name: string
  description?: string
}

const Form = getFormComponents<NamedBusinessObjectFormValues>()

export type NamedBusinessObjectFormProps = {
  initialValues: Partial<NamedBusinessObjectFormValues>
  useMutation: (isExisting: boolean) => {
    mutateAsync: (
      values: NamedBusinessObjectFormValues
    ) => Promise<NamedBusinessObjectFormValues>
  }
  onFinished?: () => void
  useTelemetry: (
    values: Partial<{ id: number; name: string }>
  ) => ReturnType<typeof useReferenceDataCrudForm>
  optionalDescription?: boolean
}

export function NamedBusinessObjectForm({
  initialValues = {},
  useMutation,
  onFinished,
  useTelemetry,
  optionalDescription = false,
}: NamedBusinessObjectFormProps) {
  const form = Form.useForm({
    defaultValues: initialValues,
  })
  const { handleSubmit: _handleSubmit, formState, getValues } = form

  const id = initialValues.id
  const isExisting = typeof id === 'number' && id > 0
  const progress = usePromiseNotification()
  const mutation = useMutation(isExisting)

  const { trackEditSave, trackEditValidationError } =
    useTelemetry(initialValues)

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync(values as any)
        const result = await progress(
          promise,
          isExisting
            ? {
                progressMessage: 'Updating ' + values.name,
                successMessage: 'Updated ' + values.name,
                failureMessage: 'Error updating ' + values.name,
              }
            : {
                progressMessage: 'Creating',
                successMessage: 'Created',
                failureMessage: 'Error creating',
              }
        )

        trackEditSave(formState.dirtyFields, result)
        onFinished?.()
      },
      (err) => {
        trackEditValidationError(formState.dirtyFields, err, getValues())
      }
    )
  }, [
    _handleSubmit,
    formState.dirtyFields,
    getValues,
    isExisting,
    mutation,
    onFinished,
    progress,
    trackEditSave,
    trackEditValidationError,
  ])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        {isExisting && <input hidden type="number" {...form.register('id')} />}

        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label={`Description ${!optionalDescription ? '(Required)' : ''}`}
          name="description"
          input={
            <Form.TextField
              rules={!optionalDescription ? Form.rules.required : undefined}
            />
          }
        />

        <Form.Row>
          <Button type="submit">{isExisting ? 'Update' : 'Create'}</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}
