import { GridRowIdGetter } from '@mui/x-data-grid-premium'

import { ImportPreview } from '@lib/pgs-types'

export const getRowId: GridRowIdGetter<ImportPreview<{ id: number }>> = (
  row
) => {
  if (row.rowObjectRelation === 'MANY_TO_ONE') {
    return row.currentVersion?.id ?? row.newVersion?.id ?? 0
  } else {
    return row.rowNumber
  }
}
