import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import { useBloombergMappingCreateTelemetry } from '@tk/frontend/app/Bloomberg/telemetry'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

export type CreateFormValues = Inputs['bloomberg']['ticker']['upsert']

const Form = getFormComponents<CreateFormValues>()

export type CreateFormProps = {
  onFinished?: () => void
}

export function CreateForm({ onFinished }: CreateFormProps) {
  const form = Form.useForm({
    defaultValues: {},
  })
  const { handleSubmit: _handleSubmit, formState } = form

  const progress = usePromiseNotification()
  const mutation = useMutation()

  const { trackCreateSuccess, trackCreateValidationError } =
    useBloombergMappingCreateTelemetry('bloomberg/tickers')

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync(values as any)
        const result = await progress(promise, {
          progressMessage: 'Creating',
          successMessage: 'Created',
          failureMessage: 'Error creating',
        })

        trackCreateSuccess(result)
        onFinished?.()
      },
      (err) => {
        trackCreateValidationError(formState.dirtyFields, err)
      }
    )
  }, [
    _handleSubmit,
    formState.dirtyFields,
    mutation,
    onFinished,
    progress,
    trackCreateSuccess,
    trackCreateValidationError,
  ])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />
        <Form.Field
          label="Object ID"
          name="objectId"
          input={<Form.TextField />}
        />

        <Form.Row>
          <Button type="submit">Create</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useMutation() {
  const utils = useUtils()

  const { useMutation } = trpc.bloomberg.ticker.upsert

  return useMutation({
    onSuccess() {
      utils.bloomberg.ticker.invalidate()
    },
  })
}
