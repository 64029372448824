import { Route } from 'react-router-dom'

import * as Icons from '@mui/icons-material'

import { RouterWhen, When } from '@tk/frontend/primitives'
import { NavButton, NavSection } from '@tk/frontend/primitives/layout'

import {
  EntitlementsCrud,
  PricingSourcesCrud,
  ProcessesCrud,
  RecordMappingsCrud,
  RecordMappingsImportPreview,
  RulesetsCrud,
  TickersCrud,
  TickersImportPreview,
} from './Bloomberg'
import * as routes from './routes'

export const Sidebar = () => {
  return (
    <When can="bloomberg-mappings.read" flag="bloombergMappings">
      <NavSection title="Bloomberg Mapping">
        <NavButton
          label="Record Mappings"
          path={routes.app.bloomberg.defaultRecordMapping({})}
          icon={
            <Icons.SettingsInputComponentRounded
              style={{ transform: 'rotate(90deg)' }}
            />
          }
        />
        <NavButton
          label="Tickers"
          path={routes.app.bloomberg.tickers}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Pricing Sources"
          path={routes.app.bloomberg.pricingSources}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Entitlements"
          path={routes.app.bloomberg.entitlements}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Processes"
          path={routes.app.bloomberg.processes}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Rulesets"
          path={routes.app.bloomberg.rulesets}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
      </NavSection>
    </When>
  )
}

export const getRoutes = () => {
  return (
    <Route
      path="/"
      element={
        <RouterWhen can="bloomberg-mappings.read" flag="bloombergMappings" />
      }
    >
      <Route
        path={routes.app.bloomberg.recordMappings}
        element={<RecordMappingsCrud />}
      />

      <Route path={routes.app.bloomberg.tickers} element={<TickersCrud />} />

      <Route
        path={routes.app.bloomberg.pricingSources}
        element={<PricingSourcesCrud />}
      />

      <Route
        path={routes.app.bloomberg.entitlements}
        element={<EntitlementsCrud />}
      />

      <Route
        path={routes.app.bloomberg.processes}
        element={<ProcessesCrud />}
      />

      <Route path={routes.app.bloomberg.rulesets} element={<RulesetsCrud />} />

      <Route path="/" element={<RouterWhen can="bloomberg-mappings.manage" />}>
        <Route
          path={routes.app.bloomberg.recordMappingsImportPreview}
          element={<RecordMappingsImportPreview />}
        />

        <Route
          path={routes.app.bloomberg.tickersImportPreview}
          element={<TickersImportPreview />}
        />
      </Route>
    </Route>
  )
}
