import { useCallback, useEffect, useRef } from 'react'

import { telemetry } from '@tk/frontend/telemetry'
import { Entities } from '@tk/frontend/telemetry/events'

import { useSimpleImportContext } from './SimpleImportContext'
import { useImportErrors } from './useImportHasAnyError'

export type { Entities }

function bytesCountToKB(bytes: number) {
  const KB = bytes / 1024
  return KB
}

export function useSimpleImportTelemetry(entity: Entities) {
  const trackStart = useCallback(
    (file: File, importId: number | string) => {
      telemetry.capture('gtk/simple-import/start', {
        entity: entity,
        fileName: file.name,
        fileSizeKb: bytesCountToKB(file.size),
        state: 'ok',
        importId: importId,
      })
    },
    [entity]
  )

  const trackStartFailed = useCallback(
    (file: File, reason: string) => {
      telemetry.capture('gtk/simple-import/start', {
        entity: entity,
        fileName: file.name,
        fileSizeKb: bytesCountToKB(file.size),
        state: 'error',
        reason: reason,
      })
    },
    [entity]
  )

  const trackPreview = useCallback(
    (importId: number | string) => {
      telemetry.capture('gtk/simple-import/preview', {
        entity: entity,
        importId: importId,
        state: 'ok',
      })
    },
    [entity]
  )

  const trackPreviewWithError = useCallback(
    (
      importId: number | string,
      failedRows: number,
      totalRows: number,
      fields: Set<string>,
      reasons: Set<string>
    ) => {
      telemetry.capture('gtk/simple-import/preview', {
        entity: entity,
        importId: importId,
        state: 'error',
        failedRows: failedRows,
        totalRows: totalRows,
        fields: Array.from(fields),
        reasons: Array.from(reasons),
      })
    },
    [entity]
  )

  const trackApply = useCallback(
    (importId: number | string) => {
      telemetry.capture('gtk/simple-import/apply', {
        entity: entity,
        importId: importId,
        state: 'ok',
      })
    },
    [entity]
  )

  const trackApplyError = useCallback(
    (importId: number | string, reason: string) => {
      telemetry.capture('gtk/simple-import/apply', {
        entity: entity,
        importId: importId,
        state: 'error',
        reason: reason,
      })
    },
    [entity]
  )

  return {
    trackStart,
    trackStartFailed,
    trackPreview,
    trackPreviewWithError,
    trackApply,
    trackApplyError,
  }
}

export function usePreviewTelemetry(entity: Entities, taskId: number) {
  const { route } = useSimpleImportContext()

  const { trackPreview, trackPreviewWithError } =
    useSimpleImportTelemetry(entity)

  const [errors] = useImportErrors(taskId)

  const [status] = route.status.useSuspenseQuery({
    taskId: taskId,
  })

  const dispatched = useRef(false)
  useEffect(() => {
    if (dispatched.current) {
      return
    }

    if (!status || !errors || status.state !== 'READY_TO_PERSIST') {
      return
    }

    dispatched.current = true
    if (errors.status === 'success') {
      if (errors.response.totalElements > 0) {
        const total = status.totalCount
        const withErrors = errors.response.totalElements

        const fields = new Set<string>()
        const reasons = new Set<string>()
        for (const row of errors.response.content) {
          for (const fieldError of row.fieldErrors ?? []) {
            fields.add(fieldError.field ?? 'Unknown Field')
            reasons.add(fieldError.message ?? `Unknown Error`)
          }
        }

        trackPreviewWithError(taskId!, withErrors, total, fields, reasons)
      }
    } else {
      trackPreview(taskId!)
    }
  }, [errors, status, taskId, trackPreview, trackPreviewWithError])
}
