import { z } from 'zod'

import * as PGS from '@lib/pgs'

import {
  BaseNamedBusinessObjectCreate,
  BaseNamedBusinessObjectUpdate,
} from './named-business-object'
import { testAssignable } from './util'

export const AssetTagType = z.enum(['product', 'instrument', 'variant'])
export type AssetTagType = (typeof AssetTagType)['_output']

const assetTags = z.array(
  z.object({
    name: z.string(),
    description: z.string().optional(),
    synonyms: z.string().array(),
    assetTagType: AssetTagType,
  })
)

export const AssetTypeCreateDto = z.object({
  ...BaseNamedBusinessObjectCreate,
  assetClass: z.object({
    id: z.number().min(0),
    name: z.string().default(''),
  }),
  assetTags,
})
testAssignable<PGS.AssetTypeDto, (typeof AssetTypeCreateDto)['_output']>()

export const AssetTypeUpdateDto = z.object({
  ...BaseNamedBusinessObjectUpdate,
  assetClass: z.object({
    id: z.number().min(0),
    name: z.string().default(''),
  }),
  assetTags,
})
testAssignable<PGS.AssetTypeDto, (typeof AssetTypeUpdateDto)['_output']>()
