import React, { useCallback, useMemo, useState } from 'react'

import * as Icons from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
} from '@mui/x-data-grid-premium'

import { trpc, useUtils } from '@tk/frontend/api'
import { usePromiseNotification } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'
import {
  BooleanCell,
  DateCell,
  NameDateCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'

import {
  transformToApiUserUpdateDto,
  transformToTransformedApiUserDto,
} from './apiUserUtil'
import { RoleSelectCell } from './RoleSelectCell'
import {
  getEntitlementsRoleValue,
  getExtractaRoleValue,
  getPgsRoleValue,
  getPicardRoleValue,
} from './roleUtils'
import TokenDialog from './TokenDialog'
import { ApiRole, TransformedApiUserDto } from './types'

function ApiUsersCrudTable() {
  const { data: apiUsers } = trpc.admin.userAdmin.list.useQuery()
  const transformedUsers: TransformedApiUserDto[] = Array.isArray(apiUsers)
    ? apiUsers.map(transformToTransformedApiUserDto)
    : []

  const utils = useUtils()
  const { useMutation } = trpc.admin.userAdmin.update

  const { mutateAsync: updateUser } = useMutation()
  const getTokenMutation = trpc.admin.userAdmin.getTokenAdmin.useMutation()
  const notify = usePromiseNotification()

  const [isGetTokenOpen, setIsGetTokenOpen] = useState(false)
  const [username, setUsername] = useState('')
  const [rowModesModel, setRowModesModel] = useState<
    Record<GridRowId, { mode: GridRowModes }>
  >({})

  const handleGetToken = useCallback(async () => {
    try {
      const response = await notify(
        getTokenMutation.mutateAsync({ username, request: undefined }),
        {
          progressMessage: 'Getting token...',
          successMessage: (result) =>
            `Token copied to clipboard: ${result.token}`,
          failureMessage: 'Failed to get token',
        }
      )

      navigator.clipboard.writeText(response.token ?? '')
      utils.admin.userAdmin.list.invalidate()
    } catch (error) {
      console.error('Get token failed:', error)
    }
  }, [getTokenMutation, notify, username, utils.admin.userAdmin.list])

  const handleGetTokenStart = useCallback((user: TransformedApiUserDto) => {
    setUsername(user.name)
    setIsGetTokenOpen(true)
  }, [])

  const handleEditClick = useCallback((id: GridRowId) => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit },
    }))
  }, [])

  const handleSaveClick = useCallback((id: GridRowId) => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }))
  }, [])

  const handleCancelClick = useCallback((id: GridRowId) => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }))
  }, [])

  const handleProcessRowUpdate = useCallback(
    async (updatedRow: TransformedApiUserDto) => {
      const updatedUser = await updateUser({
        ...transformToApiUserUpdateDto(updatedRow),
        id: updatedRow.id,
      })

      utils.admin.userAdmin.list.invalidate()
      return transformToTransformedApiUserDto(updatedUser)
    },
    [updateUser, utils.admin.userAdmin.list]
  )

  const columns = useMemo(() => {
    const columns: GridColDef<TransformedApiUserDto>[] = [
      {
        type: 'number',
        field: 'id',
        headerName: 'ID',
        editable: false,
        width: 40,
        headerAlign: 'left',
        align: 'left',
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Username',
        editable: true,
        width: 150,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        editable: true,
        width: 200,
      },
      {
        type: 'boolean',
        field: 'enabled',
        headerName: 'Enabled',
        width: 75,
        editable: true,
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <BooleanCell value={params.value ?? false} activeColor="primary" />
        ),
      },
      {
        type: 'boolean',
        field: 'admin',
        headerName: 'Admin',
        width: 100,
        editable: true,
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <BooleanCell value={params.row.admin} activeColor="primary" />
        ),
        renderEditCell: (
          params: GridRenderCellParams<TransformedApiUserDto>
        ) => (
          <Checkbox
            checked={params.row.admin}
            onChange={(event: { target: { checked: boolean } }) => {
              const newRoles = [
                ...(params.row.apiRoles || []).filter(
                  (role) => role !== ApiRole.ADMIN
                ),
                ...(event.target.checked ? [ApiRole.ADMIN] : []),
              ]
              params.api.setEditCellValue(
                {
                  id: params.id,
                  field: 'admin',
                  value: event.target.checked,
                },
                event
              )
              params.api.setEditCellValue(
                {
                  id: params.id,
                  field: 'apiRoles',
                  value: newRoles,
                },
                event
              )
            }}
          />
        ),
      },
      {
        type: 'singleSelect',
        field: 'pgsRole',
        headerName: 'PGS Role',
        headerAlign: 'center',
        width: 150,
        editable: true,
        valueOptions: ['', 'READ', 'MANAGE'],
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <Typography sx={{ textAlign: 'center', width: '100%' }}>
            {params.row.pgsRole || '-'}
          </Typography>
        ),
        renderEditCell: (
          params: GridRenderCellParams<TransformedApiUserDto>
        ) => (
          <RoleSelectCell
            {...params}
            roleType="pgsRole"
            getRoleValue={getPgsRoleValue}
          />
        ),
      },
      {
        type: 'singleSelect',
        field: 'picardRole',
        headerName: 'Picard Role',
        headerAlign: 'center',
        width: 150,
        editable: true,
        valueOptions: ['', 'READ', 'MANAGE'],
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <Typography sx={{ textAlign: 'center', width: '100%' }}>
            {params.row.picardRole || '-'}
          </Typography>
        ),
        renderEditCell: (
          params: GridRenderCellParams<TransformedApiUserDto>
        ) => (
          <RoleSelectCell
            {...params}
            roleType="picardRole"
            getRoleValue={getPicardRoleValue}
          />
        ),
      },
      {
        type: 'singleSelect',
        field: 'extractaRole',
        headerName: 'Extracta Role',
        headerAlign: 'center',
        width: 150,
        editable: true,
        valueOptions: ['', 'READ', 'MANAGE'],
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <Typography sx={{ textAlign: 'center', width: '100%' }}>
            {params.row.extractaRole || '-'}
          </Typography>
        ),
        renderEditCell: (
          params: GridRenderCellParams<TransformedApiUserDto>
        ) => (
          <RoleSelectCell
            {...params}
            roleType="extractaRole"
            getRoleValue={getExtractaRoleValue}
          />
        ),
      },
      {
        type: 'singleSelect',
        field: 'entitlementsRole',
        headerName: 'Entitlements Role',
        headerAlign: 'center',
        width: 150,
        editable: true,
        valueOptions: ['', 'READ', 'MANAGE'],
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <Typography sx={{ textAlign: 'center', width: '100%' }}>
            {params.row.entitlementsRole || '-'}
          </Typography>
        ),
        renderEditCell: (
          params: GridRenderCellParams<TransformedApiUserDto>
        ) => (
          <RoleSelectCell
            {...params}
            roleType="entitlementsRole"
            getRoleValue={getEntitlementsRoleValue}
          />
        ),
      },
      {
        type: 'date',
        field: 'tokenExpiry',
        headerName: 'Token Expiry',
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <DateCell date={params.row.tokenExpiry} />
        ),
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <NameDateCell
            name={params.row.createdBy}
            date={params.row.createdDate}
          />
        ),
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams<TransformedApiUserDto>) => (
          <NameDateCell
            name={params.row.modifiedBy}
            date={params.row.modifiedDate}
          />
        ),
      },
      {
        type: 'actions',
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        getActions: (params) => {
          const { id, row } = params
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

          if (isInEditMode) {
            return [
              <ActionButtonGroup key="table-in-edit-mode">
                <Button
                  startIcon={<Icons.Save />}
                  onClick={() => handleSaveClick(id)}
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  startIcon={<Icons.Cancel />}
                  onClick={() => handleCancelClick(id)}
                  color="secondary"
                >
                  Cancel
                </Button>
              </ActionButtonGroup>,
            ]
          }

          return [
            <ActionButtonGroup key="table-not-in-edit-mode">
              <Button
                startIcon={<Icons.Edit />}
                onClick={() => handleEditClick(id)}
                color="primary"
              >
                Edit
              </Button>
              <Button
                startIcon={<Icons.VpnKey />}
                onClick={() => handleGetTokenStart(row)}
                color="primary"
              >
                Get Token
              </Button>
            </ActionButtonGroup>,
          ]
        },
      },
    ]
    return columns
  }, [
    rowModesModel,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleGetTokenStart,
  ])

  const { filteredList, filterValue, setFilterValue } =
    useClientFilter(transformedUsers)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{ role: 'search' }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />
      <Stack flex="1 1 0" minHeight={0} minWidth={0}>
        <DataGridPremium
          rows={filteredList}
          columns={columns}
          density="compact"
          processRowUpdate={handleProcessRowUpdate}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          initialState={{
            sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
          }}
          editMode="row"
        />
      </Stack>

      <TokenDialog
        isOpen={isGetTokenOpen}
        onClose={() => setIsGetTokenOpen(false)}
        onSubmit={handleGetToken}
        username={username}
      />
    </Box>
  )
}

export default ApiUsersCrudTable
