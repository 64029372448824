import { Stack } from '@mui/material'

import { QueryErrorBoundary } from '@tk/frontend/api'
import { OnScreenError } from '@tk/frontend/primitives'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { ActionButton } from './ActionButton'
import { ProjectEditorTab } from './ProjectEditorTab'
import { ProjectStepper } from './ProjectStepper'
import { SaveStatus } from './SaveStatus'
import { TestingDatagridTab } from './TestingDatagridTab'
import { ProjectDocument, useProjectCrud } from './useProjectCrud'
import { useProjectTab } from './useProjectTab'

export type MultibrandLine = Exclude<
  ProjectDocument['lines'],
  undefined
>['result'][number]

export function ProjectPage() {
  const { tab } = useProjectTab()

  const { project } = useProjectCrud()

  return (
    <PageContentLayout
      title={`Record Design Project: ${project.name}`}
      controls={
        <Stack direction="row" alignItems="center">
          <SaveStatus />

          <ActionButton />
        </Stack>
      }
      maxContentWidth={false}
    >
      <ProjectStepper />

      <QueryErrorBoundary
        resetKeys={[tab]}
        fallbackRender={(props) => (
          <OnScreenError
            error={props.error}
            onReset={props.resetErrorBoundary}
          />
        )}
      >
        {tab === 'editor' && <ProjectEditorTab />}

        {tab === 'test' && <TestingDatagridTab />}
      </QueryErrorBoundary>
    </PageContentLayout>
  )
}
