export const ASSET_TYPE_TAG_OPTIONS = [
  {
    label: 'Instrument',
    value: 'instrument',
  },
  {
    label: 'Product',
    value: 'product',
  },
  {
    label: 'Variant',
    value: 'variant',
  },
]
