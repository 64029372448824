import { Box, TextField } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import { useBloombergMappingTableTelemetry } from '@tk/frontend/app/Bloomberg/telemetry'
import * as routes from '@tk/frontend/app/routes'
import { useFilterQueryParams } from '@tk/frontend/primitives/datagrid'
import { NamedBusinessObjectTableProps } from '@tk/frontend/primitives/datagrid/table'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import { useSimpleImportProvider } from '@tk/frontend/primitives/simple-import'

import { TickerCreateButton } from './TickerCreateButton'
import { TickersCrudTable } from './TickersCrudTable'
import { TickersImportExportMenu } from './TickersImportExportMenu'
import { Dto } from './types'

export type ExtraCols = Exclude<
  NamedBusinessObjectTableProps<Dto>['extraColumns'],
  undefined
>

export function TickersCrud() {
  useBloombergMappingTableTelemetry('bloomberg/tickers')
  const utils = useUtils()

  const filter = useFilterQueryParams()
  const simpleImportProvider = useSimpleImportProvider({
    route: trpc.bloomberg.ticker.import,
    utils: utils.bloomberg.ticker.import,
    previewUriTemplate: routes.app.bloomberg.tickersImportPreview,
  })

  return (
    <PageContentLayout
      title="Bloomberg: Tickers"
      controls={
        <>
          <simpleImportProvider.Provider
            {...simpleImportProvider.providerProps}
          >
            <TickersImportExportMenu />
          </simpleImportProvider.Provider>
          <TickerCreateButton />
        </>
      }
    >
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <TextField
          label="Search (Name)"
          placeholder="You may use the % wildcard to use partial search terms, for instance: AUD%"
          variant="filled"
          onChange={(e) => filter.setValue(e.target.value)}
          value={filter.value}
          fullWidth
        />

        <TickersCrudTable filter={filter.debouncedValue} />
      </Box>
    </PageContentLayout>
  )
}
