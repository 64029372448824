export function keyWiseDiff<T extends object>(
  current: T,
  next: T,
  ignoreKeys: (keyof T)[] = []
): [diff: Partial<T>, distance: number] {
  const diff = {} as Partial<T>

  let distance = 0
  const combined = { ...current, ...next }
  for (const _key in combined) {
    const key = _key as keyof T

    const currentValue = current[key] as unknown
    const nextValue = next[key] as unknown

    if (ignoreKeys.includes(key)) {
      continue
    }

    if (nameLike(currentValue) && nameLike(nextValue)) {
      if (currentValue?.name !== nextValue?.name) {
        distance++
        diff[key] = (nextValue ?? null) as any
      }
    } else if (dateLike(currentValue) && dateLike(nextValue)) {
      if (currentValue?.valueOf() !== nextValue?.valueOf()) {
        distance++
        diff[key] = (nextValue ?? null) as any
      }
    } else if (currentValue !== nextValue) {
      distance++
      diff[key] = (nextValue ?? null) as any
    }
  }
  return [diff, distance]
}

function nameLike(value: unknown): value is { name: unknown } {
  return typeof value === 'object' && !!value && 'name' in value
}

function dateLike(value: unknown): value is Date {
  return value instanceof Date
}
