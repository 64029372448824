import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import {
  useFileDownloader,
  usePromiseNotification,
  When,
} from '@tk/frontend/primitives'

interface ExportButtonProps {
  handleExport: () => Promise<{ objectKey: string; downloadUri: string }>
}

export function ExportButton({ handleExport }: ExportButtonProps) {
  const download = useFileDownloader('generic-export')

  const notify = usePromiseNotification()

  const startExport = async () => {
    const promise = handleExport()
    const result = await notify(promise, {
      progressMessage: 'Starting Export',
      failureMessage: 'Failed to export CSV',
      successMessage: 'Download started',
    })

    download(result.downloadUri)
  }

  return (
    <When can="exports.read">
      <Button
        variant="contained"
        color="secondary"
        startIcon={<Icons.ArrowDownward />}
        onClick={startExport}
      >
        Export CSV
      </Button>
    </When>
  )
}
