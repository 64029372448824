import { z } from 'zod'

import * as PGS from '@lib/pgs'

import {
  BaseNamedBusinessObjectCreate,
  BaseNamedBusinessObjectUpdate,
} from './named-business-object'
import { testAssignable } from './util'

export const LocationUpdateDto = z.object({
  ...BaseNamedBusinessObjectUpdate,
  region: z.object({
    id: z.number().min(0),
    name: z.string().default(''),
  }),
})
testAssignable<PGS.LocationDto, (typeof LocationUpdateDto)['_output']>()

export const LocationCreateDto = z.object({
  ...BaseNamedBusinessObjectCreate,
  region: z.object({
    id: z.number().min(0),
    name: z.string().default(''),
  }),
})
testAssignable<PGS.LocationDto, (typeof LocationCreateDto)['_output']>()
