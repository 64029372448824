import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { OktaAuth, OktaAuthOptions, toRelativeUrl } from '@okta/okta-auth-js'
import {
  // SecureRoute, // TODO: Replace when fixed by: https://github.com/okta/okta-react/issues/178
  LoginCallback,
  Security,
  useOktaAuth,
} from '@okta/okta-react'

interface AppSecurityProps {
  children: any
}

/**
 * Gets a central AppSecurity abstraction with an instance of OktaAuth preconfigured
 */
export const getAppSecurity = (config: OktaAuthOptions) => {
  const oktaAuth = new OktaAuth({
    ...config,
    scopes: ['profile', 'openid', 'email', 'offline_access'],
    tokenManager: {
      ...config.tokenManager,
      autoRenew: true,
      autoRemove: true,
    },
  })

  const AppSecurity = ({ children }: AppSecurityProps) => {
    const navigate = useNavigate()

    useOktaTokenRenewal(oktaAuth)

    return (
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={(client, uri) =>
          navigate(toRelativeUrl(uri || '/', window.location.origin), {
            replace: true,
          })
        }
      >
        {children}
      </Security>
    )
  }

  return { AppSecurity, oktaAuth }
}

export { useOktaAuth, LoginCallback }

/**
 * Tokens by default are not auto renewing, some boilerplate is needed
 * https://github.com/okta/okta-auth-js/issues/1164
 */
function useOktaTokenRenewal(oktaAuth: OktaAuth) {
  useEffect(() => {
    const startPromise = oktaAuth.start()

    return () => {
      startPromise.then(() => oktaAuth.stop())
    }
  }, [oktaAuth])

  useEffect(() => {
    const tokenRenewer = async (key: string) => {
      await oktaAuth.tokenManager.renew(key)
    }

    oktaAuth.tokenManager.on('expired', tokenRenewer)

    return () => {
      oktaAuth.tokenManager.off('expired', tokenRenewer)
    }
  }, [oktaAuth])
}
