import { ReactNode } from 'react'

import { ChevronRight } from '@mui/icons-material'
import { Button, ButtonProps, styled } from '@mui/material'
import {
  GRID_ROOT_GROUP_ID,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium'

interface ExtraParams {
  getLabel?: (children: number) => ReactNode
}

export const getGroupingColDef = (
  colDef?: Partial<GridGroupingColDefOverride<any>>,
  extraParams?: ExtraParams
): GridGroupingColDefOverride<any> => {
  return {
    headerName: ' ',
    renderCell: (params) => <TreeExpander {...params} {...extraParams} />,
    align: 'left',
    width: 180,
    filterable: false,
    sortable: false,
    aggregable: false,
    hideable: false,
    ...colDef,
  }
}

const TreeExpander = ({
  getLabel = (num) => num,
  ...props
}: GridRenderCellParams & ExtraParams) => {
  const isNavigationKey = (key: string) =>
    key === 'Home' ||
    key === 'End' ||
    key.startsWith('Arrow') ||
    key.startsWith('Page') ||
    key === ' '

  const { id, field, rowNode } = props

  const childrenExpanded =
    rowNode.type === 'group' ? rowNode.childrenExpanded ?? false : false

  const apiRef = useGridApiContext()

  const numberOfChildren = Array.isArray((props.rowNode as any).children)
    ? (props.rowNode as any).children.length
    : 0

  const handleKeyDown: ButtonProps['onKeyDown'] = (event) => {
    if (event.key === ' ') {
      event.stopPropagation()
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellKeyDown', props, event)
    }
  }

  const handleClick: ButtonProps['onClick'] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  const hasParent =
    rowNode.parent !== null && rowNode.parent !== GRID_ROOT_GROUP_ID

  if (numberOfChildren === 0 && hasParent) {
    return <ChildBar />
  }

  if (!numberOfChildren) {
    return null
  }

  return (
    <Button
      startIcon={
        <ChevronRight
          style={{
            fontSize: '24px',
            transform: childrenExpanded ? 'rotate(90deg)' : undefined,
            transition: 'all 300ms ease-in-out',
          }}
        />
      }
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={-1}
      size="medium"
      disabled={!numberOfChildren}
      color={childrenExpanded ? 'primary' : 'secondary'}
      data-testid="tree-expander"
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      {getLabel(numberOfChildren)}
    </Button>
  )
}

const ChildBar = styled('div')`
  width: 5px;
  height: 100%;

  background-color: ${({ theme }) => theme.palette.primary.main};
  box-sizing: border-box;

  position: absolute;
  left: 0;
`
