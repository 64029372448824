import * as Icons from '@mui/icons-material'

import { trpc } from '@tk/frontend/api'
import {
  useFileDownloader,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { MenuItem } from '@tk/frontend/primitives/menu'
import { telemetry } from '@tk/frontend/telemetry'

export function DownloadRelinkTemplateButton() {
  const notify = usePromiseNotification()
  const template = trpc.records.relink.template.useMutation()

  const fileDownloader = useFileDownloader('template-download')

  return (
    <MenuItem
      startIcon={<Icons.Preview />}
      disabled={template.isPending}
      onClick={async () => {
        const promise = template.mutateAsync()
        const result = await notify(promise, {
          progressMessage: 'Fetching template',
          failureMessage: 'Failed to fetch template',
          successMessage: 'Download started',
        })

        fileDownloader(result.downloadUri)

        telemetry.capture('gtk/relink/template-download')
      }}
    >
      Template
    </MenuItem>
  )
}
