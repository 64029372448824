import { ReactNode } from 'react'

import { Paper, Stack, StackProps } from '@mui/material'

export type PaperCellProps = {
  children: ReactNode
} & StackProps

export function PaperCell({ children, ...props }: PaperCellProps) {
  return (
    <Paper
      sx={{
        'display': 'flex',
        'alignItems': 'center',

        // If no content is actually in children then hide this element.
        // For instance using <When /> on a child component
        ':has(> div:empty)': { display: 'none' },
      }}
    >
      <Stack direction="row" margin="0.5rem" alignItems="center" {...props}>
        {children}
      </Stack>
    </Paper>
  )
}
