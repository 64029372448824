import { useMemo } from 'react'

import { useProjectCrud } from './useProjectCrud'
import { TabValue } from './useProjectTab'

export function useProjectTabValidity(tabId: TabValue) {
  const { project } = useProjectCrud()

  return useMemo(() => {
    switch (tabId) {
      case 'editor': {
        return true
      }
      case 'test': {
        return !!project.document.isValid
      }
      default: {
        return false
      }
    }
  }, [project.document.isValid, tabId])
}
