import { useEffect } from 'react'

import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium'
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from 'use-query-params'

import { useFilterModel } from '@tk/frontend/primitives/datagrid'
import { MultiSelectFilterId } from '@tk/frontend/primitives/datagrid/columns'

import { FilterKey } from './filters-config'

export function useNavigationParamsRedirect() {
  const { setModel } = useFilterModel()

  const [initialMarketGroup, setInitialMarketGroup] = useQueryParam(
    'initial-marketgroup',
    NumberParam
  )
  const [initialStatusItems, setInitialStatusItems] = useQueryParam(
    'initial-status-items',
    ArrayParam
  )
  const [initialAssetType, setIntialAssetType] = useQueryParam(
    'initial-asset-type',
    NumberParam
  )

  const [initialChain, setInitialChain] = useQueryParam(
    'initial-chain',
    NumberParam
  )

  const [initialFidGroup, setIntialFidGroup] = useQueryParam(
    'initial-fid-group',
    NumberParam
  )

  const [initialTouchedBy, setInitialTouchedBy] = useQueryParam(
    'initial-touched-by-name',
    StringParam
  )

  const [initialSource, setInitialSource] = useQueryParam(
    'initial-source',
    NumberParam
  )

  const [initialCurrency, setInitialCurrency] = useQueryParam(
    'initial-currency',
    NumberParam
  )

  const [initialSubscription, setInitialSubscription] = useQueryParam(
    'initial-subscription',
    NumberParam
  )

  useEffect(() => {
    if (Array.isArray(initialStatusItems) && initialStatusItems.length > 0) {
      setInitialStatusItems(undefined, 'replaceIn')

      const defaultStatusFilter: GridFilterItem = {
        id: -2,
        field: 'status' as FilterKey,
        operator: MultiSelectFilterId,
        value: ['COLLECT', 'DO_NOT_COLLECT'],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter((it) => it.field !== defaultStatusFilter.field),
          defaultStatusFilter,
        ]

        return model
      })
    }

    if (initialMarketGroup && initialMarketGroup > 0) {
      setInitialMarketGroup(undefined, 'replaceIn')

      const defaultMarketGroupFilter: GridFilterItem = {
        id: -1,
        field: 'marketGroups' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialMarketGroup],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultMarketGroupFilter.field
          ),
          defaultMarketGroupFilter,
        ]

        return model
      })
    }

    if (initialAssetType && initialAssetType > 0) {
      setIntialAssetType(undefined, 'replaceIn')

      const defaultAssetTypeFilter: GridFilterItem = {
        id: -1,
        field: 'assetType.name' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialAssetType],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultAssetTypeFilter.field
          ),
          defaultAssetTypeFilter,
        ]

        return model
      })
    }

    if (initialChain && initialChain > 0) {
      setInitialChain(undefined, 'replaceIn')

      const defaultChainFilter: GridFilterItem = {
        id: -1,
        field: 'parentChains' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialChain],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter((it) => it.field !== defaultChainFilter.field),
          defaultChainFilter,
        ]

        return model
      })
    }

    if (initialFidGroup && initialFidGroup > 0) {
      setIntialFidGroup(undefined, 'replaceIn')

      const defaultFidGroupFilter: GridFilterItem = {
        id: -1,
        field: 'fidGroup.name' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialFidGroup],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultFidGroupFilter.field
          ),
          defaultFidGroupFilter,
        ]

        return model
      })
    }

    if (initialTouchedBy && initialTouchedBy !== '') {
      setInitialTouchedBy(undefined, 'replaceIn')

      const defaultTouchedByNamesFilter: GridFilterItem = {
        id: -1,
        field: 'touchedByNames' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialTouchedBy],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultTouchedByNamesFilter.field
          ),
          defaultTouchedByNamesFilter,
        ]

        return model
      })
    }

    if (initialSubscription && initialSubscription > 0) {
      setInitialSubscription(undefined, 'replaceIn')

      const defaultSubscriptionFilter: GridFilterItem = {
        id: -1,
        field: 'subscription' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialSubscription],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultSubscriptionFilter.field
          ),
          defaultSubscriptionFilter,
        ]

        return model
      })
    }

    if (initialSource && initialSource > 0) {
      setInitialSource(undefined, 'replaceIn')

      const defaultIntialSource: GridFilterItem = {
        id: -1,
        field: 'source.name' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialSource],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter((it) => it.field !== defaultIntialSource.field),
          defaultIntialSource,
        ]

        return model
      })
    }

    if (initialCurrency && initialCurrency > 0) {
      setInitialCurrency(undefined, 'replaceIn')

      const defaultIntialCurrency: GridFilterItem = {
        id: -1,
        field: 'currency1.name' as FilterKey,
        operator: MultiSelectFilterId,
        value: [initialCurrency],
      }

      setModel((oldModel) => {
        const model = Object.assign({ items: [] }, oldModel) as GridFilterModel

        model.items = [
          ...model.items.filter(
            (it) => it.field !== defaultIntialCurrency.field
          ),
          defaultIntialCurrency,
        ]

        return model
      })
    }
  }, [
    initialAssetType,
    initialChain,
    initialCurrency,
    initialFidGroup,
    initialMarketGroup,
    initialSource,
    initialStatusItems,
    initialSubscription,
    initialTouchedBy,
    setInitialChain,
    setInitialCurrency,
    setInitialMarketGroup,
    setInitialSource,
    setInitialStatusItems,
    setInitialSubscription,
    setInitialTouchedBy,
    setIntialAssetType,
    setIntialFidGroup,
    setModel,
  ])
}
