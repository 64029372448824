import * as Icons from '@mui/icons-material'

import { MenuItemLink } from '@tk/frontend/primitives/MenuItemLink'
import { telemetry } from '@tk/frontend/telemetry'

import { generateRelinkListUri } from './Relink.routes'

export function RelinkListButton() {
  return (
    <MenuItemLink
      to={generateRelinkListUri()}
      startIcon={<Icons.List />}
      onClick={() => {
        telemetry.capture('gtk/relink/list')
      }}
    >
      Link List
    </MenuItemLink>
  )
}
