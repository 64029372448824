import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { trpc, useUtils } from '@tk/frontend/api'
import { NamedBusinessObjectForm } from '@tk/frontend/primitives/datagrid/tree'
import {
  NamedBusinessObjectCore,
  useReferenceDataCrudForm,
} from '@tk/frontend/telemetry/reference-data-crud'

type Model = {}

export const CreateModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>New Settlement Index</DialogTitle>
      <DialogContent>
        <NamedBusinessObjectForm
          initialValues={{}}
          useMutation={useSettlementIndexMutation}
          onFinished={modal.hide}
          useTelemetry={useTelemetry}
          optionalDescription={true}
        />
      </DialogContent>
    </Dialog>
  )
})

function useSettlementIndexMutation() {
  const utils = useUtils()

  const { useMutation } = trpc.reference.settlementIndex.create

  return useMutation({
    onSuccess() {
      utils.reference.settlementIndex.invalidate()
    },
  })
}

function useTelemetry(values: Partial<NamedBusinessObjectCore>) {
  return useReferenceDataCrudForm('settlement-index', values)
}
