import { useMemo } from 'react'

import { Typography } from '@mui/material'
import { DateTime } from 'luxon'

export type PairedDateCellProps = {
  dateOne: string | Date | null | undefined
  dateTwo: string | Date | null | undefined
  dateOneLabel?: string
  dateTwoLabel?: string
}

const formatDate = (date: string | Date) => {
  const luxDate =
    typeof date === 'string'
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date)

  const timePart = luxDate.toFormat('HH:mm')
  const datePart = luxDate.toFormat('dd LLL yyyy')

  return timePart === '00:00' ? datePart : `${timePart} ${datePart}`
}

export function PairedDateCell({
  dateOne,
  dateTwo,
  dateOneLabel: labelOne,
  dateTwoLabel: labelTwo,
}: Readonly<PairedDateCellProps>) {
  const fmtDateOne = useMemo(() => {
    if (!dateOne) {
      return ''
    }
    return formatDate(dateOne)
  }, [dateOne])

  const fmtDateTwo = useMemo(() => {
    if (!dateTwo) {
      return ''
    }
    return formatDate(dateTwo)
  }, [dateTwo])

  return (
    <div>
      {fmtDateOne && !fmtDateTwo && (
        <>
          {labelOne && <Typography variant="body2">{labelOne}</Typography>}
          <Typography variant="body2">{fmtDateOne}</Typography>
        </>
      )}
      {fmtDateTwo && !fmtDateOne && (
        <>
          {labelTwo && <Typography variant="body2">{labelTwo}</Typography>}
          <Typography variant="body2">{fmtDateTwo}</Typography>
        </>
      )}
      {fmtDateOne && fmtDateTwo && dateOne !== dateTwo && (
        <>
          <Typography variant="body2">{fmtDateOne}</Typography>
          <Typography variant="body2" color="GrayText">
            {fmtDateTwo}
          </Typography>
        </>
      )}
      {fmtDateOne && fmtDateTwo && dateOne === dateTwo && (
        <Typography variant="body2">{fmtDateOne}</Typography>
      )}
    </div>
  )
}

export default PairedDateCell
