import { generatePath, To } from 'react-router-dom'

import { RecordStatus } from './Records/common/crud/constants'

export const auth = {
  SignIn: '/signin',
  SignInResponse: '/login/callback',
  SignOut: '/signout',
} as const

type DefaultRecordsParams = {
  initialMarketGroupId?: number
  statusItems?: RecordStatus[]
  filter?: string
  initialAssetTypeId?: number
  chain?: number
  fidGroup?: number
  touchedByNames?: string
  source?: number
  currency?: number
  subscription?: number
}

type DefaultRecordMappingParams = {
  ticker?: number
  entitlement?: number
  pricingSource?: number
  process?: number
  ruleset?: number
}

type DefaultFidParams = {
  filter?: string
}

export const app = {
  recordDesigner: {
    start: '/record-design/start',
    startWithSlug: '/record-design/start/:slug',
    startWithSlugOld: '/multibrand-design/start/:slug',
    project: '/record-design/project/:projectName',
  },
  multibrand: {
    stubs: '/multibrand/stubs',
    attribute: '/multibrand/attribute',
  },
  entitlements: {
    actor: '/entilements/actor',
    distribution: '/entilements/distribution',
    ruleset: '/entilements/ruleset',
  },
  recordManagement: {
    defaultRecords: ({
      initialMarketGroupId,
      statusItems = ['COLLECT', 'DO_NOT_COLLECT'],
      filter,
      initialAssetTypeId,
      chain,
      fidGroup,
      touchedByNames,
      source,
      currency,
      subscription,
    }: DefaultRecordsParams) => {
      const search = new URLSearchParams()

      if (initialMarketGroupId) {
        search.append('initial-marketgroup', String(initialMarketGroupId))
      }

      if (statusItems.length > 0) {
        search.append('initial-status-items', statusItems.join(','))
      }

      if (filter) {
        search.append('filter', filter)
      }

      if (initialAssetTypeId) {
        search.append('initial-asset-type', String(initialAssetTypeId))
      }

      if (chain) {
        search.append('initial-chain', String(chain))
      }

      if (fidGroup) {
        search.append('initial-fid-group', String(fidGroup))
      }

      if (touchedByNames) {
        search.append('initial-touched-by-name', touchedByNames)
      }

      if (source) {
        search.append('initial-source', String(source))
      }

      if (currency) {
        search.append('initial-currency', String(currency))
      }

      if (subscription) {
        search.append('initial-subscription', String(subscription))
      }

      const to: To = {
        pathname: generatePath(app.recordManagement.records),
        search: search.toString(),
      }

      return to
    },
    records: '/record-management/records',
    import: '/record-management/records/imports',
    export: '/record-management/records/exports',
    relink: '/record-management/records/relink',
    commercialPackages: '/record-management/commercial-packages',
  },
  referenceData: {
    assetTypes: {
      list: '/reference-data/asset-types',
      create: '/reference-data/asset-types/create/:id',
      edit: '/reference-data/asset-types/edit/:id',
    },
    clearingTypes: '/reference-data/clearing-types',
    companies: '/reference-data/companies',
    currencies: '/reference-data/currencies',
    dayCounts: '/reference-data/day-counts',
    fids: '/reference-data/fids',
    frequencies: '/reference-data/frequencies',
    locations: '/reference-data/locations',
    periods: '/reference-data/periods',
    priceTypes: '/reference-data/price-types',
    pricingFrequencies: '/reference-data/pricing-frequencies',
    settlementIndex: '/reference-data/settlement-index',
    settlementMethods: '/reference-data/settlement-methods',
    sources: '/reference-data/sources',
    standardDiscountings: '/reference-data/standard-discountings',
    subscriptions: '/reference-data/subscriptions',
  },
  bloomberg: {
    defaultRecordMapping: ({
      ticker,
      pricingSource,
      entitlement,
      process,
      ruleset,
    }: DefaultRecordMappingParams) => {
      const search = new URLSearchParams()

      if (pricingSource) {
        search.append('initial-pricing-source-ids', String(pricingSource))
      }

      if (ticker) {
        search.append('initial-ticker-ids', String(ticker))
      }

      if (entitlement) {
        search.append('initial-entitlement-ids', String(entitlement))
      }

      if (process) {
        search.append('initial-process-ids', String(process))
      }

      if (ruleset) {
        search.append('initial-ruleset-ids', String(ruleset))
      }

      const to: To = {
        pathname: generatePath(app.bloomberg.recordMappings),
        search: search.toString(),
      }

      return to
    },
    tickers: '/bloomberg/tickers',
    tickersImportPreview: '/bloomberg/tickers/import/:taskId',
    pricingSources: '/bloomberg/pricing-sources',
    entitlements: '/bloomberg/entitlements',
    processes: '/bloomberg/processes',
    rulesets: '/bloomberg/rulesets',
    recordMappings: '/bloomberg/records-mappings',
    recordMappingsImportPreview: '/bloomberg/records-mappings/import/:taskId',
  },
  fids: {
    groups: {
      defaultFidGroup: ({ filter }: DefaultFidParams) => {
        const search = new URLSearchParams()

        if (filter) {
          search.append('filter', filter)
        }

        return {
          pathname: generatePath(app.fids.groups.table),
          search: search.toString(),
        }
      },
      table: '/fids/groups',
      create: '/fids/groups/create',
      edit: '/fids/groups/edit/:id',
    },
    list: '/fids/list',
  },
  extracta: {
    reports: '/extracta/reports',
    createReport: '/extracta/create',
    createFromCurrentQueryParams: () => {
      const search = window.location.search

      const to: To = {
        pathname: generatePath(app.extracta.createReport),
        search: search,
      }

      return to
    },
  },
  admin: {
    userRoles: '/admin/user-roles',
    roles: '/admin/roles',
    admin: '/admin/api-users',
  },
} as const

export const Playground = '/playground'

export const ROUTES = {
  auth,
  app,
}
