import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { InstrumentForm } from './InstrumentForm'
import { MarketGroupForm } from './MarketGroupForm'
import { Instrument, MarketGroup } from './types'

type BackboneModel = {
  type: 'instrument'
  data: Partial<Instrument>
}

type MarketGroupModel = {
  type: 'marketgroup'
  data: Partial<MarketGroup>
}

export const MutationModal = create<BackboneModel | MarketGroupModel>(
  (model) => {
    const modal = useModal()

    let title = ''
    if (model.type === 'marketgroup') {
      title = !model.data.id ? 'New Market Group' : 'Editing Market Group'
    } else {
      title = !model.data.id
        ? 'New Market Instrument'
        : 'Editing Market Instrument'
    }

    return (
      <Dialog open={modal.visible} onClose={modal.hide}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {model.type === 'instrument' ? (
            <InstrumentForm
              initialValues={model.data}
              onFinished={modal.hide}
            />
          ) : (
            <MarketGroupForm
              initialValues={model.data}
              onFinished={modal.hide}
            />
          )}
        </DialogContent>
      </Dialog>
    )
  }
)
