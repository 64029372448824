import z from 'zod'

import { ImportTaskDto } from '@lib/pgs'
import * as gz from '@lib/pgs-types'
import { RouteInterface } from '@tk/frontend/api'
import { theme } from '@tk/frontend/theme'

export type RouteDef = RouteInterface.SimpleImportRoute
export type RouteUtilsDef = RouteInterface.SimpleImportRouteUtils

export const entities = ['bloomberg-tickers', 'bloomberg-mappings'] as const
export type Entity = (typeof entities)[number]

export type ApiFieldError = z.infer<typeof gz.ApiFieldError>
export type ApiFieldLineError = z.infer<typeof gz.ApiFieldLineError>

//
// Import Maps

export type ImportState = ImportTaskDto['state']
export const ImportStateLabel: Record<ImportState, string> = {
  NEW: 'Processing',
  GENERATING_PREVIEW: 'Processing',
  READY_TO_PERSIST: 'Ready to Apply',
  PERSISTING: 'Applying Updates',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Complete',
  FAILED: 'Failed',
}

export const ImportProcessingStates: ImportState[] = [
  'GENERATING_PREVIEW',
  'NEW',
  'PERSISTING',
]

export const ImportStateColour: Record<ImportState, string> = {
  NEW: theme.palette.secondary.main,
  GENERATING_PREVIEW: theme.palette.secondary.main,
  READY_TO_PERSIST: theme.palette.primary.main,
  PERSISTING: theme.palette.primary.main,
  CANCELLED: theme.palette.secondary.main,
  COMPLETED: theme.palette.secondary.main,
  FAILED: theme.palette.error.main,
}
