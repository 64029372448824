import { ReactNode } from 'react'

import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material'

type FilterDropdownMenuProps = {
  value: string | undefined
  onChange: (event: SelectChangeEvent, child: ReactNode) => void
  label: string
  children: ReactNode
}

export const DropdownMenuFilter = ({
  value,
  onChange,
  label,
  children,
}: FilterDropdownMenuProps) => {
  return (
    <FormControl sx={{ minWidth: 150 }}>
      <InputLabel>{label}</InputLabel>

      <Select
        size="small"
        label={label}
        value={value}
        autoWidth
        onChange={onChange}
      >
        {children}
      </Select>
    </FormControl>
  )
}
