import { useMemo } from 'react'

import { keys } from '@lib/utils'
import { environment, Environment } from '@tk/frontend/environment'

function getForceOn() {
  return window.localStorage.getItem('FTR_FORCE_ON') === '1'
}
function setForceOn(on: string) {
  on = on ? '1' : ''
  window.localStorage.setItem('FTR_FORCE_ON', on)
  window.location.reload()
}
;(window as any).getFlagsForceOn = getForceOn
;(window as any).setFlagsForceOn = setForceOn

export type KnownFeature = keyof Environment['featureFlags']

export function useFeature(name: KnownFeature | undefined) {
  if (getForceOn()) {
    return true
  }

  if (name === undefined) {
    // No flag required
    return true
  }

  return environment.featureFlags[name]
}

export function useFeatures(): ReadonlySet<KnownFeature> {
  return useMemo(
    () =>
      new Set(
        keys(environment.featureFlags).filter((key) => {
          if (getForceOn()) {
            return true
          }

          return environment.featureFlags[key]
        })
      ),
    []
  )
}
