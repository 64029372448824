import {
  cloneElement,
  MouseEventHandler,
  ReactNode,
  useRef,
  useState,
} from 'react'

import * as Icons from '@mui/icons-material'
import { Popover, Stack, StackProps, Typography } from '@mui/material'

export type InfoPopoverProps = {
  children?: ReactNode
  content?: ReactNode
  contentContainer?: JSX.Element
  iconSize?: 'small' | 'large' | 'medium' | 'inherit'
  colour?: 'light' | 'dark' | 'inherit'
  iconPosition?: 'prefix' | 'suffix'
  icon?: ReactNode
  stackProps?: StackProps
}

export function InfoPopover({
  children,
  content,
  contentContainer = (
    <Typography
      variant="inherit"
      margin="0.5rem"
      maxWidth="25rem"
      component="div"
    />
  ),
  colour = 'light',
  iconSize,
  iconPosition = 'suffix',
  icon = (
    <Icons.Info
      color="inherit"
      fontSize={iconSize}
      sx={{ paddingBottom: '2px' }}
    />
  ),
  stackProps,
}: InfoPopoverProps) {
  const ref = useRef<HTMLElement>()
  const [open, setOpen] = useState<false | HTMLElement>(false)

  const isPopover = !!content

  let textColour = 'inherit'
  if (colour === 'light') {
    textColour = 'whitesmoke'
  }
  if (colour === 'dark') {
    textColour = 'GrayText'
  }

  return (
    <>
      {!!children && (
        <Stack
          direction="row"
          color={textColour}
          spacing={1}
          component="div"
          onClick={
            isPopover
              ? (((e) => {
                  e.stopPropagation()
                  setOpen(e.target as any)
                }) as MouseEventHandler<any>)
              : undefined
          }
          alignItems="center"
          style={{ cursor: isPopover ? 'pointer' : 'auto' }}
          {...stackProps}
          ref={ref as any}
        >
          {isPopover && iconPosition === 'prefix' && icon}

          {children}

          {isPopover && iconPosition === 'suffix' && icon}
        </Stack>
      )}

      {!children && (
        <Stack
          color={textColour}
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="0.25rem"
          onClick={
            isPopover
              ? (e) => {
                  e.stopPropagation()
                  setOpen(e.target as any)
                }
              : undefined
          }
          style={{ cursor: isPopover ? 'pointer' : 'auto' }}
          {...stackProps}
          ref={ref as any}
        >
          {icon}
        </Stack>
      )}

      {!!content && (
        <Popover
          open={!!open}
          anchorEl={ref.current}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onClose={() => setOpen(false)}
        >
          {cloneElement(contentContainer, {
            children: content,
          })}
        </Popover>
      )}
    </>
  )
}
