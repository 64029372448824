import { FC } from 'react'
import { Path } from 'react-hook-form'

import {
  getMultiSelectFilters,
  getServersideMultiSelectFilters,
} from '@tk/frontend/primitives/datagrid/columns'

import {
  RECORD_SCOPE_OPTIONS,
  RECORD_STATUS_OPTIONS,
  RECORD_TYPE_OPTIONS,
} from './common/crud/constants'
import {
  AssetTypeFilterTag,
  ChainParentFilterTag,
  CurrencyFilterTag,
  FidGroupFilterTag,
  FilterTagProps,
  MarketGroupFilterTag,
  SourceFilterTag,
  SubscriptionFilterTag,
  TouchedByNamesFilterTag,
} from './FilterTags'
import {
  GridFilterAssetType,
  GridFilterCommercialPackage,
  GridFilterCurrency,
  GridFilterFidGroup,
  GridFilterRecordChain,
  GridFilterSource,
  GridFilterSubscription,
  GridFilterTouchedByNames,
} from './GridFilters'
import { RecordColDef, RecordDto, RecordListQuery } from './types'

export const FilterKey = [
  'status',
  'type',
  'assetType.name',
  'marketGroups',
  'parentChains',
  'fidGroup.name',
  'scope',
  'source.name',
  'currency1.name',
  'touchedByNames',
  'subscription',
] satisfies Path<RecordDto>[]

export type FilterKey = (typeof FilterKey)[number]

export interface FilterConfigCustom {
  type: 'custom'
  setApiQueryProperty: (query: RecordListQuery, value: any[]) => void
  colDef: Partial<RecordColDef>
  FilterTag: FC<FilterTagProps>
}

export interface FilterConfigBasic {
  type: 'basic'
  setApiQueryProperty: (query: RecordListQuery, value: any[]) => void
  colDef: Partial<RecordColDef>
  getFieldLabel(): string
  getValueLabel(value: unknown): string
}

export const FILTERS_CONFIG = {
  'status': {
    type: 'basic',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['status'] ??= []
        query['status'].push(...value)
      }
    },
    colDef: getMultiSelectFilters({ options: RECORD_STATUS_OPTIONS }),
    getFieldLabel() {
      return 'Status'
    },
    getValueLabel(value) {
      if (typeof value !== 'string') {
        return ''
      }

      return (
        RECORD_STATUS_OPTIONS.find((option) => value === option.value)?.label ??
        value
      )
    },
  },
  'type': {
    type: 'basic',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['type'] ??= []
        query['type'].push(...value)
      }
    },
    colDef: getMultiSelectFilters({ options: RECORD_TYPE_OPTIONS }),
    getFieldLabel() {
      return 'Type'
    },
    getValueLabel(value) {
      if (typeof value !== 'string') {
        return ''
      }

      return (
        RECORD_TYPE_OPTIONS.find((option) => value === option.value)?.label ??
        value
      )
    },
  },
  'scope': {
    type: 'basic',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['scope'] ??= []
        query['scope'].push(...value)
      }
    },
    colDef: getMultiSelectFilters({ options: RECORD_SCOPE_OPTIONS }),
    getFieldLabel() {
      return 'Scope'
    },
    getValueLabel(value) {
      if (typeof value !== 'string') {
        return ''
      }

      return (
        RECORD_SCOPE_OPTIONS.find((option) => value === option.value)?.label ??
        value
      )
    },
  },
  'assetType.name': {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['assetTypeId'] ??= []
        query['assetTypeId'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterAssetType,
    }),
    FilterTag: AssetTypeFilterTag,
  },
  'marketGroups': {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['marketGroupId'] ??= []
        query['marketGroupId'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterCommercialPackage,
    }),
    FilterTag: MarketGroupFilterTag,
  },
  'parentChains': {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['chainIds'] ??= []
        query['chainIds'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterRecordChain,
    }),
    FilterTag: ChainParentFilterTag,
  },
  'fidGroup.name': {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['fidGroupIds'] ??= []
        query['fidGroupIds'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterFidGroup,
    }),
    FilterTag: FidGroupFilterTag,
  },
  'source.name': {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['sourceId'] ??= []
        query['sourceId'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterSource,
    }),
    FilterTag: SourceFilterTag,
  },
  'currency1.name': {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['currencyId'] ??= []
        query['currencyId'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterCurrency,
    }),
    FilterTag: CurrencyFilterTag,
  },
  'touchedByNames': {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['touchedByNames'] ??= []
        query['touchedByNames'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterTouchedByNames,
    }),
    FilterTag: TouchedByNamesFilterTag,
  },
  'subscription': {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['subscriptionId'] ??= []
        query['subscriptionId'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterSubscription,
    }),
    FilterTag: SubscriptionFilterTag,
  },
} satisfies Record<FilterKey, FilterConfigBasic | FilterConfigCustom>
