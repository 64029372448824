import { MutableRefObject, useEffect, useRef } from 'react'

import { GridFilterItem } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { StringParam, useQueryParam } from 'use-query-params'

import { WildcardOperatorName } from './filter-operators'

export function useSearch(
  apiRef: MutableRefObject<GridApiPremium>,
  filterBase?: Partial<GridFilterItem>
) {
  const [search, setSearch] = useQueryParam('search', StringParam)

  const staticFilterBase = useRef(filterBase ?? {})

  useEffect(() => {
    apiRef.current.upsertFilterItem({
      id: -1,
      field: 'name',
      operator: WildcardOperatorName,
      ...staticFilterBase.current,
      value: search,
    })
  }, [apiRef, search])

  return [search, setSearch] as const
}
