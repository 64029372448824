import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import NiceModal from '@parameta/nice-modal-react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SnackbarProvider } from 'notistack'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { getAppSecurity } from '@lib/okta-web'

import { ApiProvider, QueryErrorBoundary } from './api'
import { App } from './app/app'

import './fonts'
import './main.css'
import './mui-x'

import { SuspenseBoundaryWithBlockingSpinner } from './primitives'
import { telemetry } from './telemetry'
import { Theme } from './theme'

import './rum'

import { environment } from './environment'

const { AppSecurity } = getAppSecurity(environment.auth)

telemetry.init()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <Theme>
      <QueryErrorBoundary>
        <SuspenseBoundaryWithBlockingSpinner>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <AppSecurity>
                <ApiProvider>
                  <SnackbarProvider
                    maxSnack={5}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <NiceModal.Provider>
                      <App />
                    </NiceModal.Provider>
                  </SnackbarProvider>

                  {import.meta.env.DEV && (
                    <ReactQueryDevtools
                      initialIsOpen={false}
                      buttonPosition="bottom-left"
                    />
                  )}
                </ApiProvider>
              </AppSecurity>
            </QueryParamProvider>
          </BrowserRouter>
        </SuspenseBoundaryWithBlockingSpinner>
      </QueryErrorBoundary>
    </Theme>
  </StrictMode>
)
