import { useCallback } from 'react'

import { usePromiseNotification } from '@tk/frontend/primitives/progress'
import { useFileDownloader } from '@tk/frontend/primitives/useFileDownloader'

import { RouteDef } from './types'

export type DownloadTypes = {
  route: RouteDef
  fileName: string
}

export function useTemplateDownload({ fileName, route }: DownloadTypes) {
  const notify = usePromiseNotification()

  const downloadTemplateFile = useFileDownloader(fileName)

  const templateDownloadMutation = route.template.useMutation({
    onSuccess(data) {
      downloadTemplateFile(data.downloadUri)
    },
  })

  const handleDownloadTemplate = useCallback(async () => {
    await notify(templateDownloadMutation.mutateAsync(), {
      progressMessage: 'Starting download...',
      successMessage: 'Download started',
      failureMessage: 'Download failed',
    })
  }, [notify, templateDownloadMutation])

  return {
    downloadTemplate: handleDownloadTemplate,
    downloadTemplateInProgress: templateDownloadMutation.isPending,
  }
}
