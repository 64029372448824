import { Typography } from '@mui/material'
import styled from 'styled-components'

export type WrappedTextCellProps = {
  text?: string
}

export const WrappedTextCellContainer = styled.div`
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

export function WrappedTextCell({ text }: WrappedTextCellProps) {
  return (
    <WrappedTextCellContainer>
      <Typography variant="body2">{text}</Typography>
    </WrappedTextCellContainer>
  )
}
