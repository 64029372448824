// Useful tool: https://www.hexcolortool.com/

export const Colours = {
  Brand: {
    // INFO: these '2' versions are slightly off-brand but much better for UIs. May need tidying up one-day however
    RedDark: '#8C0000',
    RedMid: '#D84A00',
    RedLight: '#FF6F00',
    BlueDark: '#045FB0',
    BlueDark2: '#095e80',
    BlueMid: '#07B0F3',
    BlueMid2: '#0b9dd7',
    BlueLight: '#00FFFF',
    BlueLight2: '#07B0F3',
  },
  FgShade: {
    Dark: '#313131',
    Darkish: '#5e5e5e',
    Mid: '#7A7A7A',
    Light: '#858585',
    Lighter: '#D9D9D9',
  },
  BgShade: {
    Darker: '#dfdfdf',
    Dark: '#ECECEC',
    Mid: '#F3F3F3',
    Light: '#FFFFFF',
    Blue: '#538193',
  },
}
