import { Route, Routes } from 'react-router-dom'

import { ImportProgressList } from './ImportProgressList'
import { ImportProgressPreview } from './ImportProgressPreview'
import * as routes from './Imports.routes'

export function ImportsRouter() {
  return (
    <Routes>
      <Route path={routes.relative.list} element={<ImportProgressList />} />
      <Route
        path={routes.relative.preview}
        element={<ImportProgressPreview />}
      />
    </Routes>
  )
}
