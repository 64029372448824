import { useCallback, useRef } from 'react'
import { Path } from 'react-hook-form'

import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'

export type ColumnVisibility<T extends object> = {
  [key in Path<T>]?: boolean
}

export function useColumnVisibilityModel<T extends object>(
  defaultVisibility: ColumnVisibility<T>
) {
  const [model, _setModel] = useQueryParam<GridColumnVisibilityModel>(
    'columns',
    useRef(withDefault(JsonParam, defaultVisibility)).current
  )

  const setModel = useCallback(
    (model: GridColumnVisibilityModel) => {
      _setModel(model)
    },
    [_setModel]
  )

  return { model, setModel }
}
