import { useEffect } from 'react'

import _ from 'lodash'

import { telemetry } from '@tk/frontend/telemetry'

type Values = {
  filter: string
  page: number
}

const captureEvent = _.debounce(
  (values: Values) => {
    telemetry.capture('gtk/record-table/show', values)
  },
  1000,
  { leading: false, trailing: true }
)

export function useRecordsTableTelemetry({ filter, page }: Values) {
  useEffect(() => {
    captureEvent({ filter, page })
  }, [filter, page])
}
