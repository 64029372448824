import { Route, Routes } from 'react-router-dom'

import { RecordCreatePage } from './Create'
import { RecordEditPage } from './Edit'
import { relative } from './RecordsRouter.routes'
import { RecordsTablePage } from './RecordsTablePage'

export function RecordsRouter() {
  return (
    <Routes>
      <Route path={relative.list} element={<RecordsTablePage />} />
      <Route path={relative.edit} element={<RecordEditPage />} />
      <Route path={relative.create} element={<RecordCreatePage />} />
    </Routes>
  )
}
