import { Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

import { RecordPreview } from '@lib/pgs'
import { GridEnrichedColDef } from '@tk/frontend/primitives/datagrid'
import { getDiffCell } from '@tk/frontend/primitives/datagrid/diff'

import { actionColors, ActionType, Color } from './types'

export const useGenerateColumns = (): GridEnrichedColDef[] => [
  {
    field: 'rowNumber',
    headerName: 'Row Number',
    width: 150,
  },
  {
    field: 'summary',
    headerName: 'Summary',
    width: 150,
    renderCell: ({ row }: GridRenderCellParams<RecordPreview>) => {
      const action = row.action as ActionType
      const color: Color = actionColors[action]

      return (
        <Typography
          component="span"
          sx={{
            fontFamily: '"Roboto Mono", monospace',
            fontSize: '14px',
            color,
            fontWeight: 'bold',
          }}
        >
          {row.action}
        </Typography>
      )
    },
  },

  getDiffCell('status', 'Status', { width: 150 }),
  getDiffCell('source', 'Source', { width: 150 }),
  getDiffCell('validFromDate', 'Valid From Date', { width: 200 }),
  getDiffCell('validToDate', 'Valid To Date', { width: 200 }),
]
