import { useMemo } from 'react'

import { Button, LinearProgress, Stack, Typography } from '@mui/material'

import { MarketCategoryScope } from '@lib/pgs-types'
import { trpc, useUtils } from '@tk/frontend/api'
import { useRecordsList } from '@tk/frontend/app/Records/useRecordsList'
import { useRecordsQueryParams } from '@tk/frontend/app/Records/useRecordsQueryParams'
import {
  getFormComponents,
  SuspenseBoundaryLocal,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/Selector'

import { ExistingCommercialPackageFilters } from './ExistingCommercialPackageFilters'

interface FormProps {
  packageId: number
}

const Form = getFormComponents<FormProps>()

export type AssignmentFormProps = {
  mode: 'add' | 'remove'
  onFinished?: () => void
}

export function AssignmentForm({ mode, onFinished }: AssignmentFormProps) {
  const params = useRecordsQueryParams()
  const [records] = useRecordsList()
  const expectedCount = records.totalElements ?? 0

  const [packages] = trpc.commercialPackages.ui.listPackages.useSuspenseQuery()

  const form = Form.useForm({
    defaultValues: {
      packageId: undefined,
    },
  })
  const { handleSubmit: _handleSubmit } = form

  const progress = usePromiseNotification()
  const mutation = trpc.commercialPackages.updateRecordAssignments.useMutation()
  const utils = useUtils()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync({
          mode: mode,
          packageId: values.packageId,
          query: params.query,
          expectedCount: expectedCount,
        })

        await progress(promise, {
          progressMessage: 'Saving',
          successMessage: 'Saved',
          failureMessage: 'Error saving',
        })

        utils.invalidate()

        onFinished?.()
      },
      (errs) => {
        console.warn('Validation error', errs)
      }
    )
  }, [
    _handleSubmit,
    expectedCount,
    mode,
    mutation,
    onFinished,
    params.query,
    progress,
    utils,
  ])

  if (mutation.isPending) {
    return (
      <Stack width="30rem">
        <Form.Row>
          <Typography variant="subtitle2">
            Please wait, this may take some time...
          </Typography>
        </Form.Row>

        <Form.Row>
          <LinearProgress />
        </Form.Row>
      </Stack>
    )
  }

  if (mutation.isError) {
    return (
      <Form.Row>
        <Typography variant="subtitle2">
          Something went wrong, please report this to{' '}
          <a href="mailto:dataengineering@tpicap.com">
            dataengineering@tpicap.com
          </a>
        </Typography>
      </Form.Row>
    )
  }

  return (
    <Form.Provider {...form}>
      <Stack width="100%" component="form" onSubmit={handleSubmit}>
        <Form.Field
          label="Package (Required)"
          name="packageId"
          input={
            <Form.SelectField
              autoFocus
              options={packages}
              rules={Form.rules.required}
            />
          }
        />

        <SuspenseBoundaryLocal>
          {form.watch('packageId') && (
            <ExistingCommercialPackageFilters
              packageId={form.watch('packageId')}
            />
          )}
        </SuspenseBoundaryLocal>

        <Form.Row>
          <Button type="submit">
            {mode === 'add' ? `Include in package` : `Exclude from package`}
          </Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

type ScopeName = (typeof MarketCategoryScope)['_output']
export const SCOPE_OPTIONS: Option<ScopeName>[] = [
  {
    label: 'External',
    value: 'EXTERNAL',
  },
  {
    label: 'Internal',
    value: 'INTERNAL',
  },
  {
    label: 'Restricted',
    value: 'RESTRICTED',
  },
]
