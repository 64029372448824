import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { When } from '@tk/frontend/primitives'

import { CreateModal } from './CreateModal'

export function ActorCreateButton() {
  const modal = useModal(CreateModal)

  return (
    <When can="entitlements.manage">
      <Button
        startIcon={<Icons.Create />}
        variant="contained"
        onClick={() => modal.show()}
      >
        Create
      </Button>
    </When>
  )
}
