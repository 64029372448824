import { Box, TextField } from '@mui/material'
import {
  DataGridPremium,
  GridRowClassNameParams,
  useGridApiRef,
} from '@mui/x-data-grid-premium'

import { trpc } from '@tk/frontend/api'
import {
  defaultProps,
  useRowSelectionModel,
  useSearch,
} from '@tk/frontend/primitives/datagrid'
import {
  TREE_CHILD_CLASS,
  TREE_PARENT_CLASS,
  TreeDataGridStyles,
  useTreeGroupingProps,
} from '@tk/frontend/primitives/datagrid/tree'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import { ExportButton } from '@tk/frontend/primitives/reference-export'
import { useReferenceDataCrudTable } from '@tk/frontend/telemetry/reference-data-crud'

import { AssetClassCreateButton } from './AssetClassCreateButton'
import { TypedRowUnion } from './types'
import { useAssetColumns } from './useAssetColumns'
import { useAssetRows } from './useAssetRows'

export function AssetTypeCrud() {
  const apiRef = useGridApiRef()
  const [search, setSearch] = useSearch(apiRef)
  const selectionModel = useRowSelectionModel()

  const rows = useAssetRows()
  const columns = useAssetColumns()

  useReferenceDataCrudTable('asset-type')

  const exportMutation = trpc.reference.assetType.export.useMutation()

  const groupingProps = useTreeGroupingProps(apiRef, {
    getTreeDataPath,
    getRowId,
    getRowClassName,
  })

  return (
    <>
      <PageContentLayout
        title="Asset Classes & Types"
        controls={
          <>
            <ExportButton handleExport={exportMutation.mutateAsync} />

            <AssetClassCreateButton />
          </>
        }
        maxContentWidth={false}
      >
        <Box display="flex" flexDirection="column" height="100%" width="100%">
          <TextField
            value={search ?? ''}
            onChange={(e) => setSearch(e.target.value)}
            label="Filter"
            variant="filled"
            inputProps={{
              role: 'search',
            }}
            fullWidth
            placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%Non-Deliverable%"'
          />

          <TreeDataGridStyles hasMargin={true}>
            <DataGridPremium
              {...defaultProps}
              //
              density="compact"
              apiRef={apiRef}
              //
              {...groupingProps}
              //
              rows={rows}
              columns={columns}
              rowSelectionModel={selectionModel.model}
              onRowSelectionModelChange={selectionModel.setModel}
              keepNonExistentRowsSelected
            />
          </TreeDataGridStyles>
        </Box>
      </PageContentLayout>
    </>
  )
}

function getTreeDataPath(row: TypedRowUnion): string[] {
  if (row._type === 'assetTag') {
    return [
      row.parent.assetClass?.name ?? '[Unassigned]',
      row.parent.name,
      row.name,
    ]
  }
  if (row._type === 'assetType') {
    return [row.assetClass?.name ?? '[Unassigned]', row.name]
  } else {
    return [row.name]
  }
}

function getRowId(row: TypedRowUnion) {
  if (row._type === 'assetTag') {
    return `${row._type}/${row.parent.name}/${row.name}`
  }
  if (row._type === 'assetType') {
    return `${row._type}/${row.assetClass?.name}/${row.name}`
  } else {
    return `${row._type}/${row.id}`
  }
}

const getRowClassName = (params: GridRowClassNameParams<TypedRowUnion>) =>
  params.row._type === 'assetClass' ? TREE_PARENT_CLASS : TREE_CHILD_CLASS
