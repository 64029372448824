import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  labelify,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

import { Instrument } from './types'

type InstrumentUpsert = Inputs['multibrand']['instrument']['create'] & {
  id: number
}

const Form = getFormComponents<InstrumentUpsert>()

export type Props = {
  initialValues: Partial<InstrumentUpsert> & {
    multibrandMarketGroup?: Partial<Instrument['multibrandMarketGroup']>
  }
  onFinished?: () => void
}

export function InstrumentForm({ initialValues = {}, onFinished }: Props) {
  const id = initialValues.id
  const isExisting = typeof id === 'number' && id > 0
  const progress = usePromiseNotification()

  const form = Form.useForm({
    defaultValues: initialValues,
  })
  const { handleSubmit: _handleSubmit } = form

  const mutation = InstrumentForm.useInstrumentMutation(isExisting)
  const backboneQuery = useMarketGroupQuery()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(async (values) => {
      const promise = mutation.mutateAsync(values)
      await progress(
        promise,
        isExisting
          ? {
              progressMessage: 'Updating ' + values.name,
              successMessage: 'Updated ' + values.name,
              failureMessage: 'Error updating ' + values.name,
            }
          : {
              progressMessage: 'Creating',
              successMessage: 'Created',
              failureMessage: 'Error creating',
            }
      )

      onFinished?.()
    })
  }, [_handleSubmit, isExisting, mutation, onFinished, progress])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        {isExisting && <input hidden type="number" {...form.register('id')} />}

        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Field
          label="Market Group (Required)"
          name="multibrandMarketGroup.id"
          input={
            <Form.SelectField
              options={backboneQuery.data}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Row>
          <Button type="submit">{isExisting ? 'Update' : 'Save'}</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useInstrumentMutation(isExisting: boolean) {
  const utils = useUtils()

  const { useMutation } = isExisting
    ? trpc.multibrand.instrument.update
    : trpc.multibrand.instrument.create

  return useMutation({
    onSuccess() {
      utils.multibrand.marketGroup.invalidate()
      utils.multibrand.instrument.invalidate()
    },
  })
}
InstrumentForm.useInstrumentMutation = useInstrumentMutation

function useMarketGroupQuery() {
  return trpc.multibrand.marketGroup.list.useQuery(undefined, {
    select: (items) => {
      return items.map<Option>((item) => {
        return {
          label: labelify(item.name, item.description),
          value: item.id,
        }
      })
    },
  })
}
