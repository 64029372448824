import { createContext, memo, useContext, useEffect } from 'react'

interface Value {
  prefix: string
  separator: string
}

const Context = createContext<Value>({ prefix: '', separator: '' })

const TitleProviderInner = ({
  prefix = '',
  separator = ': ',
  ...props
}: Value & { children: any }) => {
  return <Context.Provider {...props} value={{ prefix, separator }} />
}

export const TitleProvider = memo(TitleProviderInner)

export const useTitle = (title: string) => {
  const { prefix, separator } = useContext(Context)

  useEffect(() => {
    if (!title) {
      return
    }

    const lastTitle = document.title

    document.title = prefix ? `${prefix}${separator}${title}` : title

    return () => {
      document.title = lastTitle
    }
  }, [prefix, separator, title])
}
