import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'

import { TypedInstrument, TypedMarketGroup, TypedRowUnion } from './types'

export function useRows() {
  const [[marketgroups, instruments]] = trpc.useSuspenseQueries((t) => {
    return [
      t.multibrand.marketGroup.list(undefined, {
        select(data): TypedMarketGroup[] {
          return data.map<TypedMarketGroup>((item) => {
            return {
              ...item,
              _type: 'marketgroup',
            }
          })
        },
      }),
      t.multibrand.instrument.list(undefined, {
        select(data): TypedInstrument[] {
          return data.map<TypedInstrument>((item) => {
            return {
              ...item,
              _type: 'instrument',
            }
          })
        },
      }),
    ]
  })

  const rows = useMemo<TypedRowUnion[]>(() => {
    return [...marketgroups, ...instruments]
  }, [instruments, marketgroups])

  return rows
}
