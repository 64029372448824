import { useCallback } from 'react'

import { DataGridPremiumProps, GridRowId } from '@mui/x-data-grid-premium'
import { QueryParamConfig, useQueryParam } from 'use-query-params'

export type DetailExpansionModel =
  DataGridPremiumProps['detailPanelExpandedRowIds']
export type OnDetailExpansionModelChange = Exclude<
  DataGridPremiumProps['onDetailPanelExpandedRowIdsChange'],
  undefined
>

const Param: QueryParamConfig<GridRowId[]> = {
  decode(value) {
    if (!value) {
      return []
    }
    return JSON.parse(value as string)
  },
  encode(value) {
    if (!value || value.length === 0) {
      return undefined
    }
    return JSON.stringify(value)
  },
  default: undefined,
}

export function useDetailExpansionModel(): {
  model: DetailExpansionModel
  setModel: OnDetailExpansionModelChange
} {
  const [model, setModel] = useQueryParam('detail-expansion', Param)

  return {
    model: model,
    setModel: useCallback<OnDetailExpansionModelChange>(
      (ids, details) => setModel(ids, 'replaceIn'),
      [setModel]
    ),
  }
}
