import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'

import { trpc } from '@tk/frontend/api'
import { labelify } from '@tk/frontend/primitives'
import {
  useDetailExpansionModel,
  useFilterModel,
} from '@tk/frontend/primitives/datagrid'

import { useProjectCrud } from './useProjectCrud'
import { useTestingColumns } from './useTestingColumns'

export function TestingDatagridTab() {
  const { projectName } = useProjectCrud()

  const [testResult, testResultQuery] =
    trpc.recordProjects.testProject.useSuspenseQuery({
      name: projectName,
    })

  const columns = useTestingColumns()

  const filterModel = useFilterModel('testing-filters')
  const detailExpansionModel = useDetailExpansionModel()
  console.log(testResultQuery)
  return (
    <Stack flex="1 1 0" overflow="hidden">
      <DataGridPremium
        density="compact"
        loading={testResultQuery.isFetching}
        disableAggregation
        disableRowGrouping
        columns={columns}
        getRowId={(row) => row.source.backbone + row.source.name + row.record}
        rows={testResult}
        //
        filterModel={filterModel.model}
        onFilterModelChange={filterModel.setModel}
        //
        getDetailPanelHeight={() => 300}
        detailPanelExpandedRowIds={detailExpansionModel.model}
        onDetailPanelExpandedRowIdsChange={detailExpansionModel.setModel}
        getDetailPanelContent={(params) => {
          return (
            <Box
              sx={{
                padding: '0.5rem',
                height: '100%',
                borderRadius: '0.25rem',
                overflow: 'hidden',
                bgcolor: 'rgb(0, 0, 0, 0.05)',
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: '100%',
                  bgcolor: 'white',
                  boxShadow: 2,
                  overflowY: 'scroll',
                }}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>FID</TableCell>
                      <TableCell>Problems</TableCell>
                      <TableCell>Last Value</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {params.row.validations.onBothFidGroupAndInFeedAndWithValue.map(
                      (fid, i) => (
                        <TableRow key={i}>
                          <TableCell>{labelify(fid.id, fid.acronym)}</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {fid.lastValue === null ? 'NULL' : fid.lastValue}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    {params.row.validations.onBothFidGroupAndInFeedButNullValue.map(
                      (fid, i) => (
                        <TableRow key={i}>
                          <TableCell>{labelify(fid.id, fid.acronym)}</TableCell>
                          <TableCell>Published NULL value</TableCell>
                          <TableCell>
                            {fid.lastValue === null ? 'NULL' : fid.lastValue}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    {params.row.validations.inFidGroupButNotOnFeed.map(
                      (fid, i) => (
                        <TableRow key={i}>
                          <TableCell>{labelify(fid.id, fid.acronym)}</TableCell>
                          <TableCell>Not found on RTDS</TableCell>
                          <TableCell>
                            {fid.lastValue === null ? 'NULL' : fid.lastValue}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    {params.row.validations.onFeedButNotInFidGroup.map(
                      (fid, i) => (
                        <TableRow key={i}>
                          <TableCell>{labelify(fid.id, fid.acronym)}</TableCell>
                          <TableCell>Not found in Field Group</TableCell>
                          <TableCell>
                            {fid.lastValue === null ? 'NULL' : fid.lastValue}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    {params.row.validations.excludedRtdsFids.map((fid, i) => (
                      <TableRow key={i}>
                        <TableCell>{labelify(fid.id, fid.acronym)}</TableCell>
                        <TableCell>OK: Internal / Ignored FID</TableCell>
                        <TableCell>
                          {fid.lastValue === null ? 'NULL' : fid.lastValue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        }}
        //
        experimentalFeatures={{
          lazyLoading: true,
        }}
        sx={{ border: 'none' }}
      />
    </Stack>
  )
}
