import { useMemo } from 'react'

import {
  Autocomplete,
  CircularProgress,
  Popper,
  TextField,
  TextFieldProps,
} from '@mui/material'

export type Option<Value> = {
  label: string
  value: Value
}

export interface SelectorProps<TOption extends Option<any> = Option<any>> {
  name?: string
  label?: string
  placeholder?: string
  options?: TOption[]
  width?: string
  noEmptyOption?: boolean
  fullWidth?: boolean
  fullHeight?: boolean
  loading?: boolean
  variant?: TextFieldProps['variant']
  value: TOption | null | undefined
  onSelect: (value: TOption | undefined) => void
  onSearch?: (ev: any, search: string) => void
  inputProps?: Partial<TextFieldProps>
}

const EMPTY_OPTION: Option<any> = { value: undefined, label: '[None]' }

export function Selector<TOption extends Option<any> = Option<any>>(
  props: SelectorProps<TOption>
) {
  const options = useProcessedOptions(props)

  const fullHeight = props.fullHeight ? { height: '100%' } : {}

  const value = useMemo(() => {
    if (options.length && props.value) {
      return props.value
    }

    if (props.noEmptyOption) {
      return undefined
    }

    return EMPTY_OPTION as TOption
  }, [options.length, props.noEmptyOption, props.value])

  return (
    <Autocomplete<TOption, false, true, false>
      size="small"
      sx={{
        width: props.width ?? (props.fullWidth ? 'auto' : '7rem'),
      }}
      options={options}
      PopperComponent={SelectPopper}
      selectOnFocus
      fullWidth={props.fullWidth}
      value={value}
      isOptionEqualToValue={(option, value) =>
        String(option?.value).trim() === String(value?.value).trim()
      }
      getOptionLabel={(option) =>
        (typeof option === 'string' ? option : option?.label) ?? ''
      }
      onChange={(e, item) => {
        props.onSelect(item ?? undefined)
      }}
      onInputChange={(e, value) => {
        props.onSearch?.(e, value?.trim())
      }}
      autoComplete
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            style: {
              ...fullHeight,
            },
            endAdornment: (
              <>
                {!!props.loading && (
                  <CircularProgress color="inherit" size={15} />
                )}

                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={props.label}
          variant={props.variant ?? 'standard'}
          size="small"
          placeholder={props.placeholder}
          style={{ ...fullHeight }}
          {...props.inputProps}
        />
      )}
      style={{ ...fullHeight, width: '100%' }}
    />
  )
}

const SelectPopper = function (props: any) {
  return (
    <Popper
      {...props}
      style={{ width: 'auto', maxWidth: '30rem' }}
      placement="bottom-start"
    />
  )
}
function useProcessedOptions<TOption extends Option<any> = Option<any>>(
  props: SelectorProps<TOption>
) {
  return useMemo(() => {
    const givenOptions = props.options ?? []

    const valueIsInList =
      givenOptions.findIndex((option) => option.value === props.value?.value) >=
      0

    const result: any[] = []

    return result.concat(
      props.noEmptyOption ? [] : [EMPTY_OPTION],
      valueIsInList ? [] : [props.value],
      givenOptions
    ) as TOption[]
  }, [props.options, props.noEmptyOption, props.value])
}
