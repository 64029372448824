import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { RenameForm } from './RenameForm'

type Model = {}

export const RecordRenameModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>
        <Typography variant="body1">Rename Record</Typography>
        <Typography
          variant="subtitle2"
          color={'GrayText'}
          sx={{ width: '30rem', marginTop: '.5rem' }}
        >
          Renaming a record will create a new record and link it with the
          original record
        </Typography>
      </DialogTitle>

      <DialogContent>
        <RenameForm onFinished={modal.hide} />
      </DialogContent>
    </Dialog>
  )
})
