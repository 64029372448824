import { FC } from 'react'
import { Path } from 'react-hook-form'

import { getServersideMultiSelectFilters } from '@tk/frontend/primitives/datagrid/columns'

import {
  EntitlementFilterTag,
  FilterTagProps,
  PricingSourceFilterTag,
  ProcessFilterTag,
  RulesetFilterTag,
  TickerFilterTag,
} from './FilterTags'
import {
  GridFilterEntitlement,
  GridFilterPricingSource,
  GridFilterProcess,
  GridFilterRuleset,
  GridFilterTicker,
} from './GridFilters'
import {
  RecordMappingColDef,
  RecordMappingListQuery,
  TreeItemMapping,
} from './types'

export const FilterKey = [
  'ticker',
  'pricingSource',
  'entitlement',
  'process',
  'ruleset',
] satisfies Path<TreeItemMapping>[]

export type FilterKey = (typeof FilterKey)[number]

export interface FilterConfigCustom {
  type: 'custom'
  setApiQueryProperty: (query: RecordMappingListQuery, value: any[]) => void
  colDef: Partial<RecordMappingColDef>
  FilterTag: FC<FilterTagProps>
}

export const FILTERS_CONFIG = {
  ticker: {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['tickerIds'] ??= []
        query['tickerIds'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterTicker,
    }),
    FilterTag: TickerFilterTag,
  },
  pricingSource: {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['pricingSourceIds'] ??= []
        query['pricingSourceIds'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterPricingSource,
    }),
    FilterTag: PricingSourceFilterTag,
  },
  entitlement: {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['entitlementIds'] ??= []
        query['entitlementIds'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterEntitlement,
    }),
    FilterTag: EntitlementFilterTag,
  },
  process: {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['processIds'] ??= []
        query['processIds'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterProcess,
    }),
    FilterTag: ProcessFilterTag,
  },
  ruleset: {
    type: 'custom',
    setApiQueryProperty(query, value) {
      if (value?.length > 0) {
        query['rulesetIds'] ??= []
        query['rulesetIds'].push(...value)
      }
    },
    colDef: getServersideMultiSelectFilters({
      InputComponent: GridFilterRuleset,
    }),
    FilterTag: RulesetFilterTag,
  },
} satisfies Record<FilterKey, FilterConfigCustom>
