import { useCallback, useState } from 'react'

import {
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'
import _ from 'lodash'

import { AllPossibleKeys } from '@lib/utils'

export function useRowModes<T extends GridValidRowModel>() {
  const [rowModes, setRowModes] = useState<GridRowModesModel>({})

  const setEditMode = useCallback(
    (id: number | string, fieldToFocus: AllPossibleKeys<T>) =>
      setRowModes((modes) => ({
        ..._.mapValues(modes, (mode) => {
          return {
            ...mode,
            mode: GridRowModes.View,
          }
        }),
        [id]: {
          ...(modes[id] ?? {}),
          mode: GridRowModes.Edit,
          fieldToFocus: fieldToFocus,
        } as GridRowModesModel[number],
      })),
    []
  )
  const setViewMode = useCallback(
    (id: number | string, changes: 'ignore' | 'save') =>
      setRowModes((modes) => ({
        ...modes,
        [id]: {
          ...(modes[id] ?? {}),
          mode: GridRowModes.View,
          ignoreModifications: changes === 'ignore',
        } as GridRowModesModel[number],
      })),
    []
  )

  return {
    rowModes,
    setEditMode,
    setViewMode,
  }
}
