import type * as PGS from '@lib/pgs'

import { z } from 'zod'

import { RecordScope, RecordStatus, RecordType } from './common'
import { testAssignable } from './util'

const BaseRecordListQuery = {
  filter: z
    .string()
    .optional()
    .transform((filter) => {
      if (!filter) {
        return undefined
      }
      return filter
    }),
  sort: z.union([z.literal('id'), z.literal('name')]).default('id'),
  direction: z.union([z.literal('asc'), z.literal('desc')]).default('asc'),
  marketGroupId: z.array(z.number()).optional().transform(coerceToUndefined),
  status: z.array(RecordStatus).optional().transform(coerceToUndefined),
  type: z.array(RecordType).optional().transform(coerceToUndefined),
  assetTypeId: z.array(z.number()).optional().transform(coerceToUndefined),
  chainIds: z.array(z.number()).default([]),
  fidGroupIds: z.array(z.number()).default([]),
  sourceId: z.array(z.number()).optional().transform(coerceToUndefined),
  currencyId: z.array(z.number()).optional().transform(coerceToUndefined),
  scope: z.array(RecordScope).optional().transform(coerceToUndefined),
  touchedByNames: z.array(z.string()).optional().transform(coerceToUndefined),
  subscriptionId: z.array(z.number()).optional().transform(coerceToUndefined),
}
export const RecordListQuery = z.object({
  ...BaseRecordListQuery,
})
type RecordListQueryInput = Parameters<
  PGS.RecordControllerService['getRecordsPage']
>[0]
testAssignable<RecordListQueryInput, (typeof RecordListQuery)['_output']>()

function coerceToUndefined<T>(items: T[] | undefined | null) {
  if (!items || items.length === 0) {
    return undefined
  }
  return items
}

export const RecordListPagedQuery = z.object({
  ...BaseRecordListQuery,
  page: z.number().optional(),
  size: z.number().default(100),
})
testAssignable<RecordListQueryInput, (typeof RecordListQuery)['_output']>()

export const RecordListValuesQuery = z.object({
  ...BaseRecordListQuery,
  valueName: z.array(z.string()).optional(),
})

export const RenameRecordQuery = z.object({
  id: z.number().min(1),
  newRecordName: z.string().min(1),
  newSourceId: z.number().min(1),
  newStatus: RecordStatus,
})

export type SnowflakeQuerySchema = {
  recordName: string
  source: string
  lastSeen: Date
  lastMessage: Record<string, string>
  status: string
}
