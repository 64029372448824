import React, { useMemo } from 'react'

import { Typography } from '@mui/material'
import { DateTime } from 'luxon'

export type DateCellProps = {
  date: string | Date | null | undefined
}

export function DateCell({ date }: DateCellProps) {
  const fmtDate = useMemo(() => {
    if (!date) {
      return ''
    }

    const luxDate =
      typeof date === 'string'
        ? DateTime.fromISO(date)
        : DateTime.fromJSDate(date)

    return luxDate.toFormat('HH:mm dd LLL yyyy')
  }, [date])

  return (
    <Typography variant="body2" color="GrayText">
      {fmtDate}
    </Typography>
  )
}

export default DateCell
