import { Paper } from '@mui/material'

import { When } from '@tk/frontend/primitives'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { RulesetCreateButton } from './RulesetCreateButton'
import { RulesetCrudTable } from './RulesetCrudTable'

export function RulesetCrud() {
  return (
    <>
      <PageContentLayout
        title="Ruleset"
        controls={
          <When can="entitlements.manage">
            <RulesetCreateButton />
          </When>
        }
      >
        <Paper
          sx={{
            width: '100%',
            flex: '1 1 0',
            overflow: 'hidden',
          }}
        >
          <RulesetCrudTable />
        </Paper>
      </PageContentLayout>
    </>
  )
}
