import posthog from 'posthog-js'

import { environment, isE2E } from '@tk/frontend/environment'

import { EventByKey, EventName, EventPropertiesArg } from './events'
import { debug, debugEvent } from './telemetry-logging'

const isEnabled =
  environment.posthog.token && !isE2E && !environment.posthog.forceDisable

export function init() {
  if (isEnabled) {
    posthog.init(environment.posthog.token!, {
      ...environment.posthog,
      autocapture: false,
      sanitize_properties(properties, event_name) {
        debugEvent('[posthog:sending]', event_name, properties)
        return properties
      },
    })

    posthog.register({
      app: 'gtk',
    })
  } else {
    console.warn('Posthog was not initialised')
  }
}

export function signout() {
  debug('[posthog:signout] resetting')

  if (!isEnabled) {
    return
  }

  posthog.reset()
}

export function capture<TEventName extends EventName>(
  name: TEventName,
  ...args: EventPropertiesArg<EventByKey<TEventName>>
) {
  if (!isEnabled) {
    return
  }

  const [properties] = args

  posthog.capture(name, properties)
}
