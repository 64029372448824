import { useEffect } from 'react'

import { useOktaAuth } from './okta-browser'

export type SignOutProps = {
  onSignout?: () => void
}

export function SignOut({ onSignout }: SignOutProps) {
  const auth = useOktaAuth()

  useEffect(() => {
    onSignout?.()
    auth.oktaAuth.signOut()
  }, [auth.oktaAuth, onSignout])

  return null
}
