import { generatePath } from 'react-router-dom'

import * as routes from '@tk/frontend/app/routes'

export const basePath = routes.app.recordManagement.records

export const relative = {
  list: '',
  create: 'create',
  edit: 'edit/:id',
} as const

export const generateRecordListUri = routes.app.recordManagement.defaultRecords

export const generateRecordCreateUri = () => {
  return generatePath(basePath + `/${relative.create}`)
}

export const generateRecordEditUri = (id: string | number) => {
  return generatePath(basePath + `/${relative.edit}`, { id: String(id) })
}
