import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { trpc, useUtils } from '@tk/frontend/api'
import { NamedBusinessObjectForm } from '@tk/frontend/primitives/datagrid/tree'
import {
  NamedBusinessObjectCore,
  useReferenceDataCrudForm,
} from '@tk/frontend/telemetry/reference-data-crud'

type Model = {}

export const CreateModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>New Pricing Frequency</DialogTitle>
      <DialogContent>
        <NamedBusinessObjectForm
          initialValues={{}}
          useMutation={usePricingFrequencyMutation}
          onFinished={modal.hide}
          useTelemetry={useTelemetry}
        />
      </DialogContent>
    </Dialog>
  )
})

function usePricingFrequencyMutation() {
  const utils = useUtils()

  const { useMutation } = trpc.reference.pricingFrequency.create

  return useMutation({
    onSuccess() {
      utils.reference.pricingFrequency.invalidate()
    },
  })
}

function useTelemetry(values: Partial<NamedBusinessObjectCore>) {
  return useReferenceDataCrudForm('pricing-frequency', values)
}
