import { MultibrandLine } from './RecordProjectRepository'

export function createMultibrandRecordName(line: MultibrandLine) {
  let multibrandName = ''
  multibrandName += line.stub
  multibrandName += line.rrn
  multibrandName += ':'
  multibrandName += line.location
  multibrandName += '.'
  multibrandName += line.clearing
  multibrandName += '.'
  multibrandName += line.priceType
  multibrandName += '.'
  multibrandName += line.frequency
  multibrandName += '!'
  multibrandName += line.brand

  return multibrandName
}
