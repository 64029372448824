import { ReactNode } from 'react'

import {
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material'

export type MenuItemProps = MuiMenuItemProps & {
  children: ReactNode
  startIcon?: ReactNode
  disabled?: boolean
}

export function MenuItem({
  children,
  startIcon,
  disabled,
  ...props
}: MenuItemProps) {
  return (
    <MuiMenuItem disabled={disabled} {...props}>
      {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
      <ListItemText>{children}</ListItemText>
    </MuiMenuItem>
  )
}
