import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

export type ActorFormValues = Inputs['entitlements']['actor']['create'] & {
  id: number
}

const Form = getFormComponents<ActorFormValues>()

export type ActorFormProps = {
  onFinished?: () => void
}

export function ActorForm({ onFinished }: ActorFormProps) {
  const form = Form.useForm({
    defaultValues: {},
  })
  const { handleSubmit: _handleSubmit } = form

  const progress = usePromiseNotification()
  const mutation = useMutation()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(async (values) => {
      const promise = mutation.mutateAsync(values as any)
      await progress(promise, {
        progressMessage: 'Creating',
        successMessage: 'Created',
        failureMessage: 'Error creating',
      })

      onFinished?.()
    })
  }, [_handleSubmit, mutation, onFinished, progress])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description"
          name="description"
          input={<Form.TextField />}
        />

        <Form.Row>
          <Button type="submit">Create</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useMutation() {
  const utils = useUtils()

  const { useMutation } = trpc.entitlements.actor.create

  return useMutation({
    onSuccess() {
      utils.entitlements.actor.invalidate()
    },
  })
}
