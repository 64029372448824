import type * as PGS from '@lib/pgs'

import { z } from 'zod'

import { IsoDate } from './common'
import { testAssignable } from './util'

export const BaseAuditFields = {
  id: z.number().default(0),
  modifiedBy: z.string().optional(),
  modifiedDate: IsoDate.optional(),
  createdBy: z.string().optional(),
  createdDate: IsoDate.optional(),
}

export const BaseNamedBusinessObject = {
  name: z.string().default(''),
  description: z.string().default(''),
  ...BaseAuditFields,
} as const

export const BaseNamedBusinessObjectCreate = {
  name: z.string(),
  description: z.string().default(''),
} as const

export const BaseNamedBusinessObjectUpdate = {
  id: z.number().default(0),
  name: z.string(),
  description: z.string().default(''),
} as const

//
// DTOs

export const NamedBusinessObjectUpdateDto = z.object({
  ...BaseNamedBusinessObjectUpdate,
})
testAssignable<
  PGS.NamedBusinessObjectDto,
  (typeof NamedBusinessObjectUpdateDto)['_output']
>()

export const NamedBusinessObjectCreateDto = z.object({
  ...BaseNamedBusinessObjectCreate,
})
testAssignable<
  PGS.NamedBusinessObjectDto,
  (typeof NamedBusinessObjectCreateDto)['_output']
>()
