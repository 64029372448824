import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { StringParam, useQueryParam } from 'use-query-params'

export type RelinkListFilterState = 'all' | 'completed' | 'ready' | 'failed'

export function useRelinkListParam() {
  const [_value, setValue] = useQueryParam('row-filter', StringParam)

  const value = _value as RelinkListFilterState | undefined | null

  return [
    value ?? 'all',
    (value: RelinkListFilterState) => setValue(value, 'replaceIn'),
  ] as const
}

export function RelinkListToggleFilter() {
  const [value, onChange] = useRelinkListParam()

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value: RelinkListFilterState) => onChange(value)}
    >
      <ToggleButton value={'all' as RelinkListFilterState} color="info">
        All
      </ToggleButton>

      <ToggleButton value={'ready' as RelinkListFilterState} color="info">
        Active
      </ToggleButton>

      <ToggleButton value={'completed' as RelinkListFilterState} color="info">
        Completed
      </ToggleButton>

      <ToggleButton value={'failed' as RelinkListFilterState} color="warning">
        Failed
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
