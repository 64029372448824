import {
  Box,
  Container,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import styled from 'styled-components'

const GridItem = styled(Paper)`
  min-height: 20rem;
  padding: 1rem;

  display: flex;
  flex-direction: column;
`

export function Home() {
  return (
    <Container sx={{ maxWidth: '80rem' }}>
      <Stack alignItems="center">
        <Box margin="1rem">
          <Typography variant="h1" color="Background" fontWeight={500}>
            Welcome to your Dashboard
          </Typography>
        </Box>

        <Box flexGrow={1} width="100%">
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <GridItem>
                <Typography variant="h4">Records Live</Typography>

                <Stack
                  marginTop="1rem"
                  alignItems="center"
                  justifyContent="center"
                  flex="1 1 auto"
                >
                  <Skeleton variant="circular" width="12rem" height="12rem" />
                </Stack>
              </GridItem>
            </Grid>
            <Grid item xs={4}>
              <GridItem>
                <Typography variant="h4">Data Packages Live</Typography>

                <Stack
                  marginTop="1rem"
                  alignItems="center"
                  justifyContent="center"
                  flex="1 1 auto"
                >
                  <Skeleton variant="circular" width="12rem" height="12rem" />
                </Stack>
              </GridItem>
            </Grid>
            <Grid item xs={4}>
              <GridItem>
                <Typography variant="h4">Projects Summary</Typography>

                <Stack
                  alignItems="flex-start"
                  justifyContent="center"
                  flex="1 1 auto"
                >
                  <Typography variant="subtitle1" color="GrayText">
                    <b>
                      <Skeleton variant="rounded" width="3rem" height="3rem" />
                    </b>
                    Projects Open
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                    <b>
                      <Skeleton variant="rounded" width="3rem" height="3rem" />
                    </b>
                    Projects closed this week
                  </Typography>
                  <Typography variant="subtitle1" color="GrayText">
                    <b>
                      <Skeleton variant="rounded" width="3rem" height="3rem" />
                    </b>
                    Projects older than 2 weeks
                  </Typography>
                </Stack>
              </GridItem>
            </Grid>

            <Grid item xs={6}>
              <GridItem>
                <Typography variant="h4">Your Projects</Typography>

                <Stack marginTop="1rem">
                  <Skeleton variant="rectangular" width="40%" height={40} />
                  <Skeleton variant="rectangular" width="90%" height={40} />
                  <Skeleton variant="rectangular" width="70%" height={40} />
                  <Skeleton variant="rectangular" width="95%" height={40} />
                </Stack>
              </GridItem>
            </Grid>
            <Grid item xs={6}>
              <GridItem>
                <Typography variant="h4">Oldest Projects</Typography>

                <Stack marginTop="1rem">
                  <Skeleton variant="rectangular" width="40%" height={40} />
                  <Skeleton variant="rectangular" width="90%" height={40} />
                  <Skeleton variant="rectangular" width="70%" height={40} />
                  <Skeleton variant="rectangular" width="95%" height={40} />
                </Stack>
              </GridItem>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Container>
  )
}
