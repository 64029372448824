import { get, Path } from 'react-hook-form'

import { Stack, Typography } from '@mui/material'
import { GridValidRowModel } from '@mui/x-data-grid-premium'

import { GridEnrichedColDef } from '@tk/frontend/primitives/datagrid'

export type ListCellProps = {
  lines: (string | number | boolean)[]
}

export function ListCell({ lines }: ListCellProps) {
  return (
    <Stack
      marginTop="0.5rem"
      marginBottom="0.5rem"
      spacing="0.5rem"
      width="100%"
    >
      {lines.map((line, i) => {
        if (
          typeof line === 'object' ||
          (typeof line === 'function' && line !== null)
        ) {
          console.warn(
            'Line',
            i,
            'was not a valid for use in ListCell. Got',
            line
          )
        }

        return (
          <Typography key={i} variant="body2">
            {String(line)}
          </Typography>
        )
      })}
    </Stack>
  )
}

export function getListCell<T extends GridValidRowModel>(
  field: Path<T>,
  name: string,
  overrides: Partial<GridEnrichedColDef<T>> | undefined = {}
): GridEnrichedColDef<T> {
  return {
    type: 'string',
    field: field,
    headerName: name,
    width: 200,
    filterable: false,
    sortable: false,
    aggregable: false,
    valueGetter(params) {
      return get(params.row, field) as unknown
    },
    renderCell(params) {
      const value = Array.isArray(params.value)
        ? params.value
        : [params.value].filter(Boolean)

      return <ListCell lines={value} />
    },
    ...overrides,
  }
}
