import { useEffect, useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { MarketCategoryScope } from '@lib/pgs-types'
import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/Selector'

export type UpsertFormValues = Inputs['commercialPackages']['upsertCategory']

const Form = getFormComponents<UpsertFormValues>()

export type UpsertFormProps = {
  categoryId?: number
  onFinished?: () => void
}

export function UpsertForm({ categoryId, onFinished }: UpsertFormProps) {
  const categoryQuery = trpc.commercialPackages.getCategory.useQuery(
    {
      id: categoryId!,
    },
    {
      enabled: !!categoryId,
    }
  )

  const form = Form.useForm({
    defaultValues: {
      id: categoryId,
      description: '',
      scope: 'EXTERNAL',
    },
  })
  const { handleSubmit: _handleSubmit, reset } = form

  useEffect(() => {
    if (categoryQuery.data) {
      reset({
        id: categoryId,
        description: categoryQuery.data.description,
        scope: categoryQuery.data.scope,
      })
    }
  }, [categoryId, categoryQuery.data, reset])

  const progress = usePromiseNotification()
  const mutation = trpc.commercialPackages.upsertCategory.useMutation()
  const utils = useUtils()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync(values)
        await progress(promise, {
          progressMessage: 'Saving',
          successMessage: 'Saved',
          failureMessage: 'Error saving',
        })

        utils.invalidate()

        onFinished?.()
      },
      (errs) => {
        console.warn('Validation error', errs)
      }
    )
  }, [_handleSubmit, mutation, onFinished, progress, utils])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        {!!categoryId && (
          <input hidden type="number" {...form.register('id')} />
        )}

        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Scope (Required)"
          name="scope"
          input={
            <Form.SelectField
              options={SCOPE_OPTIONS}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Row>
          <Button type="submit">Save</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

type ScopeName = (typeof MarketCategoryScope)['_output']
export const SCOPE_OPTIONS: Option<ScopeName>[] = [
  {
    label: 'External',
    value: 'EXTERNAL',
  },
  {
    label: 'Internal',
    value: 'INTERNAL',
  },
  {
    label: 'Restricted',
    value: 'RESTRICTED',
  },
]
