import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

export type MarketGroupFormValues = {
  id?: number
  name: string
  description: string
}

const Form = getFormComponents<
  Inputs['multibrand']['marketGroup']['create'] & { id: number }
>()

export type MarketGroupFormProps = {
  initialValues: Partial<MarketGroupFormValues>
  onFinished?: () => void
}

export function MarketGroupForm({
  initialValues = {},
  onFinished,
}: MarketGroupFormProps) {
  const form = Form.useForm({
    defaultValues: initialValues,
  })
  const { handleSubmit: _handleSubmit } = form

  const id = initialValues.id
  const isExisting = typeof id === 'number' && id > 0
  const progress = usePromiseNotification()
  const mutation = MarketGroupForm.useMarketGroupMutation(isExisting)

  const handleSubmit = useMemo(() => {
    return _handleSubmit(async (values) => {
      const promise = mutation.mutateAsync(values)
      await progress(
        promise,
        isExisting
          ? {
              progressMessage: 'Updating ' + values.name,
              successMessage: 'Updated ' + values.name,
              failureMessage: 'Error updating ' + values.name,
            }
          : {
              progressMessage: 'Creating',
              successMessage: 'Created',
              failureMessage: 'Error creating',
            }
      )
      onFinished?.()
    })
  }, [_handleSubmit, isExisting, mutation, onFinished, progress])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        {isExisting && <input hidden type="number" {...form.register('id')} />}

        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />

        <Form.Row>
          <Button type="submit">{isExisting ? 'Update' : 'Create'}</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useMarketGroupMutation(isExisting: boolean) {
  const utils = useUtils()

  const { useMutation } = isExisting
    ? trpc.multibrand.marketGroup.update
    : trpc.multibrand.marketGroup.create

  return useMutation({
    onSuccess() {
      utils.multibrand.instrument.invalidate()
      utils.multibrand.marketGroup.invalidate()
    },
  })
}

MarketGroupForm.useMarketGroupMutation = useMarketGroupMutation
