import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { useModal } from '@parameta/nice-modal-react'

import { Outputs, trpc } from '@tk/frontend/api'
import { When } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  defaultProps,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import {
  NameDateCell,
  WrappedTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'

import { UpsertModal } from './CreateModal'

type RulesetType = Outputs['entitlements']['ruleset']['get']

export function RulesetCrudTable() {
  const [list] = trpc.entitlements.ruleset.list.useSuspenseQuery()
  const upsertModal = useModal(UpsertModal)

  const cols = useMemo<GridEnrichedColDef<RulesetType>[]>(() => {
    const columns: GridEnrichedColDef<RulesetType>[] = [
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        width: 150,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        width: 150,
      },
      {
        type: 'string',
        field: 'actor.name',
        headerName: 'Actor',
        width: 100,
        renderCell(params) {
          const { actor } = params.row
          return <WrappedTextCell text={actor?.name} />
        },
      },
      {
        type: 'string',
        field: 'distribution.name',
        headerName: 'Distribution',
        width: 100,
        renderCell(params) {
          const { distribution } = params.row
          return <WrappedTextCell text={distribution?.name} />
        },
      },
      {
        type: 'string',
        field: 'marketGroup.name',
        headerName: 'Market Group',
        width: 100,
        renderCell(params) {
          const { marketGroup } = params.row
          return <WrappedTextCell text={marketGroup?.description} />
        },
      },
      {
        type: 'string',
        field: 'metadata',
        headerName: 'Metadata',
        width: 150,
        renderCell(params) {
          const { metadata } = params.row
          return <WrappedTextCell text={metadata ? metadata?.toString() : ''} />
        },
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: 'actions',
        width: 100,
        align: 'right',
        flex: 1,
        getActions(params) {
          const row = params.row
          return [
            <When can="entitlements.manage">
              <ActionButtonGroup>
                <Button
                  color="primary"
                  startIcon={<Icons.Edit />}
                  onClick={() => {
                    upsertModal.show({
                      data: row,
                    })
                  }}
                >
                  Edit
                </Button>
              </ActionButtonGroup>
            </When>,
          ]
        },
      },
    ]
    return columns.map((col) => {
      col.filterable ??= true
      col.sortable ??= true
      setColumnDefaults(col)
      return col
    })
  }, [upsertModal])

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <Box flex="1 1 0" minHeight={0} minWidth={0}>
        <DataGridPremium
          {...defaultProps}
          rows={filteredList}
          columns={cols}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        />
      </Box>
    </Box>
  )
}
