import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button, ButtonGroup, Stack } from '@mui/material'
import NiceModal, { useModal } from '@parameta/nice-modal-react'
import { useSnackbar } from 'notistack'

import { useRouteEffect } from '@lib/web'
import { trpc, useUtils } from '@tk/frontend/api'
import {
  RecordEditDto,
  RecordEditFieldsCore,
  RecordEditFieldsLinked,
  RecordMarketDataStateComparisonModal,
} from '@tk/frontend/app/Records/common/crud'
import {
  getFormComponents,
  useEntitlement,
  usePromiseNotification,
  useTitle,
  When,
} from '@tk/frontend/primitives'
import { CustomSnackbar } from '@tk/frontend/primitives/CustomSnackbar'

import { CommercialPackagesSection } from './CommercialPackagesSection'
import { LinkedDataSection } from './LinkedDataSection'
import { useRecordEditTelemetry } from './useRecordEditTelemetry'

const Form = getFormComponents<RecordEditDto>()

export function RecordEditPage() {
  const { id } = useParams<{ id: string }>()
  const snackbar = useSnackbar()
  const promiseNotification = usePromiseNotification()
  const { initialData, record } = useFormInitialData(id!)

  const { trackSaved, trackValidationFailed } = useRecordEditTelemetry(
    'update',
    record
  )
  useTitle('Edit: ' + record.name)

  const form = Form.useForm({
    defaultValues: initialData,
  })
  const { handleSubmit: _handleSubmit } = form
  const utils = useUtils()

  useRouteEffect(() => {
    form.reset(initialData)
  })

  const recordMutation = trpc.records.update.useMutation({
    onSuccess(data) {
      form.reset(transformToFormData(data))
      utils.records.invalidate()
    },
  })

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (data) => {
        const record = await promiseNotification(
          recordMutation.mutateAsync(data),
          {
            progressMessage: 'Saving...',
            successMessage: 'Saved',
            failureMessage: 'Error',
          }
        )

        trackSaved(record, form.formState.dirtyFields)
      },
      (err) => {
        trackValidationFailed(form.formState.dirtyFields, err)

        console.error(err)
        snackbar.enqueueSnackbar(
          <Stack>
            <div>Validation failed. Please see your form for errors</div>
          </Stack>,
          {
            content: (key, message) => (
              <CustomSnackbar key={key} message={message} variant="error" />
            ),
          }
        )
      }
    )
  }, [
    _handleSubmit,
    form.formState.dirtyFields,
    promiseNotification,
    recordMutation,
    snackbar,
    trackSaved,
    trackValidationFailed,
  ])

  const editable = useEntitlement('records.manage')
  const recordMarketDataStateComparisonModal = useModal(
    RecordMarketDataStateComparisonModal
  )

  return (
    <Form.Provider {...form}>
      <Form.FormConfigProvider editable={editable}>
        <NiceModal.Provider>
          <Form.FormStack onSubmit={handleSubmit}>
            <Form.SectionTitleRow title="Record Data">
              <When flag="unimplemented">
                <ButtonGroup color="secondary" variant="contained" disabled>
                  <Button startIcon={<Icons.Keyboard />}>Rename</Button>
                  <Button color="warning" startIcon={<Icons.DeleteForever />}>
                    Delete
                  </Button>
                </ButtonGroup>
              </When>

              <When can="data-engineering-ops.read">
                <Button
                  startIcon={<Icons.Difference />}
                  variant="contained"
                  onClick={() => {
                    recordMarketDataStateComparisonModal.show({
                      recordName: record.name,
                      source: record.source.name,
                      backbone: record.backbone?.name,
                      accountName: record.subscription?.accountName,
                    })
                  }}
                >
                  View Market State
                </Button>
              </When>
              <When can="records.manage">
                <Button
                  startIcon={<Icons.Save />}
                  type="submit"
                  variant="contained"
                  disabled={!form.formState.isDirty || recordMutation.isPending}
                >
                  Save
                </Button>
              </When>
            </Form.SectionTitleRow>

            <Form.SectionBodyGrid>
              <input {...form.register('id')} hidden />

              <RecordEditFieldsCore mode="EDIT" />

              <LinkedDataSection records={record.linkedRecords ?? []} />
            </Form.SectionBodyGrid>

            <Form.SectionBodyGrid>
              <RecordEditFieldsLinked />
            </Form.SectionBodyGrid>

            <When can="commercial-packages.read">
              <Form.SectionTitleRow title="Miscellaneous"></Form.SectionTitleRow>
              <Form.SectionBodyGrid>
                <CommercialPackagesSection
                  marketGroups={record.marketGroups ?? []}
                />
              </Form.SectionBodyGrid>
            </When>
          </Form.FormStack>
        </NiceModal.Provider>
      </Form.FormConfigProvider>
    </Form.Provider>
  )
}

function transformToFormData(dto: RecordEditDto) {
  return dto as Partial<RecordEditDto>
}

function useFormInitialData(id: string) {
  const [record] = trpc.records.get.useSuspenseQuery({ id: parseInt(id) })

  return {
    initialData: transformToFormData(record),
    record: record,
  }
}
