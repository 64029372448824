import { ButtonGroup, ButtonGroupProps } from '@mui/material'
import { GridActionsCellItemProps } from '@mui/x-data-grid-premium'

export function ActionButtonGroup({
  TouchRippleProps: _TouchRippleProps,
  touchRippleRef: _touchRippleRef,
  ...props
}: ButtonGroupProps & Partial<GridActionsCellItemProps>) {
  return (
    <ButtonGroup variant="text" size="small" color="secondary" {...props} />
  )
}
