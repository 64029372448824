import * as Icons from '@mui/icons-material'
import { Divider } from '@mui/material'

import { trpc } from '@tk/frontend/api'
import { When } from '@tk/frontend/primitives'
import { useFilterQueryParams } from '@tk/frontend/primitives/datagrid'
import { MenuButton, MenuItem } from '@tk/frontend/primitives/menu'
import { useSimpleExport } from '@tk/frontend/primitives/simple-export'
import {
  useSimpleImport,
  useTemplateDownload,
} from '@tk/frontend/primitives/simple-import'

export function TickersImportExportMenu() {
  const filter = useFilterQueryParams()
  const simpleExport = useSimpleExport({
    entity: 'bloomberg/tickers',
    filter: filter.deferredValue,
    simpleExportRoute: trpc.bloomberg.ticker.export,
  })

  const template = useTemplateDownload({
    fileName: 'bloomberg-tickers',
    route: trpc.bloomberg.ticker.import,
  })

  const simpleImport = useSimpleImport({
    entity: 'bloomberg/tickers',
    route: trpc.bloomberg.ticker.import,
  })

  return (
    <MenuButton icon={<Icons.ImportExport />} label="Import / Export">
      <MenuItem
        startIcon={<Icons.Preview />}
        onClick={template.downloadTemplate}
        disabled={template.downloadTemplateInProgress}
      >
        Download Template
      </MenuItem>

      <When can="bloomberg-mappings.manage">
        <MenuItem
          startIcon={<Icons.Upload />}
          onClick={simpleImport.startImport}
          disabled={simpleImport.startImportWorking}
        >
          Import
        </MenuItem>
      </When>

      <Divider />

      <When can="exports.read">
        <MenuItem
          startIcon={<Icons.Download />}
          onClick={simpleExport.startExport}
        >
          Export
        </MenuItem>
      </When>
    </MenuButton>
  )
}
