import { useNavigate, useParams } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import * as routes from '@tk/frontend/app/routes'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { AssetTypeFormFields } from './AssetTypeFormFields'

const Form = getFormComponents<Inputs['reference']['assetType']['create']>()

export const AssetTypeCreateFormPage = () => {
  const { id } = useParams<{ id: string }>()
  const [data] = trpc.reference.assetClass.get.useSuspenseQuery({
    id: parseInt(id!),
  })

  const utils = useUtils()
  const navigate = useNavigate()
  const form = Form.useForm({
    defaultValues: {
      assetClass: {
        id: data?.id,
      },
    },
  })

  const notify = usePromiseNotification()

  const createAssetTypeMutation = trpc.reference.assetType.create.useMutation()

  const onSubmit = form.handleSubmit(async (values) => {
    await notify(createAssetTypeMutation.mutateAsync(values), {
      progressMessage: `Creating Asset Type`,
      successMessage: `Created Asset Type Successfully`,
      failureMessage: `Failed to create Asset Type`,
    })

    navigate(routes.app.referenceData.assetTypes.list)
    utils.reference.assetType.get.invalidate()
  })

  return (
    <Form.Provider {...form}>
      <Form.FormStack onSubmit={onSubmit} submitOnCtrlEnter>
        <Form.SectionTitleRow title="Create Asset Type">
          <Button
            startIcon={<Icons.Save />}
            type="submit"
            variant="contained"
            disabled={false}
          >
            Save
          </Button>
        </Form.SectionTitleRow>
        <AssetTypeFormFields />
      </Form.FormStack>
    </Form.Provider>
  )
}
