import { useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import * as Icons from '@mui/icons-material'

import * as routes from '@tk/frontend/app/routes'
import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  getWildcardOperator,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import {
  NameDateCell,
  SecondaryHeader,
  WrappedTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'

import { AssetClassActions } from './AssetClassActions'
import { ASSET_TYPE_TAG_OPTIONS } from './common'
import { TypedRowUnion } from './types'

export function useAssetColumns() {
  const canEdit = useEntitlement('reference-data.manage')

  return useMemo<GridEnrichedColDef<TypedRowUnion>[]>(() => {
    const cols: GridEnrichedColDef<TypedRowUnion>[] = [
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 100,
        filterable: true,
        filterOperators: [
          getWildcardOperator((row) => {
            if (row._type === 'assetClass') {
              return [row.name, row.description]
            }
            if (row._type === 'assetType') {
              return [
                row.name,
                row.description,
                row.assetClass?.name,
                row.assetClass?.description,
              ]
            }
            if (row._type === 'assetTag') {
              return [
                row.name,
                row.description,
                row.parent.name,
                row.parent.description,
                row.parent.assetClass?.name,
                row.parent.assetClass?.description,
              ]
            }
            return []
          }),
        ],
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
        width: 100,
      },
      {
        type: 'string',
        field: 'assetTagType',
        headerName: 'Asset Tag Type',
        renderHeader: (params) => (
          <SecondaryHeader text={params.colDef.headerName!} />
        ),
        editable: false,
        width: 150,
        renderCell(params) {
          const assetTagLabel = ASSET_TYPE_TAG_OPTIONS.find(
            (option) => params.value === option.value
          )?.label
          return <WrappedTextCell text={assetTagLabel} />
        },
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          if (params.row._type === 'assetClass') {
            const { createdBy, createdDate } = params.row
            return <NameDateCell name={createdBy} date={createdDate} />
          }
          return null
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          if (params.row._type === 'assetClass') {
            const { modifiedBy, modifiedDate } = params.row
            return <NameDateCell name={modifiedBy} date={modifiedDate} />
          }
          return null
        },
      },
      {
        type: 'actions',
        field: 'actions',
        width: 200,
        align: 'right',
        getActions(params) {
          if (!canEdit) {
            return []
          }

          if (params.row._type === 'assetClass') {
            return [<AssetClassActions data={params.row} />]
          }
          if (params.row._type === 'assetType') {
            return [
              <ActionButtonGroup>
                <ButtonLink
                  color="primary"
                  variant="text"
                  startIcon={<Icons.Edit />}
                  to={generatePath(routes.app.referenceData.assetTypes.edit, {
                    id: String(params.row.id),
                  })}
                  data-testid="asset-type-edit-button"
                >
                  Edit
                </ButtonLink>
              </ActionButtonGroup>,
            ]
          }
          return []
        },
      },
    ]

    const packageActions: GridEnrichedColDef<TypedRowUnion> = {
      type: 'actions',
      field: 'nav-actions',
      flex: 1,
      align: 'left',
      getActions(params) {
        if (params.row._type === 'assetType') {
          return [
            <ActionButtonGroup>
              <ButtonLink
                color="primary"
                startIcon={<Icons.TableRowsRounded />}
                variant="text"
                to={routes.app.recordManagement.defaultRecords({
                  initialAssetTypeId: params.row.id,
                })}
              >
                Records
              </ButtonLink>
            </ActionButtonGroup>,
          ]
        }

        return []
      },
    }
    return cols.map(setColumnDefaults).concat([packageActions])
  }, [canEdit])
}
