import { Typography } from '@mui/material'

export const TREE_CLASSES = {
  CHILD: 'tree-child',
  PARENT: 'tree-parent',
}

export const groupingColDef = {
  headerName: 'Record Name',
  width: 500,
  valueGetter: ({ row }: any) => {
    const nameError = row.fieldErrors?.find(
      (fieldError: any) => fieldError.field === 'name'
    )

    if (nameError) {
      return (
        <Typography
          fontFamily={'"Roboto Mono", monospace'}
          fontSize="inherit"
          color={(theme) => theme.palette.error.main}
          fontWeight="bold"
        >
          {nameError.message} {nameError.value}
        </Typography>
      )
    }

    return row.name ?? 'Unnamed'
  },
  hideDescendantCount: true,
}
