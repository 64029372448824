import { Button } from '@mui/material'

import { ConfirmPopover, usePromiseNotification } from '@tk/frontend/primitives'

import { Entities, useSimpleImportTelemetry } from './simple-import-telemetry'
import { useSimpleImportContext } from './SimpleImportContext'

export type ApplyImportButtonProps = {
  taskId: number
  disabled?: boolean
  entity: Entities
}

export function ApplyImportButton({
  taskId,
  disabled,
  entity,
}: ApplyImportButtonProps) {
  const { trackApply, trackApplyError } = useSimpleImportTelemetry(entity)

  const notify = usePromiseNotification()

  const { route, utils } = useSimpleImportContext()

  const [state] = route.status.useSuspenseQuery({ taskId: taskId })
  const persist = route.commit.useMutation({
    onSuccess() {
      trackApply(taskId)

      utils.invalidate()
    },
    onError(error) {
      trackApplyError(taskId, error.message)
    },
  })

  return (
    <ConfirmPopover
      prompt="This will apply all changes to PGS and may take some time, do you want to continue?"
      onOk={async () => {
        await notify(persist.mutateAsync({ taskId }), {
          progressMessage: 'Import starting',
          failureMessage: 'Import failed',
          successMessage: 'Import started, please refresh the page for updates',
        })
      }}
    >
      <Button
        variant="contained"
        disabled={
          persist.isPending || disabled || state.state !== 'READY_TO_PERSIST'
        }
      >
        Apply All Changes
      </Button>
    </ConfirmPopover>
  )
}
