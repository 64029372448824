import { FC } from 'react'

import { Skeleton } from '@mui/material'

import { trpc } from '@tk/frontend/api'
import { FilterTag, labelify } from '@tk/frontend/primitives'

import { FilterTagProps } from './types'

export const ProcessFilterTag: FC<FilterTagProps> = ({ value, onDelete }) => {
  const labelQuery = trpc.bloomberg.process.get.useQuery(
    {
      id: parseInt(value),
    },
    {
      select(data) {
        return labelify(data.name, data.description)
      },
    }
  )
  const valueLabel = labelQuery.data

  const ready = !!valueLabel

  return (
    <FilterTag label="Process" onDelete={onDelete}>
      {ready ? (
        valueLabel
      ) : (
        <Skeleton width="5rem" height="2rem" animation="wave" />
      )}
    </FilterTag>
  )
}
