import React from 'react'

import * as Icons from '@mui/icons-material'
import { Divider } from '@mui/material'

import { When } from '@tk/frontend/primitives'
import { MenuButton } from '@tk/frontend/primitives/menu'

import { ExportButton, ExportListButton } from './Export'
import {
  DownloadTemplateButton,
  ImportButton,
  ImportListButton,
} from './Import'
import { useRecordsList } from './useRecordsList'
import { useRecordsQueryParams } from './useRecordsQueryParams'

export function ImportExportMenu() {
  const params = useRecordsQueryParams()
  const [records] = useRecordsList()

  return (
    <MenuButton label="Import / Export" icon={<Icons.ImportExport />}>
      <DownloadTemplateButton />
      <When can="records.manage">
        <ImportListButton />
        <ImportButton />
      </When>

      <Divider />

      <When can="exports.read">
        <ExportListButton />
        <ExportButton
          count={records.totalElements ?? 0}
          params={params.query}
        />
      </When>
    </MenuButton>
  )
}
