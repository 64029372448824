import { trpc } from '@tk/frontend/api'

/**
 * @deprecated A bit of a hack until the API can do this for us, and we've considered whether we want to block applying updates or not when just 1 row has an issue to solve
 */
export function useImportHasAnyError(id: number) {
  const [previewErrors] = trpc.records.import.preview.useSuspenseQuery({
    taskId: id,
    page: 0,
    size: 1,
    invalidOnly: true,
  })

  const errors =
    previewErrors.status === 'success'
      ? previewErrors.response?.totalElements
      : 0

  return errors
}
