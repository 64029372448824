import { trpc } from '@tk/frontend/api'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { FidsTable } from './FidsTable'

export function FidsCrud() {
  return (
    <PageContentLayout title="Fids">
      <FidsCrudTable />
    </PageContentLayout>
  )
}

function FidsCrudTable() {
  const [list] = trpc.fids.list.list.useSuspenseQuery({})

  return <FidsTable list={list} />
}
