import { useFormContext } from 'react-hook-form'

import Stack from '@mui/material/Stack'
import { DateTime } from 'luxon'

import { REPORT_TYPE_OPTIONS, TIMEZONE_OPTIONS } from './options'
import { Form } from './types'

type Props = {
  enableRightAndWrongTicksControl: boolean
}

export function CreateFormFields({
  enableRightAndWrongTicksControl,
}: Readonly<Props>) {
  const { watch } = useFormContext()

  const startTime = watch('startTime')
  const endTime = watch('endTime')

  return (
    <Stack alignItems="center">
      <Stack width="25rem">
        <Form.Field
          label="Name"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description"
          name="description"
          input={<Form.TextField multiline rules={Form.rules.required} />}
        />
        <br />
        <Form.SectionTitle>Report Configuration</Form.SectionTitle>
        <Form.Field
          name="type"
          label="Extract Type"
          input={
            <Form.SelectField
              options={REPORT_TYPE_OPTIONS}
              rules={Form.rules.required}
            />
          }
        />
        {enableRightAndWrongTicksControl && (
          <Form.Field
            name="ticksType"
            label="Tick Correctness"
            input={
              <Form.SelectField
                options={[
                  { value: 'CORRECT', label: 'Correct ticks' },
                  { value: 'WRONG', label: 'Wrong ticks' },
                  { value: 'BOTH', label: 'All ticks' },
                ]}
                rules={Form.rules.required}
              />
            }
          />
        )}
        <Form.Field
          name="timezone"
          label="Timezone"
          input={
            <Form.SelectField
              options={TIMEZONE_OPTIONS}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Field
          name="dateRange"
          label="Date range"
          input={<Form.DateRange rules={Form.rules.required} />}
        />

        {/* TODO: To be implemented when specification is fully fleshed out */}
        {/* https://scm.tpicapcloud.com/p-de/apps/application-monorepo/-/issues/533 */}
        {/* <Form.Field
          name="excludedDays"
          label="Excluded Days"
          input={<DayPicker name="excludedDays" />}
        /> */}

        {(watch('type') === 'ALL_TICKS' ||
          watch('type') === 'HOURLY_SNAPS') && (
          <Form.Row>
            <Form.Field
              name="startTime"
              label="Start Time"
              input={
                <Form.TimePicker
                  name="startTime"
                  maxTime={endTime && DateTime.fromISO(endTime)}
                  rules={{
                    validate: (value) => {
                      if (!value && endTime) {
                        return 'Start Time required'
                      }
                      return true
                    },
                  }}
                />
              }
            />
            <Form.Field
              name="endTime"
              label="End Time"
              input={
                <Form.TimePicker
                  name="endTime"
                  minTime={startTime && DateTime.fromISO(startTime)}
                  rules={{
                    validate: (value) => {
                      if (!value && startTime) {
                        return 'End Time required'
                      }
                      return true
                    },
                  }}
                />
              }
            />
          </Form.Row>
        )}

        {watch('type') === 'DAILY_SNAP' && (
          <Form.Field
            name="snapTime"
            label="Snap Time"
            input={<Form.TimePicker rules={Form.rules.required} />}
          />
        )}
      </Stack>
    </Stack>
  )
}
