import { trpc, useUtils } from '@tk/frontend/api'

export function useRecordMappingUpsert() {
  const utils = useUtils()

  const recordMappingMutation = trpc.bloomberg.mapping.upsert.useMutation({
    onSuccess() {
      utils.bloomberg.mapping.invalidate()
    },
  })

  return recordMappingMutation
}
