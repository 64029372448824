import { GridRenderEditCellParams } from '@mui/x-data-grid-premium'

import { Outputs, trpc } from '@tk/frontend/api'
import { SelectorCell } from '@tk/frontend/primitives/datagrid/edit-cells'

import { transformUiListToSelectorCellOptions } from './types'

type PricingSource = Outputs['bloomberg']['pricingSource']['get']

export type PricingSourceEditCellProps = {
  params: GridRenderEditCellParams<PricingSource>
}

export function PricingSourceEditCell(props: PricingSourceEditCellProps) {
  const pricingSourcesQuery = trpc.bloomberg.pricingSource.list.useQuery(
    undefined,
    {
      select: (data) => transformUiListToSelectorCellOptions(data),
    }
  )

  return (
    <SelectorCell<PricingSource>
      params={props.params}
      value={props.params.value}
      loading={pricingSourcesQuery.isPending}
      options={pricingSourcesQuery.data ?? []}
    />
  )
}
