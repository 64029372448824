import React from 'react'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { CreateModal } from './CreateForm'

export function ApiUserCreateButton() {
  const modal = useModal(CreateModal)

  return (
    <Button
      variant="contained"
      startIcon={<Icons.Add />}
      color="primary"
      onClick={() => modal.show()}
    >
      Create
    </Button>
  )
}
