import { generatePath, To } from 'react-router-dom'

import * as routes from '@tk/frontend/app/routes'

export const basePath = routes.app.recordManagement.import

export const relative = {
  list: '',
  preview: '/:id',
} as const

export const generateImportListUri = (
  focusTaskId: number | undefined = undefined
): To => {
  return {
    pathname: generatePath(basePath + relative.list),
    search: focusTaskId
      ? `?focus=${encodeURIComponent(focusTaskId)}`
      : undefined,
  }
}

export const generateImportPreviewUri = (id: number) => {
  return generatePath(basePath + relative.preview, {
    id,
  })
}
