import { generatePath, To } from 'react-router-dom'

import * as routes from '@tk/frontend/app/routes'

export const basePath = routes.app.recordManagement.export

export const relative = {
  list: '',
} as const

export const generateExportListUri = (
  focusTaskId: string | undefined = undefined
): To => {
  return {
    pathname: generatePath(basePath + relative.list),
    search: focusTaskId
      ? `?focus=${encodeURIComponent(focusTaskId)}`
      : undefined,
  }
}
