import { Backdrop, Paper, Stack, Typography } from '@mui/material'

import { Colours } from '@lib/theme'

export function UnknownPage() {
  return (
    <Backdrop open sx={{ backgroundColor: Colours.BgShade.Blue }}>
      <Paper>
        <Stack alignItems="center" margin="10rem">
          <Typography variant="h1" color={Colours.Brand.RedMid}>
            404
          </Typography>
          <Typography variant="h6" color="primary">
            Page Not Found
          </Typography>
        </Stack>
      </Paper>
    </Backdrop>
  )
}
