import _ from 'lodash'

import { environment } from '@tk/frontend/environment'

export const debug = environment.production
  ? () => {
      //
    }
  : console.debug

export const debugEvent = environment.production
  ? () => {
      //
    }
  : (scope: string, eventName: string, properties: Record<string, any>) => {
      const filteredProps = _.pickBy(properties, (value, key) => {
        const banned = ['token', 'distinct_id', 'app']
        return !key.startsWith('$') && !banned.includes(key)
      })

      console.debug(scope, eventName, filteredProps)
    }
