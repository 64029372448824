import { GridRenderEditCellParams } from '@mui/x-data-grid-premium'

import { Outputs, trpc } from '@tk/frontend/api'
import { SelectorCell } from '@tk/frontend/primitives/datagrid/edit-cells'

import { transformUiListToSelectorCellOptions } from './types'

type Entitlement = Outputs['bloomberg']['entitlements']['get']

export type EntitlementsEditCellProps = {
  params: GridRenderEditCellParams<Entitlement>
}

export function EntitlementsEditCell(props: EntitlementsEditCellProps) {
  const entitlementsQuery = trpc.bloomberg.entitlements.list.useQuery(
    undefined,
    {
      select: (data) => transformUiListToSelectorCellOptions(data),
    }
  )

  return (
    <SelectorCell<Entitlement>
      params={props.params}
      value={props.params.value}
      loading={entitlementsQuery.isPending}
      options={entitlementsQuery.data ?? []}
    />
  )
}
