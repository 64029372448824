import { useMemo } from 'react'

import {
  ErrorOutline,
  HourglassBottomOutlined,
  InfoOutlined,
} from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Tooltip, Typography } from '@mui/material'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { keepPreviousData } from '@tanstack/react-query'
import { DateTime } from 'luxon'

import { Inputs, trpc } from '@tk/frontend/api'
import { usePaginationQueryParams } from '@tk/frontend/primitives/datagrid'
import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'

import { DownloadButton } from './DownloadButton'

export const ExtractaReportsTable = (props: Inputs['extracta']['list']) => {
  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [
      props?.startDate?.valueOf(),
      props?.endDate?.valueOf(),
      props?.name,
      props?.createdBy,
      props?.types && props.types[0],
    ],
    initialPageSize: 100,
  })

  const reports = trpc.extracta.list.useQuery(
    {
      ...props,
      sort: 'createdDate',
      direction: 'desc',
      page: pagination.page,
      size: pagination.pageSize,
    },
    {
      refetchInterval: 5000,
      placeholderData: keepPreviousData,
    }
  )

  const columns: GridColDef[] = useMemo(
    () =>
      createColumns([
        {
          field: 'id',
          headerName: 'ID',
          type: 'number',
        },
        {
          field: 'statusMessage',
          headerName: 'Status Message',
          type: 'string',
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
          type: 'string',
        },
        {
          field: 'description',
          headerName: 'Description',
          width: 225,
          type: 'string',
        },
        {
          field: 'createdBy',
          headerName: 'Created By',
          width: 200,
          type: 'string',
        },
        {
          field: 'createdDate',
          headerName: 'Created Date',
          width: 150,
          type: 'string',
          renderCell(params) {
            return <Box>{DateTime.fromJSDate(params.value).toRelative()}</Box>
          },
        },
        {
          field: 'type',
          headerName: 'Type',
          width: 150,
          type: 'string',
        },
        {
          field: 'timezone',
          headerName: 'Timezone',
          width: 150,
          type: 'string',
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 250,
          type: 'string',
          renderCell(params) {
            return (
              <Box data-testid={`status-for-${params.row.name}`}>
                {params.value === 'FINISHED' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      color: 'green',
                    }}
                  >
                    <CheckCircleOutlineIcon />
                    Success
                    <DownloadButton id={params.row.id} />
                  </Box>
                )}

                {params.value === 'SUBMITTED' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      color: 'InfoText',
                    }}
                  >
                    <InfoOutlined />
                    Waiting
                  </Box>
                )}

                {params.value === 'ERROR' && (
                  <Tooltip title={params.row.statusMessage}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3,
                        color: 'red',
                      }}
                    >
                      <ErrorOutline />
                      <Typography>Failed</Typography>
                    </Box>
                  </Tooltip>
                )}

                {params.value === 'IN_PROGRESS' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      color: 'InfoText',
                    }}
                  >
                    <HourglassBottomOutlined />
                    In progress
                  </Box>
                )}

                {!['IN_PROGRESS', 'ERROR', 'SUBMITTED', 'FINISHED'].includes(
                  params.value ?? ''
                ) && params.value}
              </Box>
            )
          },
        },
      ]).map(setColumnDefaults),
    []
  )

  return (
    <DataGridPremium
      initialState={{
        columns: {
          columnVisibilityModel: {
            statusMessage: false,
            id: false,
          },
        },
      }}
      rows={reports.data?.content ?? []}
      loading={reports.isPending}
      columns={columns}
      pageSizeOptions={[100]}
      pagination
      paginationMode="server"
      paginationModel={pagination}
      onPaginationModelChange={onPaginationChange}
      rowCount={reports.data?.totalElements ?? 0}
    />
  )
}
