import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import { ConfirmPopover, usePromiseNotification } from '@tk/frontend/primitives'

export type DeleteMappingButtonProps = {
  recordId: number
  mappingId: number
}

export function DeleteMappingButton({
  recordId,
  mappingId,
  ...props
}: DeleteMappingButtonProps) {
  const notify = usePromiseNotification()

  const utils = useUtils()

  const mappingDeleter = trpc.bloomberg.mapping.deleteOne.useMutation({
    onSuccess() {
      utils.bloomberg.mapping.invalidate()
    },
  })

  return (
    <ConfirmPopover
      prompt="This mapping will be retired, are you sure you would like to continue?"
      onOk={async () => {
        await notify(mappingDeleter.mutateAsync({ recordId, mappingId }), {
          progressMessage: 'Retiring Mapping',
          failureMessage: 'Error retiring mapping',
          successMessage: 'Mapping retired',
        })
      }}
    >
      <Button color="warning" startIcon={<Icons.Delete />} {...props}>
        Retire
      </Button>
    </ConfirmPopover>
  )
}
