const BLOCKLISTED_ACRONYMS = [
  'DW_TIME_SEQUENCE',
  'TREP_SRC',
  'TREP_REC',
  'UK_SOURCE',
  'USA_SOURCE',
  'TREPEX',
]
const BLOCKLISTED_ACRONYMS_PREFIXES = ['EDF_', 'LGV_', 'RWF_']

// All valid FID acronyms are uppercase, so if the first char is in the lowercase range then we block it
const lowercaseCharCodeStart = 97

/**
 * Some FIDs are purely for internal systems use, such as EDF values
 *
 * We often want to omit these entirely when publishing or presenting data
 */
export function isInternalFidAcronym(acronym: string) {
  if (acronym.charCodeAt(0) >= lowercaseCharCodeStart) {
    return true
  }

  if (BLOCKLISTED_ACRONYMS.includes(acronym)) {
    return true
  }

  if (
    BLOCKLISTED_ACRONYMS_PREFIXES.some((prefix) => acronym.startsWith(prefix))
  ) {
    return true
  }

  return false
}

export function isPublishedFidAcronym(acronym: string) {
  return !isInternalFidAcronym(acronym)
}

/**
 * This field indicates that EDF is functioning on the record
 */
export function isEdfMarkerFid(fid: number) {
  return [99, -9099].includes(fid)
}

export function replaceKeys(
  object: { [key: string]: string | number },
  mapping: Record<string, string>
): { [key: string]: string | number } {
  if (!mapping) {
    return object
  }

  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      const mappedName = mapping[key] ?? key
      return [mappedName, value]
    })
  )
}
