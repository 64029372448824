import { z } from 'zod'

import * as PGS from '@lib/pgs'

import { IdItem } from './common'
import { BaseNamedBusinessObject } from './named-business-object'
import { testAssignable } from './util'

//

const BloombergEntitlementType = z.union([
  z.literal('TERMINAL'),
  z.literal('B_PIPE'),
  z.literal('TERMINAL_AND_B_PIPE'),
])

export const BloombergEntitlementDto = z.object({
  ...BaseNamedBusinessObject,
  allToSee: z.boolean().optional().default(false),
  type: BloombergEntitlementType.default('TERMINAL'),
})
testAssignable<(typeof BloombergEntitlementDto)['_input'], PGS.EntitlementDto>()

//

export const BloombergPricingSourceDto = z.object({
  ...BaseNamedBusinessObject,
})
testAssignable<
  (typeof BloombergPricingSourceDto)['_input'],
  PGS.NamedBusinessObjectDto
>()

//

export const BloombergTickerDto = z.object({
  ...BaseNamedBusinessObject,
  objectId: z.string().optional(),
})
testAssignable<(typeof BloombergTickerDto)['_input'], PGS.TickerDto>()

//

export const BloombergMappingUpdateDto = z.object({
  id: z.number().optional(),
  ticker: IdItem.optional(),
  entitlement: IdItem.optional(),
  pricingSource: IdItem.optional(),
  process: IdItem.optional(),
  ruleset: IdItem.optional(),
})
testAssignable<
  PGS.BloombergMappingUpdateDto,
  (typeof BloombergMappingUpdateDto)['_output']
>()

export const BloombergRecordMappingsUpdateDto = z.object({
  id: z.number(),
  bloombergMappings: z.array(BloombergMappingUpdateDto).default([]),
})
testAssignable<
  PGS.RecordMappingsUpdateDto,
  (typeof BloombergRecordMappingsUpdateDto)['_output']
>()

export const BloombergRetiredStatus = z.union([
  z.literal('ALL'),
  z.literal('NOT_RETIRED'),
  z.literal('RETIRED'),
])
