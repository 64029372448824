import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { trpc } from '@tk/frontend/api'
import {
  ImportStateColour,
  ImportStateLabel,
} from '@tk/frontend/primitives/simple-import'

export type RelinkTaskSummaryProps = {
  taskId: number
  numErrors: number // Accept numErrors as a prop
}

export function RelinkTaskSummary({
  taskId,
  numErrors,
}: RelinkTaskSummaryProps) {
  const [task] = trpc.records.relink.status.useSuspenseQuery({ taskId })

  let state = task.state
  if (numErrors > 0) {
    state = 'FAILED'
  }

  return (
    <TableContainer component={Paper} sx={{ padding: '0.5rem', paddingTop: 0 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Task ID</TableCell>
            <TableCell>Task Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Links</TableCell>
            <TableCell>Error</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell width={150}>{task.taskId}</TableCell>

            <TableCell width={300}>{task.name}</TableCell>

            <TableCell width={150}>
              <Typography
                fontSize="inherit"
                fontWeight="bold"
                color={ImportStateColour[state]}
              >
                {ImportStateLabel[state]}
              </Typography>
            </TableCell>

            <TableCell width={100}>{task.processedCount}</TableCell>

            <TableCell>
              <Typography
                fontSize="inherit"
                fontWeight="bold"
                color={ImportStateColour['FAILED']}
              >
                {numErrors > 0 ? `${numErrors} rows have problems ` : ''}
                {task.errorMessage}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
