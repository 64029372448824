import { useCallback, useEffect } from 'react'

import {
  GridCallbackDetails,
  GridPaginationModel,
} from '@mui/x-data-grid-premium'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'

export type UsePaginationQueryParamsOpts = {
  resetPageFor: (string | number | null | undefined)[]
  initialPageSize?: number
}

type OnGridPaginationModelChange = (
  model: GridPaginationModel,
  details?: GridCallbackDetails
) => void

export function usePaginationQueryParams({
  resetPageFor,
  initialPageSize,
}: UsePaginationQueryParamsOpts): [
  GridPaginationModel,
  OnGridPaginationModelChange
] {
  const [page, _setPage] = useQueryParam('page', withDefault(NumberParam, 0))
  const [size, _setSize] = useQueryParam(
    'size',
    withDefault(NumberParam, initialPageSize ?? 50)
  )

  // When parameters change which change the content of the pages, we also invalidate the current page
  useEffect(() => {
    _setPage(0, 'replaceIn')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...resetPageFor, _setPage])

  return [
    {
      page,
      pageSize: Math.max(1, size),
    },
    useCallback(
      (model, details) => {
        _setPage(model.page, 'replaceIn')
        _setSize(model.pageSize, 'replaceIn')
      },
      [_setPage, _setSize]
    ),
  ]
}
