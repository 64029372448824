import { useMemo } from 'react'

import { useEntitlement } from '@tk/frontend/primitives'
import { getWildcardOperator } from '@tk/frontend/primitives/datagrid'
import { NameDateCell } from '@tk/frontend/primitives/datagrid/cells'

import { LocationActions } from './LocationActions'
import { RegionActions } from './RegionActions'
import { GridEnrichedColDef, TypedRowUnion } from './types'

export function useColumns() {
  const canEdit = useEntitlement('reference-data.manage')

  return useMemo<GridEnrichedColDef<TypedRowUnion>[]>(() => {
    return [
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 100,
        filterable: true,
        filterOperators: [
          getWildcardOperator((row) => {
            if (row._type === 'region') {
              return [row.name, row.description]
            } else {
              return [
                row.name,
                row.description,
                row.region?.name,
                row.region?.description,
              ]
            }
          }),
        ],
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
        minWidth: 150,
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: '',
        width: 200,
        align: 'right',
        getActions(params) {
          if (!canEdit) {
            return []
          }

          if (params.row._type === 'region') {
            return [<RegionActions data={params.row} />]
          } else {
            return [<LocationActions data={params.row} />]
          }
        },
      },
    ]
  }, [canEdit])
}
