import {
  GridFilterInputMultipleSingleSelect,
  GridFilterOperator,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'

import { GridEnrichedSingleSelectColDef } from '@tk/frontend/primitives/datagrid/enhanced-types'

type PartialColDef = Omit<
  GridEnrichedSingleSelectColDef<GridValidRowModel>,
  'field'
>

type Option = { label: string; value: string | number | boolean }
interface MultiSelectFiltersOptions {
  options: Option[]
}

interface MultiServersideSelectFiltersOptions {
  InputComponent: GridFilterOperator['InputComponent']
}

export const MultiSelectFilterId = 'api'
export const MultiSelectFilterLabel = 'Any Of'

/**
 * Adds a multiselect filter with the given options
 */
export function getMultiSelectFilters({ options }: MultiSelectFiltersOptions) {
  return {
    type: 'singleSelect',
    valueOptions: options,
    getOptionValue: (v) => (v as Option).value,
    getOptionLabel: (v) => (v as Option).label,
    filterable: true,
    filterOperators: [
      {
        value: MultiSelectFilterId,
        label: MultiSelectFilterLabel,
        getApplyFilterFn() {
          // Disable this because the server will do it
          return () => true
        },
        InputComponent: GridFilterInputMultipleSingleSelect,
      },
    ],
  } satisfies PartialColDef
}

/**
 * Adds a multiselect filter driven by a given component, which handles server-side paging and filtering against its options
 */
export function getServersideMultiSelectFilters({
  InputComponent,
}: MultiServersideSelectFiltersOptions) {
  return {
    type: 'singleSelect',
    filterable: true,
    filterOperators: [
      {
        value: MultiSelectFilterId,
        label: MultiSelectFilterLabel,
        getApplyFilterFn() {
          // Disable this because the server will do it
          return () => true
        },
        InputComponent: InputComponent,
      },
    ],
  } satisfies PartialColDef
}
