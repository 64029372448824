import { useParams } from 'react-router-dom'

import { trpc } from '@tk/frontend/api'
import { useProjectCrud } from '@tk/frontend/app/RecordDesigner/useProjectCrud'
import { TabValue } from '@tk/frontend/app/RecordDesigner/useProjectTab'

export function useWarningTabs() {
  const { projectName } = useParams() as { projectName: string }

  const testResult = trpc.recordProjects.testProject.useQuery(
    {
      name: projectName,
    },
    { throwOnError: false }
  )

  const { project } = useProjectCrud()

  const invalidRecords = testResult.data?.filter((row) => !row.isValid) ?? []

  const invalidTestTab = invalidRecords.length > 0 ? 'test' : undefined

  const invalidEditorTab = !project.document.isValid ? 'editor' : undefined

  const invalidTabs = [invalidEditorTab, invalidTestTab].filter(
    Boolean
  ) as TabValue[]

  return invalidTabs
}
