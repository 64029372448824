import { Box, TextField } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  NamedBusinessObjectTable,
  useEditReferenceData,
} from '@tk/frontend/primitives/datagrid/table'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import { ExportButton } from '@tk/frontend/primitives/reference-export'
import { useReferenceDataEditableTable } from '@tk/frontend/telemetry/reference-data-crud'

import { FrequencyCreateButton } from './FrequencyCreateButton'

export function FrequencyCrud() {
  const exportMutation = trpc.reference.frequency.export.useMutation()

  return (
    <>
      <PageContentLayout
        title="Frequencies"
        controls={
          <>
            <ExportButton handleExport={exportMutation.mutateAsync} />
            <FrequencyCreateButton />
          </>
        }
      >
        <FrequencyCrudTable />
      </PageContentLayout>
    </>
  )
}

function FrequencyCrudTable() {
  const { trackEditSave, trackEditStart } =
    useReferenceDataEditableTable('frequency')

  const utils = useUtils()

  const frequencyUpdater = trpc.reference.frequency.update.useMutation({
    onMutate(variables) {
      trackEditSave('not-tracked', variables)
    },
    onSuccess() {
      utils.reference.frequency.invalidate()
    },
  })
  const [list] = trpc.reference.frequency.list.useSuspenseQuery()

  const editableFieldOverride = useEditReferenceData()

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%DAYS%"'
        fullWidth
      />

      <NamedBusinessObjectTable
        list={filteredList}
        onEditStart={trackEditStart}
        onEditSave={frequencyUpdater.mutateAsync}
        editable={editableFieldOverride}
      />
    </Box>
  )
}
