import * as Icons from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { SuspenseBoundaryLocal } from '@tk/frontend/primitives'

import { AssignmentForm } from './AssignmentForm'

type Model =
  | {
      type: 'add'
    }
  | { type: 'remove' }

export const AssignmentModel = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={modal.visible}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return
        }

        modal.hide()
      }}
    >
      <DialogTitle>
        {model.type === 'add' ? 'Include in package' : 'Exclude from package'}

        <IconButton
          onClick={modal.hide}
          sx={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
          }}
        >
          <Icons.Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <SuspenseBoundaryLocal>
          <AssignmentForm mode={model.type} onFinished={modal.hide} />
        </SuspenseBoundaryLocal>
      </DialogContent>
    </Dialog>
  )
})
