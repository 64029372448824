import { useCallback } from 'react'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { StringParam, useQueryParam } from 'use-query-params'

export type ImportPreviewRowFilterState = 'all' | 'errors'

export function usePreviewRowFilterParam() {
  const [value, setValue] = useQueryParam('row-filter', StringParam)

  const rowFilter = (value as ImportPreviewRowFilterState) ?? 'all'

  const setRowFilter = useCallback(
    (value: ImportPreviewRowFilterState) => setValue(value, 'replaceIn'),
    [setValue]
  )

  return [rowFilter, setRowFilter] as const
}

export function ImportPreviewRowFilter() {
  const [value, onChange] = usePreviewRowFilterParam()

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value: ImportPreviewRowFilterState) => onChange(value)}
    >
      <ToggleButton value="all" color="info">
        All
      </ToggleButton>
      <ToggleButton value="errors" color="warning">
        Errors
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
