import { useEffect, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import * as Icons from '@mui/icons-material'
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  FilterKey,
  FILTERS_CONFIG,
} from '@tk/frontend/app/Records/filters-config'
import { BasicFilterTag } from '@tk/frontend/app/Records/FilterTags'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'
import { theme } from '@tk/frontend/theme'

import { transformFiltersToTags } from './dataTransforms'

export type UpsertFormValues = Inputs['commercialPackages']['upsertPackage']

const Form = getFormComponents<UpsertFormValues>()

export type UpsertFormProps = {
  groupId?: number
  categoryId?: number
  onFinished?: () => void
  showFilterSections: boolean
}

export function UpsertForm({
  groupId,
  categoryId,
  onFinished,
  showFilterSections,
}: UpsertFormProps) {
  const packageQuery = trpc.commercialPackages.getPackage.useQuery(
    { id: groupId! },
    { enabled: !!groupId }
  )

  const categoriesQuery = trpc.commercialPackages.ui.listCategories.useQuery()

  const form = Form.useForm({
    defaultValues: {
      id: groupId,
      description: '',
      mode: 'STATIC',
      marketCategoryId: categoryId,
      filters: [],
    },
  })
  const { handleSubmit: _handleSubmit, reset } = form

  useEffect(() => {
    if (packageQuery.data) {
      reset({
        id: groupId,
        description: packageQuery.data.description,
        mode: packageQuery.data.mode,
        marketCategoryId: categoryId ?? packageQuery.data.marketCategory?.id,
        filters: packageQuery.data.filters,
      })
    }
  }, [categoryId, groupId, packageQuery.data, reset])

  const progress = usePromiseNotification()
  const mutation = trpc.commercialPackages.upsertPackage.useMutation()
  const utils = useUtils()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync(values)
        await progress(promise, {
          progressMessage: 'Saving',
          successMessage: 'Saved',
          failureMessage: 'Error saving',
        })

        utils.invalidate()

        onFinished?.()
      },
      (errs) => {
        console.warn('Validation error', errs)
      }
    )
  }, [_handleSubmit, mutation, onFinished, progress, utils])

  return (
    <Form.Provider {...form}>
      <Stack width="70rem" component="form" onSubmit={handleSubmit}>
        {!!groupId && <input hidden type="number" {...form.register('id')} />}
        <Form.Field
          label="Description"
          name="description"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Category"
          name="marketCategoryId"
          disabled={!!groupId}
          input={
            <Form.SelectField
              options={categoriesQuery.data ?? []}
              disabled={!!groupId}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Field
          label="Mode"
          name="mode"
          disabled={packageQuery.data?.mode === 'LEGACY'}
          info={
            <List sx={{ padding: 0 }}>
              <ListItem sx={{ gap: 1, padding: 0 }}>
                <Typography fontWeight="700">Static:</Typography>
                <Typography>Package is recalculated after each edit</Typography>
              </ListItem>
              <ListItem sx={{ gap: 1, padding: 0 }}>
                <Typography fontWeight="700">Dynamic:</Typography>
                <Typography>
                  Package is recalculated at time of each edit{' '}
                  <strong>and</strong> again every 24 hours.
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 1, padding: 0 }}>
                <Typography fontWeight="700">Legacy:</Typography>
                <Typography>
                  Package is not recalculated and records list is used instead
                  of filters.
                </Typography>
              </ListItem>
            </List>
          }
          input={
            <Form.SelectField
              options={[
                { label: 'Dynamic', value: 'DYNAMIC' },
                { label: 'Static', value: 'STATIC' },
                ...(packageQuery.data?.mode === 'LEGACY'
                  ? [{ label: 'Legacy', value: 'LEGACY' }]
                  : []),
              ]}
              rules={Form.rules.required}
            />
          }
        />

        {packageQuery.data?.mode === 'LEGACY' && (
          <Box
            sx={{
              backgroundColor: '#FFE7B3',
              padding: '12px',
              borderRadius: '6px',
              border: '1px solid #FFC53D',
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'start',
                gap: '12px',
                maxWidth: '120ch',
                color: '#302008',
              }}
            >
              <Icons.Warning fontSize="small" sx={{ mt: '3px' }} />
              <span>
                To migrate a legacy commercial package, please contact{' '}
                <Link fontWeight={700} href="mailto:dataengineering@tpicap.com">
                  Data Engineering
                </Link>
              </span>
            </Typography>
          </Box>
        )}

        {showFilterSections && packageQuery.data?.mode !== 'LEGACY' && (
          <>
            <UpsertFormFilterSection filterType="ADDITIVE" />
            <UpsertFormFilterSection filterType="SUBTRACTIVE" />
          </>
        )}

        <Button type="submit">Save</Button>
      </Stack>
    </Form.Provider>
  )
}

type UpsertFormFilterSectionProps = {
  filterType: 'ADDITIVE' | 'SUBTRACTIVE'
}

function UpsertFormFilterSection({ filterType }: UpsertFormFilterSectionProps) {
  const form = useFormContext<UpsertFormValues>()

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: 'filters',
    keyName: 'id',
  })

  const filters = useMemo(
    () =>
      transformFiltersToTags(
        filterType,
        fields.filter((field) => field.type === filterType)
      ),
    [fields, filterType]
  )

  const handleRemove = (index: number) => {
    const originalIndex = fields.findIndex((field) => field.type === filterType)
    if (originalIndex !== -1) {
      remove(originalIndex + index)
    }
  }

  return (
    <Form.Section
      title={`${filterType === 'ADDITIVE' ? 'Include' : 'Exclude'}`}
    >
      <Stack direction="row" maxWidth="70rem">
        <Stack direction="column" width="100%" spacing={4}>
          {filters.length === 0 && (
            <Typography
              sx={{ textAlign: 'center', paddingBottom: '2rem' }}
              color="GrayText"
              fontSize="13px"
            >
              No {filterType.toLowerCase()} filters added.
            </Typography>
          )}

          {filters.map((filter, i) => {
            return (
              <Stack
                key={`filter-${filterType}-${i}`}
                direction="row"
                alignItems="flex-start"
                spacing={2}
                padding="0.5rem 0.75rem"
                justifyContent="space-between"
                border="1px solid"
                borderColor={theme.palette.grey[400]}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                    flex: 1,
                  }}
                >
                  {filter.map((item) => {
                    if (
                      (item.field as string) === 'filter' &&
                      item.value !== '%'
                    ) {
                      return (
                        <BasicFilterTag
                          key={item.key}
                          fieldLabel="Search"
                          valueLabel={String(item.value)}
                        />
                      )
                    }

                    const filterConfig = FILTERS_CONFIG[item.field as FilterKey]

                    if (!filterConfig) {
                      return null
                    }

                    if (filterConfig.type === 'basic') {
                      return (
                        <BasicFilterTag
                          key={item.key}
                          fieldLabel={filterConfig.getFieldLabel()}
                          valueLabel={filterConfig.getValueLabel(item.value)}
                        />
                      )
                    }

                    const FilterTagComponent = filterConfig.FilterTag
                    return (
                      <FilterTagComponent
                        key={item.key}
                        value={String(item.value)}
                      />
                    )
                  })}
                </Box>

                <Button
                  variant="outlined"
                  color="error"
                  sx={{
                    minWidth: 'auto',
                    alignSelf: 'flex-start',
                    ml: 2,
                  }}
                  startIcon={<Icons.Remove />}
                  onClick={() => handleRemove(i)}
                >
                  Remove
                </Button>
              </Stack>
            )
          })}
        </Stack>
      </Stack>
    </Form.Section>
  )
}
