import { Route } from 'react-router-dom'

import * as Icons from '@mui/icons-material'

import { RouterWhen, When } from '@tk/frontend/primitives'
import { NavButton, NavSection } from '@tk/frontend/primitives/layout'

import * as CrudSimple from './crud-simple'
import * as CrudTree from './crud-tree'
import { AssetTypeCreateFormPage } from './crud-tree/AssetTypeCrud/AssetTypeCreatePage'
import { AssetTypeUpdateFormPage } from './crud-tree/AssetTypeCrud/AssetTypeEditPage'
import * as routes from './routes'

export const Sidebar = () => {
  return (
    <When can="reference-data.read">
      <NavSection title="Reference Data">
        <NavButton
          label="Asset Classes & Types"
          path={routes.app.referenceData.assetTypes.list}
          icon={<Icons.AccountTreeRounded />}
        />
        <NavButton
          label="Clearing Types"
          path={routes.app.referenceData.clearingTypes}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Companies"
          path={routes.app.referenceData.companies}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Currencies"
          path={routes.app.referenceData.currencies}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Day Counts"
          path={routes.app.referenceData.dayCounts}
          icon={<Icons.LabelRounded fontSize="small" />}
        />

        <NavButton
          label="Frequencies"
          path={routes.app.referenceData.frequencies}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Regions & Locations"
          path={routes.app.referenceData.locations}
          icon={<Icons.AccountTreeRounded />}
        />
        <NavButton
          label="Periods"
          path={routes.app.referenceData.periods}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Price Types"
          path={routes.app.referenceData.priceTypes}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Pricing Frequencies"
          path={routes.app.referenceData.pricingFrequencies}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Settlement Indexes"
          path={routes.app.referenceData.settlementIndex}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Settlement Methods"
          path={routes.app.referenceData.settlementMethods}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Backbones & Sources"
          path={routes.app.referenceData.sources}
          icon={<Icons.AccountTreeRounded />}
        />
        <NavButton
          label="Standard Discountings"
          path={routes.app.referenceData.standardDiscountings}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
        <NavButton
          label="Subscriptions"
          path={routes.app.referenceData.subscriptions}
          icon={<Icons.LabelRounded fontSize="small" />}
        />
      </NavSection>
    </When>
  )
}

export const getRoutes = () => {
  return (
    <Route path="/" element={<RouterWhen can="reference-data.read" />}>
      <Route
        path={routes.app.referenceData.assetTypes.list}
        element={<CrudTree.AssetTypeCrud />}
      />
      <Route
        path={routes.app.referenceData.assetTypes.edit}
        element={<AssetTypeUpdateFormPage />}
      />
      <Route
        path={routes.app.referenceData.assetTypes.create}
        element={<AssetTypeCreateFormPage />}
      />
      <Route
        path={routes.app.referenceData.clearingTypes}
        element={<CrudSimple.ClearingTypeCrud />}
      />
      <Route
        path={routes.app.referenceData.companies}
        element={<CrudSimple.CompanyCrud />}
      />
      <Route
        path={routes.app.referenceData.currencies}
        element={<CrudSimple.CurrencyCrud />}
      />
      <Route
        path={routes.app.referenceData.dayCounts}
        element={<CrudSimple.DayCountCrud />}
      />
      <Route path={routes.app.referenceData.fids} element={<div />} />
      <Route
        path={routes.app.referenceData.frequencies}
        element={<CrudSimple.FrequencyCrud />}
      />
      <Route
        path={routes.app.referenceData.locations}
        element={<CrudTree.LocationCrud />}
      />
      <Route
        path={routes.app.referenceData.periods}
        element={<CrudSimple.PeriodCrud />}
      />
      <Route
        path={routes.app.referenceData.priceTypes}
        element={<CrudSimple.PriceTypeCrud />}
      />
      <Route
        path={routes.app.referenceData.pricingFrequencies}
        element={<CrudSimple.PricingFrequencyCrud />}
      />
      <Route
        path={routes.app.referenceData.settlementIndex}
        element={<CrudSimple.SettlementIndexCrud />}
      />
      <Route
        path={routes.app.referenceData.settlementMethods}
        element={<CrudSimple.SettlementMethodCrud />}
      />
      <Route
        path={routes.app.referenceData.sources}
        element={<CrudTree.SourceCrud />}
      />
      <Route
        path={routes.app.referenceData.standardDiscountings}
        element={<CrudSimple.StandardDiscountingCrud />}
      />
      <Route
        path={routes.app.referenceData.subscriptions}
        element={<CrudSimple.SubscriptionCrud />}
      />
    </Route>
  )
}
