import type * as PGS from '@lib/pgs'

import { z } from 'zod'

import {
  BaseNamedBusinessObjectCreate,
  BaseNamedBusinessObjectUpdate,
} from './named-business-object'
import { testAssignable } from './util'

export const SourceStatus = z.union([
  z.literal('ACTIVE'),
  z.literal('INACTIVE'),
])

export const SourceUpdateDto = z.object({
  ...BaseNamedBusinessObjectUpdate,
  status: SourceStatus,
  backbone: z.object({
    id: z.number().min(0),
    name: z.string().default(''),
  }),
})
testAssignable<PGS.SourceDto, (typeof SourceUpdateDto)['_output']>()

export const SourceCreateDto = z.object({
  ...BaseNamedBusinessObjectCreate,
  status: SourceStatus,
  backbone: z.object({
    id: z.number().min(0),
    name: z.string().default(''),
  }),
})
testAssignable<PGS.SourceDto, (typeof SourceCreateDto)['_output']>()
