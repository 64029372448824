import { isMatch } from 'matcher'

export function emulateDbFilter(
  filter: string | undefined | null,
  value: string | undefined | null
) {
  if (!filter) {
    return true
  }

  // https://github.com/sindresorhus/matcher
  const escapedPreparedFilter = filter
    .replace(/\*/g, '\\*')
    .replace(/!/g, '\\!')
    .replace(/%/g, '*')

  return isMatch(value ?? '', escapedPreparedFilter, {
    caseSensitive: false,
  })
}
