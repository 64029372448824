import { useCallback, useMemo } from 'react'

import { GridSortModel } from '@mui/x-data-grid-premium'

import { SortDirection, useSortQueryParams } from './useSortQueryParams'

export function useSortModel<TSortField extends string>(
  defaultField: TSortField,
  defaultDirection: SortDirection = 'asc'
) {
  const { sort, setSort, direction, setDirection } =
    useSortQueryParams<TSortField>(defaultField, defaultDirection)

  const model = useMemo<GridSortModel>(
    () => [
      {
        field: sort as string,
        sort: direction,
      },
    ],
    [direction, sort]
  )

  const setModel = useCallback(
    (model: GridSortModel) => {
      let [order] = model
      order ??= {} as any
      setSort((order.field as TSortField) ?? defaultField)
      setDirection((order.sort as SortDirection) ?? defaultDirection)
    },
    [defaultDirection, defaultField, setDirection, setSort]
  )

  return {
    model,
    setModel,
    sort,
    setSort,
    direction,
    setDirection,
  }
}
