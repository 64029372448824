import { trpc } from '@tk/frontend/api'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { SubscriptionTable } from './SubscriptionTable'

export function SubscriptionCrud() {
  return (
    <PageContentLayout title="Subscriptions">
      <SubscriptionCrudTable />
    </PageContentLayout>
  )
}

function SubscriptionCrudTable() {
  const [list] = trpc.reference.subscription.list.useSuspenseQuery()

  return <SubscriptionTable list={list} />
}
