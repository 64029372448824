import { useMemo } from 'react'

import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'

import { Outputs } from '@tk/frontend/api'
import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'

import { useFieldSelectionModel } from './useFieldSelectionModel'

function moveArray(
  array: Array<number | string>,
  from: number,
  to: number,
  on = 1
) {
  const newArray = array.slice()
  const elementsToMove = newArray.splice(from, on)
  newArray.splice(to, 0, ...elementsToMove)

  return newArray
}

type Fids = Outputs['extracta']['getExtractableRecordsAndFids']['fids']

type FidOrderingDataGridProps = {
  loading: boolean
  fids: Fids
  nameOverrides: {
    [key: string]: string
  }
  setNameOverride: (key: string, value: string) => void
}

export function FidOrderingDataGrid({
  loading,
  fids,
  nameOverrides,
  setNameOverride,
}: FidOrderingDataGridProps) {
  const fieldSelectionModel = useFieldSelectionModel()

  const rows =
    fieldSelectionModel.model.length > 0 &&
    fids.length > 0 &&
    fieldSelectionModel.model.map((m) => {
      const fid = fids.find((f) => f.rowId === m)

      if (fid) {
        return {
          id: fid.rowId,
          fidName: fid.fidName,
          acronym: fid.acronym,
          externalId: fid.externalId,
          nameOverride: nameOverrides[fid.rowId],
          scope: fid.scope,
        }
      }

      return undefined
    })

  const columns: GridColDef[] = useMemo(
    () =>
      createColumns([
        {
          field: 'fidName',
          headerName: 'Name/Alias',
          width: 150,
          type: 'string',
        },
        {
          field: 'acronym',
          headerName: 'Acronym',
          width: 150,
          type: 'string',
        },
        {
          field: 'externalId',
          headerName: 'External Id',
          width: 150,
          type: 'string',
        },
        {
          field: 'scope',
          headerName: 'Scope',
          width: 150,
          type: 'string',
        },
        {
          field: 'nameOverride',
          headerName: 'Name Override',
          width: 150,
          type: 'string',
          editable: true,
        },
      ]).map(setColumnDefaults),
    []
  )

  return (
    <DataGridPremium
      sx={{
        marginTop: '0px !important',
      }}
      rowReordering
      onRowOrderChange={(params) => {
        const newRows = moveArray(
          fieldSelectionModel.model,
          params.oldIndex,
          params.targetIndex
        )
        fieldSelectionModel.setModel(newRows)
      }}
      loading={loading}
      density="compact"
      rows={rows !== false ? rows : []}
      columns={columns}
      hideFooter
      processRowUpdate={(newRow) => {
        setNameOverride(newRow.id, newRow.nameOverride)

        return newRow
      }}
    />
  )
}
