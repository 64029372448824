import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { SuspenseBoundaryLocal } from '@tk/frontend/primitives'

import { UpsertForm } from './UpsertForm'

type Model =
  | {
      type: 'create'
    }
  | { type: 'update'; updateId: number }

export const UpsertModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>
        {model.type === 'create' ? 'New Category' : 'Update Category'}
      </DialogTitle>

      <DialogContent>
        <SuspenseBoundaryLocal>
          <UpsertForm
            categoryId={'updateId' in model ? model.updateId : undefined}
            onFinished={modal.hide}
          />
        </SuspenseBoundaryLocal>
      </DialogContent>
    </Dialog>
  )
})
