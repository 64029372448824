export const REPORT_TYPE_OPTIONS = [
  {
    value: 'ALL_TICKS',
    label: 'All ticks',
  },
  {
    value: 'HOURLY_SNAPS',
    label: 'Hourly snaps',
  },
  {
    value: 'DAILY_SNAP',
    label: 'Daily snap',
  },
]

export const TIMEZONE_OPTIONS = [
  {
    value: 'UTC',
    label: 'UTC',
  },
  {
    value: 'CET',
    label: 'CET',
  },
  {
    value: 'Europe/London',
    label: 'Europe/London',
  },
  {
    value: 'America/New_York',
    label: 'America/New York',
  },
  {
    value: 'Asia/Kolkata',
    label: 'Asia/Kolkata',
  },
  {
    value: 'Asia/Singapore',
    label: 'Asia/Singapore',
  },
  {
    value: 'Asia/Tokyo',
    label: 'Asia/Tokyo',
  },
  {
    value: 'Australia/Sydney',
    label: 'Australia/Sydney',
  },
]
