import { MultibrandAttributeType } from '@lib/pgs-types'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

type MultibrandAttributeType = (typeof MultibrandAttributeType)['_output']
export const MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS: Option<MultibrandAttributeType>[] =
  [
    {
      value: 'CURRENCY',
      label: 'Currency',
    },
    {
      value: 'PERIOD',
      label: 'Period',
    },
    {
      value: 'BASIS_CODE',
      label: 'Basis-Code',
    },
    {
      value: 'LOCATION',
      label: 'Location',
    },
    {
      value: 'CLEARING',
      label: 'Clearing',
    },
    {
      value: 'PRICE_TYPE',
      label: 'Price-Type',
    },
    {
      value: 'FREQUENCY',
      label: 'Frequency',
    },
    {
      value: 'COMPANY',
      label: 'Company',
    },
    {
      value: 'SNAP_TIME',
      label: 'Snap Time',
    },
    {
      value: 'STRIKE',
      label: 'Strike',
    },
    {
      value: 'PRODUCT',
      label: 'Product',
    },
  ]
