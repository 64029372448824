import { useCallback } from 'react'

import * as Icons from '@mui/icons-material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import { InfoPopover } from '@tk/frontend/primitives'

import { useRecordsQueryParams } from './useRecordsQueryParams'

type InputHandler = React.ChangeEventHandler<
  HTMLInputElement | HTMLTextAreaElement
>

export function RecordsFilter() {
  const { filter, setFilter } = useRecordsQueryParams()

  const handleChange = useCallback<InputHandler>(
    (e) => setFilter(e.target.value),
    [setFilter]
  )

  return (
    <>
      <InfoPopover
        contentContainer={
          <Typography
            variant="inherit"
            margin="0.5rem"
            maxWidth="40rem"
            component="div"
          />
        }
        content={
          <>
            <h4>Record Filtering</h4>
            <p>
              The record filter is quite powerful and can solve many pattern
              matching problems
            </p>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Syntax</TableCell>
                    <TableCell>Usage</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Wildcard Operators</TableCell>
                    <TableCell>
                      <Code>%</Code>
                    </TableCell>
                    <TableCell>
                      <Code>IV%NYK%</Code>
                    </TableCell>
                    <TableCell>
                      Match any number of characters in this position
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Code>_</Code>
                    </TableCell>
                    <TableCell>
                      <Code>__GBM%</Code>
                    </TableCell>
                    <TableCell>
                      Match any single character in this position
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Or Operator</TableCell>
                    <TableCell>
                      <Code>|</Code>
                    </TableCell>
                    <TableCell>
                      <Code>IVSWO%|IVSWP%|IVSWR%</Code>
                    </TableCell>
                    <TableCell>
                      Match any number of conditions delimited by a bar
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Capture Group</TableCell>
                    <TableCell>
                      <Code>( )</Code>
                    </TableCell>
                    <TableCell>
                      <Code>IVSW(O|P|R)%</Code>
                    </TableCell>
                    <TableCell>
                      Allows you to group multiple characters together, which is
                      useful for use with the Or Operator
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>A complete example</TableCell>
                    <TableCell>
                      <Code></Code>
                    </TableCell>
                    <TableCell>
                      <Code>IRCBS%(!IC|!PI)|RFCBS%(!IC|!PI)</Code>
                    </TableCell>
                    <TableCell>
                      Matches either stub ending in either IC or PI brands
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <p>
              Under the hood this is implemented with PostgreSQL's{' '}
              <Code>SIMILAR TO</Code> operator. For more technical/detailed
              information on usage you can{' '}
              <a
                href="https://www.postgresql.org/docs/current/functions-matching.html#FUNCTIONS-SIMILARTO-REGEXP"
                target="_blank"
                rel="noreferrer"
              >
                visit the Postgres docs
              </a>
            </p>
          </>
        }
        colour="dark"
        icon={<Icons.Info color="inherit" fontSize="medium" />}
        stackProps={{
          bgcolor: 'rgba(0,0,0,0.06)',
        }}
      />

      <TextField
        test-id="RecordsFilter"
        label="Search"
        placeholder="You may use wildcards (%) to search for partial record names. ie. '%CRCNNTSYCHN%'"
        variant="filled"
        onChange={handleChange}
        value={filter}
        size="small"
        fullWidth
      />
    </>
  )
}

function Code(
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
) {
  return (
    <code
      {...props}
      style={{
        background: 'rgb(0, 0, 0, 0.15)',
        borderRadius: '0.25rem',
        padding: '2px 4px',
        fontWeight: '700',
        letterSpacing: '1px',
      }}
    />
  )
}
