import { Inputs, Outputs } from '@tk/frontend/api'

export type FidGroupRequestDto = Inputs['fids']['groups']['create']

export type FidGroupEditDto = Inputs['fids']['groups']['update']

export type FidGroupDto = Exclude<
  Outputs['fids']['groups']['list']['content'],
  undefined
>[0]

export type FidMapDto = FidGroupDto['fidMaps'][0]

export type FidDto = Outputs['fids']['list']['list'][0]

export type System = FidDto['system']
export const SystemLabels: Record<System, string> = {
  RTDS: 'RTDS (TREP)',
}

export type TreeFidGroup = FidGroupDto & {
  _type: 'fid-group'
  id: number
}
export type TreeFidMap = FidMapDto & {
  _type: 'fid-map'
  parent: FidGroupDto
}
export type TreeItem = TreeFidGroup | TreeFidMap

export type FidScopeValues = FidMapDto['scope']

export const scopeValues: FidScopeValues[] = ['INTERNAL', 'EXTERNAL']
