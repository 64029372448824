import { ReactNode } from 'react'

import { z } from 'zod'

import * as gz from '@lib/pgs-types'

type Option<T extends string> = {
  label: string
  value: T
  definition: ReactNode
}

type RecordType = Exclude<z.infer<typeof gz.RecordType>, undefined>
export type RecordStatus = Exclude<z.infer<typeof gz.RecordStatus>, undefined>
type RecordScope = Exclude<z.infer<typeof gz.RecordScope>, undefined>

export const RECORD_SCOPE_OPTIONS: Option<RecordScope>[] = [
  {
    label: 'External',
    value: 'EXTERNAL',
    definition: (
      <>
        <strong>External</strong>: Can be part of products. Can be sold.
      </>
    ),
  },
  {
    label: 'Internal',
    value: 'INTERNAL',
    definition: (
      <>
        <strong>Internal</strong>: Data under collection but not currently
        expected to form part of a product
      </>
    ),
  },
  {
    label: 'Restricted',
    value: 'RESTRICTED',
    definition: (
      <>
        <strong>Restricted</strong>: Cannot be part of any product. Cannot be
        sold.
      </>
    ),
  },
]

export const RECORD_TYPE_OPTIONS: Option<RecordType>[] = [
  {
    label: 'Chain',
    value: 'CHAIN',
    definition: (
      <>
        <strong>Chain</strong>: A Chain Record, usually with a Multibrand name
        beginning `0#`
      </>
    ),
  },
  {
    label: 'Chain Item',
    value: 'CHAIN_ITEM',
    definition: (
      <>
        <strong>Chain Item</strong>: Known to exist on a Chain Record. These are
        often tracked automatically into PGS from their parents, but may have
        been created manually. They will always collect if their chain is
        collecting, but you can set the status to "Collect" independently if
        required.
      </>
    ),
  },
  {
    label: 'Item',
    value: 'ITEM',
    definition: (
      <>
        <strong>Item</strong>: A standard record, created or maintained by us
        manually.
      </>
    ),
  },
]

export const RECORD_STATUS: Record<RecordStatus, RecordStatus> = {
  COLLECT: 'COLLECT',
  DO_NOT_COLLECT: 'DO_NOT_COLLECT',
  DELETED: 'DELETED',
}

export const RECORD_STATUS_OPTIONS: Option<RecordStatus>[] = [
  {
    label: 'Collect',
    value: RECORD_STATUS.COLLECT,
    definition: (
      <>
        <strong>COLLECT</strong>: We will collect data from the source, even if
        the record is on a chain.
      </>
    ),
  },
  {
    label: 'Do Not Collect',
    value: RECORD_STATUS.DO_NOT_COLLECT,
    definition: (
      <>
        <strong>DO NOT COLLECT</strong>: We will not collect data from the
        source. Use if the record is known to have stopped ticking. If the
        record is on a chain it may collect via that regardless.
      </>
    ),
  },
  {
    label: 'Deleted',
    value: RECORD_STATUS.DELETED,
    definition: (
      <>
        <strong>DELETED</strong>: We will not collect data from the source. We
        will also filter out of products. Use if the record needs removing due
        to a mistake or replacement record.
      </>
    ),
  },
]
