import { useCallback } from 'react'

import {
  DataGridPremiumProps,
  GridRowEditStopReasons,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'
import _ from 'lodash'

import { AllPossibleKeys } from '@lib/utils'
import { usePromiseNotification } from '@tk/frontend/primitives/progress'

import { useRowModes } from './useRowModes'

type RowModes<T extends GridValidRowModel> = ReturnType<typeof useRowModes<T>>

type EditingModelOptions<T extends GridValidRowModel> = RowModes<T> & {
  onUpdateNeeded: (row: T, oldRow: T) => Promise<T>
  fieldToFocus: AllPossibleKeys<T>
}

export function useEditingProps<T extends GridValidRowModel & { id: number }>({
  rowModes,
  setEditMode,
  setViewMode,
  onUpdateNeeded,
  fieldToFocus,
}: EditingModelOptions<T>): Partial<DataGridPremiumProps<T>> {
  type GridProps = Required<DataGridPremiumProps<T>>

  const progress = usePromiseNotification()

  const onCellKeyDown = useCallback<GridProps['onCellKeyDown']>(
    (params, e) => {
      if (e.key === 'Enter' && e.ctrlKey) {
        setViewMode(params.id, 'save')
      }
    },
    [setViewMode]
  )

  const onRowEditStop = useCallback<GridProps['onRowEditStop']>(
    (params) => {
      const shouldBail = [GridRowEditStopReasons.escapeKeyDown].includes(
        params.reason!
      )

      if (shouldBail) {
        setViewMode(params.id, 'ignore')
      }
    },
    [setViewMode]
  )

  const processRowUpdate = useCallback<GridProps['processRowUpdate']>(
    async (row: T, old: T) => {
      const isIdentical = _.isEqual(row, old)
      if (isIdentical) {
        return row
      }

      try {
        return await progress(onUpdateNeeded(row, old), {
          progressMessage: 'Saving...',
          failureMessage: 'Save failed',
          successMessage: 'Saved!',
        })
      } catch (error) {
        setEditMode(row.id, fieldToFocus)

        // FIXME: setting the new version when forcing the user to edit again is a good experience
        // FIXME: but it has a bug where abandoning the changes after this doesn't make the row reset anymore. Page refresh ends up needed
        return row
      }
    },
    [fieldToFocus, onUpdateNeeded, progress, setEditMode]
  )
  return {
    editMode: 'row',
    rowModesModel: rowModes,
    onCellKeyDown,
    onRowEditStop,
    processRowUpdate,
  }
}
