import { useEntitlement } from '@tk/frontend/primitives/roles'

import { Editable, EditableDefaults } from './types'

export function useEditReferenceData(
  editable: Editable = EditableDefaults
): Editable | false {
  const canEdit = useEntitlement('reference-data.manage')

  if (!canEdit) {
    return false
  }

  return editable
}
