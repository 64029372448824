import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps,
  DateRangeValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { DateTime } from 'luxon'

interface FilterDateRangeProps<TDate>
  extends Omit<DateRangePickerProps<TDate>, 'value' | 'onChange'> {
  value: DateRange<TDate>
  onChange: (
    value: DateRange<TDate>,
    context: PickerChangeHandlerContext<DateRangeValidationError>
  ) => void
  localeText: {
    start: string
    end: string
  }
}

export function DateRangeFilter({
  value,
  onChange,
  localeText,
}: FilterDateRangeProps<DateTime>) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} localeText={localeText}>
      <DateRangePicker
        value={value}
        onChange={onChange}
        timezone="utc"
        slotProps={{
          textField: {
            size: 'small',
          },
          fieldSeparator: {
            children: 'to',
          },
        }}
        sx={{
          '& .MuiFormControl-root, & .MuiInputBase-formControl, & .MuiInputBase-input':
            { height: '100%' },
        }}
      />
    </LocalizationProvider>
  )
}
