import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

interface TokenDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  username: string
}

const TokenDialog: React.FC<TokenDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  username,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Get Token</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A token will be generated for the following user:
        </DialogContentText>
        <Typography variant="body1" gutterBottom>
          {username}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TokenDialog
