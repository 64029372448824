import React from 'react'

import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { GridRenderEditCellParams } from '@mui/x-data-grid-premium'

interface RoleSelectCellProps extends GridRenderEditCellParams<any, any, any> {
  roleType: string
  getRoleValue: (role: string) => string
}

export function RoleSelectCell({
  id,
  value,
  api,
  field,
  roleType,
  getRoleValue,
}: RoleSelectCellProps) {
  const handleChange = (event: SelectChangeEvent) => {
    api.setEditCellValue(
      {
        id: id,
        field: roleType,
        value: event.target.value,
      },
      event
    )
  }

  return (
    <FormControl fullWidth>
      <Select value={value || ''} onChange={handleChange}>
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value="READ">READ</MenuItem>
        <MenuItem value="MANAGE">MANAGE</MenuItem>
      </Select>
    </FormControl>
  )
}
