import React, { ReactNode } from 'react'

import { Grid } from '@mui/material'

type SectionBodyGridProps = {
  children: ReactNode
}

export function SectionBodyGrid({ children }: SectionBodyGridProps) {
  return (
    <Grid container spacing={2} style={{ marginTop: 0, marginLeft: '-0.5rem' }}>
      {children}
    </Grid>
  )
}
