import * as Icons from '@mui/icons-material'
import { Button, ButtonGroup } from '@mui/material'

import { When } from '@tk/frontend/primitives'

import { useProjectCrud } from './useProjectCrud'
import { useSheetDownload } from './useSheetDownload'
import { useSheetTemplateDownload } from './useSheetTemplateDownload'
import { useSheetUpload } from './useSheetUpload'

export function DocumentLoadButtons() {
  const { project } = useProjectCrud()

  const sheetUpload = useSheetUpload()
  const sheetDownload = useSheetDownload(project.document.linesSheet)
  const templateDownload = useSheetTemplateDownload()

  return (
    <ButtonGroup variant="text" color="secondary">
      <Button
        onClick={templateDownload.startDownload}
        startIcon={<Icons.Preview />}
      >
        Template
      </Button>

      <When can="record-projects.manage">
        <Button
          startIcon={<Icons.Upload />}
          onClick={sheetUpload.start}
          disabled={sheetUpload.working || project.complete}
          color="primary"
        >
          Upload
        </Button>
      </When>

      <Button
        startIcon={<Icons.Download />}
        onClick={sheetDownload.startDownload}
      >
        Download
      </Button>
    </ButtonGroup>
  )
}
