import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { DistributionCreateButton } from './DistributionCreateButton'
import { DistributionCrudTable } from './DistributionCrudTable'

export function DistributionCrud() {
  return (
    <>
      <PageContentLayout
        title="Distribution"
        controls={
          <>
            <DistributionCreateButton />
          </>
        }
      >
        <DistributionCrudTable />
      </PageContentLayout>
    </>
  )
}
