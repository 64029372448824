import { GridFilterInputValueProps } from '@mui/x-data-grid-premium'

import { trpc } from '@tk/frontend/api'
import {
  ServersideGridFilter,
  ServersideGridFilterOption,
} from '@tk/frontend/primitives/datagrid/columns'

export function GridFilterTouchedByNames(props: GridFilterInputValueProps) {
  return (
    <ServersideGridFilter
      {...props}
      useFilteredList={useFilteredList}
      useSelectedOption={useSelectedOption}
    />
  )
}

function useFilteredList(filter: string | undefined) {
  return trpc.records.getTouchedByNames.useQuery(
    {
      filter: '%' + filter + '%',
    },
    {
      select(data): ServersideGridFilterOption[] {
        return (
          data.content?.map((option) => {
            return {
              label: option,
              value: option,
            }
          }) ?? []
        )
      },
    }
  )
}

function useSelectedOption(touchedByNames: string[] | undefined) {
  const all = trpc.useQueries((t) => {
    return (touchedByNames ?? []).map((name) =>
      t.records.getTouchedByNames(
        { filter: name },
        {
          select(option): ServersideGridFilterOption {
            return {
              label: option.content?.[0] ?? '',
              value: option.content?.[0] ?? '',
            }
          },
        }
      )
    )
  })

  return all.map((v) => v.data!).filter(Boolean)
}
