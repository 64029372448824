import { MarketEntityRelinkDto, RecordPreview } from '@lib/pgs'

export const useGenerateRows = (preview: {
  status: string
  response?: { content: Array<any> }
}) => {
  if (preview.status !== 'success' || !preview.response) {
    return []
  }

  let letterIndex = 0

  return preview.response.content.reduce((acc: any[], item: any) => {
    const marketEntity = item.newVersion as MarketEntityRelinkDto

    const marketEntityRow = {
      _type: 'marketEntity',
      id: marketEntity.marketEntityId?.toString() ?? 'Unknown',
      name:
        marketEntity.marketEntityId === -1
          ? 'Invalid Records'
          : `Linked Collection ${++letterIndex}`,
    }
    acc.push(marketEntityRow)

    if (marketEntity.records) {
      marketEntity.records.forEach(
        (record: RecordPreview, recordIndex: number) => {
          acc.push({
            _type: 'record',
            id: record.newVersion?.recordNumber ?? record.currentVersion?.id,
            name: record.newVersion?.name ?? 'Unnamed',
            parentId: marketEntityRow.id,
            action: record.action,
            rowNumber: record.rowNumber,
            currentVersion: record.currentVersion,
            newVersion: record.newVersion,
            fieldErrors: record.recordValidation?.fieldErrors ?? [],
            errorMessage: record.recordValidation?.globalError,
            index: recordIndex,
          })
        }
      )
    }

    return acc
  }, [])
}
