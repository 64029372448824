import { Typography } from '@mui/material'

export type SecondaryTextCellProps = {
  text: string
}

export function SecondaryTextCell({ text }: SecondaryTextCellProps) {
  return (
    <Typography variant="body2" color="GrayText">
      {text}
    </Typography>
  )
}
