import { useMemo } from 'react'

import { Button, Stack } from '@mui/material'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import { useBloombergMappingCreateTelemetry } from '@tk/frontend/app/Bloomberg/telemetry'
import {
  ALL_TO_SEE_OPTIONS,
  TYPE_OPTIONS,
} from '@tk/frontend/app/Bloomberg/types'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

export type CreateFormValues = Omit<
  Inputs['bloomberg']['entitlements']['upsert'],
  'allToSee'
> & {
  allToSee: 'ALL_TO_SEE' | 'NONE_TO_SEE'
}

const Form = getFormComponents<CreateFormValues>()

export type CreateFormProps = {
  onFinished?: () => void
}

export function CreateForm({ onFinished }: CreateFormProps) {
  const form = Form.useForm({
    defaultValues: {
      type: 'TERMINAL',
      allToSee: 'ALL_TO_SEE',
    },
  })
  const { handleSubmit: _handleSubmit, formState } = form

  const progress = usePromiseNotification()
  const mutation = useMutation()

  const { trackCreateSuccess, trackCreateValidationError } =
    useBloombergMappingCreateTelemetry('bloomberg/entitlements')

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const promise = mutation.mutateAsync({
          ...values,
          allToSee: values.allToSee === 'ALL_TO_SEE',
        })
        const result = await progress(promise, {
          progressMessage: 'Creating',
          successMessage: 'Created',
          failureMessage: 'Error creating',
        })

        trackCreateSuccess(result)
        onFinished?.()
      },
      (err) => {
        trackCreateValidationError(formState.dirtyFields, err)
      }
    )
  }, [
    _handleSubmit,
    formState.dirtyFields,
    mutation,
    onFinished,
    progress,
    trackCreateSuccess,
    trackCreateValidationError,
  ])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit}>
        <Form.Field
          label="Name (Required)"
          name="name"
          input={<Form.TextField autoFocus rules={Form.rules.required} />}
        />
        <Form.Field
          label="Description (Required)"
          name="description"
          input={<Form.TextField rules={Form.rules.required} />}
        />
        <Form.Field
          label="Type (Required)"
          name="type"
          input={
            <Form.SelectField
              options={TYPE_OPTIONS}
              rules={Form.rules.required}
            />
          }
        />
        <Form.Field
          label="All To See? (Required)"
          name="allToSee"
          input={
            <Form.SelectField
              options={ALL_TO_SEE_OPTIONS}
              rules={Form.rules.required}
            />
          }
        />

        <Form.Row>
          <Button type="submit">Create</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useMutation() {
  const utils = useUtils()

  const { useMutation } = trpc.bloomberg.entitlements.upsert

  return useMutation({
    onSuccess() {
      utils.bloomberg.entitlements.invalidate()
    },
  })
}
