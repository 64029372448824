import { getFormComponents } from '@tk/frontend/primitives'
import { STRIKE_OPTIONS } from '@tk/frontend/primitives/presentation-utils'

import { RECORD_SCOPE_OPTIONS } from './constants'
import { RecordEditDto } from './types'
import { useRecordReferenceLists } from './useRecordReferenceLists'

const Form = getFormComponents<RecordEditDto>()

export function RecordEditFieldsLinked() {
  const [lists] = RecordEditFieldsLinked.useRecordReferenceLists()

  return (
    <>
      <Form.Section title="Brand Details">
        <Form.Field
          label="Scope (Saleability) (Required)"
          name="scope"
          info={
            <Form.FieldInfo
              title="Controls how this record can be sold/distributed"
              options={RECORD_SCOPE_OPTIONS}
            />
          }
          input={
            <Form.SelectField
              rules={Form.rules.required}
              options={RECORD_SCOPE_OPTIONS}
            />
          }
        />

        <Form.Field
          label="Company / Brand"
          name="company.id"
          input={<Form.SelectField options={lists.company} />}
        />
      </Form.Section>

      <Form.Section title="Asset Details">
        <Form.Field
          label="Clearing Type"
          name="clearingType.id"
          input={<Form.SelectField options={lists.clearingType} />}
        />

        <Form.Row>
          <Form.Field
            label="Currency 1"
            name="currency1.id"
            input={
              <Form.SelectField
                options={lists.currency}
                rules={{
                  deps: 'currency2.id',
                }}
              />
            }
          />
          <Form.Field
            label="Currency 2"
            name="currency2.id"
            input={
              <Form.SelectField
                options={lists.currency}
                rules={{
                  validate: (value, formValue) =>
                    !formValue.currency1?.id && value
                      ? 'Currency 2 cannot be filled if Currency 1 is empty '
                      : true,
                }}
              />
            }
          />
        </Form.Row>

        <Form.Row>
          <Form.Field
            label="Period 1"
            name="period1.id"
            input={
              <Form.SelectField
                options={lists.period}
                rules={{
                  deps: ['period2.id', 'period3.id', 'period4.id'],
                }}
              />
            }
          />
          <Form.Field
            label="Period 2"
            name="period2.id"
            input={
              <Form.SelectField
                options={lists.period}
                rules={{
                  deps: ['period3.id', 'period4.id'],
                  validate: (value, formValue) =>
                    !formValue.period1?.id && value
                      ? 'Period 2 cannot be filled if Period 1 is empty '
                      : true,
                }}
              />
            }
          />
        </Form.Row>

        <Form.Row>
          <Form.Field
            label="Period 3"
            name="period3.id"
            input={
              <Form.SelectField
                options={lists.period}
                rules={{
                  deps: 'period4.id',
                  validate: (value, formValue) =>
                    (!formValue.period1?.id || !formValue.period2?.id) && value
                      ? 'Period 3 cannot be filled if Period 1 or Period 2 is empty '
                      : true,
                }}
              />
            }
          />
          <Form.Field
            label="Period 4"
            name="period4.id"
            input={
              <Form.SelectField
                options={lists.period}
                rules={{
                  validate: (value, formValue) =>
                    (!formValue.period1?.id ||
                      !formValue.period2?.id ||
                      !formValue.period3?.id) &&
                    value
                      ? 'Period 4 cannot be filled if Period 1 or Period 2 or Period 3 is empty'
                      : true,
                }}
              />
            }
          />
        </Form.Row>

        <Form.Field
          label="Price Type"
          name="priceType.id"
          input={<Form.SelectField options={lists.priceType} />}
        />

        <Form.Field
          label="Pricing Frequency"
          name="pricingFrequency.id"
          input={<Form.SelectField options={lists.pricingFrequency} />}
        />

        <Form.Field
          label="Location"
          name="location.id"
          input={<Form.SelectField options={lists.location} />}
        />
      </Form.Section>

      <Form.Section title="Leg Details">
        <Form.Row>
          <Form.Field
            label="Day Count 1"
            name="dayCount1.id"
            input={
              <Form.SelectField
                options={lists.dayCount}
                rules={{
                  deps: 'dayCount2.id',
                }}
              />
            }
          />
          <Form.Field
            label="Day Count 2"
            name="dayCount2.id"
            input={
              <Form.SelectField
                options={lists.dayCount}
                rules={{
                  validate: (value, formValue) =>
                    !formValue.dayCount1?.id && value
                      ? 'Day Count 2 cannot be filled if Day Count 1 is empty '
                      : true,
                }}
              />
            }
          />
        </Form.Row>

        <Form.Row>
          <Form.Field
            label="Payment Frequency 1"
            name="paymentFrequency1.id"
            input={
              <Form.SelectField
                options={lists.frequency}
                rules={{
                  deps: 'paymentFrequency2.id',
                }}
              />
            }
          />
          <Form.Field
            label="Payment Frequency 2"
            name="paymentFrequency2.id"
            input={
              <Form.SelectField
                options={lists.frequency}
                rules={{
                  validate: (value, formValue) =>
                    !formValue.paymentFrequency1?.id && value
                      ? 'Payment Frequency 2 cannot be filled if Payment Frequency 1 is empty '
                      : true,
                }}
              />
            }
          />
        </Form.Row>

        <Form.Row>
          <Form.Field
            label="Reset Frequency 1"
            name="resetFrequency1.id"
            input={
              <Form.SelectField
                options={lists.frequency}
                rules={{
                  deps: 'resetFrequency2.id',
                }}
              />
            }
          />
          <Form.Field
            label="Reset Frequency 2"
            name="resetFrequency2.id"
            input={
              <Form.SelectField
                options={lists.frequency}
                rules={{
                  deps: 'resetFrequency1.id',
                  validate: (value, formValue) =>
                    !formValue.resetFrequency1?.id && value
                      ? 'Reset Frequency 2 cannot be filled if Reset Frequency 1 is empty '
                      : true,
                }}
              />
            }
          />
        </Form.Row>

        <Form.Row>
          <Form.Field
            label="Settlement Index 1"
            name="settlementIndex1.id"
            input={
              <Form.SelectField
                options={lists.settlementIndex}
                rules={{
                  deps: 'settlementIndex2.id',
                }}
              />
            }
          />
          <Form.Field
            label="Settlement Index 2"
            name="settlementIndex2.id"
            input={
              <Form.SelectField
                options={lists.settlementIndex}
                rules={{
                  validate: (value, formValue) =>
                    !formValue.settlementIndex1?.id && value
                      ? 'Settlement Index 2 cannot be filled if Settlement Index 1 is empty '
                      : true,
                }}
              />
            }
          />
        </Form.Row>
      </Form.Section>

      <Form.Section title="Vol Specific Details">
        <Form.Field
          label="Settlement Method"
          name="settlementMethod.id"
          input={<Form.SelectField options={lists.settlementMethod} />}
        />

        <Form.Field
          label="Standard Discounting"
          name="standardDiscounting.id"
          input={<Form.SelectField options={lists.standardDiscounting} />}
        />

        <Form.Row>
          <Form.Field
            label="Strike Unit"
            name="strikeUnit"
            input={
              <Form.SelectField
                options={STRIKE_OPTIONS}
                rules={{
                  deps: 'strikeValue',
                }}
              />
            }
          />
          <Form.Field
            label="Strike"
            name="strikeValue"
            input={
              <Form.TextField
                type="number"
                rules={{
                  validate(val, other) {
                    const { strikeUnit } = other

                    console.log('VALIDATING', { val, strikeUnit })

                    if (
                      strikeUnit === 'BASIS_POINTS' ||
                      strikeUnit === 'PERCENT'
                    ) {
                      return isNumber(val)
                        ? true
                        : 'Strike is Required and it should be number.'
                    }

                    if (strikeUnit === 'ATM') {
                      return val
                        ? 'ATM does not expect a value in Strike'
                        : true
                    }

                    if (!strikeUnit) {
                      return val
                        ? 'Stike Unit is None, No value expected in Strike'
                        : true
                    }

                    return true
                  },
                }}
              />
            }
          />
        </Form.Row>
      </Form.Section>
    </>
  )
}

RecordEditFieldsLinked.useRecordReferenceLists = useRecordReferenceLists

function isNumber(val: any) {
  return !isNaN(parseInt(val))
}
