import { createContext, ReactNode, useContext, useMemo } from 'react'

export type FormConfigContextType = {
  editable: boolean
}

interface ProviderProps {
  editable: boolean
  children: ReactNode
}

export const FormConfigContext = createContext<FormConfigContextType>({
  editable: true,
})

export const FormConfigProvider = ({ editable, children }: ProviderProps) => {
  const value = useMemo(() => {
    return { editable }
  }, [editable])
  return (
    <FormConfigContext.Provider value={value}>
      {children}
    </FormConfigContext.Provider>
  )
}

export const useFormContext = () => useContext(FormConfigContext)
