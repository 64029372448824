import { GridValidRowModel } from '@mui/x-data-grid-premium'

import { GridEnrichedColDef } from '@tk/frontend/primitives/datagrid/enhanced-types'

export const setColumnDefaults = <
  TType extends GridValidRowModel,
  V = any,
  F = V
>(
  col: GridEnrichedColDef<TType, V, F>
): GridEnrichedColDef<TType, V, F> => {
  col.editable ??= false
  col.filterable ??= false
  col.groupable ??= false
  col.aggregable ??= false
  col.pinnable ??= false
  col.sortable ??= false

  return col
}
