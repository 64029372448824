import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { CreateForm } from './CreateForm'

type Model = {}

export const CreateModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>New Ticker</DialogTitle>
      <DialogContent>
        <CreateForm onFinished={modal.hide} />
      </DialogContent>
    </Dialog>
  )
})
