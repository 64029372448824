import { useMemo } from 'react'

import { trpc } from '@tk/frontend/api'

import { TypedLocation, TypedRegion, TypedRowUnion } from './types'

export function useRows() {
  const [regions] = trpc.reference.region.list.useSuspenseQuery(undefined, {
    select(data): TypedRegion[] {
      return data.map<TypedRegion>((item) => {
        return {
          ...item,
          _type: 'region',
        }
      })
    },
  })

  const [locations] = trpc.reference.location.list.useSuspenseQuery(undefined, {
    select(data): TypedLocation[] {
      return data.map<TypedLocation>((item) => {
        return {
          ...item,
          _type: 'location',
        }
      })
    },
  })

  const rows = useMemo<TypedRowUnion[]>(() => {
    const parent = regions ?? []
    const child = locations ?? []

    return [...parent, ...child]
  }, [locations, regions])

  return rows
}
