import { z } from 'zod'

import * as PGS from '@lib/pgs'

import {
  BaseNamedBusinessObjectCreate,
  BaseNamedBusinessObjectUpdate,
} from './named-business-object'
import { testAssignable } from './util'

export const CurrencyUpdateDto = z.object({
  ...BaseNamedBusinessObjectUpdate,
  nonIsoCurrency: z
    .boolean()
    .optional()
    .transform((value) => value ?? false),
})
testAssignable<PGS.CurrencyDto, (typeof CurrencyUpdateDto)['_output']>()

export const CurrencyCreateDto = z.object({
  ...BaseNamedBusinessObjectCreate,
  nonIsoCurrency: z
    .boolean()
    .optional()
    .transform((value) => value ?? false),
})
testAssignable<PGS.CurrencyDto, (typeof CurrencyCreateDto)['_output']>()
