import { Typography } from '@mui/material'

import { trpc } from '@tk/frontend/api'

import { useProjectCrud } from './useProjectCrud'

export function AffectedRecordsSummary() {
  const { projectName } = useProjectCrud()

  const [summary] = trpc.recordProjects.getSummary.useSuspenseQuery({
    name: projectName,
  })

  return (
    <div>
      <Typography color={(t) => t.palette.success.main}>
        {summary?.changes.added ?? '?'} New Records
      </Typography>

      <Typography color={(t) => t.palette.info.main}>
        {summary?.changes.existing ?? '?'} Existing Records
      </Typography>
    </div>
  )
}
