import { Route, Routes } from 'react-router-dom'

import { ExportProgressList } from './ExportProgressList'
import * as routes from './Exports.routes'

export function ExportsRouter() {
  return (
    <Routes>
      <Route path={routes.relative.list} element={<ExportProgressList />} />
    </Routes>
  )
}
