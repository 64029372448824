import { createContext, ReactNode, useContext, useMemo } from 'react'

import NiceModal from '@parameta/nice-modal-react'

import { RouteDef, RouteUtilsDef } from './types'

export type SimpleImportContextType = {
  route: RouteDef
  utils: RouteUtilsDef
  previewUriTemplate: `${string}/:taskId`
}

const SimpleImportContext = createContext<SimpleImportContextType | undefined>(
  undefined
)

export function useSimpleImportContext() {
  const context = useContext(SimpleImportContext)
  if (!context) {
    throw new Error(
      'SimpleImportContext was not found above this point in the component tree'
    )
  }

  return context
}

export function SimpleImportProvider({
  children,
  route,
  previewUriTemplate,
  utils,
}: SimpleImportContextType & { children: ReactNode }) {
  const value = useMemo<SimpleImportContextType>(() => {
    return { route, utils, previewUriTemplate }
  }, [previewUriTemplate, route, utils])

  return (
    <SimpleImportContext.Provider value={value}>
      <NiceModal.Provider>{children}</NiceModal.Provider>
    </SimpleImportContext.Provider>
  )
}
