import { Checkbox } from '@mui/material'

export type BooleanCellProps = {
  value: boolean
  activeColor?: 'primary' | 'secondary'
}

export function BooleanCell({
  value,
  activeColor = 'primary',
}: BooleanCellProps) {
  return (
    <Checkbox
      checked={value}
      style={{ pointerEvents: 'none' }}
      color={activeColor}
    />
  )
}
