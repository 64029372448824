import { z } from 'zod'

import * as PGS from '@lib/pgs'

import { testAssignable } from './util'

export const IsoDate = z.date()
// .union([z.string().optional(), z.date().optional()])
// .transform((value, ctx) => {
//   if (value instanceof Date) {
//     return value
//   }
//   if (typeof value === 'string') {
//     return new Date(value)
//   }
//   if (value === null || value === undefined) {
//     return undefined
//   }

//   throw `Unexpected value type for ${ctx.path}: ${typeof value}::${value}`
// })
// .optional()

testAssignable<(typeof IsoDate)['_input'], string>()
testAssignable<Date | undefined, (typeof IsoDate)['_output']>()

export const SortOrder = z
  .union([z.literal('asc'), z.literal('desc')])
  .optional()
  .default('asc')

export const IdItem = z.object({
  id: z.number(),
})

export const RecordType = z.union([
  z.literal('ITEM'),
  z.literal('CHAIN_ITEM'),
  z.literal('CHAIN'),
])
testAssignable<
  (typeof RecordType)['_input'],
  Exclude<PGS.RecordDto['type'], undefined>
>()
testAssignable<
  Exclude<PGS.RecordDto['type'], undefined>,
  (typeof RecordType)['_output']
>()

export const RecordStatus = z.union([
  z.literal('COLLECT'),
  z.literal('DO_NOT_COLLECT'),
  z.literal('DELETED'),
])
testAssignable<
  (typeof RecordStatus)['_input'],
  Exclude<PGS.RecordDto['status'], undefined>
>()
testAssignable<
  Exclude<PGS.RecordDto['status'], undefined>,
  (typeof RecordStatus)['_output']
>()

export const RecordScope = z.union([
  z.literal('EXTERNAL'),
  z.literal('INTERNAL'),
  z.literal('RESTRICTED'),
])
testAssignable<
  (typeof RecordScope)['_input'],
  Exclude<PGS.RecordDto['scope'], undefined>
>()
testAssignable<
  Exclude<PGS.RecordDto['scope'], undefined>,
  (typeof RecordScope)['_output']
>()

export const PageDto = <T extends z.ZodType>(item: T) =>
  z.object({
    page: z.number().default(0),
    size: z.number().default(0),
    sort: z.string().optional(),
    direction: z.string().optional().transform(SortOrder.parse),
    content: z.array(item).default([]),
    totalElements: z.number().default(0),
    totalPages: z.number().default(0),
  })

const PageBase = PageDto(z.object({}))
export type Page<T> = Omit<(typeof PageBase)['_output'], 'content'> & {
  content: T[]
}
export type PageInput<T> = Omit<(typeof PageBase)['_input'], 'content'> & {
  content?: T[] | undefined
}

type StringPagedDto = Omit<PGS.PageDtoRecordListingItemDto, 'content'> & {
  content: string[]
}
type ZodStringPagedDto = ReturnType<typeof PageDto<z.ZodString>>
testAssignable<ZodStringPagedDto['_input'], StringPagedDto>()
testAssignable<StringPagedDto, ZodStringPagedDto['_output']>()

export const ApiFieldError = z.object({
  field: z.string().optional(),
  message: z.string().optional(),
  value: z.any().optional(),
})
testAssignable<(typeof ApiFieldError)['_input'], PGS.ApiFieldError>()
testAssignable<PGS.ApiFieldError, (typeof ApiFieldError)['_output']>()

export const ApiFieldLineError = z
  .object({
    field: z.string().optional(),
    message: z.string().optional(),
    value: z.any().optional(),
    rowNumber: z.number(),
    line: z.string(),
  })
  .transform((obj) => {
    const updateField = obj.field?.includes('[') === true

    return {
      ...obj,
      fieldDotIndexed: updateField
        ? obj.field?.replace(/\[/, '.').replace(/]/, '')
        : obj.field,
    }
  })
testAssignable<(typeof ApiFieldError)['_input'], PGS.ApiFieldError>()
testAssignable<PGS.ApiFieldError, (typeof ApiFieldError)['_output']>()

export const MultibrandAttributeType = z.union([
  z.literal('CURRENCY'),
  z.literal('PERIOD'),
  z.literal('BASIS_CODE'),
  z.literal('LOCATION'),
  z.literal('CLEARING'),
  z.literal('PRICE_TYPE'),
  z.literal('FREQUENCY'),
  z.literal('COMPANY'),
  z.literal('SNAP_TIME'),
  z.literal('STRIKE'),
  z.literal('PRODUCT'),
])
testAssignable<
  (typeof MultibrandAttributeType)['_input'],
  Exclude<PGS.MultibrandAttributeDto['type'], undefined>
>()
testAssignable<
  Exclude<PGS.MultibrandAttributeDto['type'], undefined>,
  (typeof MultibrandAttributeType)['_output']
>()
