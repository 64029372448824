import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, TextField } from '@mui/material'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  useBloombergMappingTableTelemetry,
  useBloombergMappingTelemetry,
} from '@tk/frontend/app/Bloomberg/telemetry'
import { PackageActions } from '@tk/frontend/app/Bloomberg/types'
import * as routes from '@tk/frontend/app/routes'
import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'
import {
  EditableDefaults,
  NamedBusinessObjectTable,
} from '@tk/frontend/primitives/datagrid/table'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { RulesetCreateButton } from './RulesetCreateButton'

export function RulesetsCrud() {
  useBloombergMappingTableTelemetry('bloomberg/rulesets')

  return (
    <>
      <PageContentLayout
        title="Bloomberg: Rulesets"
        controls={
          <>
            <RulesetCreateButton />
          </>
        }
      >
        <RulesetsCrudTable />
      </PageContentLayout>
    </>
  )
}

function RulesetsCrudTable() {
  const { trackEditSave, trackEditStart } =
    useBloombergMappingTelemetry('bloomberg/rulesets')

  const utils = useUtils()

  const mutator = trpc.bloomberg.ruleset.update.useMutation({
    onMutate(variables) {
      trackEditSave('not-tracked', variables)
    },
    onSuccess() {
      utils.bloomberg.ruleset.invalidate()
    },
  })
  const [list] = trpc.bloomberg.ruleset.list.useSuspenseQuery()

  const canManage = useEntitlement('bloomberg-mappings.manage')

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  const packageActions = useMemo<PackageActions>(() => {
    return [
      {
        type: 'actions',
        field: 'nav-actions',
        flex: 1,
        align: 'left',
        getActions(params) {
          return [
            <ActionButtonGroup>
              <ButtonLink
                color="primary"
                startIcon={<Icons.TableRowsRounded />}
                variant="text"
                to={routes.app.bloomberg.defaultRecordMapping({
                  ruleset: params.row.id,
                })}
              >
                Mappings
              </ButtonLink>
            </ActionButtonGroup>,
          ]
        },
      },
    ]
  }, [])

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <NamedBusinessObjectTable
        list={filteredList}
        onEditStart={trackEditStart}
        onEditSave={mutator.mutateAsync}
        editable={canManage && EditableDefaults}
        packageActions={packageActions}
      />
    </Box>
  )
}
