import { useState } from 'react'

import { GridRenderEditCellParams } from '@mui/x-data-grid-premium'
import { keepPreviousData } from '@tanstack/react-query'

import { useDebounce } from '@lib/web'
import { Outputs, trpc } from '@tk/frontend/api'
import { SelectorCell } from '@tk/frontend/primitives/datagrid/edit-cells'

import { transformUiListToSelectorCellOptions } from './types'

type Ticker = Outputs['bloomberg']['ticker']['get']

export type TickerEditCellProps = {
  params: GridRenderEditCellParams<Ticker>
}

export function TickerEditCell(props: TickerEditCellProps, ref: any) {
  const [filter, setFilter] = useState('')

  const debouncedFilter = useDebounce(filter, 300)
  const tickersQuery = trpc.bloomberg.ticker.list.useQuery(
    {
      filter: debouncedFilter ? debouncedFilter : undefined,
      sort: 'name',
      size: 100,
    },
    {
      select: (data) => transformUiListToSelectorCellOptions(data.content),

      placeholderData: keepPreviousData,
    }
  )

  return (
    <SelectorCell<Ticker>
      params={props.params}
      value={props.params.value}
      noEmptyOption
      options={tickersQuery.data ?? []}
      loading={tickersQuery.isFetching}
      onSearch={(e, value) => {
        setFilter(`%${value}%`)
      }}
    />
  )
}
