import { FieldError, Path } from 'react-hook-form'

export type FlattenedUntil<TLeaf> = Record<string, TLeaf>

export function flattenUntil<
  TLeaf,
  T extends Record<string, unknown> = {},
  TKey extends Path<T> = Path<T>
>(
  data: T,
  isLeaf: (node: unknown, key: string) => node is TLeaf
): FlattenedUntil<TLeaf> {
  const entries = Object.entries(data) as [TKey, unknown][]
  const result: FlattenedUntil<TLeaf> = {}

  while (entries.length > 0) {
    const entry = entries.pop()
    if (!entry) {
      continue
    }

    const [key, value] = entry
    if (value === null || value === undefined) {
      continue
    }

    if (isLeaf(value, key)) {
      result[key] = value
    } else if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        entries.push([`${key}.${i}` as TKey, value[i]])
      }
    } else if (typeof value === 'object') {
      for (const [subKey, subValue] of Object.entries(value)) {
        entries.push([`${key}.${subKey}` as TKey, subValue])
      }
    } else {
      console.warn(`Unsupported value in flattenUntil:`, value)
    }
  }

  return result
}

export const isTrueBoolean = (value: unknown): value is true => {
  return value === true
}

const FIELD_ERROR_KEYS: Record<keyof FieldError, true> = {
  message: true,
  ref: true,
  root: true,
  type: true,
  types: true,
}
export const isFieldError = (
  value: unknown,
  key: string
): value is FieldError => {
  if (typeof value !== 'object' || Array.isArray(value) || value == null) {
    return false
  }

  // Check no unknown keys
  for (const key of Object.keys(value)) {
    if (FIELD_ERROR_KEYS[key as keyof FieldError] !== true) {
      return false
    }
  }

  const maybe = value as FieldError

  // Check has type as minimum
  return !!maybe.type
}
