import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { ActorCreateButton } from './ActorCreateButton'
import { ActorCrudTable } from './ActorCrudTable'

export function ActorCrud() {
  return (
    <>
      <PageContentLayout
        title="Actor"
        controls={
          <>
            <ActorCreateButton />
          </>
        }
      >
        <ActorCrudTable />
      </PageContentLayout>
    </>
  )
}
