import { Suspense, useEffect, useState } from 'react'

import * as Icons from '@mui/icons-material'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'
import { keepPreviousData } from '@tanstack/react-query'

import { trpc } from '@tk/frontend/api'

type StatusModalRendererModel = {
  type: 'status'
  uuid: string
}

type StatusViewProps = StatusModalRendererModel

const StatusView = ({ uuid }: StatusViewProps) => {
  const [enabled, setEnabled] = useState(true)
  const modal = useModal()

  const { data } = trpc.recordProjects.recordStatus.useQuery(
    {
      uuid: uuid,
    },
    {
      refetchInterval: enabled ? 500 : false,
      placeholderData: keepPreviousData,
    }
  )

  useEffect(() => {
    if (data?.state === 'COMPLETED') {
      setEnabled(false)
    }
  }, [data?.state])

  return (
    <Dialog open={modal.visible} onClose={modal.hide} fullWidth maxWidth="xs">
      <DialogTitle>
        Status
        <IconButton
          sx={{ position: 'absolute', right: 8, top: 8 }}
          onClick={modal.hide}
        >
          <Icons.Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {data?.state === 'COMPLETED' && (
          <Stack direction="row" alignItems="center">
            <Icons.Check color="success" />
            <span>Records Created</span>
          </Stack>
        )}

        {data?.state === 'RUNNING' && (
          <Stack direction="row" alignItems="center">
            <CircularProgress size={16} thickness={10} />
            <span>In progress...</span>
          </Stack>
        )}

        {data?.state === 'FAILED' && (
          <>
            <Stack direction="row" alignItems="center">
              <Icons.Error color="error" />
              <span>Record creation has Failed</span>
            </Stack>
            <p>"{data?.message ?? ''}"</p>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export const StatusModalRenderer = create<StatusModalRendererModel>(
  (model, utils) => {
    if (model.type === 'status') {
      return (
        <Suspense fallback="Fetching data...">
          <StatusView {...utils} {...model} />
        </Suspense>
      )
    }

    return null
  }
)
