import { useCallback, useLayoutEffect, useRef } from 'react'
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'

interface TextFieldProps<FormValues extends FieldValues = FieldValues> {
  name?: Path<FormValues>
  label?: string
  placeholder?: string
  fullWidth?: boolean
  autoFocus?: boolean
  width?: string
  variant?: MuiTextFieldProps['variant']
  align?: 'left' | 'right'
  disabled?: boolean
  rules?: UseControllerProps<FormValues>['rules']
  autoComplete?: 'off' | (string & {})
  multiline?: boolean | Pick<MuiTextFieldProps, 'minRows' | 'maxRows'>
  type?: MuiTextFieldProps['type']
  style?: React.CSSProperties | undefined
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export function TextField<FormValues extends FieldValues = FieldValues>({
  name,
  align = 'left',
  fullWidth,
  autoFocus,
  label,
  placeholder,
  variant = 'standard',
  width = '100%',
  disabled,
  rules,
  autoComplete = 'off',
  multiline = false,
  type,
  style: customStyle,
  onBlur,
}: TextFieldProps<FormValues>) {
  const { field, fieldState } = useController<FormValues>({
    name: name ?? ('' as any),
    rules: rules,
  })

  const errorMessage = fieldState.error?.message

  const ref = useRef<any>()
  const focus = useRef(autoFocus)
  useLayoutEffect(() => {
    if (focus.current) {
      ref.current?.focus?.()
    }
  }, [])

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      field.onBlur()
      onBlur?.(event)
    },
    [field, onBlur]
  )

  return (
    <MuiTextField
      {...field}
      {...(typeof multiline === 'object'
        ? { multiline: true, ...multiline }
        : { multiline: multiline })}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      value={field.value ?? ''}
      sx={{
        'width': width ?? '100%',
        '.MuiFormHelperText-contained': {
          margin: 0,
        },
      }}
      fullWidth={fullWidth}
      label={label}
      placeholder={placeholder}
      variant={variant ?? 'standard'}
      size="small"
      inputProps={{
        maxLength: 255,
        style: { textAlign: align, ...customStyle },
      }}
      disabled={disabled}
      error={!!errorMessage}
      helperText={errorMessage}
      inputRef={ref}
      type={type}
      onBlur={handleBlur}
    />
  )
}
