import React from 'react'
import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded'

import * as routes from '@tk/frontend/app/routes'
import { When } from '@tk/frontend/primitives'
import { MenuButton, MenuItem } from '@tk/frontend/primitives/menu'

export function RecordDesignerMenu() {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(routes.app.recordDesigner.start)
  }

  return (
    <When flag="recordDesigner" can="record-projects.read">
      <MenuButton label="Record Projects" icon={<BusinessCenterRoundedIcon />}>
        <MenuItem startIcon={<Icons.Add />} onClick={handleNavigate}>
          Open Project
        </MenuItem>
      </MenuButton>
    </When>
  )
}
