import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'

import { MutationModal } from './MutationModal'
import { Instrument } from './types'

export type Props = {
  data: Instrument
}

export function InstrumentActions(props: Props) {
  const modal = useModal(MutationModal)

  return (
    <ActionButtonGroup>
      <Button
        startIcon={<Icons.Edit />}
        onClick={() => {
          modal.show({
            type: 'instrument',
            data: props.data,
          })
        }}
      >
        Edit
      </Button>
    </ActionButtonGroup>
  )
}
