import { FieldValues, UseControllerProps } from 'react-hook-form'

export type Rules = UseControllerProps<FieldValues>['rules']

export const getRules = () => {
  const Required: Rules = Object.freeze({
    required: {
      value: true,
      message: 'Required',
    },
  })

  return {
    required: Required,
  }
}
