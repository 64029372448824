import React, { useMemo } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { Inputs, trpc, useUtils } from '@tk/frontend/api'
import {
  getFormComponents,
  usePromiseNotification,
} from '@tk/frontend/primitives'

import { ApiRole } from './types'

type ExtendedCreateFormValues = Inputs['admin']['userAdmin']['create'] & {
  pgsRole: string
  picardRole: string
  entitlementsRole: string
  extractaRole: string
  admin: boolean
}

const Form = getFormComponents<ExtendedCreateFormValues>()

export type CreateFormProps = { onFinished?: () => void }

export function CreateForm({ onFinished }: Readonly<CreateFormProps>) {
  const form = Form.useForm({
    defaultValues: {
      username: '',
      password: '',
      description: '',
      enabled: true,
      apiRoles: [],
      pgsRole: '',
      picardRole: '',
      extractaRole: '',
      entitlementsRole: '',
      admin: false,
    },
  })

  const { handleSubmit: _handleSubmit, watch, setValue } = form
  const progress = usePromiseNotification()
  const mutation = useCreateMutation()

  const handleSubmit = useMemo(() => {
    return _handleSubmit(
      async (values) => {
        const apiRoles: ApiRole[] = []

        if (values.admin) {
          apiRoles.push(ApiRole.ADMIN)
        }

        if (values.pgsRole) {
          apiRoles.push(
            ApiRole[`PGS_${values.pgsRole}` as keyof typeof ApiRole]
          )
        }

        if (values.picardRole) {
          apiRoles.push(
            ApiRole[`PICARD_${values.picardRole}` as keyof typeof ApiRole]
          )
        }

        if (values.extractaRole) {
          apiRoles.push(
            ApiRole[`EXTRACTA_${values.extractaRole}` as keyof typeof ApiRole]
          )
        }

        if (values.entitlementsRole) {
          apiRoles.push(
            ApiRole[
              `ENTITLEMENTS_${values.entitlementsRole}` as keyof typeof ApiRole
            ]
          )
        }

        const {
          pgsRole,
          picardRole,
          extractaRole,
          entitlementsRole,
          admin,
          ...rest
        } = values
        const promise = mutation.mutateAsync({ ...rest, apiRoles })
        await progress(promise, {
          progressMessage: 'Creating',
          successMessage: 'Created',
          failureMessage: 'Error creating',
        })
        onFinished?.()
      },
      (err) => {
        console.error(err)
      }
    )
  }, [_handleSubmit, mutation, onFinished, progress])

  return (
    <Form.Provider {...form}>
      <Stack width="30rem" component="form" onSubmit={handleSubmit} spacing={2}>
        <Form.Field
          label="Username (Required)"
          name="username"
          input={<Form.TextField autoFocus />}
        />
        <Form.Field
          label="Password (Required)"
          name="password"
          input={<Form.TextField type="password" />}
        />
        <Form.Field
          label="Description"
          name="description"
          input={<Form.TextField />}
        />
        <Box display="flex" justifyContent="space-between" ml="30%" mr="30%">
          <FormControlLabel
            control={
              <Checkbox
                checked={watch('enabled')}
                onChange={(e) => setValue('enabled', e.target.checked)}
              />
            }
            label="Enabled"
            sx={{ flex: 1, justifyContent: 'center' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={watch('admin')}
                onChange={(e) => setValue('admin', e.target.checked)}
              />
            }
            label="Admin"
            sx={{ flex: 1, justifyContent: 'center' }}
          />
        </Box>

        <Form.Field
          label="PGS Role"
          name="pgsRole"
          input={
            <FormControl fullWidth size="small">
              <Select
                value={watch('pgsRole') || ''}
                onChange={(e) => setValue('pgsRole', e.target.value)}
                displayEmpty
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="READ">READ</MenuItem>
                <MenuItem value="MANAGE">MANAGE</MenuItem>
              </Select>
            </FormControl>
          }
        />
        <Form.Field
          label="Picard Role"
          name="picardRole"
          input={
            <FormControl fullWidth size="small">
              <Select
                value={watch('picardRole') || ''}
                onChange={(e) => setValue('picardRole', e.target.value)}
                displayEmpty
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="READ">READ</MenuItem>
                <MenuItem value="MANAGE">MANAGE</MenuItem>
              </Select>
            </FormControl>
          }
        />
        <Form.Field
          label="Extracta Role"
          name="extractaRole"
          input={
            <FormControl fullWidth size="small">
              <Select
                value={watch('extractaRole') || ''}
                onChange={(e) => setValue('extractaRole', e.target.value)}
                displayEmpty
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="READ">READ</MenuItem>
                <MenuItem value="MANAGE">MANAGE</MenuItem>
              </Select>
            </FormControl>
          }
        />
        <Form.Field
          label="Entitlements Role"
          name="entitlementsRole"
          input={
            <FormControl fullWidth size="small">
              <Select
                value={watch('entitlementsRole') || ''}
                onChange={(e) => setValue('entitlementsRole', e.target.value)}
                displayEmpty
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="READ">READ</MenuItem>
                <MenuItem value="MANAGE">MANAGE</MenuItem>
              </Select>
            </FormControl>
          }
        />
        <Form.Row>
          <Button type="submit">Create</Button>
        </Form.Row>
      </Stack>
    </Form.Provider>
  )
}

function useCreateMutation() {
  const utils = useUtils()
  const { useMutation } = trpc.admin.userAdmin.create

  return useMutation({
    onSuccess() {
      utils.admin.userAdmin.list.invalidate()
    },
  })
}

export const CreateModal = create(() => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>New Api User</DialogTitle>
      <DialogContent>
        <CreateForm onFinished={modal.hide} />
      </DialogContent>
    </Dialog>
  )
})

export function ApiUserCreateButton() {
  const modal = useModal(CreateModal)

  return (
    <Button variant="contained" color="primary" onClick={() => modal.show()}>
      Create API User
    </Button>
  )
}
