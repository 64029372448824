import { useNavigate } from 'react-router-dom'

import * as Icons from '@mui/icons-material'

import { Inputs, trpc } from '@tk/frontend/api'
import { ConfirmPopover, useSimplifiedNumber } from '@tk/frontend/primitives'
import { MenuItem } from '@tk/frontend/primitives/menu'
import { telemetry } from '@tk/frontend/telemetry'

import { generateExportListUri } from './Exports.routes'

type Params = Inputs['records']['export']['start']

export type ExportButtonProps = {
  count: number
  params: Params
}

export function ExportButton({ count, params }: ExportButtonProps) {
  const num = useSimplifiedNumber(count)

  const startExport = trpc.records.export.start.useMutation()
  const navigate = useNavigate()

  return (
    <ConfirmPopover
      prompt={`This will start an export of ${count} rows. Are you sure you want to continue?`}
      onOk={async () => {
        const { taskId } = await startExport.mutateAsync(params)

        telemetry.capture('gtk/record-export/start', { exportId: taskId })
        navigate(generateExportListUri(taskId))
      }}
    >
      <MenuItem startIcon={<Icons.Download />}>Export {num}</MenuItem>
    </ConfirmPopover>
  )
}
