import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { trpc, useUtils } from '@tk/frontend/api'
import { NamedBusinessObjectForm } from '@tk/frontend/primitives/datagrid/tree'
import {
  NamedBusinessObjectCore,
  useReferenceDataCrudForm,
} from '@tk/frontend/telemetry/reference-data-crud'

import { AssetClass } from './types'

type AssetClassModel = {
  type: 'assetClass'
  data: Partial<AssetClass>
}

export const MutationModal = create<AssetClassModel>((model) => {
  const modal = useModal()

  const title = !model.data.id ? 'New Asset Class' : 'Editing Asset Class'

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <NamedBusinessObjectForm
          initialValues={model.data}
          useMutation={useAssetClassMutation}
          onFinished={modal.hide}
          useTelemetry={useTelemetry}
        />
      </DialogContent>
    </Dialog>
  )
})

function useAssetClassMutation(isExisting: boolean) {
  const utils = useUtils()

  const { useMutation } = isExisting
    ? trpc.reference.assetClass.update
    : trpc.reference.assetClass.create

  return useMutation({
    onSuccess() {
      utils.reference.assetClass.invalidate()
    },
  })
}

function useTelemetry(values: Partial<NamedBusinessObjectCore>) {
  return useReferenceDataCrudForm('asset-class', values)
}
