import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { useSimpleImportContext } from './SimpleImportContext'
import { ImportStateColour, ImportStateLabel } from './types'
import { useImportHasAnyError } from './useImportHasAnyError'

export type ImportPreviewSummaryProps = {
  taskId: number
}

export function ImportPreviewSummary({ taskId }: ImportPreviewSummaryProps) {
  const { route } = useSimpleImportContext()

  const [task] = route.status.useSuspenseQuery(
    { taskId },
    {
      refetchInterval(query) {
        return query.state.data.state === 'PERSISTING' ? 1500 : false
      },
    }
  )

  const numErrors = useImportHasAnyError(taskId)

  let state = task.state
  if (numErrors > 0) {
    state = 'FAILED'
  }

  return (
    <TableContainer component={Paper} sx={{ padding: '0.5rem', paddingTop: 0 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Task ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Records</TableCell>
            <TableCell>Error</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell width={300}>{taskId}</TableCell>

            <TableCell width={150}>
              <Typography
                fontSize="inherit"
                fontWeight="bold"
                color={ImportStateColour[state]}
              >
                {ImportStateLabel[state]}
              </Typography>
            </TableCell>

            <TableCell width={100}>
              {task.processedCount} / {task.totalCount}
            </TableCell>

            <TableCell>
              <Typography
                fontSize="inherit"
                fontWeight="bold"
                color={ImportStateColour['FAILED']}
              >
                {numErrors > 0 ? `${numErrors} rows have problems ` : ''}
                {task.errorMessage}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
