import { CircularProgress, Typography } from '@mui/material'
import { DateTime } from 'luxon'

import { useProjectCrud } from './useProjectCrud'

export function SaveStatus() {
  const { project, projectMutation } = useProjectCrud()

  return (
    <>
      {projectMutation.isPending ? (
        <Typography variant="body1" color="GrayText">
          Saving... <CircularProgress size="1.5rem" thickness={10} />
        </Typography>
      ) : (
        <Typography variant="body1" color="GrayText">
          Updated {DateTime.fromJSDate(project.updatedAt).toRelative()} by{' '}
          {project.updatedBy}
        </Typography>
      )}
    </>
  )
}
