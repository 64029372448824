import { Stack, Typography } from '@mui/material'

export function AppIcon() {
  return (
    <div
      style={{
        minHeight: '2rem',
        minWidth: '2rem',
        maxHeight: '2rem',
        maxWidth: '2rem',
        overflow: 'hidden',
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M186.415 116.364H325.818V162.909H279.273V326.051C279.273 338.618 289.746 349.091 302.313 349.091H325.818V395.636H302.313C263.913 395.636 232.727 364.451 232.727 326.051V162.909H186.182L186.415 116.364Z"
          fill="#676767"
        />
        <path
          d="M186.415 93.0909V116.364L186.182 186.182C186.182 211.782 165.469 232.727 139.636 232.727H69.8182C44.2182 232.727 23.2727 212.015 23.2727 186.182V93.0909C23.2727 67.4909 44.2182 46.5454 69.8182 46.5454H139.869C165.469 46.5454 186.415 67.2581 186.415 93.0909Z"
          fill="#2A7FEF"
        />
        <path
          d="M488.96 93.0909V116.364L488.727 186.182C488.727 211.782 468.015 232.727 442.182 232.727H372.364C346.764 232.727 325.818 212.015 325.818 186.182V93.0909C325.818 67.4909 346.764 46.5454 372.364 46.5454H442.415C468.015 46.5454 488.96 67.2581 488.96 93.0909Z"
          fill="#D84A00"
        />
        <path
          d="M488.96 325.818V349.091L488.727 418.909C488.727 444.509 468.015 465.455 442.182 465.455H372.364C346.764 465.455 325.818 444.742 325.818 418.909V325.818C325.818 300.218 346.764 279.273 372.364 279.273H442.415C468.015 279.273 488.96 299.985 488.96 325.818Z"
          fill="#D84A00"
        />
      </svg>
    </div>
  )
}

export function AppHeader() {
  const host = window.location.host

  const isProd = host.includes('dhb-prd')

  let appName = 'Parameta'
  if (!isProd) {
    if (host.includes('dhb-npd')) {
      appName = 'NON-PROD'
    }
    if (host.includes('localhost')) {
      appName = 'LOCAL DEV'
    }
  }

  return (
    <Stack direction="row" alignItems="center" display="flex">
      <AppIcon />

      <Stack spacing={0} flex="0 1 auto">
        <Typography
          fontWeight="700"
          lineHeight={1.2}
          color={(theme) =>
            isProd ? theme.palette.secondary.light : theme.palette.warning.main
          }
          fontSize="1rem"
        >
          {appName}
        </Typography>
        <Typography
          fontWeight="500"
          color={(theme) => theme.palette.primary.main}
          lineHeight={1.2}
          fontSize="0.8rem"
        >
          Toolkit
        </Typography>
      </Stack>
    </Stack>
  )
}
