import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  GridRowModes,
} from '@mui/x-data-grid-premium'

import { AllPossibleKeys } from '@lib/utils'
import { Outputs, trpc, useUtils } from '@tk/frontend/api'
import { useEntitlement } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  defaultProps,
  GridEnrichedColDef,
  useEditingProps,
  useRowModes,
} from '@tk/frontend/primitives/datagrid'
import { NameDateCell } from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'
import { useClientFilter } from '@tk/frontend/primitives/datagrid/useClientFilter'

import { MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS } from './type'

type AttributeType = Outputs['multibrand']['attribute']['get']

export function AttributeCrudTable() {
  const utils = useUtils()

  const { rowModes, setEditMode, setViewMode } = useRowModes<AttributeType>()

  const mutator = trpc.multibrand.attribute.update.useMutation({
    onSuccess() {
      utils.multibrand.attribute.invalidate()
    },
  })

  const [list] = trpc.multibrand.attribute.list.useSuspenseQuery()

  const editable = useEntitlement('reference-data.manage')

  const cols = useMemo<GridEnrichedColDef<AttributeType>[]>(() => {
    const columns: GridEnrichedColDef<AttributeType>[] = [
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
      },
      {
        type: 'singleSelect',
        field: 'type',
        headerName: 'Type',
        valueOptions: MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS,
        width: 150,
        editable: editable,
        valueFormatter(params) {
          return MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS.find(
            (t) => t.value === params.value
          )?.label
        },
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1,
        editable: editable,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
        editable: editable,
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: 'actions',
        headerName: '',
        width: 170,
        cellClassName: 'actions',
        align: 'right',
        getActions: ({ id, row, columns }) => {
          const isInEditMode = rowModes[id]?.mode === GridRowModes.Edit

          function handleEditClick() {
            const columnToFocus = getFocusColumn(columns)
            setEditMode(id, columnToFocus)
          }

          function handleSaveClick() {
            setViewMode(id, 'save')
          }
          function handleCancelClick() {
            setViewMode(id, 'ignore')
          }

          if (isInEditMode) {
            return [
              <ActionButtonGroup key="table-in-edit-mode">
                <Button
                  startIcon={<Icons.Save />}
                  onClick={handleSaveClick}
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  startIcon={<Icons.Cancel />}
                  onClick={handleCancelClick}
                  color="warning"
                >
                  Cancel
                </Button>
              </ActionButtonGroup>,
            ]
          } else {
            return [
              <ActionButtonGroup key={'table-not-in-edit-mode'}>
                <Button
                  startIcon={<Icons.Edit />}
                  onClick={handleEditClick}
                  color="primary"
                >
                  Edit
                </Button>
              </ActionButtonGroup>,
            ]
          }
        },
      },
    ]
    return columns.map((col) => {
      col.filterable ??= true
      col.sortable ??= true
      setColumnDefaults(col)
      return col
    })
  }, [editable, rowModes, setEditMode, setViewMode])

  const editingProps = useEditingProps<AttributeType>({
    rowModes,
    setEditMode,
    setViewMode,
    async onUpdateNeeded(row, oldRow) {
      return await mutator.mutateAsync(row)
    },
    fieldToFocus: getFocusColumn(cols),
  })

  const { filteredList, filterValue, setFilterValue } = useClientFilter(list)

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <TextField
        value={filterValue ?? ''}
        onChange={(e) => setFilterValue(e.target.value)}
        label="Filter"
        variant="filled"
        inputProps={{
          role: 'search',
        }}
        placeholder='You may use wildcards (%) to search for partial Name and Description. ie. "%ICAP%"'
        fullWidth
      />

      <Box flex="1 1 0" minHeight={0} minWidth={0}>
        <DataGridPremium
          {...defaultProps}
          rows={filteredList}
          columns={cols}
          density="compact"
          initialState={{
            sorting: {
              sortModel: [
                { field: 'type', sort: 'asc' },
                { field: 'name', sort: 'asc' },
              ],
            },
          }}
          {...editingProps}
        />
      </Box>
    </Box>
  )
}

function getFocusColumn(columns: GridColDef[]) {
  const columnToFocus =
    columns.find((column) => column.editable)?.field ?? 'name'

  return columnToFocus as AllPossibleKeys<AttributeType>
}
