import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { create, useModal } from '@parameta/nice-modal-react'

import { trpc, useUtils } from '@tk/frontend/api'
import { useBloombergMappingCreateTelemetry } from '@tk/frontend/app/Bloomberg/telemetry'
import {
  NamedBusinessObjectForm,
  NamedBusinessObjectFormProps,
} from '@tk/frontend/primitives/datagrid/tree'

type Model = {}

export const CreateModal = create<Model>((model) => {
  const modal = useModal()

  return (
    <Dialog open={modal.visible} onClose={modal.hide}>
      <DialogTitle>New Pricing Source</DialogTitle>
      <DialogContent>
        <NamedBusinessObjectForm
          initialValues={{}}
          useMutation={useBackboneMutation}
          onFinished={modal.hide}
          useTelemetry={useTelemetry}
        />
      </DialogContent>
    </Dialog>
  )
})

function useBackboneMutation() {
  const utils = useUtils()

  const { useMutation } = trpc.bloomberg.pricingSource.upsert

  return useMutation({
    onSuccess() {
      utils.bloomberg.pricingSource.invalidate()
    },
  })
}

const useTelemetry: NamedBusinessObjectFormProps['useTelemetry'] = (values) => {
  const { trackCreateSuccess, trackCreateValidationError } =
    useBloombergMappingCreateTelemetry('bloomberg/pricing-sources')

  return {
    trackEditSave: (dirty, dto) => trackCreateSuccess(dto),
    trackEditValidationError: trackCreateValidationError,
  }
}
