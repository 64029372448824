import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColDef,
  GridRowModes,
  GridRowsProp,
} from '@mui/x-data-grid-premium'

import { AllPossibleKeys } from '@lib/utils'
import { ConfirmPopover } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  defaultProps,
  GridEnrichedColDef,
  useEditingProps,
  useRowModes,
} from '@tk/frontend/primitives/datagrid'
import { NameDateCell } from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'

import {
  Editable,
  EditableDefaults,
  NamedBusinessObject,
  NamedBusinessObjectUpdate,
} from './types'

// TODO: validate edit results

export type NamedBusinessObjectTableProps<
  T extends NamedBusinessObject = NamedBusinessObject
> = {
  list: T[]
  onEditStart: (dto: T) => void
  onEditSave: (
    // TODO: probably base this on T instead?
    update: NamedBusinessObjectUpdate
  ) => Promise<T>
  onDelete?: (dto: T) => void
  editable?: Editable | false
  extraColumns?: GridEnrichedColDef<T>[] | undefined
  initialSortField?: keyof T
  gridProps?: Omit<Partial<DataGridPremiumProps<T>>, 'rows' | 'columns'>
  clientSideData?: boolean
  packageActions?: GridEnrichedColDef<T>[] | undefined
}

export function NamedBusinessObjectTable<
  T extends NamedBusinessObject = NamedBusinessObject
>({
  list,
  onEditStart,
  onEditSave,
  onDelete,
  editable = EditableDefaults,
  extraColumns,
  initialSortField = 'name',
  gridProps = {},
  clientSideData = false,
  packageActions,
}: NamedBusinessObjectTableProps<T>) {
  const { rowModes, setEditMode, setViewMode } =
    useRowModes<NamedBusinessObject>()

  // FIXME: GridEnrichedColDef<NamedBusinessObject> should be GridEnrichedColDef<T> but Typescript loses the ability to pull the keys from the type.
  // FIXME: For now we just use the base-type and cast to T before passing back out of this abstraction
  const cols = useMemo<GridEnrichedColDef<NamedBusinessObject>[]>(() => {
    const columns: GridEnrichedColDef<NamedBusinessObject>[] = [
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1,
        editable: editable ? editable['name'] : false,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
        editable: editable ? editable['description'] : false,
      },
      ...((extraColumns ?? []) as any[]),
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          const { createdBy, createdDate } = params.row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          const { modifiedBy, modifiedDate } = params.row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: 'actions',
        headerName: '',
        width: 170,
        cellClassName: 'actions',
        align: 'right',
        getActions: ({ id, row, columns }) => {
          if (editable === false) {
            return []
          }

          const isInEditMode = rowModes[id]?.mode === GridRowModes.Edit

          function handleEditClick() {
            const columnToFocus = getFocusColumn(columns)
            setEditMode(id, columnToFocus)

            onEditStart?.(row as T)
          }

          function handleSaveClick() {
            setViewMode(id, 'save')
          }
          function handleCancelClick() {
            setViewMode(id, 'ignore')
          }

          if (isInEditMode) {
            return [
              <ActionButtonGroup key="table-in-edit-mode">
                <Button
                  startIcon={<Icons.Save />}
                  onClick={handleSaveClick}
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  startIcon={<Icons.Cancel />}
                  onClick={handleCancelClick}
                  color="warning"
                >
                  Cancel
                </Button>
              </ActionButtonGroup>,
            ]
          } else {
            return [
              <ActionButtonGroup key={'table-not-in-edit-mode'}>
                <Button
                  startIcon={<Icons.Edit />}
                  onClick={handleEditClick}
                  color="primary"
                >
                  Edit
                </Button>

                {!!onDelete && (
                  <ConfirmPopover
                    onOk={() => onDelete(row as T)}
                    prompt="Will be permanently deleted, continue?"
                  >
                    <Button startIcon={<Icons.Delete />} color="warning">
                      Delete
                    </Button>
                  </ConfirmPopover>
                )}
              </ActionButtonGroup>,
            ]
          }
        },
      },
      ...((packageActions ?? []) as any[]),
    ]

    return columns.map((col) => {
      col.filterable ??= clientSideData
      col.sortable ??= clientSideData
      setColumnDefaults(col)
      return col
    })
  }, [
    clientSideData,
    editable,
    extraColumns,
    onDelete,
    onEditStart,
    packageActions,
    rowModes,
    setEditMode,
    setViewMode,
  ])

  const rows: GridRowsProp<T> = list

  const editingProps = useEditingProps<NamedBusinessObject>({
    rowModes,
    setEditMode,
    setViewMode,
    async onUpdateNeeded(row, oldRow) {
      return await onEditSave(row)
    },
    fieldToFocus: getFocusColumn(cols),
  })

  return (
    <Stack flex="1 1 0" minHeight={0} minWidth={0}>
      <DataGridPremium
        {...defaultProps}
        {...(gridProps as any)}
        rows={rows}
        columns={cols}
        density="compact"
        initialState={{
          sorting: {
            sortModel: [{ field: initialSortField as any, sort: 'asc' }],
          },
        }}
        {...editingProps}
      />
    </Stack>
  )
}

function getFocusColumn(columns: GridColDef[]) {
  const columnToFocus =
    columns.find((column) => column.editable)?.field ?? 'name'

  return columnToFocus as AllPossibleKeys<NamedBusinessObject>
}
