import { z } from 'zod'

import { IsoDate } from './common'

export const ExportStatus = z.union([
  z.literal('NEW'),
  z.literal('GENERATING_EXPORT'),
  z.literal('COMPLETED'),
  z.literal('FAILED'),
  z.literal('CANCELLED'),
])

export const ExportStateDtoCore = {
  taskId: z.string().default(''),
  state: ExportStatus.default('NEW'),
  totalCount: z.number().default(0),
  processedCount: z.number().default(0),
  errorMessage: z.string().optional(),
  submittedAt: IsoDate.default(new Date(0)),
  submittedBy: z.string().default(''),
}
