import { ReactNode } from 'react'
import { NavLink, To } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'

import { telemetry } from '@tk/frontend/telemetry'

export type NavSectionProps = {
  title: string
  children: ReactNode
}
export function NavSection({ title, children }: NavSectionProps) {
  return (
    <div data-testid="sidebar-section" data-title={title}>
      <Divider />

      <Box data-testid="sidebar-section-title" padding="1rem 0 0 1rem">
        <Typography variant="subtitle2">{title}</Typography>
      </Box>

      <List data-testid="sidebar-section-links" role="menubar">
        {children}
      </List>
    </div>
  )
}

export type NavButtonProps = {
  label: string
  path: To
  icon?: ReactNode
  disabled?: boolean
  end?: boolean
}
export function NavButton({
  label,
  icon = <Icons.FiberManualRecordOutlined />,
  disabled = false,
  path,
  end,
}: NavButtonProps) {
  const theme = useTheme()

  return (
    <ListItem disablePadding>
      <NavLink
        to={path}
        end={end}
        style={{
          width: '100%',
          textDecoration: 'none',
          color: 'inherit',
          cursor: disabled ? 'default' : undefined,
        }}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault()
          }

          telemetry.capture('gtk/nav-button-click', {
            route: path.toString() ?? label,
          })
        }}
        data-testid="sidebar-nav-button"
        role="menuitem"
        aria-disabled={disabled || !path}
      >
        {({ isActive }) => {
          return (
            <ListItemButton
              color="primary"
              sx={{
                paddingY: '0.25rem',
              }}
              style={{
                backgroundColor: isActive
                  ? theme.palette.primary.main
                  : undefined,
                color: isActive
                  ? theme.palette.primary.contrastText
                  : undefined,
              }}
              disabled={disabled || !path}
              component="div"
            >
              <ListItemIcon
                sx={{
                  minWidth: '1.5rem',
                  maxWidth: '1.5rem',
                  marginRight: '10px',
                  color: 'inherit',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {icon}
              </ListItemIcon>

              <ListItemText
                primary={label}
                data-testid="sidebar-nav-button-text"
              />
            </ListItemButton>
          )
        }}
      </NavLink>
    </ListItem>
  )
}
