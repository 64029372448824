import { generatePath } from 'react-router-dom'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import * as routes from '@tk/frontend/app/routes'
import { ButtonLink } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'

import { MutationModal } from './MutationModal'
import { AssetClass } from './types'

export type Props = {
  data: AssetClass
}

export function AssetClassActions(props: Props) {
  const modal = useModal(MutationModal)

  return (
    <ActionButtonGroup>
      <ButtonLink
        color="primary"
        variant="text"
        startIcon={<Icons.Add />}
        to={generatePath(routes.app.referenceData.assetTypes.create, {
          id: String(props.data.id),
        })}
      >
        Add
      </ButtonLink>

      <Button
        startIcon={<Icons.Edit />}
        onClick={() => {
          modal.show({
            type: 'assetClass',
            data: props.data,
          })
        }}
      >
        Edit
      </Button>
    </ActionButtonGroup>
  )
}
