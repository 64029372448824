import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Outputs, trpc, useUtils } from '@tk/frontend/api'

export type Project = Outputs['recordProjects']['get']
export type ProjectDocument = Project['document']

export function useProjectCrud() {
  const { projectName } = useParams() as { projectName: string }
  const utils = useUtils()

  const invalidateProject = useCallback(() => {
    utils.recordProjects.invalidate()
  }, [utils.recordProjects])

  const optimisticUpdate = useCallback(
    (project: Project) => {
      utils.recordProjects.get.setData(
        {
          name: projectName,
        },
        project
      )
    },
    [projectName, utils]
  )

  const [project] = trpc.recordProjects.get.useSuspenseQuery({
    name: projectName,
  })
  const updateMutation = trpc.recordProjects.save.useMutation({
    onSuccess(data, variables, context) {
      invalidateProject()
    },
  })

  const updateProject = useCallback(
    async (next: Partial<ProjectDocument>) => {
      const current = utils.recordProjects.get.getData({
        name: projectName,
      })

      if (!current) {
        return
      }

      // We merge updates locally in case of multiple updates in close succession
      const document: ProjectDocument = {
        ...current.document,
        ...next,
      }

      optimisticUpdate({
        ...current,
        document,
      })

      await updateMutation.mutateAsync({
        name: projectName,
        document,
      })
    },

    [optimisticUpdate, projectName, updateMutation, utils.recordProjects.get]
  )

  return {
    projectName,
    project,
    projectMutation: updateMutation,
    updateProject,
    invalidateProject,
    optimisticUpdate,
  }
}
