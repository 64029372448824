import * as Icons from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

import { InfoPopover } from '@tk/frontend/primitives/InfoPopover'

export function PopoverCell({ text }: { text: string | undefined }) {
  if (!text || text.trim() === '') {
    return null
  }

  return (
    <InfoPopover
      colour="inherit"
      contentContainer={<Stack direction="column" padding="0.5rem 0.75rem" />}
      icon={
        <Icons.Launch
          fontSize="inherit"
          color="primary"
          style={{ marginLeft: '0.15rem' }}
        />
      }
      iconPosition="suffix"
      content={
        <Box maxWidth="40ch" fontSize="13px">
          {text}
        </Box>
      }
      stackProps={{
        sx: {
          'width': '100%',
          'justifyContent': 'space-between',
          '& > p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
      }}
    >
      <Typography variant="body2">{text}</Typography>
    </InfoPopover>
  )
}
